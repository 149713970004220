import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { AlertService } from 'src/app/Services/alert.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { LanguageService } from 'src/app/Services/language.service';
import { SUCCESS_KEY, TR_RESET_PASSWORD_KEY, TR_RESET_PASSWORD_SUCCESS_KEY } from 'src/app/Constants/main-keys';
import { ACCESS_TO_NEW_PASSWORD_PATH_KEY, UNAUTH_NEW_PASSWORD_PATH_KEY } from 'src/app/Constants/request-path-keys';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  isInvalidLength: boolean;
  isInvalidSubmit: boolean;
  password: string;
  confirmPassword: string;
  guid: string;
  errorMessage: string;
  form: FormGroup;
  isPageLoaded: boolean;
  currentYear: any;

  constructor(
    private dataHandlerService: DataHandlerService,
    private route: ActivatedRoute,
    private router: Router,
    private languageService: LanguageService,
    private translationService: TranslationService,
    private formBuilder: FormBuilder,
    private alertService: AlertService
  ) { 
    this.currentYear = new Date().getFullYear()
  }

  ngOnInit() {
    this.translationService.setPageTitle(TR_RESET_PASSWORD_KEY);
    

    this.form = this.formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      resetPasswordKey: ['', Validators.required]
    });

    this.route.paramMap.subscribe(params => {
      this.guid = params.get('guid');
      this.guid && this.form.patchValue({ resetPasswordKey: this.guid });
      this.dataHandlerService.getDataWithSpinner(`${ACCESS_TO_NEW_PASSWORD_PATH_KEY}/${this.guid}`).then(() => {
        this.isPageLoaded = true;
      });
    });
  }

  resetPassword(): void {
    if (this.form.valid) {
      if (this.form.value.password !== this.form.value.confirmPassword || !this.form.value.password.trim() || !this.form.value.confirmPassword.trim()) {
        this.isInvalidSubmit = true;
      } else if (this.form.value.password.length < 6 || this.form.value.password.length > 100) {
        this.isInvalidLength = true;
      } else {
        this.isInvalidSubmit = false;
        const form = { ...this.form.value };
        form.password = btoa(form.password);
        form.confirmPassword = btoa(form.confirmPassword);

        this.dataHandlerService.updateData(form, UNAUTH_NEW_PASSWORD_PATH_KEY, null).then(() => {
          this.alertService.translateAndAlertMessage(TR_RESET_PASSWORD_SUCCESS_KEY, SUCCESS_KEY);
          this.router.navigate([this.languageService.currentLanguage]);
        })
      }
    } else {
      this.isInvalidSubmit = true;
    }
  }
}
