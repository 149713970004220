import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { AlertService } from 'src/app/Services/alert.service';
import { LanguageService } from 'src/app/Services/language.service';
import { ILanguageData, ILanguageTranslationData, ITranslationData } from 'src/app/Models/ILanguageData';
import { ISO_CODE_PATH_PARAM_KEY, LANGUAGES_PATH_KEY, TRANSLATIONS_PATH_KEY, TYPE_PARAM_KEY } from 'src/app/Constants/request-path-keys';
import {
  SUCCESS_KEY,
  TRANSLATION_CREATE,
  TRANSLATION_DELETE,
  TRANSLATION_TYPES_KEY,
  TRANSLATION_UPDATE,
  TRANSLATION_VIEW,
  TR_DELETE_MESSAGE_KEY,
  TR_TRANSLATIONS_KEY,
  TR_UPDATE_MESSAGE_KEY,
} from 'src/app/Constants/main-keys';
import { guid } from '@progress/kendo-angular-common';
import { MainService } from 'src/app/Services/main.service';

declare let ClipboardJS: any;

@Component({
  selector: 'app-translations',
  templateUrl: './translations.component.html',
  styleUrls: ['./translations.component.scss']
})
export class TranslationsComponent implements OnInit {
  translation_view: boolean;
  translation_create: boolean;
  translation_update: boolean;
  translation_delete: boolean;
  selectedTabId: string;
  deleteTranslationId: number;
  deleteTranslationName: string;
  selectedTypeName: string;
  selectedFormTypeName: string;
  searchValue: string;
  englishTranslation: string;
  updateSpinnerId: string = guid();
  deleteSpinnerId: string = guid();
  form: FormGroup;
  userRoles: Array<string> = [];
  languages: Array<ILanguageTranslationData> = [];
  searchLanguages: Array<ILanguageTranslationData> = [];
  translationTypes: Array<string> = TRANSLATION_TYPES_KEY;

  constructor(
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    private translationService: TranslationService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private languageService: LanguageService,
    private mainService: MainService,
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_TRANSLATIONS_KEY);

    this.form = this.formBuilder.group({
      id: [null, Validators.required],
      languageId: [null, Validators.required],
      shortKey: ['', [Validators.required, Validators.pattern("^[a-zA-Z_]*$")]],
      translation1: ['', Validators.required],
      type: ['', Validators.required],
    });

    this.getLanguages();

    new ClipboardJS('#btnCopy');
  }

  setRoles(): void {
    this.userRoles.forEach(role => {
      switch (role) {
        case TRANSLATION_VIEW:
          this.translation_view = true;
          break;
        case TRANSLATION_CREATE:
          this.translation_create = true;
          break;
        case TRANSLATION_UPDATE:
          this.translation_update = true;
          break;
        case TRANSLATION_DELETE:
          this.translation_delete = true;
          break;
      }
    });
  }

  getLanguages() {
    this.dataHandlerService.getData(LANGUAGES_PATH_KEY).then(response => {
      // this.languages = response;
      this.languages = response.splice(0, 2);
      this.selectedTabId = this.languageService.currentLanguage;
      this.getTranslationsData();
    });
  }

  getTranslationsData(): void {
    if (!this.translation_view) { return; }
    this.selectedTypeName = '';
    this.languages.forEach(item => {
      this.getTranslationsByLanguage(item.isocode2).then(response => {
        item.content = response;
        this.searchValue && item.languageId === this.languages[this.languages.length - 1].languageId && this.filter();
      });
    });

    this.searchLanguages = [...this.languages];
  }

  getTranslationsByLanguage(languageCode: string): Promise<Array<ITranslationData>> {
    return (new Promise((resolve, reject) => {
      this.dataHandlerService.getData(`${TRANSLATIONS_PATH_KEY}?${ISO_CODE_PATH_PARAM_KEY}${languageCode}`).then(response => {
        resolve(response.reverse());
      });
    }));
  }

  setSelectedType(name: string, index: number): void {
    if (name !== this.selectedTypeName) {
      this.selectedTypeName = name;
      this.languages.forEach(language => {
        this.dataHandlerService.getData(`${TRANSLATIONS_PATH_KEY}?${ISO_CODE_PATH_PARAM_KEY}${language.isocode2}&${TYPE_PARAM_KEY}${index}`).then(response => {
          language.content = response.reverse();
        });
      });
      this.searchLanguages = [...this.languages];
    }
  }

  setSelectedFormType(name: string, index: number): void {
    this.selectedFormTypeName = name;
    this.form.patchValue({ type: index });
  }

  confirmDelete(content: any, id: number, name: string): void {
    this.deleteTranslationId = id;
    this.deleteTranslationName = name;
    this.modalService.open(content).result;
  }

  delete(): void {
    if (!this.translation_delete) { return; }
    this.dataHandlerService.deleteData(this.deleteTranslationId, TRANSLATIONS_PATH_KEY, this.deleteSpinnerId).then(() => {
      this.alertService.translateAndAlertMessage(TR_DELETE_MESSAGE_KEY, SUCCESS_KEY);
      this.getTranslationsData();
      this.modalService.dismissAll();
    });
  }

  // filter(): void {
  //   const searchValue = this.searchValue.toLowerCase()
  //   this.languages.forEach((language, languageIndex) => {
  //     const languageContent = [...language.content];
  //     const searchLanguage = { ...this.searchLanguages[languageIndex] };
  //     searchLanguage.content = [...languageContent.filter(item => {
  //       // const shortKey = item.shortKey.toLowerCase();
  //       // const translation = item.translation1.toLowerCase();
  //       if (item.shortKey.toLowerCase().includes(searchValue)) {
  //         return { ...item };
  //       }
  //       if (item.translation1.toLowerCase().includes(searchValue)) {
  //         return { ...item };
  //       }
  //     })];
  //     this.searchLanguages[languageIndex] = searchLanguage;
  //   });
  // }

  filter(): void {
    this.languages.forEach((language, languageIndex) => {
      const languageContent = [...language.content];
      const searchLanguage = { ...this.searchLanguages[languageIndex] };
      searchLanguage.content = [...languageContent.filter(item => {
        const shortKey = item.shortKey.toLowerCase();
        const translation = item.translation1.toLowerCase();
        const searchValue = this.searchValue.toLowerCase()
        if (shortKey.includes(searchValue)) {
          return { ...item };
        }
        if (translation.includes(searchValue)) {
          return { ...item };
        }
      })];
      this.searchLanguages[languageIndex] = searchLanguage;
    });
  }

  update(langaugeId: number | null, translation: ITranslationData): void {
    this.form.patchValue({
      id: translation.id,
      languageId: langaugeId,
      translation1: translation.translation1,
      shortKey: translation.shortKey,
      type: translation.type,
    });
    this.selectedFormTypeName = this.translationTypes[translation.type];
    this.getEnglishTranslation(translation.id);
  }

  cancelUpdate(): void {
    this.form.patchValue({
      id: null,
      languageId: null,
      translation1: '',
      type: null,
    });
    this.selectedFormTypeName = '';
  }

  submitUpdate(): void {
    if(this.form.valid) {
      const form = { ...this.form.value };
      const selectedLanguage: ILanguageData = this.languages.find(data => data.languageId === form.languageId);
      if(selectedLanguage) {
        this.selectedTabId = selectedLanguage.isocode2;
      }
      this.dataHandlerService.updateData(form, TRANSLATIONS_PATH_KEY, this.updateSpinnerId).then(() => {
        this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
        this.getTranslationsData();
        this.cancelUpdate();
      });
    }
  }

  create(content: any): void {
    this.modalService.open(content).result;
  }

  createTranslation() {
    this.getTranslationsData();
    this.closeModal();
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  beforeChange($event) {
    this.selectedTabId = $event.nextId;
    this.cancelUpdate();
  }

  removeTranslationText() {
    this.form.patchValue({ translation1: '' });
  }

  getEnglishTranslation(id) {
    this.englishTranslation = this.languages.find(data => data.isocode2 === 'en')?.content.find(data => data.id === id)?.translation1;
  }

}
