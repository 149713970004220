<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon"><i class="vsm-icon pe-7s-user icon-gradient bg-plum-plate"></i></div>
      <div>{{ 'TR_PROFILE' | translate }}
      </div>
    </div>
  </div>
</div>
<!-- <div class="title-block mb-3">
  <h4 class="card-title">{{ 'TR_PROFILE' | translate }}</h4>
</div> -->

<div class="row">
  <div class="col-lg-10 col-md-12 col-12">
    <div class="main-card card mb-2">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-3">
            <div *ngIf="uploadedImagePath" [ngStyle]="{ 'background-image' : 'url(' + uploadedImagePath + ')' }"
              class="user-avatar"></div>
          </div>
          <div class="col-lg-9 col-md-8 col-sm-6 col-12 mt-1">
            <div class="user-data">
              <h5>{{ user.lastname }} {{ user.firstname }} {{ user.middlename }}</h5>
              <p>{{ ('TR_EMAIL' | translate) + '-' + user.email }}</p>
              <p>{{ ('TR_USERNAME' | translate) + '-' + user.username }}</p>
              <p>{{ user.addressResidence }}</p>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-3">
            <div class="avatar-actions">
              <button *ngIf="!uploadedImage" type="button" class="btn-transition btn btn-outline-info upload"
                (click)="avatar.click()">{{ 'TR_UPLOAD' | translate }}</button>
              <input #avatar hidden type="file" name="upload-avatar"
                (change)="handleImageUpload($event.target.files)" />
              <button *ngIf="uploadedImage" type="button" class="btn-transition btn btn-outline-info mr-2"
                (click)="upload()">{{ 'TR_SAVE' | translate }}</button>
              <button *ngIf="uploadedImage" type="button" class="btn-transition btn btn-outline-dark"
                (click)="cancel()">{{ 'TR_CANCEL' | translate }}</button>
            </div>
          </div>
          <div class="col-lg-9 col-md-8 col-sm-6 col-12">
            <div class="actions">
              <button type="button" class="btn-transition btn btn-outline-dark"
                (click)="changePassword(changepassword)">{{ 'TR_CHANGE_PASSWORD' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #changepassword let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title">{{ 'TR_CHANGE_PASSWORD' | translate }}</h5>
    <button type="button" class="close pb-0" aria-label="Close" (click)="c('close')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form" (submit)="submitData()">
      <div class="mb-4">
        <label for="password">{{ ('TR_ENTER_PASSWORD' | translate) + mainService.asterisk }}</label>
        <input name="password" [placeholder]="('TR_ENTER_PASSWORD' | translate) + mainService.asterisk" type="password"
          class="form-control"
          [class.error-border]="(isInvalidSubmit && !form.value.password) || (isInvalidSubmit && form.controls['password'].hasError('pattern'))"
          autocomplete="new-password" formControlName="password" />
        <small class="red" *ngIf="isInvalidSubmit && form.controls['password'].hasError('pattern')">
          {{ 'TR_PASSWORD_CHARACTERS_MIN_MAX_ERROR' | translate }}
        </small>
      </div>
      <div class="mb-4">
        <label for="confirmPassword">{{ ('TR_ENTER_CONFIRM_PASSWORD' | translate) + mainService.asterisk }}</label>
        <input name="confirmPassword" [placeholder]="('TR_ENTER_CONFIRM_PASSWORD' | translate) + mainService.asterisk"
          type="password" class="form-control"
          [class.error-border]="(isInvalidSubmit && !form.value.confirmPassword) || (isInvalidSubmit && form.controls['confirmPassword'].hasError('pattern'))"
          autocomplete="new-password" formControlName="confirmPassword" />
        <small class="red" *ngIf="isInvalidSubmit && form.controls['confirmPassword'].hasError('pattern')">
          {{ 'TR_PASSWORD_CHARACTERS_MIN_MAX_ERROR' | translate }}
        </small>
        <small class="red" *ngIf="notEqualError">
          {{ 'TR_CHECK_PASSWORD' | translate }}
        </small>
      </div>
      <button type="submit"
        class="btn-transition btn btn-outline-info float-right">{{ 'TR_SUBMIT' | translate }}</button>
    </form>
  </div>
</ng-template>