import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { guid } from '@progress/kendo-angular-common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslationService } from 'src/app/Services/translation.service';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { RolesService } from 'src/app/Services/roles.service';
import { MainService } from 'src/app/Services/main.service';
import { AlertService } from 'src/app/Services/alert.service';
import { ILanguageData } from 'src/app/Models/ILanguageData';
import { IOrganizationData } from 'src/app/Models/IOrganizationData';
import { IUserData, IUserTypeData } from 'src/app/Models/IUserData';
import {
  LIBRARY_VIEW,
  ORGANIZATION_VIEW,
  SUCCESS_KEY,
  TIME_ZONE_KEY,
  USERTYPE_VIEW,
  USER_CREATE,
  USER_UPDATE,
  USER_VIEW,
  TR_UPDATE_MESSAGE_KEY,
  TR_CREATE_MESSAGE_KEY,
  TR_CREATEUSER_KEY,
  TR_UPDATEUSER_KEY
} from '../../../Constants/main-keys';
import {
  LANGUAGE_PATH_KEY,
  USER_TYPE_PATH_KEY,
  LIBRARY_BY_DISPLAY_NAME_PATH_KEY,
  USERS_PATH_KEY,
  USERS_URL_KEY,
  ORGANIZATIONS_PATH_KEY,
  USERS_FILTER_PATH_KEY
} from 'src/app/Constants/request-path-keys';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {
  form: FormGroup;
  languages: Array<ILanguageData> = [];
  timeZoneData: Array<any> = [];
  userTypes: Array<IUserTypeData> = [];
  organizations: Array<IOrganizationData> = [];
  languagesObs: BehaviorSubject<Array<ILanguageData>> = new BehaviorSubject<Array<ILanguageData>>(null);
  uploadedAvatar: any;
  uploadedAvatarPath: any;
  userId: number;
  isCreateForm: boolean = true;
  selectedOrganizationId: number;
  selectedOrganizationName: string;
  selectedUserTypeId: number;
  selectedUserTypeName: string;
  selectedTimeZone: string;
  selectedLanguageId: number;
  selectedLanguageName: string;
  avatarPath: string;
  users: Array<IUserData> = [];
  user_view: boolean;
  user_create: boolean;
  user_update: boolean;
  organization_view: boolean;
  usertype_view: boolean;
  library_view: boolean;
  userRoles: Array<string> = [];
  isInvalidSubmit: boolean;
  isInvalidFirstname: boolean;
  isInvalidLastname: boolean;
  isInvalidDob: boolean;
  isInvalidPhonenumber: boolean;
  submitSpinnerId: string = guid();
  today: String = new Date().toISOString().split('T')[0];
  filterData = {
    username: '',
    email: '',
    phone: '',
    mobilePhone: '',
  };
  searchText: string = "";
  defaultTimeZones: Array<any> = [];

  constructor(
    private formBuilder: FormBuilder,
    private dataHandlerService: DataHandlerService,
    private translationService: TranslationService,
    private route: ActivatedRoute,
    public mainService: MainService,
    private rolesService: RolesService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_CREATEUSER_KEY);

    this.form = this.formBuilder.group({
      id: [null],
      username: [''],
      password: [''],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      middlename: [''],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$")],
      mobilePhone: ['', Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$")],
      dob: [''],
      addressResidence: [''],
      addressRegistration: [''],
      image: [''],
      userTypeId: [null, Validators.required],
      organizationId: [null],
      createDate: [null],
      isUserId: [null],
      tziidDisplayname: [null],
      defaultLanguageId: [null],
      sendEmail: [true],
      sendNotification: [true]
    });

    this.getLanguages();
    this.getTimeZoneData();
    this.getUserTypes();
    this.getOrganizations();

    this.route.paramMap.subscribe(params => {
      this.userId = +params.get('item.id');

      if (this.userId) {
        this.isCreateForm = false;
        this.translationService.setPageTitle(TR_UPDATEUSER_KEY);
        this.getUserDataById(this.userId);
      }
    });
  }

  setRoles() {
    this.userRoles.forEach(item => {
      switch (item) {
        case USER_CREATE:
          this.user_create = true;
          break;
        case USER_UPDATE:
          this.user_update = true;
          break;
        case USERTYPE_VIEW:
          this.usertype_view = true;
          break;
        case USER_VIEW:
          this.user_view = true;
          break;
        case LIBRARY_VIEW:
          this.library_view = true;
          break;
        case ORGANIZATION_VIEW:
          this.organization_view = true;
          break;
      }
    });
  }

  async getOrganizations() {
    if (!this.organization_view) { return; }
    this.organizations = await this.dataHandlerService.getDataWithSpinner(ORGANIZATIONS_PATH_KEY);
  }

  async getTimeZoneData() {
    if (!this.library_view) { return; }
    this.dataHandlerService.getDataWithSpinner(`librarydata/time_zones`).then(response => {
      if (response.timezones) {
        this.timeZoneData = [...response.timezones];
        this.defaultTimeZones =  [...response.timezones]
      }
      if (response.defaulttimezone) {
        var selectedTimeZoneName = response.defaulttimezone.name;
        this.selectedTimeZone = selectedTimeZoneName;
        this.form.patchValue({ selectedTimeZoneName });
      }
    })
  }

  getUserTypes() {
    if (!this.usertype_view) { return; }
    this.dataHandlerService.getDataWithSpinner(USER_TYPE_PATH_KEY).then(response => {
      this.userTypes = response;
      this.userTypes.forEach(userType => {
        this.translationService.translateUserTypes(userType.displayName).subscribe(translatedUserTypeName => {
          if (translatedUserTypeName) {
            userType.displayName = translatedUserTypeName;
          }
        });
      });
    });
  }

  getLanguages() {
    this.dataHandlerService.getDataWithSpinner(LANGUAGE_PATH_KEY).then(response => {
      this.languages = response.splice(0, 2);
      this.languagesObs.next(this.languages);
    });
  }


  searchTimeZone(event: any): void {
    var searchValue = event.target.value;
    if (searchValue && searchValue.trim().length) {
      var a = this.defaultTimeZones.filter(timeZoneDataCopyItem => timeZoneDataCopyItem.name.toLowerCase().includes(searchValue.toLowerCase()));
      this.timeZoneData = a;
    } else {
      this.timeZoneData = this.defaultTimeZones;
    }
  }

  getUserDataById(userId: number): void {
    if (!this.user_view) { return; }
    this.dataHandlerService.getDataWithSpinner(`${USERS_PATH_KEY}/${userId}`).then(response => {
      const user: IUserData = response;
      this.selectedOrganizationName = user.organizationName;
      this.translationService.translateUserTypes(user.userTypeDisplayName).subscribe(data => this.selectedUserTypeName = data);
      this.selectedOrganizationId = user.organizationId;
      this.selectedUserTypeId = user.userTypeId;
      this.uploadedAvatarPath = user.avatarFilePath;
      this.selectedTimeZone = user.tziidDisplayname;
      this.selectedLanguageId = user.defaultLanguageId;

      if (this.languages && this.languages.length) {
        const selectedLanguage: ILanguageData = this.languages.find(data => data.languageId === this.selectedLanguageId);
        if (selectedLanguage) {
          this.selectedLanguageName = selectedLanguage.name;
        }
      }

      this.filterData = {
        username: user.username,
        email: user.email,
        phone: user.phone,
        mobilePhone: user.mobilePhone,
      };

      this.form.patchValue({
        username: user.username,
        firstname: user.firstname,
        lastname: user.lastname,
        middlename: user.middlename,
        email: user.email,
        phone: user.phone,
        mobilePhone: user.mobilePhone,
        dob: user.dob ? this.mainService.getDateFormat(new Date(user.dob)) : "",
        addressResidence: user.addressResidence,
        addressRegistration: user.addressRegistration,
        createDate: user.createDate,
        id: user.id,
        isUserId: user.isUserId,
        image: this.uploadedAvatarPath,
        tziidDisplayname: user.tziidDisplayname,
        sendEmail: user.sendEmail,
        sendNotification: user.sendNotification,
        userTypeId: user.userTypeId
      });

      this.form.get('username').disable();
      this.form.get('password').disable();
    });
  }

  getUserData(data: IUserData): void {
    if (data) {
      this.userId = data.id;
      this.getUserDataById(data.id);
    }
  }

  setSelectedOrganization(id: number, name: string): void {
    this.selectedOrganizationId = id;
    this.selectedOrganizationName = name;
    this.form.patchValue({ organizationId: id });
  }

  setSelectedUserType(id: number, displayName: string): void {
    this.selectedUserTypeId = id;
    this.selectedUserTypeName = displayName;
    this.form.patchValue({ userTypeId: id });
  }

  setSelectedTimeZone(name: string): void {
    this.selectedTimeZone = name;
    this.form.patchValue({ tziidDisplayname: name });
  }

  setSelectedLanguage(languageId: number, name: string): void {
    this.selectedLanguageName = name;
    this.selectedLanguageId = languageId;
    this.form.patchValue({ defaultLanguageId: languageId });
  }

  async filter(value: string, key: string) {
    if (!this.user_view) { return; }
    this.filterData[key] = value;
    this.users = await this.dataHandlerService.createData(this.filterData, USERS_FILTER_PATH_KEY, null);
  }

  async handleFileInput(files: FileList) {
    if (files && files[0]) {
      this.form.patchValue({ image: files[0] });
      this.uploadedAvatarPath = await this.mainService.imageUpload(files[0]);
    }
  }

  checkFieldIsValid(event: any, fieldName: string): void {
    if (fieldName === "firstname") {
      const firstNameValue = event.target.value;
      if (!firstNameValue.trim().length || firstNameValue.trim().length < 2 || firstNameValue.trim().length >= 100) {
        this.isInvalidFirstname = true;
        return;
      } else {
        const isValidFirstname = this.mainService.isValidField(firstNameValue, "firstname");
        if (!isValidFirstname) {
          this.isInvalidFirstname = true;
          return;
        } else {
          this.isInvalidFirstname = false;
        }
      }
    }
    if (fieldName === "lastname") {
      const lastNameValue = event.target.value;
      if (!lastNameValue.trim().length || lastNameValue.trim().length < 2 || lastNameValue.trim().length >= 100) {
        this.isInvalidLastname = true;
        return;
      } else {
        const isValidLastname = this.mainService.isValidField(lastNameValue, "lastname");
        if (!isValidLastname) {
          this.isInvalidLastname = true;
          return;
        } else {
          this.isInvalidLastname = false;
        }
      }
    }
    if (fieldName === "phonenumber" && event.target.value.length) {
      if (event.target.value.length) {
        const isValidPhoneNumber = this.mainService.isValidField(event.target.value, "phonenumber");
        if (!isValidPhoneNumber) {
          this.isInvalidPhonenumber = true;
          return;
        } else {
          this.isInvalidPhonenumber = false;
        }
      } else {
        this.isInvalidPhonenumber = false;
      }
    }
  }

  checkDates(event: any): void {
    //firstDate > secondDate ? true : false
    const firstDate = event.target.value;
    if (firstDate) {
      const isValidDate = this.mainService.checkDatesMoreOrEqual(firstDate, this.today);
      if (isValidDate) {
        this.isInvalidDob = false;
      } else {
        this.isInvalidDob = true;
      }
    } else {
      this.isInvalidDob = false;
    }
  }

  submitData(): void {
    if (this.form.valid && !this.isInvalidFirstname && !this.isInvalidLastname && !this.isInvalidPhonenumber && !this.isInvalidDob) {
      const form = { ...this.form.value };
      if (this.userId && this.user_update) {
        form.username = this.form.get('username').value.trim();
        form.firstname = this.form.get('firstname').value.trim();
        form.lastname = this.form.get('lastname').value.trim();
        form.middlename = this.form.get('middlename').value && this.form.get('middlename').value.trim();
        form.password = this.form.get('password').value && this.form.get('password').value.trim();
        form.organizationId = this.selectedOrganizationId;
        let formData: any = new FormData();
        for (let i in form) {
          form[i] && i !== 'password' && formData.append(`${i}`, form[i]);
        }
        this.dataHandlerService.updateFormData(formData, USERS_PATH_KEY, this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
          this.mainService.goBack(USERS_URL_KEY);
        });

      } else if (this.user_create) {
        let formData: any = new FormData();
        for (let i in form) {
          if (form[i]) {
            if (i === 'password') {
              form[i] = btoa(form[i]);
            }
            formData.append(`${i}`, form[i]);
          }
        }
        this.dataHandlerService.createFormData(formData, USERS_PATH_KEY, this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
          this.mainService.goBack(USERS_URL_KEY);
        });
      }
    } else {
      this.isInvalidSubmit = true;
    }
  }

  generatePassword() {
    const randomPassword = Math.random().toString(36).slice(-8);
    this.form.patchValue({ password: randomPassword });
  }
}
