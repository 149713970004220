import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  readonly _userRoles$ = new BehaviorSubject<any>(null);

  get userRoles() {
    return this._userRoles$.getValue();
  }

  setRoles(roles: Array<string>): void {
    this._userRoles$.next(roles);
  }
}