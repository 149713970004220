<div class="main-card mb-3 card">
  <div class="card-body">
    <div class="title-block mb-3">
      <i class="fa fa-fw" [title]="'TR_GOBACK' | translate" (click)="goBack()"></i>
      <h5 class="card-title">{{ isCreateForm ? 'Create file library' : 'Update file library' }}</h5>
    </div>
    <form (submit)="submitData()" [formGroup]="form">
      <div class="form-row">
        <div *ngIf="file_library_view" class="col-12" ngbDropdown>
          <button
            type="button"
            ngbDropdownToggle
            class="mb-2 mr-2 btn btn-outline-primary"
            [class.error-border]="isInvalidSubmit && !form.value.categoryId"
            >
             {{ selectedFolderName ? selectedFolderName : 'TR_FOLDER' | translate }}*
            </button>
          <div ngbDropdownMenu>
            <button class="dropdown-item" *ngFor="let item of folders" (click)="selectParentFolder(item.name, item.id)" type="button">
              {{ item.name }}
            </button>
          </div>
        </div>
        <div class="col-lg-6 col-md-8">
          <div class="position-relative form-group">
            <label for="name">{{ 'TR_NAME' | translate }}*</label>
            <input
              type="text"
              name="name"
              [placeholder]="'TR_NAME' | translate"
              [class.error-border]="(isInvalidSubmit && (!form.value.name || (form.value.name && !form.value.name.trim()))) || (form.value.name.trim().length && form.value.name.trim().length > nameMaxLength)"
              class="form-control"
              autocomplete="off"
              formControlName="name" 
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-lg-3 col-md-4 col-12">
          <div class="mt-3 mb-3 upload-block form-outline">
            <div class="mb-2">
              <button
                type="button"
                class="btn-transition btn btn-warning upload-btn"
                [class.error-border]="isInvalidSubmit && !form.value.fileEntity"
                (click)="file.click()"
              >
                <i class="pe-7s-plus"></i>
                <p>{{ 'TR_UPLOAD_FILE' | translate }}</p>
              </button>
              <input #file hidden type="file" name="upload-avatar" (change)="handleFileUpload($event)" />
            </div>
            <video *ngIf="isVideo && uploadedFile" [src]="uploadedFile" class="upload-avatar" controls></video>
            <div *ngIf="isImage && uploadedFile" [ngStyle]="{ 'background-image' : 'url(' + uploadedFile + ')' }"
              class="upload-avatar"></div>
            <p *ngIf="isFile && uploadedFile" class="uploaded-file">{{ form.value.fileEntity.name }}</p>
          </div>
        </div>
      </div>
      <app-submit-button [spinnerId]="submitSpinnerId"
        [buttonName]="isCreateForm ? ('TR_CREATE' | translate) : ('TR_UPDATE' | translate)"
        [isInvalidSubmit]="isInvalidSubmit" [isFormValid]="form.valid">
      </app-submit-button>
    </form>
  </div>
</div>