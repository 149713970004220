<div class="title-block mb-3">
  <h4 class="card-title">{{ 'TR_TEMPLATES' | translate }}</h4>
</div>

<div *ngIf="template_create" class="d-inline-block">
  <button class="mb-2 mr-2 btn btn-success create-btn" title="Create template" [routerLink]="['template-form']">
    <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_CREATE' | translate }}</span>
  </button>
</div>

<div class="main-card mb-3 card">
  <div class="table-responsive">
    <table class="align-middle mb-0 table table-striped table-hover">
      <thead>
        <tr>
          <th>#</th>
          <th>{{ 'TR_NAME' | translate }}</th>
          <th>{{ 'TR_DESCRIPTION' | translate }}</th>
          <th>{{ 'TR_CONTENT' | translate }}</th>
          <th *ngIf="template_update" class="text-right">{{ 'TR_ACTIONS' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of templates let i = index">
          <td>{{ i + 1 }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.description }}</td>
          <td>{{ item.body }}</td>
          <td *ngIf="template_update" class="text-right">
            <div role="group" class="btn-group-sm btn-group">
              <button class="btn-transition btn btn-outline-info" title="Edit template"
                [routerLink]="['template-form', item.id]">
                <i class="pe-7s-note"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>