import { Component, Input, OnInit, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { delay, startWith, tap } from 'rxjs/operators';
import { HelpAlertDirective } from 'src/app/Directives/help-alert.directive';
import { MainService } from 'src/app/Services/main.service';
import { HelpAlertViewComponent } from '../help-alert-view/help-alert-view.component';
import { IHelpResponseData } from 'src/app/Models/IHelpAlertData';

@Component({
  selector: 'app-help-alert',
  templateUrl: './help-alert.component.html',
  styleUrls: ['./help-alert.component.scss']
})
export class HelpAlertComponent implements OnInit {
  @Input() alertKey: string;
  @ViewChild(HelpAlertDirective, { static: true }) adHost: HelpAlertDirective;

  alertData: IHelpResponseData;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private mainService: MainService
  ) { }

  ngOnInit(): void {
    this.mainService.helpData.pipe(
      startWith(null),
      delay(0),
      tap(helpAlerts => {
        const viewContainerRef = this.adHost.viewContainerRef;
        if (helpAlerts?.length) {
          // this.alertData = helpAlerts.find(data => data.name == this.alertKey);
          this.alertData = helpAlerts.find(data => data.name == this.alertKey);
          if (this.alertData) {
            this.alertData.timeout = setTimeout(() => this.loadComponent(this.alertData), this.alertData.startTime * 1000);
            setTimeout(() => { viewContainerRef.clear() }, (this.alertData.startTime + this.alertData.seconds) * 1000);
          }
        } else {
          viewContainerRef.clear();
          clearTimeout(this.alertData?.timeout);
        }
      })
    ).subscribe();
  }

  loadComponent(alertData: any) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(HelpAlertViewComponent);
    const viewContainerRef = this.adHost.viewContainerRef;
    const componentRef = viewContainerRef.createComponent<any>(componentFactory);

    const parentElementPosition = this.getPosition(this.adHost.elementRef.nativeElement.parentElement.parentElement);
    parentElementPosition['offsetWidth'] = this.adHost.elementRef.nativeElement.parentElement.parentElement.offsetWidth;
    parentElementPosition['offsetHeight'] = this.adHost.elementRef.nativeElement.parentElement.parentElement.offsetHeight;

    componentRef.instance.data = alertData;
    componentRef.instance.containerPosition = parentElementPosition;
  }

  getPosition(element: any) {
    let offsetLeft = 0;
    let offsetTop = 0;

    while (element) {
      offsetLeft += element.offsetLeft;
      offsetTop += element.offsetTop;
      element = element.offsetParent;
    }
    return { offsetTop: offsetTop, offsetLeft: offsetLeft };
  }

}
