import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IPageData } from 'src/app/Models/IPageData';
import { IUserTypeData } from 'src/app/Models/IUserData';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { AlertService } from 'src/app/Services/alert.service';
import {
  PAGE_CREATE,
  PAGE_UPDATE,
  PAGE_VIEW,
  SUCCESS_KEY,
  TR_ADMIN_PAGES_KEY,
  TR_CREATE_MESSAGE_KEY,
  TR_PAGES_KEY,
  TR_SITE_PAGES_KEY,
  PAGE_DELETE,
  TR_DELETE_MESSAGE_KEY
} from 'src/app/Constants/main-keys';
import {
  ENABLE_PATH_PARAM_KEY,
  ID_PATH_PARAM_KEY,
  IS_FRONT_END_PAGE_PATH_PARAM_KEY,
  MENU_GROUPS_FOR_PAGES_PATH_KEY,
  MENU_GROUP_ID_PATH_PARAM_KEY,
  PAGE_ENABLE_PATH_KEY,
  PAGE_GET_PATH_KEY
} from 'src/app/Constants/request-path-keys';
import { guid } from '@progress/kendo-angular-common';

@Component({
  selector: 'app-pages-table',
  templateUrl: './pages-table.component.html',
  styleUrls: ['./pages-table.component.scss']
})
export class PagesTableComponent implements OnInit {
  pageId: number;
  selectedPageCategoryName: string;
  selectedMenuGroupName: string;
  isDefaultPage: boolean;
  isFrontEndPages: boolean = true;
  menuGroups: Array<any> = [];;
  pages: Array<IPageData> = [];
  userTypesData: Array<IUserTypeData> = [];
  userRoles: Array<string> = [];
  pageCategories = [{ name: TR_SITE_PAGES_KEY }, { name: TR_ADMIN_PAGES_KEY }];
  page_view: boolean;
  page_create: boolean;
  page_update: boolean;
  page_delete: boolean;
  deletePageName: string;
  deletePageId: number;
  deleteSpinnerId: string = guid();

  constructor(
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    private translationService: TranslationService,
    private alertService: AlertService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.pageCategories.forEach(item => {
      this.translationService.translate(item.name).pipe(
        map((data: string) => item.name = data)
      ).subscribe();
    });

    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_PAGES_KEY);

    this.getPagesByType(true);
    this.getMenuGroups();
  }

  setRoles() {
    this.userRoles.forEach(role => {
      switch (role) {
        case PAGE_VIEW:
          this.page_view = true;
          break;
        case PAGE_CREATE:
          this.page_create = true;
          break;
        case PAGE_UPDATE:
          this.page_update = true;
          break;
        case PAGE_DELETE:
          this.page_delete = true;
          break;
      }
    });
  }

  async getMenuGroups() {
    if (!this.page_view) { return; }
    this.menuGroups = await this.dataHandlerService.getData(MENU_GROUPS_FOR_PAGES_PATH_KEY);
  }

  getPagesByType(hasSpinner: boolean): void {
    if (this.page_view) {
      (hasSpinner
        ? this.dataHandlerService.getData(`${PAGE_GET_PATH_KEY}?${IS_FRONT_END_PAGE_PATH_PARAM_KEY}${this.isFrontEndPages}`)
        : this.dataHandlerService.getDataWithSpinner(`${PAGE_GET_PATH_KEY}?${IS_FRONT_END_PAGE_PATH_PARAM_KEY}${this.isFrontEndPages}`)).then(response => {
          this.pages = response;
          this.getAllPages(this.pages);
          // this.pages.forEach(page => {
          //   this.translationService.translatePages(page.name).subscribe(translatedPageName => {
          //     if (translatedPageName) {
          //       page.name = translatedPageName;
          //     }
          //     if (page.children.length) {
          //       page.children.forEach(children => {
          //         if (children.name) {
          //           this.translationService.translatePages(children.name).subscribe(translatedChildrenPageName => {
          //             if (translatedChildrenPageName) {
          //               children.name = translatedChildrenPageName;
          //             }
          //           });
          //         }
          //       });
          //     }
          //   });
          // });
        });
    }
  }

  getAllPages(allPages: any) {
    allPages.forEach(page => {
      this.translationService.translatePages(page.name).subscribe(translatedPageName => {
        if (translatedPageName) {
          page.name = translatedPageName;
        }
      })     
      if (page.children && page.children.length) {
        this.getAllPages(page.children);
      }
    });
  }

  setSelectedPageCategory(name: string): void {
    if (!name) { return; }
    this.isFrontEndPages = name === this.pageCategories[0].name;
    this.selectedPageCategoryName = name;
    this.getPagesByType(false);
  }

  setSelectedMenuGroup(id: number, name: string): void {
    if (!id) { return; }
    this.selectedMenuGroupName = name;
    if (this.page_view) {
      this.dataHandlerService.getData(
        `${PAGE_GET_PATH_KEY}?${MENU_GROUP_ID_PATH_PARAM_KEY}${id}&${IS_FRONT_END_PAGE_PATH_PARAM_KEY}${true}`,
        true
      ).then(response => {
        this.pages = response.data;
        this.getAllPages(this.pages);
        // this.pages.length && this.pages.forEach(page => {
        //   this.translationService.translatePages(page.name).subscribe(translatedPageName => {
        //     if (translatedPageName) {
        //       page.name = translatedPageName;
        //     }
        //     if (page.children.length) {
        //       page.children.forEach(children => {
        //         if (children.name) {
        //           this.translationService.translatePages(children.name).subscribe(translatedChildrenPageName => {
        //             if (translatedChildrenPageName) {
        //               children.name = translatedChildrenPageName;
        //             }
        //           });
        //         }
        //       });
        //     }
        //   });
        // });
      });
    }
  }

  confirmDelete(content: any, item: any): void {
    this.modalService.open(content).result;
    this.deletePageName = item.name;
    this.deletePageId = item.id;
  }

  delete(): void {
    if (!this.page_delete) { return; }
    this.dataHandlerService.deleteData(this.deletePageId, "page/delete", this.deleteSpinnerId).then(() => {
      this.alertService.translateAndAlertMessage(TR_DELETE_MESSAGE_KEY, SUCCESS_KEY);
      this.getPagesByType(true);
    });
    this.modalService.dismissAll();
  }


  enableDisable(id: number, val: boolean): void {
    const enabled = !val;
    if (this.page_view) {
      this.dataHandlerService.getData(`${PAGE_ENABLE_PATH_KEY}?${ID_PATH_PARAM_KEY}${id}&${ENABLE_PATH_PARAM_KEY}${enabled}`).then(() => {
        this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
        this.getPagesByType(false);
      });
    }
  }

}
