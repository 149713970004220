import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { guid } from '@progress/kendo-angular-common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IDocumentCategoryData, IDocumentData } from 'src/app/Models/IDocumentData';
import { ITemplateData } from 'src/app/Models/ITemplateData';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/Services/alert.service';
import { MainService } from 'src/app/Services/main.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { RolesService } from 'src/app/Services/roles.service';
import {
  CATEGORY_ID_PATH_PARAM_KEY,
  DOCUMENT_CATEGORY_PATH_KEY,
  DOCUMENT_TEMPLATE_PATH_KEY,
  DOCUMENT_URL_KEY,
  ID_PATH_PARAM_KEY,
  ORGANIZATION_DOCUMENT_PATH_KEY
} from 'src/app/Constants/request-path-keys';
import {
  DOCUMENTCATEGORY_VIEW,
  DOCUMENTTEMPLATE_VIEW,
  ORGANIZATIONDOCUMENT_CREATE,
  ORGANIZATIONDOCUMENT_UPDATE,
  ORGANIZATIONDOCUMENT_VIEW,
  SUCCESS_KEY,
  TR_CREATE_DOCUMENT_KEY,
  TR_CREATE_MESSAGE_KEY,
  TR_UPDATE_DOCUMENT_KEY,
  TR_UPDATE_MESSAGE_KEY
} from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-document-form',
  templateUrl: './document-form.component.html',
  styleUrls: ['./document-form.component.scss']
})
export class DocumentFormComponent implements OnInit {
  selectedFile: File;
  fileName: string;
  filePath: string;
  isCreateForm: boolean = true;
  submitSpinnerId: string = guid();
  form: FormGroup;
  documentId: number;
  openRTE: boolean;
  openTemplate: boolean;
  openFile: boolean;
  selectedTemplateId: number;
  selectedTemplateName: string;
  selectedDocumentCategoryId: number;
  selectedDocumentCategoryName: string;
  documentBodyData: string;
  selectedTemplateData: ITemplateData;
  documentUpdateData: IDocumentData;
  templates: Array<ITemplateData> = [];
  documentCategories: Array<IDocumentCategoryData> = [];
  userRoles: Array<string>;
  document_create: boolean;
  document_update: boolean;
  documentcategory_view: boolean;
  organizationdocument_view: boolean;
  documenttemplate_view: boolean;
  isInvalidSubmit: boolean;
  uploadedImagePath: any = null;
  nameMaxLength: number = 200;
  desriptionMaxLength: number = 1000;

  private templateData = new BehaviorSubject<Array<ITemplateData>>(null);
  private categoryData = new BehaviorSubject<Array<IDocumentCategoryData>>(null);
  templateObs = this.templateData.asObservable();
  categoryObs = this.categoryData.asObservable();

  constructor(
    private dataHandlerService: DataHandlerService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public mainService: MainService,
    private translationService: TranslationService,
    private rolesService: RolesService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_CREATE_DOCUMENT_KEY);

    this.form = this.formBuilder.group({
      id: [null],
      documentTemplateId: [null],
      documentCategoryId: [null, Validators.required],
      data: [''],
      name: ['', [Validators.required, Validators.pattern(`^[^&\v]*$`), Validators.maxLength(this.desriptionMaxLength)]],
      description: ['', Validators.maxLength(this.desriptionMaxLength)],
      documentFile: ['']
    });

    this.getDocumentCategories();

    this.route.paramMap.subscribe(params => {
      this.selectedDocumentCategoryId = +params.get('item.id');
      this.documentId = +params.get('item.id');
      this.selectedDocumentCategoryId && this.getCategoryById();

      if (this.documentId) {
        this.isCreateForm = false;
        this.translationService.setPageTitle(TR_UPDATE_DOCUMENT_KEY);
        this.getDocumentById(this.documentId);
      }
    });

    this.categoryObs.subscribe((data: Array<IDocumentCategoryData>) => {
      if (this.documentUpdateData && !this.selectedDocumentCategoryName) {
        this.selectedDocumentCategoryName = data.find(data => data.id === this.documentUpdateData.documentCategoryId).name;
      }
    });

    this.templateObs.subscribe((data: Array<ITemplateData>) => {
      if (this.documentUpdateData && !this.selectedTemplateName) {
        const templateData: ITemplateData = data.find(item => item.id === this.documentUpdateData.documentTemplateId);
        if (templateData) {
          this.openTemplate = true;
          this.selectedTemplateName = templateData.name;
          this.selectedTemplateData = templateData;
        }
      }
    });

    this.openRTE = !this.selectedTemplateId
  }

  setRoles() {
    this.userRoles.forEach(role => {
      switch (role) {
        case ORGANIZATIONDOCUMENT_CREATE:
          this.document_create = true;
          break;
        case ORGANIZATIONDOCUMENT_UPDATE:
          this.document_update = true;
          break;
        case DOCUMENTCATEGORY_VIEW:
          this.documentcategory_view = true;
          break;
        case ORGANIZATIONDOCUMENT_VIEW:
          this.organizationdocument_view = true;
          break;
        case DOCUMENTTEMPLATE_VIEW:
          this.documenttemplate_view = true;
          break;
      }
    });
  }

  getCategoryById() {
    if (!this.documentcategory_view) { return; }
    this.dataHandlerService.getData(`${DOCUMENT_CATEGORY_PATH_KEY}?${ID_PATH_PARAM_KEY}${this.selectedDocumentCategoryId}`).then(response => {
      if (response && response.length) {
        const categoryData: IDocumentCategoryData = response[0];
        this.selectedDocumentCategoryName = categoryData.name;
        this.getDocumentTemplatesByCategoryId(categoryData.id);
      }
    });
  }

  getDocumentById(id: number): void {
    if (!this.organizationdocument_view) { return; }
    this.dataHandlerService.getDataWithSpinner(`${ORGANIZATION_DOCUMENT_PATH_KEY}/${id}`).then(response => {

      const documentData: IDocumentData = response;
      this.documentUpdateData = documentData;
      this.form.patchValue({
        id: documentData.id,
        documentTemplateId: documentData.documentTemplateId,
        documentCategoryId: documentData.documentCategoryId,
        data: documentData.data,
        name: documentData.name,
        documentFile: documentData.filePath,
        description: documentData.description
      });
      this.selectedTemplateId = documentData.documentTemplateId;
      if (this.documentCategories.length) {
        this.selectedDocumentCategoryName = this.documentCategories.find(data => data.id === documentData.documentCategoryId).name;
      }
      this.getDocumentTemplatesByDocument(documentData);
      this.filePath = documentData.filePath;
      this.documentBodyData = documentData.data;
    });
  }

  getDocumentTemplatesByDocument(documentData: IDocumentData): void {
    if (!this.documenttemplate_view) { return; }
    this.dataHandlerService.getDataWithSpinner(`${DOCUMENT_TEMPLATE_PATH_KEY}?${CATEGORY_ID_PATH_PARAM_KEY}${documentData.documentCategoryId}`).then(response => {
      this.templates = response;
      this.templateData.next(this.templates);
      if (this.templates.length) {
        const templateData: ITemplateData = this.templates.find(data => data.id === documentData.documentTemplateId);
        if (templateData) {
          this.openTemplate = true;
          this.openRTE = false;
          this.selectedTemplateName = templateData.name;
          this.selectedTemplateData = templateData;
        }
      }
    });
  }

  getDocumentTemplatesByCategoryId(categoryId: number): void {
    if (categoryId && this.documenttemplate_view) {
      this.dataHandlerService.getData(`${DOCUMENT_TEMPLATE_PATH_KEY}?${CATEGORY_ID_PATH_PARAM_KEY}${categoryId}`).then(response => {
        this.templates = response;
        this.templateData.next(this.templates);
      });
    }
  }

  getDocumentCategories(): void {
    if (!this.documentcategory_view) { return; }
    this.dataHandlerService.getDataWithSpinner(DOCUMENT_CATEGORY_PATH_KEY).then(response => {
      this.documentCategories = response;
      this.categoryData.next(this.documentCategories);
    });
  }

  submitData(): void {
    if (this.selectedTemplateId) {
      this.form.value.documentTemplateId = this.selectedTemplateId;
      this.mainService.templateData.next(true);
    } else {
      this.submitDocumentData();
    }
  }

  getTemplateData(data: any): void {
    this.form.value.data = JSON.stringify(data);
    this.submitDocumentData();
  }

  submitDocumentData(): void {
    if (this.form.valid) {
      const form = { ...this.form.value };
      let formData: any = new FormData();
      for (let i in form) {
        !form[i] ? delete form[i] : formData.append(`${i}`, form[i]);
      }

      if (this.documentId && this.document_update) {
        this.dataHandlerService.updateFormData(formData, ORGANIZATION_DOCUMENT_PATH_KEY, this.submitSpinnerId).then(() => {
          this.mainService.templateData.next(null);
          this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
          this.mainService.goBack(DOCUMENT_URL_KEY);
        });
      } else if (this.document_create) {
        this.dataHandlerService.createFormData(formData, ORGANIZATION_DOCUMENT_PATH_KEY, this.submitSpinnerId).then(() => {
          this.mainService.templateData.next(null);
          this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
          this.mainService.goBack(DOCUMENT_URL_KEY);
        });
      }
    } else {
      this.isInvalidSubmit = true;
    }
  }

  async handleFileInput(files: FileList) {
    if (files[0]) {
      this.mainService.fileUpload(files[0]).then(() => {
        this.selectedFile = files[0];
        this.fileName = files[0].name;
        this.form.patchValue({ data: null, documentTemplateId: null });
        this.selectedTemplateName = null;
        this.selectedTemplateId = null;
        this.selectedTemplateData = null;
        this.openRTE = false;
        this.openTemplate = false;
        this.form.patchValue({ documentTemplateId: null, documentFile: this.selectedFile });
      });
      this.uploadedImagePath = await this.mainService.imageUpload(files[0]);
    }
    
  }

  removeFile(): void {
    this.selectedFile = null;
    this.fileName = null;
    this.openRTE = true;
    this.form.patchValue({ documentFile: null });
    this.uploadedImagePath = null;
  }

  setSelectedDocumentCategory(id: number, name: string): void {
    this.selectedDocumentCategoryId = id;
    this.selectedDocumentCategoryName = name;
    this.form.patchValue({ documentCategoryId: id });
    this.getDocumentTemplatesByCategoryId(this.selectedDocumentCategoryId);
  }

  setSelectedTemplate(templateData: ITemplateData): void {
    this.selectedTemplateData = templateData;
    this.selectedTemplateId = templateData.id;
    this.selectedTemplateName = templateData.name;
    this.openTemplate = true;
    this.openRTE = false;
    this.form.patchValue({ documentTemplateId: templateData.id })
  }

  closeTemplate(): void {
    this.openTemplate = false;
    this.openRTE = true;
    this.selectedTemplateId = null;
    this.selectedTemplateName = null;
    this.form.patchValue({ documentTemplateId: null });
  }

}
