<div class="main-card">
  <div class="">
    <form (submit)="submitData()" [formGroup]="form">
      <div class="form-row form-group">
        <div class="col-md-6 col-12">
          <label>{{ ('TR_LANGUAGE' | translate) + mainService.asterisk }}</label>
          <select
            type="select"
            id="languageId"
            name="languageId"
            class="custom-select"
            formControlName="languageId"
            [class.error-border]="isInvalidSubmit && !form.value.languageId"
          >
            <option *ngFor="let item of languages" [value]="item.languageId">{{ item.name }}</option>
          </select>
        </div>
        <div class="col-md-6 col-12">
          <label>{{ ('TR_ELEMENT_TYPE' | translate) + mainService.asterisk }}</label>
          <select
            type="select"
            id="type"
            name="type"
            class="custom-select"
            formControlName="type"
            [class.error-border]="isInvalidSubmit && !form.value.type"
          >
            <option *ngFor="let item of libraryTypes; index as i" [value]="i">{{ item }}</option>
          </select>
        </div>
      </div>
      <div class="form-row form-group">
        <div class="col-12">
          <label>{{ ('TR_PARENT' | translate) + mainService.asterisk }}</label>
          <select type="select" id="parentId" name="parentId" class="custom-select" formControlName="parentId">
            <option *ngFor="let item of libraries" [value]="item.id">{{ item.name }}</option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="col-12">
          <div class="position-relative form-group">
            <label for="name">{{ ('TR_NAME' | translate) + mainService.asterisk }}</label>
            <input
              type="text"
              name="name"
              autocomplete="off"
              class="form-control"
              [placeholder]="('TR_NAME' | translate) + mainService.asterisk"
              [class.error-border]="(isInvalidSubmit && !form.value.name)"
              formControlName="name"
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-12">
          <div class="position-relative form-group">
            <label for="displayString">{{ ('TR_DISPLAY_STRING' | translate) + mainService.asterisk }}</label>
            <input
              type="text"
              id="displayString"
              name="displayString"
              autocomplete="off"
              class="form-control"
              [class.error-border]="isInvalidSubmit && !form.value.displayString"
              [placeholder]="('TR_DISPLAY_STRING' | translate) + mainService.asterisk"
              value="form.value.displayString"
              formControlName="displayString"
            />
          </div>
        </div>
      </div>
      <app-submit-button
        [spinnerId]="submitSpinnerId"
        [buttonName]="library ? ('TR_UPDATE' | translate) : ('TR_CREATE' | translate)"
        [isInvalidSubmit]="isInvalidSubmit"
        [isFormValid]="form.valid"
        [isFloatRight]="true"
      ></app-submit-button>
    </form>
  </div>
</div>
<!-- <div *ngIf="!isModalLoaded" class="modal-body min-modal">
  <app-page-loader [isModalLoader]="true"></app-page-loader>
</div> -->