import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { guid } from '@progress/kendo-angular-common';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { RolesService } from 'src/app/Services/roles.service';
import { IOrganizationData } from 'src/app/Models/IOrganizationData';
import { ORGANIZATION_VIEW } from 'src/app/Constants/main-keys';
import { ORGANIZATIONS_PATH_KEY } from 'src/app/Constants/request-path-keys';

@Component({
  selector: 'app-organization-view',
  templateUrl: './organization-view.component.html',
  styleUrls: ['./organization-view.component.scss']
})
export class OrganizationViewComponent implements OnInit {
  @Input() organizationId: number;
  @Output() closeEmit = new EventEmitter();

  organization: IOrganizationData;
  userRoles: Array<string> = [];
  user_view: boolean;
  organization_view: boolean;
  organizationSpinnerId: string = guid();

  constructor(
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.organization_view = this.userRoles.includes(ORGANIZATION_VIEW);

    this.getOrganizationData();
  }

  getOrganizationData() {
    if (!this.organizationId || !this.organization_view) { return; }
    this.dataHandlerService.getDataWithModalSpinner(`${ORGANIZATIONS_PATH_KEY}/${this.organizationId}`, this.organizationSpinnerId)
      .then(response => this.organization = response);
  }
}
