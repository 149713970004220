import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { guid } from '@progress/kendo-angular-common';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { RolesService } from 'src/app/Services/roles.service';
import { AlertService } from 'src/app/Services/alert.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { MainService } from 'src/app/Services/main.service';
import { IDocumentCategoryData } from 'src/app/Models/IDocumentData';
import { DOCUMENT_CATEGORY_PATH_KEY } from 'src/app/Constants/request-path-keys';
import {
  DOCUMENTCATEGORY_CREATE,
  DOCUMENTCATEGORY_DELETE,
  DOCUMENTCATEGORY_UPDATE,
  DOCUMENTCATEGORY_VIEW,
  SUCCESS_KEY,
  TR_CREATE_MESSAGE_KEY,
  TR_DELETE_MESSAGE_KEY,
  TR_DOCUMENT_CATEGORY_KEY,
  TR_UPDATE_MESSAGE_KEY
} from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-document-category',
  templateUrl: './document-category.component.html',
  styleUrls: ['./document-category.component.scss']
})
export class DocumentCategoryComponent implements OnInit {
  documentcategory_view: boolean;
  documentcategory_create: boolean;
  documentcategory_update: boolean;
  documentcategory_delete: boolean;
  isInvalidSubmit: boolean;
  categoryId: number;
  deleteCategoryId: number;
  deleteCategoryName: string;
  deleteSpinnerId: string = guid();
  saveSpinnerId: string = guid();
  userRoles: Array<string> = [];
  documentCategories: Array<any> = [];
  categoryModel: IDocumentCategoryData = {
    id: null,
    name: '',
    description: ''
  };

  constructor(
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    private translationService: TranslationService,
    private modalService: NgbModal,
    public mainService: MainService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_DOCUMENT_CATEGORY_KEY);

    this.getDocumentCategories(true);
  }

  setRoles(): void {
    this.userRoles.forEach(role => {
      switch (role) {
        case DOCUMENTCATEGORY_VIEW:
          this.documentcategory_view = true;
          break;
        case DOCUMENTCATEGORY_CREATE:
          this.documentcategory_create = true;
          break;
        case DOCUMENTCATEGORY_UPDATE:
          this.documentcategory_update = true;
          break;
        case DOCUMENTCATEGORY_DELETE:
          this.documentcategory_delete = true;
          break;
      }
    });
  }

  getDocumentCategories(hasSpinner: boolean) {
    (hasSpinner
      ? this.dataHandlerService.getDataWithSpinner(DOCUMENT_CATEGORY_PATH_KEY)
      : this.dataHandlerService.getData(DOCUMENT_CATEGORY_PATH_KEY))
      .then(response => this.documentCategories = response);
  }

  update(data): void {
    this.categoryModel = data;
  }

  confirmDelete(content, id: number, name: string): void {
    this.modalService.open(content).result;
    this.deleteCategoryId = id;
    this.deleteCategoryName = name;
  }

  delete(): void {
    if (!this.documentcategory_delete || !this.deleteCategoryId) { return; }
    this.dataHandlerService.deleteData(this.deleteCategoryId, DOCUMENT_CATEGORY_PATH_KEY, this.deleteSpinnerId).then(() => {
      this.alertService.translateAndAlertMessage(TR_DELETE_MESSAGE_KEY, SUCCESS_KEY);
      this.getDocumentCategories(false);
      this.modalService.dismissAll();
    });
  }

  cancel(): void {
    this.isInvalidSubmit = false;
    this.categoryModel = {
      id: null,
      name: '',
      description: ''
    };
    this.getDocumentCategories(false);
  }

  submitData(): void {
    if (!this.categoryModel.name) {
      this.isInvalidSubmit = true;
    } else {
      const categoryModel = { ...this.categoryModel };
      if (categoryModel.id && this.documentcategory_update) {
        this.dataHandlerService.updateData(this.categoryModel, DOCUMENT_CATEGORY_PATH_KEY, this.saveSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
          this.cancel();
        });
      } else if (this.documentcategory_create) {
        delete categoryModel.id;
        this.dataHandlerService.createData(this.categoryModel, DOCUMENT_CATEGORY_PATH_KEY, this.saveSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
          this.cancel();
        });
      }
    }
  }

}
