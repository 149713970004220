<div class="title-block mb-3">
  <h4 class="card-title">{{ 'TR_COURSES' | translate }}</h4>
</div>

<div class="wrap-flex">
  <button type="button" class="mb-2 mr-3 btn btn-primary create-btn filter-btn pointer" [class.active]="isVerifiedCourses" (click)="toggleVerifiedCourses()">
    <i class="pe-7s-check"></i><span class="ml-2">{{ 'TR_GET_VERIFIED_COURSES' | translate }}</span>
  </button>
</div>

<div class="main-card mb-3 card">
  <div class="table-responsive">
    <table class="align-middle mb-0 table table-striped table-hover">
      <thead>
        <tr>
          <th>{{ 'TR_CREATOR' | translate }}</th>
          <th>{{ 'TR_NAME' | translate }}</th>
          <th>{{ 'TR_DESCRIPTION' | translate }}</th>
          <th>{{ 'TR_COST' | translate }}</th>
          <th>{{ 'TR_DURATION_DAYS' | translate }}</th>
          <th>{{ 'TR_DISCOUNT_PERCENTAGE' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="pointer" *ngFor="let item of courses" (click)="openCourseView(item.id)">
          <td>{{ item.creatorUserName }}</td>
          <td>{{ item.name }}</td>
          <td><p class="max-two-lines m-0">{{ item.description }}</p></td>
          <td>{{ item.cost }}</td>
          <td>{{ item.period }}</td>
          <td>{{ item.discount }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
