import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { startWith, tap, delay } from 'rxjs/operators';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { LanguageService } from 'src/app/Services/language.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { MainService } from 'src/app/Services/main.service';
import { AlertService } from 'src/app/Services/alert.service';
import { SUCCESS_KEY, TR_FIND_YOUR_ACCOUNT_KEY, TR_YOUR_REQUEST_SEND_MESSAGE_KEY } from 'src/app/Constants/main-keys';
import { CHANGE_CREDENTIALS_LINK_KEY, EMAIL_PATH_PARAM_KEY, GET_RESET_LINK_PATH_KEY, USERS_PATH_KEY } from 'src/app/Constants/request-path-keys';

@Component({
  selector: 'app-forgot-password-boxed',
  templateUrl: './forgot-password-boxed.component.html',
  styleUrls: ['./forgot-password-boxed.component.scss']
})
export class ForgotPasswordBoxedComponent implements OnInit {
  form: FormGroup;
  isInvalidSubmit: boolean;
  isPageLoaded: boolean;
  currentYear: any;

  constructor(
    private formBuilder: FormBuilder,
    private dataHandlerService: DataHandlerService,
    private translationService: TranslationService,
    public languageService: LanguageService,
    private mainService: MainService,
    private alertService: AlertService,
  ) { 
    this.currentYear = new Date().getFullYear()
  }

  ngOnInit() {
    this.translationService.setPageTitle(TR_FIND_YOUR_ACCOUNT_KEY);
    this.translationService.isPageLoading.pipe(
      startWith(null),
      delay(0),
      tap(isLoading => this.isPageLoaded = !isLoading)
    ).subscribe();

    this.form = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]]
    });
  }

  sendEmailData(): void {
    if (this.form.valid) {
      // this.dataHandlerService.createDataByQuery(this.form.value.email, `${USERS_PATH_KEY}/${GET_RESET_LINK_PATH_KEY}?${EMAIL_PATH_PARAM_KEY}`).then(() => {
      this.dataHandlerService.createDataByQuery(this.form.value.email, `${USERS_PATH_KEY}/${CHANGE_CREDENTIALS_LINK_KEY}?${EMAIL_PATH_PARAM_KEY}`).then(() => {
        this.alertService.translateAndAlertMessage(TR_YOUR_REQUEST_SEND_MESSAGE_KEY, SUCCESS_KEY);
      });
    } else {
      this.isInvalidSubmit = true;
    }
  }


}
