<button
  type="button"
  [ngClass]="buttonClasses"
  [disabled]="isSubmitted"
  (click)="buttonClicked()"
>
  {{ buttonName | translate }}
  <div *ngIf="isSubmitted" class="spinner-border" [ngClass]="spinnerClasses" role="status">
    <span class="sr-only">Loading...</span> 
  </div>
</button>