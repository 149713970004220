import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgReduxModule } from '@angular-redux/store';
import { NgRedux, DevToolsExtension } from '@angular-redux/store';
import { rootReducer, ArchitectUIState } from './ThemeOptions/store';
import { ConfigActions } from './ThemeOptions/store/config.actions';
import { AppRoutingModule } from './app-routing.module';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CKEditorModule } from 'ckeditor4-angular';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';

import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { MatExpansionModule } from '@angular/material/expansion';

// BOOTSTRAP COMPONENTS

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ChartsModule } from 'ng2-charts';

// LAYOUT

import { BaseLayoutComponent } from './Layout/base-layout/base-layout.component';
import { PagesLayoutComponent } from './Layout/pages-layout/pages-layout.component';
import { PageTitleComponent } from './Layout/Components/page-title/page-title.component';

// HEADER

import { HeaderComponent } from './Layout/Components/header/header.component';
import { SearchBoxComponent } from './Layout/Components/header/elements/search-box/search-box.component';
import { UserBoxComponent } from './Layout/Components/header/elements/user-box/user-box.component';

// SIDEBAR

import { SidebarComponent } from './Layout/Components/sidebar/sidebar.component';
import { LogoComponent } from './Layout/Components/sidebar/elements/logo/logo.component';

// FOOTER

import { FooterComponent } from './Layout/Components/footer/footer.component';

import { JwtInterceptor } from './Interceptors/jwt.interceptor';
import { UsersTableComponent } from './Pages/Users/users-table/users-table.component';
import { UserFormComponent } from './Pages/Users/user-form/user-form.component';
import { PipeModule } from './Pipes/pipe.module';
import { HomeComponent } from './Pages/Home/home/home.component';
import { WebinarsTableComponent } from './Pages/Webinars/webinars-table/webinars-table.component';
import { WebinarFormComponent } from './Pages/Webinars/webinar-form/webinar-form.component';
import { StudentsTableComponent } from './Pages/Students/students-table/students-table.component';
import { StudentFormComponent } from './Pages/Students/student-form/student-form.component';
import { StudentUpdateComponent } from './Pages/Students/student-update/student-update.component';
import { AlertComponent } from './Components/alert/alert.component';
import { GroupTableComponent } from './Pages/Groups/group-table/group-table.component';
import { GroupFormComponent } from './Pages/Groups/group-form/group-form.component';
import { UserViewComponent } from './Pages/Users/user-view/user-view.component';
import { StudentViewComponent } from './Pages/Students/student-view/student-view.component';
import { GroupStudentsComponent } from './Pages/Groups/group-students/group-students.component';
import { UserTypeFormComponent } from './Pages/UserTypes/user-type-form/user-type-form.component';
import { UserTypesTableComponent } from './Pages/UserTypes/user-types-table/user-types-table.component';
import { RolesComponent } from './Pages/UserTypes/roles/roles.component';
import { OrganizationsTableComponent } from './Pages/Organizations/organizations-table/organizations-table.component';
import { OrganizationFormComponent } from './Pages/Organizations/organization-form/organization-form.component';
import { PagesTableComponent } from './Pages/Pages/pages-table/pages-table.component';
import { PageFormComponent } from './Pages/Pages/page-form/page-form.component';
import { OrganizationSettingsTablesComponent } from './Pages/OrganizationSettings/organization-settings-tables/organization-settings-tables.component';
import { SubjectLecturerComponent } from './Pages/OrganizationSettings/subject-lecturer/subject-lecturer.component';
import { OrganizationViewComponent } from './Pages/Organizations/organization-view/organization-view.component';
import { StreamSettingsComponent } from './Pages/Organizations/stream-settings/stream-settings.component';
import { DocumentsTableComponent } from './Pages/Documents/documents-table/documents-table.component';
import { DocumentFormComponent } from './Pages/Documents/document-form/document-form.component';
import { DocumentPrintComponent } from './Pages/Documents/document-print/document-print.component';
import { DocumentSubscriptionComponent } from './Pages/Documents/document-subscription/document-subscription.component';
import { ErrorInterceptor } from './Interceptors/error.interceptor';
import { TemplateDocumentFormComponent } from './Pages/Documents/template-document-form/template-document-form.component';
import { TemplateDocumentViewComponent } from './Pages/Documents/template-document-view/template-document-view.component';
import { PaymentPackagesTableComponent } from './Pages/PaymentPackages/payment-packages-table/payment-packages-table.component';
import { PaymentPackageFormComponent } from './Pages/PaymentPackages/payment-package-form/payment-package-form.component';
import { ConfigTableComponent } from './Pages/Config/config-table/config-table.component';
import { ConfigFormComponent } from './Pages/Config/config-form/config-form.component';
import { CurrencyTableComponent } from './Pages/Currency/currency-table/currency-table.component';
import { CurrencyFormComponent } from './Pages/Currency/currency-form/currency-form.component';
import { LibraryComponent } from './Pages/Library/library/library.component';
import { TranslationsComponent } from './Pages/Translations/translations/translations.component';
import { CategoryComponent } from './Pages/Category/category/category.component';
import { PageContentTableComponent } from './Pages/PageContent/page-content-table/page-content-table.component';
import { PageContentFormComponent } from './Pages/PageContent/page-content-form/page-content-form.component';
import { TemplatesTableComponent } from './Pages/Templates/templates-table/templates-table.component';
import { TemplateFormComponent } from './Pages/Templates/template-form/template-form.component';
import { TemplateInfoTableComponent } from './Pages/TemplateInfo/template-info-table/template-info-table.component';
import { TemplateInfoFormComponent } from './Pages/TemplateInfo/template-info-form/template-info-form.component';
import { TemplateInfoDataTableComponent } from './Pages/TemplateInfoData/template-info-data-table/template-info-data-table.component';
import { TemplateInfoDataFormComponent } from './Pages/TemplateInfoData/template-info-data-form/template-info-data-form.component';
import { DocumentCategoryComponent } from './Pages/DocumentCategory/document-category/document-category.component';
import { LanguageTableComponent } from './Pages/Languages/language-table/language-table.component';
import { LanguageFormComponent } from './Pages/Languages/language-form/language-form.component';
import { TranslationFormComponent } from './Pages/Translations/translation-form/translation-form.component';
import { CoursesTableComponent } from './Pages/Courses/courses-table/courses-table.component';
import { CourseFormComponent } from './Pages/Courses/course-form/course-form.component';
import { CourseViewComponent } from './Pages/Courses/course-view/course-view.component';
import { TopicFormComponent } from './Pages/Courses/topic-form/topic-form.component';
import { SectionFormComponent } from './Pages/Courses/section-form/section-form.component';
import { PageLoaderComponent } from './Components/page-loader/page-loader.component';
import { FileLibraryFormComponent } from './Pages/FileLibrary/file-library-form/file-library-form.component';
import { FileLibraryTableComponent } from './Pages/FileLibrary/file-library-table/file-library-table.component';
import { TopicAttachFileComponent } from './Pages/Courses/topic-attach-file/topic-attach-file.component';
import { ProfileComponent } from './Pages/Profile/profile/profile.component';
import { QuestionsTableComponent } from './Pages/Tests/Questions/questions-table/questions-table.component';
import { QuestionFormComponent } from './Pages/Tests/Questions/question-form/question-form.component';
import { SubmitButtonComponent } from './Components/submit-button/submit-button.component';
import { FailLogsTableComponent } from './Pages/FailLogs/fail-logs-table/fail-logs-table.component';
import { SaveButtonComponent } from './Components/save-button/save-button.component';
import { QuestionGroupFormComponent } from './Pages/Tests/Questions/question-group-form/question-group-form.component';
import { MultiChoiseAnswerComponent } from './Pages/Tests/QuestionTypesAnswers/multi-choise-answer/multi-choise-answer.component';
import { MatchingQuestionAnswerComponent } from './Pages/Tests/QuestionTypesAnswers/matching-question-answer/matching-question-answer.component';
import { ShortAnswerComponent } from './Pages/Tests/QuestionTypesAnswers/short-answer/short-answer.component';
import { TrueFalseAnswerComponent } from './Pages/Tests/QuestionTypesAnswers/true-false-answer/true-false-answer.component';
import { QuizFormComponent } from './Pages/Tests/Quizzes/quiz-form/quiz-form.component';
import { QuizViewComponent } from './Pages/Tests/Quizzes/quiz-view/quiz-view.component';
import { LoginBoxedComponent } from './UserPages/login-boxed/login-boxed.component';
import { ForgotPasswordBoxedComponent } from './UserPages/forgot-password-boxed/forgot-password-boxed.component';
import { RegisterBoxedComponent } from './UserPages/register-boxed/register-boxed.component';
import { ResetPasswordComponent } from './UserPages/reset-password/reset-password.component';
import { LibraryFormComponent } from './Pages/Library/library-form/library-form.component';
import { RoleFormComponent } from './Pages/Role/role-form/role-form.component';
import { RolesTableComponent } from './Pages/Role/roles-table/roles-table.component';
import { StudentsBulkUploadComponent } from './Pages/Students/students-bulk-upload/students-bulk-upload.component';
import { ActionButtonComponent } from './Components/action-button/action-button.component';
import { ModalLoaderComponent } from './Components/modal-loader/modal-loader.component';
import { HelpAlertDirective } from './Directives/help-alert.directive';
import { HelpAlertComponent } from './Components/help-alert/help-alert.component';
import { HelpAlertViewComponent } from './Components/help-alert-view/help-alert-view.component';
import { FileLibraryComponent } from './Pages/Documents/file-library/file-library.component';
import { FileLibraryForm } from './Pages/Documents/file-library-form/file-library-form.component';
import { OrganizationPaymentPackageFormComponent } from "./Pages/Organizations/payment-package/payment-package-form.component";
import { PackagePropertiesTableComponent } from './Pages/PaymentPackages/package-property-table/package-properties-table.component';
import { PackagePropertyForm } from './Pages/PaymentPackages/package-property-form/package-property-form.component';
import { OrganizationContractComponent } from './Pages/Organizations/organization-contract/organization-contract.component';
import { AuthenticationComponent } from './UserPages/authentication/authentication.component';
import { PageMainInComponent } from './Components/page-main-info/page-main-info.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [

    // LAYOUT

    AppComponent,
    BaseLayoutComponent,
    PagesLayoutComponent,
    PageTitleComponent,

    // HEADER

    HeaderComponent,
    SearchBoxComponent,
    UserBoxComponent,

    // SIDEBAR

    SidebarComponent,
    LogoComponent,

    // FOOTER

    FooterComponent,

    // USER PAGES
    LoginBoxedComponent,
    ForgotPasswordBoxedComponent,
    RegisterBoxedComponent,
    AuthenticationComponent,

    //New
    HomeComponent,
    AlertComponent,

    UsersTableComponent,
    UserFormComponent,
    UserViewComponent,

    WebinarsTableComponent,
    WebinarFormComponent,

    StudentsTableComponent,
    StudentFormComponent,
    StudentUpdateComponent,
    StudentViewComponent,

    GroupTableComponent,
    GroupFormComponent,
    GroupStudentsComponent,

    UserTypesTableComponent,
    UserTypeFormComponent,
    RolesComponent,

    OrganizationsTableComponent,
    OrganizationFormComponent,
    OrganizationPaymentPackageFormComponent,

    PagesTableComponent,
    PageFormComponent,
    PackagePropertiesTableComponent,
    PackagePropertyForm,
    OrganizationSettingsTablesComponent,
    SubjectLecturerComponent,
    OrganizationViewComponent,
    StreamSettingsComponent,
    OrganizationContractComponent,
    DocumentsTableComponent,
    DocumentFormComponent,
    DocumentPrintComponent,
    DocumentSubscriptionComponent,
    TemplateDocumentFormComponent,
    TemplateDocumentViewComponent,
    PaymentPackagesTableComponent,
    PaymentPackageFormComponent,
    ConfigTableComponent,
    ConfigFormComponent,
    CurrencyTableComponent,
    CurrencyFormComponent,
    LibraryComponent,
    TranslationsComponent,
    CategoryComponent,
    PageContentTableComponent,
    PageContentFormComponent,
    TemplatesTableComponent,
    TemplateFormComponent,
    TemplateInfoTableComponent,
    TemplateInfoFormComponent,
    TemplateInfoDataTableComponent,
    TemplateInfoDataFormComponent,
    DocumentCategoryComponent,
    LanguageTableComponent,
    LanguageFormComponent,
    TranslationFormComponent,
    ResetPasswordComponent,
    CoursesTableComponent,
    CourseFormComponent,
    CourseViewComponent,
    TopicFormComponent,
    SectionFormComponent,
    PageLoaderComponent,
    FileLibraryFormComponent,
    FileLibraryTableComponent,
    TopicAttachFileComponent,
    ProfileComponent,
    QuestionsTableComponent,
    QuestionFormComponent,
    SubmitButtonComponent,
    FailLogsTableComponent,
    SaveButtonComponent,
    PageMainInComponent,
    QuestionGroupFormComponent,
    MultiChoiseAnswerComponent,
    MatchingQuestionAnswerComponent,
    ShortAnswerComponent,
    TrueFalseAnswerComponent,
    QuizFormComponent,
    QuizViewComponent,
    LibraryFormComponent,
    RoleFormComponent,
    RolesTableComponent,
    StudentsBulkUploadComponent,
    ActionButtonComponent,
    ModalLoaderComponent,
    HelpAlertDirective,
    HelpAlertComponent,
    HelpAlertViewComponent,
    FileLibraryComponent,
    FileLibraryForm,
   
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgReduxModule,
    CommonModule,
    LoadingBarRouterModule,
    DragDropModule,
    MatExpansionModule,

    // Angular Bootstrap Components

    PerfectScrollbarModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    // Charts

    ChartsModule,
    PipeModule,
    CKEditorModule,
    PDFExportModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide:
        PERFECT_SCROLLBAR_CONFIG,
      // DROPZONE_CONFIG,
      useValue:
        DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      // DEFAULT_DROPZONE_CONFIG,
    },
    ConfigActions,
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(
    private ngRedux: NgRedux<ArchitectUIState>,
    private devTool: DevToolsExtension
  ) {

    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [devTool.isEnabled() ? devTool.enhancer() : f => f]
    );

  }
}
