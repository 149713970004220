<div class="title-block mb-3">
  <h4 class="card-title">{{ 'TR_ROLES' | translate }}</h4>
</div>

<button *ngIf="role_create" type="button" class="mb-2 mr-2 btn btn-success create-user-btn"
  [title]="'TR_CREATE' | translate" [routerLink]="['role-form']">
  <i class="pe-7s-add-user"></i><span class="ml-2">{{ 'TR_CREATE' | translate }}</span>
</button>

<div class="main-card mb-3 card">
  <div class="table-responsive">
    <table class="align-middle mb-0 table table-striped table-hover">
      <thead>
        <tr>
          <th>#</th>
          <th>{{ 'TR_NAME' | translate }}</th>
          <th class="text-right">{{ 'TR_ACTIONS' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of roles let i = index">
          <td>{{ i + 1 }}</td>
          <td>{{ item.name }}</td>
          <td class="text-right">
            <div role="group" class="btn-group-sm btn-group">
              <button *ngIf="role_update" class="ml-1 btn-transition btn btn-outline-primary"
                [title]="'TR_EDIT' | translate" [routerLink]="['role-form', item.id]">
                <i class="pe-7s-note"> </i>
              </button>
              <button *ngIf="role_delete" class="ml-1 btn-transition btn btn-outline-danger"
                [title]="'TR_DELETE' | translate">
                <i class="pe-7s-trash"> </i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>