import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { guid } from '@progress/kendo-angular-common';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { IUserData } from 'src/app/Models/IUserData';
import { SUBJECT_ID_PATH_KEY, SUBJECT_LECTURER_PATH_KEY } from 'src/app/Constants/request-path-keys';
import { AlertService } from 'src/app/Services/alert.service';
import { SUCCESS_KEY } from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-subject-lecturer',
  templateUrl: './subject-lecturer.component.html',
  styleUrls: ['./subject-lecturer.component.scss']
})
export class SubjectLecturerComponent implements OnInit {
  @Input() subjectId: number;
  @Input() subjectName: string;

  @Output() closeEmit = new EventEmitter();

  lecturers: Array<IUserData> = [];
  currentLecturer: IUserData;
  modalSpinnerId: string = guid();

  constructor(
    private dataHandlerService: DataHandlerService,
    private modalService: NgbModal,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this.getLecturersBySubjectId();
  }

  getLecturersBySubjectId() {
    this.dataHandlerService.getDataWithModalSpinner(
      `${SUBJECT_LECTURER_PATH_KEY}/${SUBJECT_ID_PATH_KEY}/${this.subjectId}/${true}`,
      this.modalSpinnerId
    ).then(response => {
      this.lecturers = response;
    });
  }

  changeCheckboxValue(event: any, isBelongToCurrentSubject: boolean, lecturer: IUserData, content: any): void {
    event.preventDefault();
    this.currentLecturer = lecturer;
    if (!isBelongToCurrentSubject) {
      this.modalService.open(content).result;
    } else {
      this.submit(isBelongToCurrentSubject, lecturer.id);
    }
  }

  submit(isBelongToCurrentSubject: boolean, lecturerId: number): void {
    const body = {
      create: isBelongToCurrentSubject,
      subjectId: this.subjectId,
      lecturerId: lecturerId
    };

    this.dataHandlerService.createData(body, SUBJECT_LECTURER_PATH_KEY, null).then(() => {
      var lecturersCopy = [...this.lecturers];
      if (this.currentLecturer) {
        lecturersCopy.forEach(lecturer => {
          if (lecturer.id === this.currentLecturer.id) {
            lecturer.isBelongToCurrentSubject = isBelongToCurrentSubject;
          }
        })
      }
      this.alertService.translateAndAlertMessage("TR_DATA_SAVED", SUCCESS_KEY);
      this.lecturers = lecturersCopy;
      this.currentLecturer = null;
    });
  }

}


