import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { guid } from '@progress/kendo-angular-common';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { RolesService } from 'src/app/Services/roles.service';
import { IUserData } from 'src/app/Models/IUserData';
import { USERS_PATH_KEY } from 'src/app/Constants/request-path-keys';
import { ORGANIZATION_VIEW, USER_PASSWORD_VIEW, USER_VIEW } from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss']
})
export class UserViewComponent implements OnInit {
  @Input() userId: number;
  @Output() closeEmit = new EventEmitter();

  user: IUserData;
  userRoles: Array<string> = [];
  user_view: boolean;
  user_password_view: boolean;
  organization_view: boolean;
  userSpinnerId: string = guid();

  constructor(
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.organization_view = this.userRoles.includes(ORGANIZATION_VIEW);
    this.user_view = this.userRoles.includes(USER_VIEW);
    this.user_password_view = this.userRoles.includes(USER_PASSWORD_VIEW);

    this.getUserData();
  }

  getUserData() {
    if (!this.userId) { return; }
    this.dataHandlerService.getDataWithModalSpinner(`${USERS_PATH_KEY}/${this.userId}`, this.userSpinnerId).then(response => {
      this.user = response;
      if(this.user.password) {
        this.user.password = atob(this.user.password);
      }
    });
  }
}
