<div class="main-card mb-3 card">
  <div class="card-body">
    <div class="title-block mb-3">
      <i class="fa fa-fw" [title]="'TR_GOBACK' | translate" (click)="mainService.goBack('webinar')"></i>
      <h5 class="card-title">{{ isCreateForm ? ('TR_WEBINARCREATE' | translate) : ('TR_WEBINARUPDATE' | translate) }}</h5>
    </div>
    <div class="wrap-flex form-outline">
      <div ngbDropdown>
        <div class="flex flex-column">
          <label>{{ ('TR_GROUPS' | translate) + mainService.asterisk }}</label>
          <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-outline-primary" [class.error-border]="isInvalidSubmit && !selectedGroupId">
            {{ selectedGroupName ? selectedGroupName : ('TR_GROUPS' | translate) + mainService.asterisk }}
          </button>
        </div>
        <div ngbDropdownMenu>
          <button class="dropdown-item" *ngFor="let item of groups" (click)="setSelectedGroup(item.id, item.groupName)">
            {{ item.groupName }}
          </button>
        </div>
      </div>
      <div ngbDropdown>
        <div class="flex flex-column">
          <label>{{ ('TR_DISCIPLINE' | translate) + mainService.asterisk }}</label>
          <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-outline-primary" [disabled]="disabledSubject" [class.error-border]="isInvalidSubmit && !selectedSubjectId">
            {{ selectedSubjectName ? selectedSubjectName : ('TR_DISCIPLINE' | translate) + mainService.asterisk }}
          </button>
        </div>
        <div ngbDropdownMenu>
          <button class="dropdown-item" *ngFor="let item of subjects" (click)="setSelectedSubject(item.id, item.name)">
            {{ item.name }}
          </button>
        </div>
      </div>
      <div ngbDropdown>
        <div class="flex flex-column">
          <label>{{ ('TR_LECTURERS' | translate) + mainService.asterisk }}</label>
          <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-outline-primary" [disabled]="disabledLecturer" [class.error-border]="isInvalidSubmit && !selectedLecturerId">
            {{ selectedLecturerName ? selectedLecturerName : ('TR_LECTURERS' | translate) + mainService.asterisk }}
          </button>
        </div>
        <div ngbDropdownMenu>
          <button class="dropdown-item" *ngFor="let item of lecturers" (click)="setSelectedLecturer(item.id, item.fullName)">
            {{ item.fullName }}
          </button>
        </div>
      </div>
    </div>
    <div class="mt-2 mb-3 upload-block form-outline">
      <button class="mb-2 mr-2 btn-transition btn btn-warning upload-btn" (click)="avatar.click()">
        <i class="fa fa-plus"></i>
        <p>{{ 'TR_UPLOADAVATAR' | translate }}</p>
      </button>
      <input #avatar hidden type="file" name="upload-avatar" (change)="handleFileInput($event.target.files)" />
      <div *ngIf="imgURL" [ngStyle]="{ 'background-image' : 'url(' + imgURL + ')' }" class="upload-avatar"></div>
    </div>
    <form (submit)="submitData()" [formGroup]="form">
      <div class="form-row">
        <div class="col-lg-4 col-md-6">
          <div class="position-relative form-group">
            <label for="name">{{ ('TR_NAME' | translate) + mainService.asterisk }}</label>
            <input
              name="name"
              [placeholder]="('TR_NAME' | translate) + mainService.asterisk"
              type="text"
              class="form-control"
              [class.error-border]="isInvalidSubmit && !form.value.name"
              autocomplete="off"
              formControlName="name"
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-lg-4 col-md-6">
          <div class="position-relative form-group">
            <label for="description">{{ 'TR_DESCRIPTION' | translate }}</label>
            <input
              name="description"
              [placeholder]="'TR_DESCRIPTION' | translate"
              type="text"
              class="form-control"
              autocomplete="off"
              formControlName="description"
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-lg-4 col-md-6">
          <div class="position-relative form-group">
            <label for="duration">{{ 'TR_DURATION' | translate }}</label>
            <input
              name="duration"
              [placeholder]="'TR_DURATION' | translate"
              type="number"
              class="form-control"
              autocomplete="off"
              formControlName="duration"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div *ngIf="createWebinar && readySubtitles.length" class="card main-card mb-3" [class.error-border]="isInvalidSubmit && !form.value.periodicity">
            <div class="card-body">
              <ngb-tabset (tabChange)="tabChangeHandler($event)">
                <ngb-tab id="1" [title]="readySubtitles[0]">
                  <ng-template ngbTabContent>
                    <div formArrayName="days">
                      <ng-container formGroupName="0">
                        <div class="form-row">
                          <div class="col-lg-4 col-md-6 mt-2">
                            <div class="position-relative form-group">
                              <label for="webinarDate">{{ ('TR_WEBINAR_DATE' | translate) + mainService.asterisk }}</label>
                              <input
                                name="webinarDate"
                                [placeholder]="'TR_WEBINAR_DATE' | translate"
                                type="date"
                                class="form-control"
                                [class.error-border]="isInvalidSubmit && form.value.periodicity === 1 && !form.get('days')['controls']['0'].value.webinarDate"
                                autocomplete="off"
                                formControlName="webinarDate"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="col-lg-4 col-md-6">
                            <div class="position-relative form-group">
                              <label for="startTime">{{ ('TR_START_TIME' | translate) + mainService.asterisk }}</label>
                              <input
                                [max]="maxTime"
                                name="startTime"
                                type="time"
                                class="form-control"
                                [class.error-border]="isInvalidSubmit && form.value.periodicity === 1 && !form.get('days')['controls'][0].value.startTime"
                                autocomplete="off"
                                formControlName="startTime"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="col-lg-4 col-md-6">
                            <div class="position-relative form-group">
                              <label for="endTime">{{ ('TR_END_TIME' | translate) + mainService.asterisk }}</label>
                              <input
                                [min]="minTime"
                                name="endTime"
                                [placeholder]="'TR_WEBINAR_DATE' | translate"
                                type="time"
                                class="form-control"
                                [class.error-border]="isInvalidSubmit && form.value.periodicity === 1 && !form.get('days')['controls'][0].value.endTime"
                                autocomplete="off"
                                formControlName="endTime"
                              />
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </ng-template>
                </ngb-tab>
                <ngb-tab id="2" [title]="readySubtitles[1]">
                  <ng-template ngbTabContent>
                    <div>
                      <div class="form-row">
                        <div class="col-lg-4 col-md-6 mt-2">
                          <div class="position-relative form-group">
                            <label for="startDate">{{ ('TR_START_DATE' | translate) + mainService.asterisk }}</label>
                            <input
                              name="startDate"
                              [max]="maxDate"
                              [placeholder]="'TR_START_DATE' | translate"
                              type="date"
                              class="form-control"
                              [class.error-border]="isInvalidSubmit && form.value.periodicity === 2 && !form.value.startDate"
                              autocomplete="off"
                              formControlName="startDate"
                              (ngModelChange)="setMinValue(form.value.startDate)"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col-lg-4 col-md-6">
                          <div class="position-relative form-group">
                            <label for="endDate">{{ ('TR_END_DATE' | translate) + mainService.asterisk }}</label>
                            <input
                              name="endDate"
                              [min]="minDate"
                              [placeholder]="'TR_END_DATE' | translate"
                              type="date"
                              class="form-control"
                              [class.error-border]="isInvalidSubmit && form.value.periodicity === 2 && !form.value.endDate"
                              autocomplete="off"
                              formControlName="endDate"
                              (ngModelChange)="setMaxValue(form.value.endDate)"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row week-days">
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12 custom-padding" *ngFor="let item of weekDaysArr; index as i">
                          <div
                            class="week-day-container"
                            [class.error-border]="isInvalidSubmit && form.value.periodicity === 2 && (!form.value.days || !form.value.days.length)"
                          >
                            <div class="position-relative week-name-block">
                              <div class="week-name">
                                <label [for]="'webinarDay' + i" class="form-check-label">{{ item | translate }}</label>
                              </div>
                              <div class="buttons-container">
                                <button
                                  type="button"
                                  class="btn btn-secondary"
                                  [title]="'TR_ADD' | translate"
                                  (click)="addPeriodicWeekDay(i)"
                                >
                                  <i class="fa fa-fw" aria-hidden="true"></i>
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-secondary"
                                  [title]="'TR_REMOVE' | translate"
                                  (click)="removePeriodicWeekDay(i)"
                                >
                                  <i class="fa fa-fw" aria-hidden="true"></i>
                                </button>
                              </div>
                            </div>
                            <div class="time-inputs-container">
                              <div formArrayName="days" *ngFor="let day of form.get('days')['controls']; let dayIndex=index" class="time-picker-container">
                                <ng-container [formGroupName]="dayIndex" *ngIf="day.value.webinarDay === i">
                                  <div>
                                    <label for="startTime" [title]="'TR_START_TIME' | translate">
                                      {{ ('TR_START_TIME_SHORT' | translate) + mainService.asterisk }}
                                    </label>
                                    <input
                                      name="startTime"
                                      type="time"
                                      [max]="maxTime"
                                      class="form-control"
                                      [class.error-border]="isInvalidSubmit && form.value.periodicity === 2 && !day.value.startTime"
                                      autocomplete="off"
                                      formControlName="startTime"
                                    />
                                  </div>
                                  <div>
                                    <label for="endTime" [title]="'TR_END_TIME' | translate">
                                      {{ ('TR_END_TIME_SHORT' | translate) + mainService.asterisk }}
                                    </label>
                                    <input
                                      name="endTime"
                                      type="time"
                                      [min]="minTime"
                                      class="form-control"
                                      [class.error-border]="isInvalidSubmit && form.value.periodicity === 2 && !day.value.endTime"
                                      autocomplete="off"
                                      formControlName="endTime"
                                    />
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-tab>
                <ngb-tab id="3" [title]="readySubtitles[2]">
                  <ng-template ngbTabContent>
                    <div class="form-row">
                      <div class="col-lg-4 col-md-6 mt-2">
                        <div class="position-relative form-group">
                          <label for="startDateCustom">{{ ('TR_START_DATE' | translate) + mainService.asterisk }}</label>
                          <input
                            name="startDateCustom"
                            [max]="maxDate"
                            [placeholder]="'TR_START_DATE' | translate"
                            type="date"
                            class="form-control"
                            [class.error-border]="isInvalidSubmit && form.value.periodicity === 3 && !form.value.startDate"
                            autocomplete="off"
                            [formControl]="this.form.get('startDate')"
                            (ngModelChange)="setMinValue(form.value.startDate)"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-lg-4 col-md-6">
                        <div class="position-relative form-group">
                          <label for="endDateCustom">{{ ('TR_END_DATE' | translate) + mainService.asterisk }}</label>
                          <input
                            name="endDateCustom"
                            [min]="minDate"
                            [placeholder]="'TR_END_DATE' | translate"
                            type="date"
                            class="form-control"
                            [class.error-border]="isInvalidSubmit && form.value.periodicity === 3 && !form.value.endDate"
                            autocomplete="off"
                            [formControl]="this.form.get('endDate')"
                            (ngModelChange)="setMaxValue(form.value.endDate)"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="month-container" *ngFor="let month of rangeDays">
                          <h4 class="webinar-title">{{ months[month[0].month - 1] | translate }} {{ month[0].year }}</h4>
                          <div class="row">
                            <div class="col-lg-3 col-md-4 col-sm-6 col-12 custom-padding" *ngFor="let item of month">
                              <div [class.selected]="item.open" class="month-day">
                                <div class="day-block">
                                  <div class="day-name">
                                    <p>{{ item.day }}, {{ item.dayName | translate }}</p>
                                  </div>
                                  <div class="buttons-container">
                                    <button
                                      type="button"
                                      class="btn btn-success"
                                      [title]="'TR_ADD' | translate"
                                      (click)="addCustomWebinarDay(item)"
                                    >
                                      <i class="fa fa-fw" aria-hidden="true"></i>
                                    </button>
                                    <button
                                      type="button"
                                      class="btn btn-success"
                                      [title]="'TR_REMOVE' | translate"
                                      (click)="removeCustomWebinarDay(item)"
                                    >
                                      <i class="fa fa-fw" aria-hidden="true"></i>
                                    </button>
                                  </div>
                                </div>
                                <div class="time-inputs-container scroll">
                                  <ng-container formArrayName="days" *ngFor="let day of form.get('days')['controls']; let dayIndex=index">
                                    <div [formGroupName]="dayIndex" *ngIf="day.value.webinarDate === (item.year + '-' + item.month + '-' + item.day)" class="time-picker-container">
                                      <div class="time-block">
                                        <label for="startTimeCustom" [title]="'TR_START_TIME' | translate" >
                                          {{ ('TR_START_TIME_SHORT' | translate) + mainService.asterisk }}
                                        </label>
                                        <input
                                          name="startTimeCustom"
                                          type="time"
                                          [max]="maxTime"
                                          class="form-control"
                                          [class.error-border]="isInvalidSubmit && form.value.periodicity === 3 && !day.value.startTime"
                                          autocomplete="off"
                                          formControlName="startTime"
                                        />
                                      </div>
                                      <div class="time-block">
                                        <label for="endTimeCustom" [title]="'TR_END_TIME' | translate">
                                          {{ ('TR_END_TIME_SHORT' | translate) + mainService.asterisk }}
                                        </label>
                                        <input
                                          name="endTimeCustom"
                                          type="time"
                                          [min]="minTime"
                                          class="form-control"
                                          [class.error-border]="isInvalidSubmit && form.value.periodicity === 3 && !day.value.endTime"
                                          autocomplete="off"
                                          formControlName="endTime"
                                        />
                                      </div>
                                    </div>
                                  </ng-container>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-tab>
              </ngb-tabset>
            </div>
          </div>
        </div>
      </div>
      <app-submit-button
        [spinnerId]="submitSpinnerId"
        [buttonName]="isCreateForm ? ('TR_CREATE' | translate) : ('TR_UPDATE' | translate)"
        [isInvalidSubmit]="isInvalidSubmit"
        [isFormValid]="form.valid"
      ></app-submit-button>
    </form>
  </div>
</div>