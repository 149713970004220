<div class="modal-header">
  <div class="absolute-container">
    <p class="modal-title">{{ 'TR_STUDENTS_BULK_UPLOAD' | translate }}</p>
  </div>
  <button type="button" class="close" aria-label="Close" (click)="closeEmit.emit(null)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <div class="mt-3 mb-3 upload-block form-outline">
        <button type="button" class="mb-2 mr-2 btn-transition btn btn-outline-info upload-btn" (click)="avatar.click()">
          <i class="pe-7s-plus"></i>
          <p>{{ 'TR_UPLOAD_FILE' | translate }}</p>
        </button>
        <input #avatar hidden type="file" name="upload-avatar" (change)="handleFileInput($event.target.files)" />
      </div>
      <div *ngIf="uploadedFile" class="upload-file">
        <div class="font-icon-wrapper font-icon-lg">
          <i class="pe-7s-file icon-gradient bg-grow-early"></i>
        </div>
        <p>{{ uploadedFileName }}</p>
      </div>
    </div>
  </div>
</div>
<div class="custom-modal modal-footer">
  <button type="button" class="mr-2 btn-transition btn btn-outline-info" (click)="submit()">{{ 'TR_SUBMIT' | translate }}</button>
  <button type="button" class="mr-2 btn-transition btn btn-outline-dark" (click)="closeEmit.emit(null)">{{ 'TR_CANCEL' | translate }}</button>
</div>