<div *ngIf="quizData" class="main-card card p-4">
  <div class="row">
    <div class="col-12">
      <div class="title-block mb-3">
        <i class="fa fa-fw" [title]="'TR_GOBACK' | translate" (click)="goBack()"></i>
        <h4 class="card-title">{{ quizData.name }}</h4>
      </div>
      <div class="quiz-content-wrapper">
        <p>{{ quizData.description }}</p>
        <p *ngIf="quizData.openDate" class="mb-1">
          <strong>{{ 'TR_START_DATE' | translate }}:</strong> <span class="ml-3">{{ quizData.openDate | date }}</span>
        </p>
        <p *ngIf="quizData.closeDate" class="mb-1">
          <strong>{{ 'TR_END_DATE' | translate }}:</strong> <span class="ml-3">{{ quizData.closeDate | date }}</span>
        </p>
        <p *ngIf="quizData.gradingMethod" class="mb-1">
          <strong>{{ 'TR_GRADING_METHOD' | translate }}:</strong> <span class="ml-3">{{ gradingMethods[quizData.gradingMethod] | translate }}</span>
        </p>
        <p *ngIf="quizData.attemptsAllowed" class="mb-1">
          <strong>{{ 'TR_ATTEMPTS_ALLOWED' | translate }}:</strong> <span class="ml-3">{{ quizData.attemptsAllowed }}</span>
        </p>
        <p *ngIf="quizData.maxGrade" class="mb-1">
          <strong>{{ 'TR_MAX_GRADE' | translate }}:</strong> <span class="ml-3">{{ quizData.maxGrade }}</span>
        </p>
        <p *ngIf="quizData.passGrade" class="mb-1">
          <strong>{{ 'TR_GRADE_TO_PASS' | translate }}:</strong> <span class="ml-3">{{ quizData.passGrade }}</span>
        </p>
        <p *ngIf="quizData.timeLimit" class="mb-1">
          <strong>{{ 'TR_TIME_LIMIT' | translate }}:</strong> <span class="ml-3">{{ quizData.timeLimit }} {{ timeTypes[quizData.timeLimitEntity] | translate }}</span>
        </p>
        <p *ngIf="quizData.whenTimeExpires" class="mb-1">
          <strong>{{ 'TR_WHEN_TIME_EXPIRES' | translate }}:</strong> <span class="ml-3">{{ expireTypes[quizData.whenTimeExpires] | translate }}</span>
        </p>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <h4>{{ 'TR_ATTACHED_QUESTIONS' | translate }}</h4>
        </div>
      </div>
      <div class="main-card card">
        <div class="table-responsive">
          <table class="align-middle mb-0 table table-hover inner-table">
            <thead>
              <tr>
                <th>{{ 'TR_QUESTION_GROUP' | translate }}</th>
                <th>{{ 'TR_NAME' | translate }}</th>
                <th>{{ 'TR_QUESTION' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="pointer"
                [title]="'TR_VIEW_QUESTION_DATA' | translate"
                *ngFor="let question of quizData.questions"
                (click)="openQuestionModal(question, questionModal)"
              >
                <td>{{ question.groupName }}</td>
                <td>{{ question.name }}</td>
                <td>{{ question.text }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #questionModal let-c="close" let-d="dismiss">
  <div class="custom-modal modal-body">
    <div class="row">
      <div class="col-12">
        <h4>{{ currentQuestionData.name }}</h4>
        <hr />
        <div class="row">
          <div class="col-12">
            <p class="mb-1"><strong>{{ 'TR_QUESTION' | translate}}: </strong>{{ currentQuestionData.text }}</p>
            <p class="mb-1">
              <strong>{{ 'TR_QUESTION_TYPE' | translate }}: </strong>
              {{ questionTypes[currentQuestionData.questionType].name | translate }}
            </p>
            <p class="mb-1"><strong>{{ 'TR_QUESTION_GROUP' | translate}}: </strong>{{ currentQuestionData.groupName }}</p>
            <p class="mb-1"><strong>{{ 'TR_MARK' | translate}}: </strong>{{ currentQuestionData.mark }}</p>
            <ng-container *ngIf="currentQuestionData.questionType === 0 || currentQuestionData.questionType === 1">
              <p class="mb-1">
                <strong>{{ 'TR_PENALTY_PERCENT' | translate}}: </strong>{{ currentQuestionData.penaltyForIncorrectAnswer }}
              </p>
              <p class="mb-1">
                <strong>{{ 'TR_SHUFFLE_WITHIN_QUESTIONS' | translate}}: </strong>
                {{ (currentQuestionData.isShuffle ? 'TR_TRUE' : 'TR_FALSE') | translate }}
              </p>
              <p class="mb-1" *ngIf="currentQuestionData.choiceNumbering !== null">
                <strong>{{ 'TR_CHOICE_NUMBERING' | translate}}: </strong>
                {{
                  !currentQuestionData.choiceNumbering
                    ? (choiseNumberingTypes[currentQuestionData.choiceNumbering].name | translate)
                    : choiseNumberingTypes[currentQuestionData.choiceNumbering].name
                }}
              </p>
            </ng-container>
            <p class="mb-1" *ngIf="currentQuestionData.questionType === 0">
              <strong>{{ 'TR_ONE_OR_MULTI_ANSWERS' | translate}}: </strong>
              {{ (currentQuestionData.isMultiAnswer ? 'TR_MULTIPLE_ANSWERS_ALLOWED' : 'TR_ONE_ANSWER_ONLY') | translate }}
            </p>
            <p class="mb-1" *ngIf="currentQuestionData.questionType === 2">
              <strong>{{ 'TR_ANSWER_CASE_SENSITIVITY' | translate}}: </strong>
              {{ (currentQuestionData.isCaseSensitive ? 'TR_CASE_MUST_MATCH' : 'TR_UNIMPORTANT_CASE') | translate }}
            </p>
            <ng-container *ngIf="currentQuestionData.questionType !== 3">
              <h5 class="mt-3">{{ 'TR_ANSWERS' | translate }}</h5>
              <div class="row">
                <div class="col-12" *ngFor="let answer of currentQuestionData.questionAnswers; index as answerIndex">
                  <div class="d-flex">
                    <div class="mr-2">
                      <strong>{{ answerIndex + 1 }}. </strong>
                    </div>
                    <div>
                      <p><strong>{{ 'TR_ANSWER' | translate }}: </strong> {{ answer.text }}</p>
                      <p><strong>{{ 'TR_GRADE_PERCENT' | translate }}: </strong> {{ answer.markPercent }}</p>
                      <p><strong>{{ 'TR_FEEDBACK' | translate }}: </strong> {{ answer.feedback }}</p>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </ng-container>
            <p *ngIf="currentQuestionData.questionType === 3">
              <strong>{{ 'TR_ANSWER' | translate}}: </strong>
              {{ (currentQuestionData.correctAnswer ? 'TR_TRUE' : 'TR_FALSE') | translate }}
            </p>
            <h5 class="mt-3">{{ 'TR_HINTS' | translate }}</h5>
            <div class="row">
              <div class="col-12" *ngFor="let hint of currentQuestionData.questionHints; index as hintIndex">
                <div class="d-flex">
                  <p class="mr-2">
                    <strong>{{ hintIndex + 1 }}. </strong> {{ hint.text }}
                  </p>
                </div>
                <hr />
              </div>
            </div>
            <p class="mb-1" *ngIf="currentQuestionData.feedback"><strong>{{ 'TR_FEEDBACK' | translate}}: </strong>{{ currentQuestionData.feedback }}</p>
            <p class="mb-1" *ngIf="currentQuestionData.correctRespFeedback">
              <strong>{{ 'TR_CORRECT_RESP_FEEDBACK' | translate}}: </strong>{{ currentQuestionData.correctRespFeedback }}
            </p>
            <p class="mb-1" *ngIf="currentQuestionData.partCorrectRespFeedback">
              <strong>{{ 'TR_PART_CORRECT_RESP_FEEDBACK' | translate}}: </strong>{{ currentQuestionData.partCorrectRespFeedback }}
            </p>
            <p class="mb-1" *ngIf="currentQuestionData.inCorrectRespFeedback">
              <strong>{{ 'TR_INCORRECT_RESP_FEEDBACK' | translate}}: </strong>{{ currentQuestionData.inCorrectRespFeedback }}
            </p>
            
            <p class="mb-1" *ngIf="currentQuestionData.trueRespFeedback">
              <strong>{{ 'TR_TRUE_RESP_FEEDBACK' | translate}}: </strong>{{ currentQuestionData.trueRespFeedback }}
            </p>
            <p class="mb-1" *ngIf="currentQuestionData.falseRespFeedback">
              <strong>{{ 'TR_FALSE_RESPONSE_FEEDBACK' | translate}}: </strong>{{ currentQuestionData.falseRespFeedback }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="custom-modal modal-footer">
    <button type="button" class="mr-2 btn-transition btn btn-outline-dark" (click)="c('Close click'); currentQuestionData = null;">
      {{ 'TR_CLOSE' | translate }}
    </button>
  </div>
</ng-template>