import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RolesService } from 'src/app/Services/roles.service';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { LanguageService } from 'src/app/Services/language.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { IOrganizationData } from 'src/app/Models/IOrganizationData';
import { guid } from '@progress/kendo-angular-common';
import { GROUP_URL_KEY, ORGANIZATIONS_PATH_KEY } from 'src/app/Constants/request-path-keys';
import { ADMIN_KEY, GROUP_VIEW, ORGANIZATION_CREATE, ORGANIZATION_UPDATE, ORGANIZATION_VIEW, SUCCESS_KEY, TR_ORGANIZATIONS_KEY } from 'src/app/Constants/main-keys';
import { AlertService } from 'src/app/Services/alert.service';

@Component({
  selector: 'app-organizations-table',
  templateUrl: './organizations-table.component.html',
  styleUrls: ['./organizations-table.component.scss']
})
export class OrganizationsTableComponent implements OnInit {
  organizations: Array<IOrganizationData> = [];
  userRoles: Array<string> = [];
  organization_view: boolean;
  organization_create: boolean;
  organization_update: boolean;
  org_contract_create: boolean;
  org_contract_update: boolean;
  group_view: boolean;
  organizationViewId: number;
  initializatiOrganizationName: string = "";
  initializatiOrganizationId: number = null;
  initializationSpinnerId: string = guid();

  constructor(
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    private modalService: NgbModal,
    private router: Router,
    private languageService: LanguageService,
    private translationService: TranslationService,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_ORGANIZATIONS_KEY);

    this.getOrganizations();

  }

  getOrganizations(): void {
    this.dataHandlerService.getDataWithSpinner(ORGANIZATIONS_PATH_KEY).then(response => {
      this.organizations = response;
    });
  }

  setRoles() {
    this.userRoles.forEach(role => {
      switch (role) {
        case ORGANIZATION_CREATE:
          this.organization_create = true;
          break;
        case ORGANIZATION_UPDATE:
          this.organization_update = true;
          break;
        case ORGANIZATION_VIEW:
          this.organization_view = true;
          break;
        case GROUP_VIEW:
          this.group_view = true;
          break;
        case "org_contract_create":
          this.org_contract_create = true;
          break;
        case "org_contract_update":
          this.org_contract_update = true;
          break;
      }
    });
  }

  confirmInitialization(content: any, id: number, name: string): void {
    this.modalService.open(content).result;
    this.initializatiOrganizationId = id;
    this.initializatiOrganizationName = name;
  }

  initializationOrganization(): void {
    this.dataHandlerService.createDataById(this.initializatiOrganizationId, "OrganizatoinInitialization", this.initializationSpinnerId).then(() => {
      this.initializatiOrganizationId = null;
      this.initializatiOrganizationName = "";
      this.modalService.dismissAll();
      this.alertService.translateAndAlertMessage("TR_DATA_SAVED", SUCCESS_KEY);
    });
  }

  viewOrganization(target: HTMLElement, content: any, organizationId: number) {
    if (target.nodeName === 'TD') {
      this.organizationViewId = organizationId;
      this.modalService.open(content).result;
    }
  }

  navigateToGroups(id: number) {
    this.router.navigate([this.languageService.currentLanguage, ADMIN_KEY, GROUP_URL_KEY, id]);
  }

}
