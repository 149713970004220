<ng-container *ngIf="subjectId">
  <div class="modal-header modal-list-header">
    <h5 class="modal-title">{{ subjectName }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="closeEmit.emit(null)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-list p-0">
    <app-modal-loader [modalSpinners]="[modalSpinnerId]"></app-modal-loader>
    <div class="p-4">
      <div class="list-row">
        <h6 for="getActiveStudents" class="mr-2 pointer font-500">{{ 'TR_LECTURERS' | translate }}</h6>
      </div>
      <div class="list-row" *ngFor="let lecturer of lecturers">
        <label class="mr-2 pointer">{{ lecturer.fullName }}</label>
        <input
          [name]="lecturer.studentName"
          type="checkbox"
          class="mt-2 pointer float-right"
          [checked]="lecturer.isBelongToCurrentSubject"
          (click)="changeCheckboxValue($event, !lecturer.isBelongToCurrentSubject, lecturer, confirmremove)"
        />
      </div>
    </div>
  </div>
</ng-container>

<ng-template #confirmremove let-c="close" let-d="dismiss">
  <div class="custom-modal modal-body">
    <p>{{ 'TR_REMOVE_QUESTION' | translate }} <b class="word-break-break-word">{{ currentLecturer.fullName }}</b> {{ 'TR_FROM' | translate }} <b>{{ subjectName }}</b>?</p>
  </div>
  <div class="custom-modal modal-footer">
    <button
      type="button"
      class="mr-2 btn-transition btn btn-outline-info"
      (click)="submit(!currentLecturer.isBelongToCurrentSubject, currentLecturer.id); c('Close click')"
    >
      {{ 'TR_YES' | translate }}
    </button>
    <button type="button" class="btn-transition btn btn-danger" (click)="c('Close click')">{{ 'TR_NO' | translate }}</button>
  </div>
</ng-template>