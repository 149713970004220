<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon k-cursor-pointer goback" (click)="mainService.goBack('paymentpackage')"
        [title]="'TR_GOBACK' | translate"><i class="icon pe-7s-left-arrow icon-gradient bg-plum-plate"></i></div>
      <div>{{ isCreateForm ? ('TR_CREATE_PAYMENT_PACKAGE' | translate) : ('TR_UPDATE_PAYMENT_PACKAGE'
        | translate) }}
      </div>
    </div>
  </div>
</div>
<div class="main-card mb-3 card">
  <div class="card-body">
    <!-- <div class="title-block mb-3">
      <i class="fa fa-fw" [title]="'TR_GOBACK' | translate" (click)="mainService.goBack('paymentpackage')"></i>
      <h5 class="card-title">{{ isCreateForm ? ('TR_CREATE_PAYMENT_PACKAGE' | translate) : ('TR_UPDATE_PAYMENT_PACKAGE'
        | translate) }}</h5>
    </div> -->
    <form (submit)="submitData()" [formGroup]="form">

      <div class="position-relative form-check my-1 d-flex flex-column pl-0">
        <label for=" " class="form-check-label mb-2">{{ 'TR_PAYMENT_PACKAGE_TYPE' | translate }}</label>
        <div class="d-inline-block" ngbDropdown>
          <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-primary">
            {{ selectedTypeName ? (selectedTypeName | translate) : 'TR_CUSTOM' | translate }}
          </button>
          <div ngbDropdownMenu>
            <button class="dropdown-item" (click)="changePaymentPackageType($event, 3, 'TR_CUSTOM')">{{ 'TR_CUSTOM' |
              translate }}</button>
            <button class="dropdown-item" (click)="changePaymentPackageType($event, 1, 'TR_FOR_SALE' )">{{ 'TR_FOR_SALE'
              | translate }}</button>
          </div>
        </div>
      </div>
      <hr>
      <div class="form-row">
        <div class="col-md-4">
          <div class="position-relative form-group">
            <label for="packageName">{{ ('TR_NAME' | translate) + mainService.asterisk }}</label>
            <!-- [placeholder]="('TR_NAME' | translate) + mainService.asterisk" -->
            <input name="packageName" type="text" class="form-control"
              [class.error-border]="isInvalidSubmit && !form.value.packageName" autocomplete="off"
              formControlName="packageName" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="position-relative form-group">
            <label for="description">{{ 'TR_DESCRIPTION' | translate }}</label>
            <!-- [placeholder]="'TR_DESCRIPTION' | translate" -->
            <input name="description" type="text" class="form-control" autocomplete="off"
              formControlName="description" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="mb-1 w-100 mr-1">
            <label for="currencyName" class="m-0">{{ 'TR_CURRENCY' | translate}} *</label>
            <div ngbDropdown>
              <button type="button" ngbDropdownToggle class="mb-2 mr-1 btn btn-outline-primary w-100"
                [class.error-border]="isInvalidSubmit  && !selectedCurrencyName">
                {{ selectedCurrencyName ? selectedCurrencyName : 'TR_CURRENCY' | translate
                }}
              </button>
              <div ngbDropdownMenu>
                <button class="dropdown-item" *ngFor="let item of currencies"
                  (click)="setCurrency(item.id, item.isocode)" type="button">
                  {{ item.isocode }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-4">
          <div class="position-relative form-group">
            <label for="cost">{{('TR_COST' | translate) + mainService.asterisk}}</label>
            <!-- [placeholder]="('TR_COST' | translate) + mainService.asterisk" -->
            <input name="cost" type="number" class="form-control"
              [class.error-border]="isInvalidSubmit && !form.value.cost" autocomplete="off" formControlName="cost" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="position-relative form-group">
            <label for="storageSize">{{ ('TR_STORAGE_SIZE' | translate) + mainService.asterisk}}</label>
            <!-- [placeholder]="'TR_STORAGE_SIZE' | translate" -->
            <input name="storageSize" type="number" class="form-control"
              [class.error-border]="isInvalidSubmit && !form.value.storageSize" autocomplete="off"
              formControlName="storageSize" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="position-relative form-group">
            <label for="webinarStudentsMaxCount">{{ ('TR_WEBINAR_STUDENTS_MAX_COUNT' | translate) + mainService.asterisk
              }}</label>
            <!-- [placeholder]="'TR_WEBINAR_STUDENTS_MAX_COUNT' | translate" -->
            <input name="webinarStudentsMaxCount"
              [class.error-border]="isInvalidSubmit && !form.value.webinarStudentsMaxCount" type="number"
              class="form-control" autocomplete="off" formControlName="webinarStudentsMaxCount" />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-4">
          <div class="position-relative form-group">
            <label for="userMaxCount">{{ ('TR_ORGANIZATION_USERS_MAX_COUNT' | translate) + mainService.asterisk
              }}</label>
            <!-- [placeholder]="'TR_ORGANIZATION_USERS_MAX_COUNT' | translate" -->
            <input name="userMaxCount" type="number" [class.error-border]="isInvalidSubmit && !form.value.userMaxCount"
              class="form-control" autocomplete="off" formControlName="userMaxCount" />
          </div>
        </div>
      </div>
      <hr>
      <div class="position-relative form-check my-1">
        <input name="allowSaveVideo" id="allowSaveVideo" type="checkbox" class="form-check-input pointer"
          formControlName="allowSaveVideo">
        <label for="allowSaveVideo" class="form-check-label pointer">{{ 'TR_ALLOW_VIDEO_SAVE' | translate }}</label>
      </div>
      <div class="position-relative form-check my-1">
        <input name="documentService" id="documentService" type="checkbox" class="form-check-input pointer"
          formControlName="documentService">
        <label for="documentService" class="form-check-label pointer">{{ 'TR_DOCUMENT_SERVICE' | translate }}</label>
      </div>
      <div class="position-relative form-check my-1">
        <input name="userTypeService" id="userTypeService" type="checkbox" class="form-check-input pointer"
          formControlName="userTypeService">
        <label for="userTypeService" class="form-check-label pointer">{{ 'TR_USER_TYPE_SERVICE' | translate }}</label>
      </div>
      <div class="position-relative form-check my-1">
        <input name="courseService" id="courseService" type="checkbox" class="form-check-input pointer"
          formControlName="courseService">
        <label for="courseService" class="form-check-label pointer">{{ 'TR_COURSE_SERVICE' | translate }}</label>
      </div>
      <div class="position-relative form-check my-1">
        <input name="webinarService" id="webinarService" type="checkbox" class="form-check-input pointer"
          formControlName="webinarService">
        <label for="webinarService" class="form-check-label pointer">{{ 'TR_WEBINAR_SERVICE' | translate }}</label>
      </div>
      <div class="position-relative form-check my-1">
        <input name="fileLibraryService" id="fileLibraryService" type="checkbox" class="form-check-input pointer"
          formControlName="fileLibraryService">
        <label for="fileLibraryService" class="form-check-label pointer">{{ 'TR_FIBRARY_SERVICE' | translate }}</label>
      </div>
      <div class="position-relative form-check my-1">
        <input name="innerPaymentService" id="innerPaymentService" type="checkbox" class="form-check-input pointer"
          formControlName="innerPaymentService">
        <label for="innerPaymentService" class="form-check-label pointer">{{ 'TR_INTERNAL_PAYMENT' | translate
          }}</label>
      </div>

      <app-submit-button [spinnerId]="submitSpinnerId"
        [buttonName]="isCreateForm ? ('TR_CREATE' | translate) : ('TR_UPDATE' | translate)"
        [isInvalidSubmit]="isInvalidSubmit" [isFormValid]="form.valid"></app-submit-button>
    </form>
  </div>
</div>