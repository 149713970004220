import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { guid } from '@progress/kendo-angular-common';
import { ActivatedRoute } from '@angular/router';
import { IFileLibraryData } from 'src/app/Models/IFileLibraryData';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { AlertService } from 'src/app/Services/alert.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { FILE_LIBRARY_PATH_KEY, FILE_LIBRARY_URL_KEY } from 'src/app/Constants/request-path-keys';
import {
  SUCCESS_KEY,
  TR_CREATE_FILE_KEY,
  TR_CREATE_MESSAGE_KEY,
  TR_UPDATE_FILE_KEY,
  TR_UPDATE_MESSAGE_KEY,
  VALID_FILE_TYPES_KEY,
  VALID_IMAGE_TYPES_KEY
} from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-file-library-form',
  templateUrl: './file-library-form.component.html',
  styleUrls: ['./file-library-form.component.scss']
})
export class FileLibraryFormComponent implements OnInit {
  form: FormGroup;
  fileId: number;
  uploadedFile: any;
  isImage: boolean;
  isVideo: boolean;
  isFile: boolean;
  isInvalidSubmit: boolean;
  isCreateForm: boolean = true;
  submitSpinnerId: string = guid();
  userRoles: Array<string> = [];

  constructor(
    private formBuilder: FormBuilder,
    private dataHandlerService: DataHandlerService,
    private translationService: TranslationService,
    private route: ActivatedRoute,
    public mainService: MainService,
    private rolesService: RolesService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.translationService.setPageTitle(TR_CREATE_FILE_KEY);
    this.userRoles = this.rolesService.userRoles;

    this.form = this.formBuilder.group({
      name: [''],
      fileEntity: ['']
    });

    this.route.paramMap.subscribe(params => {
      this.fileId = +params.get('item.id');

      if (this.fileId) {
        this.isCreateForm = false;
        this.translationService.setPageTitle(TR_UPDATE_FILE_KEY);
        this.getFileDataById(this.fileId);
      }
    });
  }

  getFileDataById(id: number): void {
    this.dataHandlerService.getDataWithSpinner(`${FILE_LIBRARY_PATH_KEY}/${id}`).then(response => {
      const fileData: IFileLibraryData = response;
      this.form.patchValue({
        name: fileData.name,
        fileEntity: fileData.filePath
      });
      this.uploadedFile = fileData.filePath;
      this.isImage = fileData.mimeType.includes('image');
      this.isVideo = fileData.mimeType.includes('video');
    });
  }

  async handleFileUpload(files: FileList) {
    if (files && files[0]) {
      this.mainService.fileLibraryUpload(files[0]).then((response: any) => {
        this.uploadedFile = response.result;
        this.form.patchValue({ fileEntity: files[0] });
        const fileExtention = response.fileExtention;
        this.isImage = VALID_IMAGE_TYPES_KEY.includes(fileExtention);
        this.isFile = VALID_FILE_TYPES_KEY.includes(fileExtention);
        this.isVideo = (fileExtention === 'mp4');
      });
    }
  }

  submitData(): void {
    if (this.form.valid) {
      const form = { ...this.form.value };
      const formData = new FormData();
      for (let i in form) {
        formData.append(`${i}`, form[i]);
      }
      if (this.fileId) {
        formData.append('id', this.fileId.toString());
        this.dataHandlerService.updateFormData(formData, FILE_LIBRARY_PATH_KEY, this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
          this.mainService.goBack(FILE_LIBRARY_URL_KEY);
        });
      } else {
        this.dataHandlerService.createFormData(formData, FILE_LIBRARY_PATH_KEY, this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
          this.mainService.goBack(FILE_LIBRARY_URL_KEY);
        });
      }
    } else {
      this.isInvalidSubmit = true;
    }
  }
}
