import { Injectable, OnDestroy } from "@angular/core";
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { AlertService } from "./alert.service";
import { LanguageService } from './language.service';
import { ITemplateData } from '../Models/ITemplateData';
import { IHelpAlertData, IHelpResponseData } from "../Models/IHelpAlertData";
import {
  ADMIN_KEY,
  ERROR_KEY,
  TR_VALID_FILE_MESSAGE_ERROR_KEY,
  TR_VALID_IMAGE_MESSAGE_ERROR_KEY,
  VALID_FILE_LIBRARY_TYPES_KEY,
  VALID_FILE_TYPES_KEY,
  VALID_IMAGE_TYPES_KEY
} from '../Constants/main-keys';

@Injectable({
  providedIn: 'root'
})
export class MainService implements OnDestroy {
  public asterisk: string = '*';

  templateData: BehaviorSubject<ITemplateData | boolean> = new BehaviorSubject(null);
  selectedDocumentCategory: BehaviorSubject<any> = new BehaviorSubject(null);

  questionSubmitted: BehaviorSubject<ITemplateData | boolean> = new BehaviorSubject(null);

  pageSpinners: BehaviorSubject<Array<string>> = new BehaviorSubject<Array<string>>([]);
  modalSpinners: BehaviorSubject<Array<string>> = new BehaviorSubject<Array<string>>([]);
  buttonSpinners: BehaviorSubject<Array<string>> = new BehaviorSubject<Array<string>>([]);

  handleValidationError: BehaviorSubject<Array<string> | undefined> = new BehaviorSubject<Array<string> | undefined>(null);
  // helpData: BehaviorSubject<Array<IHelpResponseData> | undefined> = new BehaviorSubject<Array<IHelpResponseData> | undefined>(null);
  helpData: BehaviorSubject<Array<any> | undefined> = new BehaviorSubject<Array<any> | undefined>(null);

  constructor(
    private router: Router,
    private languageService: LanguageService,
    private alertService: AlertService
  ) { }

  ngOnDestroy() {
    this.pageSpinners.unsubscribe();
    this.modalSpinners.unsubscribe();
    this.buttonSpinners.unsubscribe();
    this.questionSubmitted.unsubscribe();
    this.selectedDocumentCategory.unsubscribe();
    this.templateData.unsubscribe();
    this.handleValidationError.unsubscribe();

  }

  //PAGE LOADER
  addPageSpinner(spinnerId: string): void {
    this.pageSpinners.value.push(spinnerId)
    this.pageSpinners.next(this.pageSpinners.value);
  }

  removePageSpinner(spinnerId: string): void {
    const filteredSpinners = this.pageSpinners.value.filter(data => data !== spinnerId);
    this.pageSpinners.next(filteredSpinners);
  }

  //MODAL LOADER
  addModalSpinner(spinnerId: string): void {
    this.modalSpinners.value.push(spinnerId)
    this.modalSpinners.next(this.modalSpinners.value);
  }

  removeModalSpinner(spinnerId: string): void {
    const filteredSpinners = this.modalSpinners.value.filter(data => data !== spinnerId);
    this.modalSpinners.next(filteredSpinners);
  }

  //FORM SUBMIT BUTTON LOADER
  addButtonSpinner(spinnerId: string): void {
    this.buttonSpinners.value.push(spinnerId)
    this.buttonSpinners.next(this.buttonSpinners.value);
  }

  removeButtonSpinner(spinnerId: string): void {
    const filteredSpinners = this.buttonSpinners.value.filter(data => data !== spinnerId);
    this.buttonSpinners.next(filteredSpinners);
  }

  //CLEAR SPINNERS
  clearSpinners(): void {
    this.buttonSpinners.next([]);
    this.modalSpinners.next([]);
    this.pageSpinners.next([]);
  }

  //SET HELP DATA
  setHelpData(data: Array<IHelpResponseData>) {
    this.helpData.next(data);
  }

  getHelpData(): Array<IHelpResponseData> | undefined {
    return this.helpData.getValue();
  }

  //GO BACK (NAVIGATION)
  goBack(URL: string): void {
    this.router.navigate([this.languageService.currentLanguage, ADMIN_KEY, URL]);
  }

  //GO BACK MULTIPARAMS
  goBackWithParams(params: Array<any>): void {
    this.router.navigate([this.languageService.currentLanguage, ADMIN_KEY, ...params]);
  }

  //DATE
  getDateFormat(date: Date): string {
    const year = date.getFullYear();
    const month = this.checkDateNumber(date.getMonth() + 1);
    const day = this.checkDateNumber(date.getDate());
    return `${year}-${month}-${day}`;
  }

  checkDateNumber(date) {
    return date.toString().length === 1 ? `0${date}` : date;
  }

  convertUTCDateToLocalDate(date) {
    let offset = date.getTimezoneOffset() / 60;
    let hours = date.getHours();

    date.setHours(hours - offset);

    return date;
  }

  getUTCTime(time: string, checkDateChangings: boolean): any {
    if (!time) { return; }
    let minusDate;
    let plusDate;
    const split = time.split(':');
    const date = new Date();
    date.setHours(+split[0]);
    date.setMinutes(+split[1]);
    if (checkDateChangings) {
      const minutes = (+split[0] * 60) + +split[1];
      const timeZoneOffset = date.getTimezoneOffset();
      if (minutes + timeZoneOffset < 0) {
        minusDate = true;
      }
      else if (minutes + timeZoneOffset > 1440) {
        plusDate = true;
      }
    }
    return { time: moment.utc(date).format('HH:mm'), minusDate: minusDate, plusDate: plusDate };
  }

  //firstname,lastname
  isValidField(fieldValue: string, fieldName: string) {
    if (fieldName === "firstname" || fieldName === "lastname") {
      const idValid = /^(?:((([^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]'’,\-.\s])){1,}(['’,\-\.]){0,1}){2,}(([^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]'’,\-. ]))*(([ ]+){0,1}(((([^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]'’,\-\.\s])){1,})(['’\-,\.]){0,1}){2,}((([^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]'’,\-\.\s])){2,})?)*)$/;
      return idValid.test(fieldValue);
    }
    if (fieldName === "email") {
      const idValid = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
      return idValid.test(fieldValue);
    }
    if (fieldName === "password" || fieldName === "confirmPassword") {
      const idValid = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*$/;
      return idValid.test(fieldValue);
    }
    if (fieldName === "phonenumber") {
      const idValid = /^[0-9]+$/;
      return idValid.test(fieldValue);
    }
  }

  checkDates(firstDate: any, secondDate: any) {
    if (!firstDate || !secondDate) { return; }
    if (new Date(firstDate).getTime() < new Date(secondDate).getTime()) {
      return true;
    } else return false;
  }

  checkDatesMoreOrEqual(firstDate: any, secondDate: any) {
    if (!firstDate || !secondDate) { return; }
    if (new Date(firstDate).getTime() <= new Date(secondDate).getTime()) {
      return true;
    } else return false;
  }

  //IMAGE UPLOAD
  imageUpload(file: File) {
    return new Promise((resolve, reject) => {
      const validTypes = VALID_IMAGE_TYPES_KEY;
      const fileName = file.name;
      const lastDotIndex = fileName.lastIndexOf('.');
      const fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1) : ' ';
      const isValid = validTypes.find(data => data === fileExtention);

      if (!isValid) {
        this.alertService.translateAndAlertMessage(TR_VALID_IMAGE_MESSAGE_ERROR_KEY, ERROR_KEY);
        reject();
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          resolve(reader.result);
        }
        reader.onerror = reject;
      }
    });
  }

  //FILE UPLOAD
  fileUpload(file: File) {
    return new Promise((resolve, reject) => {
      const validTypes = VALID_FILE_TYPES_KEY;
      const fileName = file.name;
      const lastDotIndex = fileName.lastIndexOf('.');
      const fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1) : ' ';
      const isValid = validTypes.find(data => data === fileExtention);

      if (!isValid) {
        this.alertService.translateAndAlertMessage(TR_VALID_FILE_MESSAGE_ERROR_KEY, ERROR_KEY);
        reject();
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          resolve(reader.result);
        }
        reader.onerror = reject;
      }
    });
  }

  //FILE UPLOAD
  csvFileUpload(file: File) {
    return new Promise((resolve, reject) => {
      const fileName = file.name;
      const lastDotIndex = fileName.lastIndexOf('.');
      const fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1) : ' ';
      const isValid = 'csv' === fileExtention;

      if (!isValid) {
        this.alertService.translateAndAlertMessage(TR_VALID_FILE_MESSAGE_ERROR_KEY, ERROR_KEY);
        reject();
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          resolve(reader.result);
        }
        reader.onerror = reject;
      }
    });
  }

  //FILE LIBRARY UPLOAD
  fileLibraryUpload(file: File) {
    return new Promise((resolve, reject) => {
      const validTypes = VALID_FILE_LIBRARY_TYPES_KEY;
      const fileName = file.name;
      const lastDotIndex = fileName.lastIndexOf('.');
      const fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1) : ' ';
      const isValid = validTypes.find(data => data === fileExtention);
      if (!isValid) {
        this.alertService.translateAndAlertMessage(TR_VALID_FILE_MESSAGE_ERROR_KEY, ERROR_KEY);
        reject();
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          resolve({ result: reader.result, fileExtention: fileExtention });
        }
        reader.onerror = reject;
      }
    });
  }

  //VIDEO UPLOAD
  videoUpload(file: File) {
    return new Promise((resolve, reject) => {
      const fileName = file.name;
      const lastDotIndex = fileName.lastIndexOf('.');
      const fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1) : ' ';
      const isValid = fileExtention == 'mp4';

      if (!isValid) {
        this.alertService.translateAndAlertMessage(TR_VALID_FILE_MESSAGE_ERROR_KEY, ERROR_KEY);
        reject();
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          resolve(reader.result);
        }
        reader.onerror = reject;
      }
    });
  }
}
