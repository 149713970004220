import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { AlertService } from 'src/app/Services/alert.service';
import { IPaymentPackageData } from 'src/app/Models/IPaymentPackageData';
import {
  PACKAGE_PROPERTY_CREATE,
  PACKAGE_PROPERTY_DELETE,
  PACKAGE_PROPERTY_UPDATE,
  PACKAGE_PROPERTY_VIEW,
  PAYMENT_PACKAGE_CREATE,
  PAYMENT_PACKAGE_DELETE,
  PAYMENT_PACKAGE_UPDATE,
  PAYMENT_PACKAGE_VIEW,
  SUCCESS_KEY,
  TR_CREATE_MESSAGE_KEY,
  TR_DELETE_MESSAGE_KEY,
  TR_PAYMENT_PACKAGES_KEY,
  TR_UPDATE_MESSAGE_KEY
} from 'src/app/Constants/main-keys';
import { guid } from '@progress/kendo-angular-common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MainService } from 'src/app/Services/main.service';
import { IPaymentPackagePropertyDiscountData } from 'src/app/Models/IPaymentPackagePropertyDiscountData';
import { IPaymentPackagePropertiesData } from 'src/app/Models/IPaymentPackagePropertiesData';

@Component({
  selector: 'app-package-properties-table',
  templateUrl: './package-properties-table.component.html',
  styleUrls: ['./package-properties-table.component.scss']
})
export class PackagePropertiesTableComponent implements OnInit {
  form: FormGroup;
  priceForm: FormGroup;

  package_property_view: boolean;
  package_property_create: boolean;
  package_property_update: boolean;
  package_property_delete: boolean;


  userRoles: Array<string> = [];
  deleteSpinnerId: string = guid();
  packageProperties: Array<IPaymentPackagePropertiesData> = [];
  deletePackagePropertyId: number;
  deletePackagePropertyDiscountId: number;
  deletePackagePropertyPriceId: number;
  deletePackagePropertyName: string;


  isInvalidSubmit: boolean;
  isInvalidPriceSubmit: boolean;
  packagePropertyName: string = "";
  packagePropertyId: number = null;
  maxPrice: number = 10000;
  packagePropertyDiscounts: Array<IPaymentPackagePropertyDiscountData> = [];
  packagePropertyDiscountData: object = null;
  packagePropertyDiscountId: number = null;
  submitSpinnerId: string = guid();


  inInvalidCurrency: boolean;
  currencies: any = [];
  priceModel: any = [];


  packagePropertyPrices: any = [];
  selectedCurrencyName: string = "";
  selectedCurrencyId: number = null;


  constructor(
    private formBuilder: FormBuilder,
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    private translationService: TranslationService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private mainService: MainService,
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle("TR_PAYMENT_PACKAGE_PROPERTIES");

    this.form = this.formBuilder.group({
      minValue: [null, [Validators.required, Validators.min(1), Validators.max(this.maxPrice)]],
      maxValue: [null, [Validators.required, Validators.min(1), Validators.max(this.maxPrice)]],
      discountPercent: [null, [Validators.min(0), Validators.max(100)]],
    });

    this.priceForm = this.formBuilder.group({
      currencyId: [null, Validators.required],
      price: [null, [Validators.required, Validators.min(1), Validators.max(this.maxPrice)]],
    });

    this.getCurrencies();
    this.getPackagProperties();
  }

  getCurrencies() {
    if (!this.package_property_create) { return; }
    this.dataHandlerService.getDataWithSpinner("currency").then(response => {
      const data = [...response];
      this.currencies = data;
    });
  }

  setCurrency(id: number, name: string) {
    this.selectedCurrencyName = name;
    this.priceForm.patchValue({
      currencyId: id,
    });
  }

  setRoles() {
    this.userRoles.forEach(role => {
      switch (role) {
        case PACKAGE_PROPERTY_VIEW:
          this.package_property_view = true;
          break;
        case PACKAGE_PROPERTY_CREATE:
          this.package_property_create = true;
          break;
        case PACKAGE_PROPERTY_UPDATE:
          this.package_property_update = true;
          break;
        case PACKAGE_PROPERTY_DELETE:
          this.package_property_delete = true;
          break;
      }
    });
  }

  openPackagePropertyModal(target: HTMLElement, content: any, data: any) {
    if (target.nodeName !== 'TD' || !data) { return; }
    this.getPackagePropertyDiscounts(data.id);
    this.packagePropertyId = data.id;
    this.packagePropertyName = data.displayName;
    this.modalService.open(content, { size: 'lg' }).result;
  }

  openPackagePropertyPriceModal(target: HTMLElement, content: any, data: any) {
    if (!data) { return; }
    this.getPackagePropertyPrices(data.id);
    this.packagePropertyName = data.displayName;
    this.packagePropertyId = data.id;
    this.modalService.open(content, { size: 'lg' }).result;
  }

  getPackagePropertyDiscounts(id: number) {
    this.dataHandlerService.getDataWithSpinner(`PackageProperty/discountbyproperty/${id}`).then(response => {
      this.packagePropertyDiscounts = response;
    });
  }

  getPackagePropertyPrices(id: number) {
    this.dataHandlerService.getDataWithSpinner(`PackageProperty/pricebyproperty/${id}`).then(response => {
      const data = [...response];
      if (data && data.length && this.currencies && this.currencies.length) {
        this.currencies.forEach(currency => {
          data.forEach(priceItem => {
            if (priceItem.currencyId === currency.id) {
              priceItem.selectedCurrencyName = currency.isocode;
            }
          });
        })
        this.packagePropertyPrices = data;
      } else {
        this.packagePropertyPrices = [];
      }
    });
  }

  closePackagePropertyModal() {
    this.packagePropertyName = '';
    this.packagePropertyId = null;
    this.isInvalidSubmit = false;
    this.modalService.dismissAll();
  }

  closePropertyPriceModal() {
    this.isInvalidPriceSubmit = false;
    this.selectedCurrencyName = "";
    this.selectedCurrencyId = null;
    this.priceForm.patchValue({
      currencyId: null,
      price: "",
    });
    this.modalService.dismissAll();
  }

  getPackagProperties() {
    this.dataHandlerService.getDataWithSpinner("PackageProperty/admin").then(response => this.packageProperties = response);
  }

  confirmDelete(content, id: number, name: string): void {
    this.deletePackagePropertyId = id;
    this.deletePackagePropertyName = name;
    this.modalService.open(content).result;
  }

  onCkeckboxChange(event: any) {
    event.preventDefault();
    event.target.checked = !event.target.checked;
  }

  delete(): void {
    this.dataHandlerService.deleteData(this.deletePackagePropertyId, "PackageProperty", this.deleteSpinnerId).then(() => {
      this.alertService.translateAndAlertMessage(TR_DELETE_MESSAGE_KEY, SUCCESS_KEY);
      this.getPackagProperties();
      this.modalService.dismissAll();
    });
  }

  confirmPackagePropertyDiscountDelete(content, id: number): void {
    this.deletePackagePropertyDiscountId = id;
    this.modalService.open(content).result;
  }

  confirmPackagePropertyPriceDelete(content, id: number): void {
    this.deletePackagePropertyPriceId = id;
    this.modalService.open(content).result;
  }

  deletePackagePropertyDiscount(): void {
    if (!this.deletePackagePropertyDiscountId) { return; }
    this.dataHandlerService.deleteData(this.deletePackagePropertyDiscountId, "PackageProperty/discount", this.deleteSpinnerId).then(() => {
      this.alertService.translateAndAlertMessage(TR_DELETE_MESSAGE_KEY, SUCCESS_KEY);
      this.modalService.dismissAll();
    });
  }

  deletePackagePropertyPrice(): void {
    if (!this.deletePackagePropertyPriceId) { return; }
    this.dataHandlerService.deleteData(this.deletePackagePropertyPriceId, "PackageProperty/price", this.deleteSpinnerId).then(() => {
      this.alertService.translateAndAlertMessage(TR_DELETE_MESSAGE_KEY, SUCCESS_KEY);
      this.modalService.dismissAll();
    });
  }

  cancel() {
    this.packagePropertyDiscountId = null;
    this.selectedCurrencyId = null;
    this.selectedCurrencyName = "";

    this.form.patchValue({
      minValue: null,
      maxValue: null,
      discountPercent: null,
    });

    this.priceForm.patchValue({
      currencyId: null,
      price: "",
    });
  }

  setPackagePropertyDiscount(data: any) {
    if (!data) { return; }
    this.packagePropertyDiscountId = data.id;
    this.form.patchValue({
      minValue: data.minValue,
      maxValue: data.maxValue,
      discountPercent: data.discountPercent,
    });
  }

  setPackagePropertyPrice(data: any) {
    if (!data) { return; }
    this.selectedCurrencyId = data.id;
    let currentPackagePropertyPrice = this.currencies.find(item => item.id === data.currencyId);
    this.selectedCurrencyName = currentPackagePropertyPrice.isocode;
    this.priceForm.patchValue({
      currencyId: data.currencyId,
      price: data.price,
    });
  }

  submitPriceData(): void {
    if (this.priceForm.valid) {
      const form = { ...this.priceForm.value };
      form.packagePropertyId = this.packagePropertyId;
      if (this.selectedCurrencyId) {
        form.id = this.selectedCurrencyId;
        this.dataHandlerService.updateData(form, "PackageProperty/price", this.submitSpinnerId).then(response => {
          this.mainService.goBack("package-property");
          this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
          this.getPackagePropertyPrices(this.packagePropertyId);
        });
      } else {
        this.dataHandlerService.createData(form, "PackageProperty/price", this.submitSpinnerId).then(response => {
          this.mainService.goBack("package-property");
          this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
          this.getPackagePropertyPrices(this.packagePropertyId);
        });
      }
      this.selectedCurrencyName = "";
      this.selectedCurrencyId = null;
      this.priceForm.patchValue({
        currencyId: null,
        price: "",
      });
    } else {
      this.isInvalidPriceSubmit = true;
    }
  }

  submitData(): void {
    if (this.form.valid) {
      const form = { ...this.form.value };
      if (((!form.minValue || !form.maxValue) || (form.minValue && form.maxValue && (form.maxValue <= 0 || form.maxValue < form.minValue)))) {
        this.isInvalidSubmit = true;
        return;
      }
      form.packagePropertyId = this.packagePropertyId;
      if (this.packagePropertyDiscountId) {
        form.id = this.packagePropertyDiscountId;
        this.dataHandlerService.updateData(form, "PackageProperty/discount", this.submitSpinnerId).then(response => {
          this.mainService.goBack("package-property");
          this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
          this.getPackagePropertyDiscounts(this.packagePropertyId);
        });
      } else {
        this.dataHandlerService.createData(form, "PackageProperty/discount", this.submitSpinnerId).then(response => {
          this.mainService.goBack("package-property");
          this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
          this.getPackagePropertyDiscounts(this.packagePropertyId);
        });
      }
      this.packagePropertyDiscountId = null;
      this.form.patchValue({
        minValue: null,
        maxValue: null,
        discountPercent: null,
      });

    } else {
      this.isInvalidSubmit = true;
    }
  }

}
