<div class="h-100 bg-plum-plate bg-animation">
  <div class="d-flex h-100 justify-content-center align-items-center">
    <div class="mx-auto app-login-box col-md-8">
      <div class="app-logo-inverse mx-auto mb-3"></div>
      <div class="modal-dialog w-100 mx-auto">
        <div class="modal-content">
          <form (submit)="signIn()" [formGroup]="form">
            <div class="modal-header">
              <div class="h5 modal-title">
                {{ 'TR_WELCOME' | translate }}
                <!-- <h6 class="mt-1 mb-0 opacity-8"><span>{{ 'TR_RECOVER_MSG' | translate }}</span></h6> -->
              </div>
            </div>
            <div class="modal-body">
              <div id="exampleInputGroup1" role="group" aria-describedby="exampleInputGroup1__BV_description_" class="form-group">
                <div>
                  <label>{{ 'TR_ENTER_LOGIN' | translate }}</label>
                  <input
                    id="exampleInput1"
                    type="text"
                    autocomplete="off"
                    [placeholder]="'TR_ENTER_LOGIN' | translate"
                    required="required"
                    class="form-control"
                    formControlName="username"
                  />
                </div>
              </div>
              <div id="exampleInputGroup2" role="group" class="form-group">
                <div>
                  <label>{{ 'TR_ENTER_PASSWORD' | translate }}</label>
                  <input
                    id="exampleInput2"
                    type="password"
                    [placeholder]="'TR_ENTER_PASSWORD' | translate"
                    required="required"
                    aria-required="true"
                    class="form-control"
                    formControlName="password"
                  />
                </div>
                  <small
                    *ngIf="errorMessage"
                    tabindex="-1"
                    id="exampleInputGroup1__BV_description_"
                    class="form-text text-muted error"
                  >
                    {{ errorMessage }}
                  </small>
              </div>
            </div>
            <div class="modal-footer clearfix">
              <div class="float-left">
                <a [routerLink]="['recover-password']" class="btn-lg btn btn-link">{{ 'TR_RECOVER_PASSWORD' | translate }}</a>
              </div>
              <div class="float-right">
                <div [ngClass]="isDisabledSignButton ? 'disabled' : '' ">
                  <app-submit-button
                    [buttonName]="'TR_SIGN_IN' | translate"
                    [buttonColor]="'btn-warning'"
                    [spinnerId]="submitSpinnerId"
                  >
                  </app-submit-button>
                </div>
                </div>
            </div>
          </form>
        </div>
      </div>
      <div class="text-center text-white opacity-8 mt-3">
        © {{ currentYear }} Mindalay
      </div>
    </div>
  </div>
</div>