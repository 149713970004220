import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MainService } from 'src/app/Services/main.service';
import { AlertService } from 'src/app/Services/alert.service';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { IUserData } from 'src/app/Models/IUserData';
import { NEW_PASSWORD_PATH_KEY, UPDATEPASSWORD_KEY, USERS_PATH_KEY, USERS_URL_KEY } from 'src/app/Constants/request-path-keys';
import { SUCCESS_KEY, TR_AVATAR_SUCCESS_UPLOAD_KEY, TR_RESET_PASSWORD_SUCCESS_KEY, USER_KEY } from 'src/app/Constants/main-keys';
import { DataStorageService } from './../../../Services/data-storage.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  user: IUserData = JSON.parse(localStorage.getItem(USER_KEY));
  form: FormGroup;
  isInvalidSubmit: boolean;
  notEqualError: boolean;
  uploadedImagePath: any = this.user.avatarFilePath;
  uploadedImage: File;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private alertService: AlertService,
    private dataHandlerService: DataHandlerService,
    public mainService: MainService,
    private dataStorageService: DataStorageService,
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      password: ['', [Validators.required, Validators.pattern(`^.{6,100}$`)]],
      confirmPassword: ['', [Validators.required, Validators.pattern(`^.{6,100}$`)]],
    });

    this.form.controls['password'].valueChanges.subscribe(() => this.notEqualError = false);
    this.form.controls['confirmPassword'].valueChanges.subscribe(() => this.notEqualError = false);
  }

  changePassword(content: any): void {
    this.modalService.open(content);
  }

  async handleImageUpload(files: FileList) {
    if (files && files[0]) {
      this.uploadedImage = files[0];
      this.uploadedImagePath = await this.mainService.imageUpload(files[0]);
    }
  }

  upload() {
    if (!this.uploadedImage) { return; }
    const user = { ...this.user };
    user.image = this.uploadedImage;
    delete user.userTypeName;
    delete user.organizationName;
    delete user.avatarFilePath;    
    

    let formData = new FormData();
    for (let i in user) {
      if (!user[i]) {
        delete user[i];
      } else {
        formData.append(`${i}`, user[i]);
      }
    }

    this.dataHandlerService.updateFormData(formData, USERS_PATH_KEY, null).then(() => {
      this.alertService.translateAndAlertMessage(TR_AVATAR_SUCCESS_UPLOAD_KEY, SUCCESS_KEY);
      this.uploadedImage = null;
      window.location.reload();
    });
  }

  submitData(): void {
    if (this.form.valid) {
      const form = { ...this.form.value };
      if (form.password !== form.confirmPassword) {
        this.notEqualError = true;
        return;
      }
      form.password = btoa(form.password);
      form.confirmPassword = btoa(form.confirmPassword);

      // this.dataHandlerService.updateData(form, `${USERS_PATH_KEY}/${NEW_PASSWORD_PATH_KEY}`, null).then(() => {
      this.dataHandlerService.updateData(form, `${USER_KEY}/${UPDATEPASSWORD_KEY}`, null).then(() => {
        this.alertService.translateAndAlertMessage(TR_RESET_PASSWORD_SUCCESS_KEY, SUCCESS_KEY);
        this.modalService.dismissAll();
      });
    } else {
      this.isInvalidSubmit = true;
    }
  }

  cancel(): void {
    this.uploadedImage = null;
    this.uploadedImagePath = this.user?.avatarFilePath;
  }

}
