<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon"><i class="icon pe-7s-star icon-gradient bg-plum-plate"></i></div>
      <div>{{ 'TR_ORGANIZATIONS' | translate }}</div>
    </div>
    <div *ngIf="organization_create" class="page-title-actions d-flex"><button class="mr-2 btn btn-success create-btn"
        [title]="'TR_CREATE_ORGANIZATION' | translate" [routerLink]="['organization-form']">
        <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_CREATE' | translate }}</span>
      </button></div>
  </div>
</div>
<!-- <div class="title-block mb-3">
  <h4 class="card-title">{{ 'TR_ORGANIZATIONS' | translate }}</h4>
</div> -->

<!-- <div *ngIf="organization_create" class="d-inline-block">
  <button class="mb-2 mr-2 btn btn-success create-btn" [title]="'TR_CREATE_ORGANIZATION' | translate"
    [routerLink]="['organization-form']">
    <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_CREATE' | translate }}</span>
  </button>
</div> -->

<div class="row">
  <div class="col-12">
    <div *ngIf="organization_view" class="main-card mb-3 card">
      <div class="table-responsive">
        <table class="align-middle mb-0 table table-striped table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ 'TR_NAME' | translate }}</th>
              <th>{{ 'TR_ADMINISTRATOR' | translate }}</th>
              <th>{{ 'TR_ACTIVE' | translate }}</th>
              <th class="text-right">{{ 'TR_ACTIONS' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="pointer" title="Click to see full data" *ngFor="let item of organizations let i = index"
              (click)="viewOrganization($event.target, organizationview, item.id)">
              <td>{{ i + 1 }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.organizationAdminName }}</td>
              <td class="text-center">
                <input name="active-organization" type="checkbox" class="mt-2 pointer" disabled
                  [checked]="item.isActive" />
              </td>
              <td class="text-right">
                <div role="group" class="btn-group-sm btn-group">
                  <button *ngIf="organization_update" class="m-1 btn-transition btn btn-outline-info"
                    [title]="'TR_UPDATE_ORGANIZATION' | translate" [routerLink]="['organization-form', item.id]">
                    <i class="pe-7s-note"></i>
                  </button>
                  <button *ngIf="group_view" class="m-1 btn-transition btn btn-outline-success"
                    [title]="'TR_ORGANIZATION_GROUPS' | translate" (click)="navigateToGroups(item.id)">
                    <i class="pe-7s-users"></i>
                  </button>
                  <button class="m-1 btn-transition btn btn-outline-warning"
                    [title]="'TR_STREAMING_SETTINGS' | translate" [routerLink]="['settings', item.id]">
                    <i class="pe-7s-settings"></i>
                  </button>

                  <button *ngIf="organization_update" class="m-1 btn-transition btn btn-outline-info"
                    [title]="'TR_UPDATE_PACKAGE' | translate" [routerLink]="['payment-package', item.id]">
                    <i class="pe-7s-note"></i>
                  </button>

                  <button *ngIf="item.type == 0 && !item.isInitialized" class="m-1 btn-transition btn btn-outline-info"
                    [title]="'TR_INITIALIZATION' | translate"
                    (click)="confirmInitialization(initializationconfirm, item.id, item.name)">
                    <i class="pe-7s-magic-wand"></i>
                  </button>

                  <button *ngIf="org_contract_create" class="m-1 btn-transition btn btn-outline-info"
                    [title]="('TR_CREATE_OR_UPDATE_CONTRACT' | translate)" [routerLink]="['contract', item.id]">
                    <i class="pe-7s-note2"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #initializationconfirm let-c="close" let-d="dismiss">
  <div class="custom-modal modal-body">
    <p>{{ 'TR_INITIALIZATION_CONFIRM' | translate }} <b class="word-break-break-word">{{ initializatiOrganizationName
        }}</b>?</p>
  </div>
  <div class="custom-modal modal-footer">
    <app-action-button [isCurrent]="true" [spinnerId]="initializationSpinnerId"
      buttonClasses="btn-transition btn btn-outline-primary" spinnerClasses="small-button-spinner"
      [buttonName]="'TR_YES'" (clicked)="initializationOrganization()"></app-action-button>
    <button type="button" class="btn-transition btn btn-danger" (click)="c('Close click')">{{ 'TR_NO' | translate
      }}</button>
  </div>
</ng-template>

<ng-template #organizationview let-c="close" let-d="dismiss">
  <app-organization-view [organizationId]="organizationViewId" (closeEmit)="c('Close click')"></app-organization-view>
</ng-template>