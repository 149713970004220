export class IAlertModel {
  id: string;
  type: AlertType;
  message: string;
  autoClose: boolean;

  constructor(init?: Partial<IAlertModel>) {
    Object.assign(this, init);
  }
}

export enum AlertType {
  Success,
  Error,
  Info,
  Warning
}