<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon"><i class="icon pe-7s-star icon-gradient bg-plum-plate"></i></div>
      <div> {{ 'TR_USERTYPES' | translate }}
        <page-main-info-component id="main_info" data-page-name="angular_admin_user_types">
        </page-main-info-component>
      </div>
    </div>
    <div class="page-title-actions"><button *ngIf="usertype_create" class="mr-2 btn btn-success create-btn"
        [title]="'TR_CREATEUSERTYPE' | translate" [routerLink]="['usertype-form']">
        <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_CREATE' | translate }}</span>
        <app-help-alert alertKey="usertype_create"></app-help-alert>
      </button></div>
  </div>
</div>
<!-- <div class="title-block mb-3">
  <h4 class="card-title">{{ 'TR_USERTYPES' | translate }}</h4>
</div> -->

<div class="row">
  <div class="col-12">
    <div class="flex flex-wrap">
      <!-- <div class="flex-1">
        <button *ngIf="usertype_create" class="mb-2 mr-2 btn btn-success create-btn"
          [title]="'TR_CREATEUSERTYPE' | translate" [routerLink]="['usertype-form']">
          <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_CREATE' | translate }}</span>
          <app-help-alert alertKey="usertype_create"></app-help-alert>
        </button>
      </div> -->

      <div *ngIf="organization_view" ngbDropdown>
        <button type="button" ngbDropdownToggle class="mb-2 btn btn-primary">
          {{ selectedOrganizationName ? selectedOrganizationName : 'TR_ORGANIZATIONS' | translate }}
        </button>
        <div ngbDropdownMenu>
          <button class="dropdown-item" (click)="getAllUsertypes(false)">{{ 'TR_ALL' | translate }}</button>
          <button class="dropdown-item" *ngFor="let item of organizations"
            (click)="getOrganizationUserTypes(item)">{{ item.name }}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="main-card mb-3 card">
      <div class="table-responsive">
        <table class="align-middle mb-0 table table-striped table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ 'TR_USERTYPE' | translate }}</th>
              <th *ngIf="organization_view">{{ 'TR_ORGANIZATION' | translate }}</th>
              <th class="text-center">
                {{ 'TR_ISDEFAULT' | translate }}
                <app-help-alert alertKey="users_available_pages_view"></app-help-alert>
              </th>
              <th *ngIf="usertype_update || usertype_delete" class="text-right">{{ 'TR_ACTIONS' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="pointer" [class.selected]="selectedUserTypeId === item.id"
              *ngFor="let item of userTypes let i = index" (click)="pagesView($event.target, item)">
              <td>{{ i + 1 }}</td>
              <td>{{  item.displayName || item.name }}</td>
              <td *ngIf="organization_view">{{ item.organizationName }}</td>
              <td class="text-center">
                <input disabled [name]="item.name || item.displayName" type="checkbox" class="mt-2 pointer"
                  [(ngModel)]="item.isDefault" />
              </td>
              <td class="text-right">
                <div role="group" class="btn-group-sm btn-group">
                  <button *ngIf="(item.isDefault && default_usertype_update) || (!item.isDefault && usertype_update)"
                    class="btn-transition btn btn-outline-info" [title]="'TR_EDITUSERTYPE' | translate"
                    [routerLink]="['usertype-form', item.id]">
                    <i class="pe-7s-note"></i>
                  </button>
                  <button *ngIf="(item.isDefault && default_usertype_delete) || (!item.isDefault && usertype_delete)"
                    type="button" class="btn btn-outline-danger ml-1" [title]="'TR_DELETE' | translate"
                    (click)="confirmDelete(deleteblock, item.id, item.name)">
                    <i class="pe-7s-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-md-4 col-sm-12 col-12">
    <ng-container *ngIf="page_view && pages && pages.length">
      <div class="main-card mb-3 card">
        <div class="table-responsive">
          <table class="align-middle mb-0 table table-hover">
            <thead>
              <tr>
                <th>
                  {{ 'TR_PAGES' | translate }}
                  <app-help-alert alertKey="available_features_view"></app-help-alert>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="pointer" *ngFor="let page of pages; let i = index"
                (click)="rolesView(rolesview, $event.target, page)">
                <td>
                  {{ page.name }}
                  <div *ngIf="page.children && page.children.length" class="main-card mb-2 mt-2 card border-none">
                    <div class="table-responsive child-table">
                      <table class="align-middle mb-0 table table-hover">
                        <tbody>
                          <tr class="pointer" *ngFor="let item of page.children"
                            (click)="rolesView(rolesview, $event.target, item)">
                            <td>{{ item.name }}</td>
                            <td class="text-center">
                              <input [id]="item.name" [name]="item.name" type="checkbox" class="mt-2 pointer"
                                [disabled]="isDisablePage" [(ngModel)]="item.isBelongToCurrentUserType"
                                (ngModelChange)="changePageValue(item.isBelongToCurrentUserType, item.id)" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </td>
                <td class="text-center">
                  <input [id]="page.name" [name]="page.name" type="checkbox" class="mt-2 pointer"
                    [disabled]="isDisablePage" [(ngModel)]="page.isBelongToCurrentUserType"
                    (ngModelChange)="changePageValue(page.isBelongToCurrentUserType, page.id)" />
                  <div *ngIf="i == 0">
                    <app-help-alert alertKey="allow_deny_access_to_pages"></app-help-alert>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #rolesview let-c="close" let-d="dismiss">
  <app-roles [pageId]="selectedPageId" [userTypeId]="userTypeId" [isDefaultUserTypeSelected]="isDefaultUserTypeSelected"
    (closeEmit)="c('Close click')"></app-roles>
</ng-template>

<ng-template #deleteblock let-c="close" let-d="dismiss">
  <div class="custom-modal modal-body">
    <p class="word-break-break-word">{{ 'TR_DELETE_ERROR' | translate }} {{ deleteUserTypeName }}? </p>
  </div>
  <div class="custom-modal modal-footer">
    <app-action-button [isCurrent]="true" [spinnerId]="userTypeDeleteSpinnerId"
      buttonClasses="btn-transition btn btn-outline-primary" spinnerClasses="small-button-spinner"
      [buttonName]="'TR_YES'" (clicked)="delete()"></app-action-button>
    <button type="button" class="btn-transition btn btn-danger"
      (click)="c('Close click')">{{ 'TR_NO' | translate }}</button>
  </div>
</ng-template>