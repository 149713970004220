import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { guid } from '@progress/kendo-angular-common';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { RolesService } from 'src/app/Services/roles.service';
import { AlertService } from 'src/app/Services/alert.service';
import { TranslationService } from 'src/app/Services/translation.service';
import {
  ORGANIZATION_CREATE,
  ORGANIZATION_UPDATE,
  ORGANIZATION_VIEW,
  PAYMENT_PACKAGE_VIEW,
  SUCCESS_KEY,
  TR_CREATE_MESSAGE_KEY,
  TR_CREATE_ORGANIZATION_KEY,
  TR_UPDATE_MESSAGE_KEY,
  USER_VIEW,
} from 'src/app/Constants/main-keys';
import {
  CURRENCY_PATH_KEY,
  ORGANIZATIONS_PATH_KEY,
} from 'src/app/Constants/request-path-keys';
import { ICurrencyData } from 'src/app/Models/ICurrencyData';
import * as moment from 'moment';

@Component({
  selector: 'app-organization-contract',
  templateUrl: './organization-contract.component.html',
  styleUrls: ['./organization-contract.component.scss']
})
export class OrganizationContractComponent implements OnInit {
  userRoles: Array<string>;
  org_contract_create: boolean;
  org_contract_update: boolean;
  user_view: boolean;
  organizationId: number;
  form: FormGroup;
  contractId: number = null;
  isFeeByPercent: boolean = true;
  isFeeByFixPrice: boolean = false;
  isInvalidSubmit: boolean;
  today: String = new Date().toISOString().split('T')[0];
  currencies: Array<ICurrencyData> = [];
  selectedCurrencyId: number = null;
  selectedCurrencyName: string;
  submitSpinnerId: string = guid();
  fieldsMaxLength: number = 120;

  constructor(
    private formBuilder: FormBuilder,
    private dataHandlerService: DataHandlerService,
    private route: ActivatedRoute,
    private rolesService: RolesService,
    private translationService: TranslationService,
    public mainService: MainService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_CREATE_ORGANIZATION_KEY);

    this.form = this.formBuilder.group({
      currencyId: [null, Validators.required],
      feeByPercent: [null],
      feeByFixPrice: [null],
      endDate: [null, Validators.required],
      routingNumber: ["", Validators.maxLength(this.fieldsMaxLength)],
      accountNumber: ["", Validators.maxLength(this.fieldsMaxLength)],
      bankName: ["", Validators.maxLength(this.fieldsMaxLength)],
      swiftCode: ["", Validators.maxLength(this.fieldsMaxLength)],
      address: ["", Validators.maxLength(this.fieldsMaxLength)],
      receiverName: ["", Validators.maxLength(this.fieldsMaxLength)],
    });

    this.getCurrencies();
    this.route.paramMap.subscribe(params => {
      this.organizationId = +params.get('item.id');
      if (this.organizationId) {
        this.getContractByOrganizationId();
      }
    });
  }

  setRoles() {
    this.userRoles.forEach(role => {
      switch (role) {
        case "org_contract_create":
          this.org_contract_create = true;
          break;
        case "org_contract_update":
          this.org_contract_update = true;
          break;
      }
    });
  }

  getCurrencies() {
    this.dataHandlerService.getDataWithSpinner(CURRENCY_PATH_KEY).then(response => this.currencies = response);
  }

  onRadioChange(event: any, name: string) {
    if (name === "isFeeByPercent") {
      this.isFeeByPercent = event.target.checked;
      this.isFeeByFixPrice = !event.target.checked;
    }
    if (name === "isFeeByFixPrice") {
      this.isFeeByFixPrice = event.target.checked;
      this.isFeeByPercent = !event.target.checked;
    }
  }

  setCurrency(id: number, isoCode: string) {
    this.selectedCurrencyId = id;
    this.selectedCurrencyName = isoCode;
    this.form.patchValue({ currencyId: id });
  }

  getContractByOrganizationId() {
    this.dataHandlerService.getDataWithSpinner(`${"OrganizationInnerPaymentContract/byorganization"}/${this.organizationId}`).then(response => {
      const data = { ...response };
      this.contractId = data.id;
      if (data.feeByFixPrice) {
        this.isFeeByFixPrice = true;
        this.isFeeByPercent = false;
      } else {
        this.isFeeByPercent = true;
        this.isFeeByFixPrice = false;
      }
      if (data.currencyId && this.currencies && this.currencies.length) {
        this.selectedCurrencyId = data.currencyId;
        const selectedCurrency = this.currencies.find(currnecy => currnecy.id === data.currencyId);
        if (selectedCurrency) {
          this.selectedCurrencyName = selectedCurrency.isocode;
        }
      }
      this.form.patchValue({
        currencyId: data.currencyId,
        feeByPercent: data.feeByPercent,
        feeByFixPrice: data.feeByFixPrice,
        endDate: moment(this.mainService.convertUTCDateToLocalDate(new Date(data.endDate))).format('YYYY-MM-DD'),

        routingNumber: data.routingNumber ? data.routingNumber : "",
        accountNumber: data.accountNumber ? data.accountNumber : "",
        bankName: data.bankName ? data.bankName : "",
        swiftCode: data.swiftCode ? data.swiftCode : "",
        address: data.address ? data.address : "",
        receiverName: data.receiverName ? data.receiverName : "",
      });
    });
  }

  submitData(): void {
    if (this.form.valid) {
      const form = { ...this.form.value };
      if (!form.feeByPercent && !form.feeByFixPrice) {
        this.isInvalidSubmit = true;
        return;
      } else {
        form.organizationId = this.organizationId;
        if (this.isFeeByPercent) {
          form.feeByFixPrice = null;
        } else {
          form.feeByPercent = null;
        }

        form.routingNumber = form.routingNumber ? form.routingNumber : null;
        form.accountNumber = form.accountNumber ? form.accountNumber : null;
        form.bankName = form.bankName ? form.bankName : null;
        form.swiftCode = form.swiftCode ? form.swiftCode : null;
        form.address = form.address ? form.address : null;
        form.receiverName = form.receiverName ? form.receiverName : null;

        if (this.contractId) {
          form.id = this.contractId;
          this.dataHandlerService.updateFormData(form, "OrganizationInnerPaymentContract", this.submitSpinnerId).then(() => {
            this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
            this.mainService.goBack(ORGANIZATIONS_PATH_KEY);
          });
        } else {
          this.dataHandlerService.createFormData(form, "OrganizationInnerPaymentContract", this.submitSpinnerId).then(() => {
            this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
            this.mainService.goBack(ORGANIZATIONS_PATH_KEY);
          });
        }
      }
    } else {
      this.isInvalidSubmit = true;
    }
  }

}
