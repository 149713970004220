<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon"><i class="vsm-icon pe-7s-users icon-gradient bg-plum-plate"></i></div>
      <div>{{ 'TR_GROUPS' | translate }}
        <page-main-info-component id="main_info" [attr.data-page-name]="currentUserData.userTypeId === userTypes.ADMIN_USER_TYPE_KEY ?
          'angular_admin_group_managment' : currentUserData.userTypeId === userTypes.MET_DEPARTMENT_WORKER_KEY ?
          'angular_mdw_group_managment' : ''">
        </page-main-info-component>
      </div>
    </div>
    <div class="page-title-actions"><button class="mb-2 mr-2 btn btn-success create-btn"
        [title]="'TR_CREATE_GROUP' | translate" [routerLink]="['group-form']">
        <i class="fa fa-plus"></i><span class="ml-2">
          {{ 'TR_CREATE' | translate }}
          <app-help-alert alertKey="group_create_admin"></app-help-alert>
        </span>
      </button></div>
  </div>
</div>
<!-- <div class="title-block mb-3">
  <h4 class="card-title">{{ 'TR_GROUPS' | translate }}</h4>
</div> -->

<div class="flex flex-wrap">
  <!-- <div *ngIf="group_create">
    <button class="mb-2 mr-2 btn btn-success create-btn" [title]="'TR_CREATE_GROUP' | translate"
      [routerLink]="['group-form']">
      <i class="fa fa-plus"></i><span class="ml-2">
        {{ 'TR_CREATE' | translate }}
        <app-help-alert alertKey="group_create_admin"></app-help-alert>
      </span>
    </button>
  </div> -->

  <div *ngIf="organization_view" ngbDropdown>
    <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-primary">{{ 'TR_ORGANIZATIONS' | translate
      }}</button>
    <div ngbDropdownMenu>
      <button class="dropdown-item" (click)="getAllGroups(false)">{{ 'TR_ALL' | translate }}</button>
      <button class="dropdown-item" *ngFor="let item of organizations" (click)="getGroupsByOrganizationId(item.id)">{{
        item.name }}</button>
    </div>
  </div>
</div>

<div *ngIf="group_view" class="row">
  <div class="col-12">
    <div class="main-card mb-3 card">
      <div class="table-responsive">
        <table class="align-middle mb-0 table table-striped table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>
                {{ 'TR_NAME' | translate }}
                <app-help-alert alertKey="student_attach"></app-help-alert>
              </th>
              <th>{{ 'TR_OWNER' | translate }}</th>
              <th>{{ 'TR_PROGRAM_NAME' | translate }}</th>
              <th *ngIf="group_update" class="text-right">
                {{ 'TR_ACTIONS' | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="pointer" title="Click to see group students" *ngFor="let item of groups  let i = index"
              (click)="getGroupStudents($event.target, groupstudents, item.id)">
              <td>{{ i + 1 }}</td>
              <td>{{ item.groupName }}</td>
              <td>{{ item.ownerName }}</td>
              <td>{{ item.organizationEducationLevelName }}</td>
              <td *ngIf="group_update" class="text-right">
                <div role="group" class="btn-group-sm btn-group">
                  <button class="btn-transition btn btn-outline-info" [title]="'TR_UPDATE_GROUP' | translate"
                    [routerLink]="['group-form', item.id]">
                    <i class="pe-7s-note"></i>
                    <div *ngIf="i == 0">
                      <app-help-alert alertKey="group_update_admin"></app-help-alert>
                    </div>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #groupstudents let-c="close" let-d="dismiss">
  <app-group-students *ngIf="groupId" [groupId]="groupId" (closeEmit)="c('Close click')"></app-group-students>
</ng-template>