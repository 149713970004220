import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { guid } from '@progress/kendo-angular-common';
import { AlertService } from 'src/app/Services/alert.service';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { RolesService } from 'src/app/Services/roles.service';
import { ROLE_CREATE, ROLE_UPDATE, ROLE_VIEW, SUCCESS_KEY, TR_CREATE_MESSAGE_KEY, TR_UPDATE_MESSAGE_KEY } from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-role-form',
  templateUrl: './role-form.component.html',
  styleUrls: ['./role-form.component.scss']
})
export class RoleFormComponent implements OnInit {
  role_view: boolean;
  role_create: boolean;
  role_update: boolean;

  roleId: number;
  form: FormGroup;
  userRoles: Array<string> = [];
  isInvalidSubmit: boolean;
  isCreateForm: boolean = true;
  submitSpinnerId: string = guid();

  constructor(
    private formBuilder: FormBuilder,
    private dataHandlerService: DataHandlerService,
    private route: ActivatedRoute,
    public mainService: MainService,
    private rolesService: RolesService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();

    this.form = this.formBuilder.group({
      name: ['', Validators.required],
    });

    this.route.paramMap.subscribe(params => {
      this.roleId = +params.get('item.id');

      if (this.roleId) {
        this.isCreateForm = false;
        this.getRoleById(this.roleId);
      }
    });
  }

  setRoles() {
    this.userRoles.forEach(item => {
      switch (item) {
        case ROLE_CREATE:
          this.role_create = true;
          break;
        case ROLE_UPDATE:
          this.role_update = true;
          break;
        case ROLE_VIEW:
          this.role_view = true;
          break;
      }
    });
  }

  getRoleById(roleId: number) {
    this.dataHandlerService.getDataWithSpinner(`role/${roleId}`).then(response => {
      this.form.patchValue({ name: response.name });
    });
  }

  submitData() {
    if (this.form.valid) {
      const form = { ...this.form.value };
      if(this.isCreateForm) {
        this.dataHandlerService.createData(form, 'role', this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
          this.form.patchValue({ name: '' });
        });
      } else {
        form.id = this.roleId;
        this.dataHandlerService.updateData(form, 'role', this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
          this.form.patchValue({ name: '' });
          this.roleId = null;
        });
      }
    } else {
      this.isInvalidSubmit = true;
    }
  }

}
