import { Component, OnInit } from '@angular/core';
import { delay, map, startWith, tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { guid } from '@progress/kendo-angular-common';
import { TranslationService } from '../../../Services/translation.service';
import { DataHandlerService } from '../../../Services/data-handler.service';
import { DataStorageService } from '../../../Services/data-storage.service';
import { RolesService } from '../../../Services/roles.service';
import { AlertService } from 'src/app/Services/alert.service';
import { MainService } from 'src/app/Services/main.service';
import { IResponseModel } from '../../../Models/IResponseModel';
import { IUserData, IUserTypeData } from '../../../Models/IUserData';
import {
  MESSAGE_CREATE,
  ORGANIZATION_VIEW,
  SUCCESS_KEY,
  TR_ACTIVATED_SUCCESSFULLY_KEY,
  TR_DEACTIVATED_SUCCESSFULLY_KEY,
  TR_DELETE_MESSAGE_KEY,
  TR_MESSAGE_SEND_KEY,
  TR_USERS_KEY,
  USERTYPE_VIEW,
  USER_CREATE,
  USER_DELETE,
  USER_UPDATE,
  USER_VIEW
} from '../../../Constants/main-keys';
import {
  MESSAGE_PATH_KEY,
  PAGE_NUMBER_PATH_PARAM_KEY,
  PAGE_SIZE_PATH_PARAM_KEY,
  USERS_PATH_KEY,
  USER_ACTIVATE_PATH_KEY,
  USER_TYPE_ID_PATH_PARAM_KEY,
  USER_TYPE_PATH_KEY
} from '../../../Constants/request-path-keys';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss']
})
export class UsersTableComponent implements OnInit {
  userIdForMessage: number;
  userViewId: number;
  currentUserData: IUserData;
  canSendMessage: boolean;
  user_create: boolean;
  user_update: boolean;
  delete_user: boolean;
  send_message: boolean;
  usertype_view: boolean;
  user_view: boolean;
  organization_view: boolean;
  passwordView: boolean;
  isActivation: boolean;
  checkAll: boolean;
  totalPages: number = 0;
  totalDataCount: number = 0;
  currentPage: number = 1;
  pageSize: number = 10;
  selectedUserTypeName: string;
  selectedUserTypeId: number;
  deleteUser: IUserData;
  messageContent: string;
  activeInactiveUserData: IUserData;
  sendMessageSpinnerId: string = guid();
  activationSpinnerId: string = guid();
  userDeleteSpinnerId: string = guid();
  users: Array<IUserData> = [];
  userRoles: Array<string> = [];
  userTypes: Array<IUserTypeData> = [];
  pageSizeArr = [10, 20, 50, 100, 200, 500, 1000];
  // helpData: Array<object> = [];

  constructor(
    private dataHandlerService: DataHandlerService,
    private dataStorageService: DataStorageService,
    private translationService: TranslationService,
    private modalService: NgbModal,
    private rolesService: RolesService,
    public mainService: MainService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_USERS_KEY);

    this.currentUserData = this.dataStorageService.getUser();
    this.getUsersByPagination();

    this.getUserTypes();

  }

  setRoles() {
    this.userRoles.forEach(role => {
      switch (role) {
        case MESSAGE_CREATE:
          this.send_message = true;
          break;
        case USER_CREATE:
          this.user_create = true;
          break;
        case USER_UPDATE:
          this.user_update = true;
          break;
        case USERTYPE_VIEW:
          this.usertype_view = true;
          break;
        case USER_VIEW:
          this.user_view = true;
          break;
        case USER_DELETE:
          this.delete_user = true;
          break;
        case ORGANIZATION_VIEW:
          this.organization_view = true;
          break;
      }
    });
  }

  checkAllUsers(): void {
    this.users.forEach(user => user.checked = this.checkAll);
    this.canSendMessage = this.checkAll;
  }

  getUserTypes() {
    if (!this.usertype_view) { return; }
    this.dataHandlerService.getData(USER_TYPE_PATH_KEY).then(response => {
      this.userTypes = response;
      this.userTypes.forEach(userType => {
        this.translationService.translateUserTypes(userType.displayName).subscribe(translatedUserTypeName => {
          if (translatedUserTypeName) {
            userType.displayName = translatedUserTypeName;
          }
        });
      });
    });
  }

  getUsersByPagination(): void {
    if (!this.user_view) { return; }
    if(isNaN(this.currentPage) || isNaN(this.pageSize) || this.currentPage == 0){
      return;
    }
    this.dataHandlerService.getDataWithSpinner(
      `${USERS_PATH_KEY}?${PAGE_NUMBER_PATH_PARAM_KEY}${this.currentPage}&${PAGE_SIZE_PATH_PARAM_KEY}${this.pageSize}`, true
    ).then((response: IResponseModel) => {
      if (response.pagination) {
        const paginationData = JSON.parse(response.pagination);
        this.totalPages = paginationData.TotalPages;
        this.totalDataCount = paginationData.TotalCount;
        this.pageSize = paginationData.PageSize;
        if(paginationData.CurrentPage >= paginationData.TotalPages){
          this.currentPage = paginationData.TotalPages;
        }
        else{
          this.currentPage = paginationData.CurrentPage;
        }
      }
      this.users = response.data;
      this.users.forEach(item => {
        if (item.password) {
          this.passwordView = true;
          item.password = atob(item.password);
          item.togglePasswordType = 'password';
        }
        this.translationService.translateUserTypes(item.userTypeDisplayName).pipe(
          map((data: string) => {
            if (data) {
              item.userTypeName = data;
            }
          })
        ).subscribe();
      });
    });
  }

  getAllUsers(name: string): void {
    this.selectedUserTypeName = name;
    this.selectedUserTypeId = null;
    this.getUsersByPagination();
  }

  setPagination(currentPage: string): void {
    if(currentPage === "»"){
      if(this.currentPage < this.totalPages){
        this.currentPage += 1;
      }
      else{
        return;
      }
    }
    else if(currentPage === "«"){
      if(this.currentPage > 1){
        this.currentPage -= 1;
      }
      else{
        return;
      }
    }
    else if(currentPage === "»»"){
      if(this.currentPage < this.totalPages){
        this.currentPage = this.totalPages;
      }
      else{
        return;
      }
    }
    else if(currentPage === "««"){
      if(this.currentPage > 1){
        this.currentPage = 1;
      }
      else{
        return;
      }
    }
    else{
      const semyColumnIndex = currentPage.indexOf('(');
      if(!isNaN(+currentPage.substring(0, semyColumnIndex))){
        this.currentPage = +currentPage.substring(0, semyColumnIndex);
      }
    }
    
    this.selectedUserTypeId ? this.getUsersByUserTypeId() : this.getUsersByPagination();
  }

  setSelectedUserType(id: number): void {
    const selectedUserType = this.userTypes.find(data => data.id === id);
    this.selectedUserTypeName = selectedUserType.displayName || selectedUserType.name;
    this.selectedUserTypeId = id;
    this.currentPage = 1;
    this.getUsersByUserTypeId();
  }

  getUsersByUserTypeId(): void {
    if (!this.user_view) { return; }
    this.dataHandlerService.getData(
      `${USERS_PATH_KEY}?${USER_TYPE_ID_PATH_PARAM_KEY}${this.selectedUserTypeId}&${PAGE_NUMBER_PATH_PARAM_KEY}${this.currentPage}&${PAGE_SIZE_PATH_PARAM_KEY}${this.pageSize}`,
      true
    ).then((response: IResponseModel) => {
      if (response.pagination) {
        const paginationData = JSON.parse(response.pagination);
        this.totalPages = paginationData.TotalPages;
        this.totalDataCount = paginationData.TotalCount;
        this.pageSize = paginationData.PageSize;
        if(paginationData.CurrentPage >= paginationData.TotalPages){
          this.currentPage = paginationData.TotalPages;
        }
        else{
          this.currentPage = paginationData.CurrentPage;
        }
      }
      this.users = response.data;
      this.users && this.users.forEach(item => {
        if (item.password) {
          this.passwordView = true;
          item.password = atob(item.password);
          item.togglePasswordType = 'password';
        }
        this.translationService.translateUserTypes(item.userTypeDisplayName).pipe(
          map((data: string) => {
            if (data) {
              item.userTypeName = data
            }
          })
        ).subscribe();
      });
    });
  }

  setSelectedPageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.currentPage = 1;
    this.selectedUserTypeId ? this.getUsersByUserTypeId() : this.getUsersByPagination();
  }

  confirmDelete(content, id: number) {
    this.deleteUser = this.users.find(data => data.id === id);
    this.modalService.open(content).result;
  }

  confirmActiveInactiveAction(content, id: number): void {
    const user = this.users.find(user => user.id === id);
    this.isActivation = !user.isActive;
    this.activeInactiveUserData = user;
    this.modalService.open(content).result;
  }

  openMessageBox(content, id: number): void {
    this.messageContent = '';
    this.userIdForMessage = id;
    this.modalService.open(content).result.then(() => {
      this.canSendMessage = false;
      this.userIdForMessage = null;
      this.users.forEach(item => {
        item.checked = false;
      });
      this.checkAll = false;
    });
  }

  delete(): void {
    if (this.deleteUser && this.deleteUser.id && this.delete_user) {
      this.dataHandlerService.deleteData(this.deleteUser.id, USERS_PATH_KEY, this.userDeleteSpinnerId).then(() => {
        this.selectedUserTypeId ? this.getUsersByUserTypeId() : this.getUsersByPagination();
        this.alertService.translateAndAlertMessage(TR_DELETE_MESSAGE_KEY, SUCCESS_KEY);
        this.modalService.dismissAll();
      });
    }
  }

  triggerActivation(): void {
    if (this.activeInactiveUserData.id && this.user_create) {
      this.dataHandlerService.userActivation(this.activeInactiveUserData.id, this.isActivation, USER_ACTIVATE_PATH_KEY, this.activationSpinnerId).then(() => {
        const successMessage = this.isActivation ? TR_ACTIVATED_SUCCESSFULLY_KEY : TR_DEACTIVATED_SUCCESSFULLY_KEY;
        this.alertService.translateAndAlertMessage(successMessage, SUCCESS_KEY);
        this.users.find(data => data.id === this.activeInactiveUserData.id).isActive = this.isActivation;
        this.modalService.dismissAll();
      });
    }
  }

  sendMessage(): void {
    if (!this.messageContent || !this.send_message) { return; }
    let receiverUsers = [];
    if (this.userIdForMessage) {
      receiverUsers.push(this.userIdForMessage);
    } else {
      receiverUsers = this.users.filter(item => item.checked);
    }
    const message = { message: this.messageContent, senderId: this.currentUserData.id, receiverId: receiverUsers };
    this.dataHandlerService.createData(message, MESSAGE_PATH_KEY, this.sendMessageSpinnerId).then(() => {
      this.canSendMessage = false;
      this.userIdForMessage = null;
      this.users.forEach(item => item.checked = false);
      this.checkAll = false;
      this.alertService.translateAndAlertMessage(TR_MESSAGE_SEND_KEY, SUCCESS_KEY);
      this.modalService.dismissAll();
    });
  }

  getCheckboxValue(): void {
    for (let i = 0; i < this.users.length; i++) {
      if (this.users[i].checked) {
        this.canSendMessage = this.users[i].checked;
        return;
      } else {
        this.canSendMessage = this.users[i].checked;
      }
    }
  }

  userView(target: HTMLElement, content, userId: number): void {
    if (target.nodeName === 'TD') {
      this.userViewId = userId;
      this.modalService.open(content).result;
    }
  }
}
