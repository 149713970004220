import { Injectable } from "@angular/core";
import { CookieService } from "./cookie.service";
import { BehaviorSubject } from "rxjs";
import { LANGUAGE_KEY } from '../Constants/main-keys';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  readonly _languageData$ = new BehaviorSubject<string>(null);

  constructor(
    private cookieService: CookieService,
  ) {
    this.getLanguage();
  }

  get currentLanguage(): string {
    return this._languageData$.getValue();
  }

  getLanguage(): void {
    const language = this.cookieService.getCookie(LANGUAGE_KEY);
    this._languageData$.next(language);
  }

  setLanguage(name: string, val: string) {
    this.cookieService.deleteCookie(name);
    this.cookieService.setCookie(name, val);
    this._languageData$.next(val);
  }

}