<div class="h-100 bg-plum-plate bg-animation">
  <div class="d-flex h-100 justify-content-center align-items-center">
    <div *ngIf="isPageLoaded" class="mx-auto app-login-box col-md-8">
      <div class="app-logo-inverse mx-auto mb-3"></div>
      <div class="modal-dialog w-100 mx-auto">
        <div class="modal-content">
          <form (submit)="resetPassword()" [formGroup]="form">
            <div class="modal-header">
              <div class="h5 modal-title">
                {{ 'TR_RESET_PASSWORD' | translate }}
              </div>
            </div>
            <div class="modal-body">
              <div id="password" role="group" aria-describedby="exampleInputGroup1__BV_description_" class="form-group">
                <div>
                  <label for="exampleEmail">{{ 'TR_ENTER_PASSWORD' | translate }}</label>
                  <input
                    type="password"
                    autocomplete="new-password"
                    [placeholder]="'TR_ENTER_PASSWORD' | translate"
                    required="required"
                    aria-required="true"
                    class="form-control"
                    formControlName="password"
                  />
                </div>
              </div>
              <div id="confirmPassword" role="group" class="form-group">
                <div>
                  <label for="exampleEmail">{{ 'TR_ENTER_CONFIRM_PASSWORD' | translate }}</label>
                  <input
                    type="password"
                    autocomplete="new-password"
                    [placeholder]="'TR_ENTER_CONFIRM_PASSWORD' | translate"
                    required="required"
                    aria-required="true"
                    class="form-control"
                    formControlName="confirmPassword"
                  />
                </div>
              </div>
              <p *ngIf="isInvalidLength" class="red">{{ TR_PASSWORD_CHARACTERS_MIN_MAX_ERROR | translate }}</p>
            </div>
            <div class="modal-footer clearfix">
              <div class="float-right">
                <app-submit-button [buttonName]="'TR_SUBMIT' | translate" [buttonColor]="'btn-warning'"></app-submit-button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="text-center text-white opacity-8 mt-3">
        © {{ currentYear }} Mindalay
      </div>
    </div>
  </div>
</div>