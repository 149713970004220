import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { AlertService } from 'src/app/Services/alert.service';
import { guid } from '@progress/kendo-angular-common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/Services/language.service';
import {
  SUCCESS_KEY,
  TR_PAGES_KEY,
  TR_DELETE_MESSAGE_KEY,
  FILE_LIBRARY_VIEW,
  FILE_LIBRARY_CREATE,
  FILE_LIBRARY_UPDATE,
  FILE_LIBRARY_DELETE,
  FILE_FOLDER_VIEW,
  FILE_FOLDER_CREATE,
  FILE_FOLDER_UPDATE,
  FILE_FOLDER_DELETE,
} from 'src/app/Constants/main-keys';

@Component({
  // selector: 'app-file-library-table',
  selector: 'ngbd-accordion-basic',
  templateUrl: './file-library.component.html',
  styleUrls: ['./file-library.component.scss']
})

export class FileLibraryComponent implements OnInit {
  userRoles: Array<string> = [];
  deleteSpinnerId: string = guid();
  submitSpinnerId: string = guid();
  isCreateForm: boolean = true;
  form: FormGroup;
  selectedFolderName: string;
  selectedFolderId: number;
  isInvalidSubmit: boolean = false;
  folderName: string;
  deleteFileName: string;
  deleteFolderName: string;
  deleteFolderId: number;
  updateFolderId: number = null;
  deleteFileId: number;
  fileLibrary: Array<string> = [];
  list: any;
  zoomImagePath: any;
  zoomVideoPath: any;

  folders: Array<any> = [];
  groups: Array<string> = [];
  defaultGroups = [];
  filteredStudents = [];

  file_library_view: boolean;
  file_library_create: boolean;
  file_library_update: boolean;
  file_library_delete: boolean;
  file_folder_view: boolean;
  file_folder_create: boolean;
  file_folder_update: boolean;
  file_folder_delete: boolean;

  searchValue: string;
  groupName: string = "";
  selectedGroupId: number = null;

  nameMaxLength: number = 200;
  activeFileCheckBoxId: number = null;
  isFileCheckBoxChecked: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    private translationService: TranslationService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private router: Router,
    private languageService: LanguageService,

  ) { }

  ngOnInit() {

    this.userRoles = this.rolesService.userRoles;
    this.translationService.setPageTitle(TR_PAGES_KEY);

    this.setRoles();
    this.getFolders();
    this.getFileLibrary();
    this.getGroups();

    this.form = this.formBuilder.group({
      name: ['', Validators.maxLength(this.nameMaxLength)],
      parentId: [null],
      isPrivate: [true],
    });

  }

  setRoles() {
    this.userRoles.forEach(role => {
      switch (role) {
        case FILE_LIBRARY_VIEW:
          this.file_library_view = true;
          break;
        case FILE_LIBRARY_CREATE:
          this.file_library_create = true;
          break;
        case FILE_LIBRARY_UPDATE:
          this.file_library_update = true;
          break;
        case FILE_LIBRARY_DELETE:
          this.file_library_delete = true;
          break;

        case FILE_FOLDER_VIEW:
          this.file_folder_view = true;
          break;
        case FILE_FOLDER_CREATE:
          this.file_folder_create = true;
          break;
        case FILE_FOLDER_UPDATE:
          this.file_folder_update = true;
          break;
        case FILE_FOLDER_DELETE:
          this.file_folder_delete = true;
          break;
      }
    });
  }

  updateFolder(event: any, id: number, parentId: any, name: string) {
    event.stopPropagation();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.isCreateForm = false;
    this.updateFolderId = id;
    if (parentId) {
      this.folders.forEach(folder => {
        if (folder.id === parentId) {
          this.selectedFolderName = folder.name
        }
      })
    }
    this.form.patchValue({
      name: name,
      parentId: parentId ? parentId : null,
      isPrivate: true,
      id: id
    });
  }

  cancel(): void {
    this.isCreateForm = true;
    this.updateFolderId = null;
    this.isInvalidSubmit = false;
    this.selectedFolderName = '';
    this.form.patchValue({
      name: "",
      parentId: null,
      isPrivate: true,
      id: null
    });
  }

  closeZoomFiles(): void {
    this.zoomImagePath = null;
    this.zoomVideoPath = null;
  }

  confirmDeleteFile(content: any, id: number, name: string): void {
    this.deleteFileId = id
    this.deleteFileName = name;
    this.modalService.open(content).result;
  }

  confirmDeleteFolder(event: any, content: any, id: number, name: string): void {
    event.stopPropagation();
    this.deleteFolderId = id
    this.deleteFolderName = name;
    this.modalService.open(content).result;
  }

  getFolders() {
    if (!this.file_library_view) { return; }
    this.dataHandlerService.getDataWithSpinner("category/bytype/1").then(response => {
      if (response) {
        const data = [...response];
        this.list = data;
        this.folders = [];
        this.setFolders(data);
      } else {
        this.list = [];
        this.setFolders([]);
      }
    });
  }

  getGroups() {
    if (!this.file_library_view) { return; }
    this.dataHandlerService.getDataWithSpinner("filelibrary/filesandstudents").then(response => {
      if (response.studentResponses) {
        const data = [...response.studentResponses];
        data.forEach(group => {
          if (group.students && group.students.length) {
            group.students.forEach(student => {
              student.groupId = group.id;
            });
          }
        })
        this.groups = data;
        this.defaultGroups = data;
      } else {
        this.groups = [];
        this.defaultGroups = [];
      }
    });
  }

  getFileLibrary() {
    if (!this.file_library_view) { return; }
    this.dataHandlerService.getDataWithSpinner("filelibrary").then(response => {
      const data = [...response];
      this.fileLibrary = data;
    });
  }

  getFileLibraryByFolderId(event: any) {
    if (!event || !event.panelId) { return; }
    this.activeFileCheckBoxId = null;
    this.dataHandlerService.getData(`filelibrary/bycategory/${event.panelId}`).then(response => {
      if (response) {
        this.fileLibrary = response;
      }
    });
  }

  setFolders(array: any) {
    if (!array || (array && !array.length)) {
      return;
    }
    array.forEach((element: any) => {
      this.folders.push(element);
      if (element.children && element.children.length) {
        this.setFolders(element.children);
      }
    });
  }

  selectParentFolder(name: string, id: number): void {
    if (!name || !id) { return; }
    this.selectedFolderName = name;
    this.form.patchValue({ parentId: id });
  }

  deleteFile(): void {
    this.dataHandlerService.deleteData(this.deleteFileId, "filelibrary", this.deleteSpinnerId).then(() => {
      this.deleteFileId = null;
      this.deleteFileName = "";
      this.alertService.translateAndAlertMessage(TR_DELETE_MESSAGE_KEY, SUCCESS_KEY);
      this.modalService.dismissAll();
      this.getFolders();
    }).catch(e => this.modalService.dismissAll());
  }

  deleteFolder(): void {
    this.dataHandlerService.deleteData(this.deleteFolderId, "category/filelibfolder", this.deleteSpinnerId).then(() => {
      this.getFolders();
      this.deleteFolderId = null;
      this.deleteFolderName = "";
      this.alertService.translateAndAlertMessage(TR_DELETE_MESSAGE_KEY, SUCCESS_KEY);
      this.modalService.dismissAll();
    }).catch(e => this.modalService.dismissAll());
  }

  zoomImage(filePath: any) {
    this.zoomImagePath = filePath;
  }

  zoomVideo(filePath: any) {
    this.zoomVideoPath = filePath;
  }

  zoomFile(filePath: any) {
    window.open(filePath, "_blank");
  }

  onFileCheckBoxChange(event: any, item: any) {
    this.groupName = '';
    this.selectedGroupId = null;
    if (event.target.checked) {
      this.activeFileCheckBoxId = item.id;
      this.isFileCheckBoxChecked = true;
    } else {
      this.activeFileCheckBoxId = null;
      this.isFileCheckBoxChecked = false;
    }
    this.dataHandlerService.createDataWithoutSpinnerId(event.target.checked ? [item.id] : [], "filelibrary/studentsbyfiles").then(response => {
      if (response) {
        const data = [...response];
        data.forEach(group => {
          if (group.students && group.students.length) {
            group.students.forEach(student => {
              student.groupId = group.id;
            });
          }
        });
        this.groups = data;
        this.defaultGroups = data;
      } else {
        this.groups = [];
        this.defaultGroups = [];
      }
    });
  }

  setAllGroups() {
    this.groups = this.defaultGroups;
    this.groupName = "";
    this.selectedGroupId = null;
  }

  filterStudentsByDocNumber(): void {
    if (!this.searchValue.trim()) {
      this.groups = this.defaultGroups;
      this.filteredStudents = [];
    } else {
      let defaultGroupsCopy = [...this.defaultGroups];
      let filteredStudents = []
      // let filteredGroups = [];
      // defaultGroupsCopy.forEach(defaultGroupsCopyItem => {
      //   if (defaultGroupsCopyItem.students && defaultGroupsCopyItem.students.length) {
      //     defaultGroupsCopyItem.students.forEach(student => {
      //       if (student.personalDocumentNumber.includes(this.searchValue)) {
      //         filteredGroups.push(defaultGroupsCopyItem);
      //       }
      //     });
      //   }
      // })
      // this.groups = filteredGroups;
      defaultGroupsCopy.forEach(defaultGroupsCopyItem => {
        if (defaultGroupsCopyItem.students && defaultGroupsCopyItem.students.length) {
          defaultGroupsCopyItem.students.forEach(student => {
            if (student.personalDocumentNumber.includes(this.searchValue)) {
              filteredStudents.push(student);
            }
          });
        }

      });
      this.filteredStudents = filteredStudents;

    }
  }

  onUserCheckBoxChange(event: any, item: any) {
    const data = {
      fileIds: [this.activeFileCheckBoxId],
      save: event.target.checked ? true : false,
      studentIds: [item.id],
    }

    if (event.target.checked) {
      this.dataHandlerService.createDataWithoutSpinnerId(data, "filelibrary/attachfiletostudents").then(() => {
        this.alertService.translateAndAlertMessage("student successfully attached", SUCCESS_KEY);
        // this.activeFileCheckBoxId = null;
        // this.isFileCheckBoxChecked = false;
      });
    } else {
      this.dataHandlerService.createDataWithoutSpinnerId(data, "filelibrary/attachfiletostudents").then(() => {
        this.alertService.translateAndAlertMessage("student successfully unattached", SUCCESS_KEY);
        // this.isFileCheckBoxChecked = false;

      });
    }
  }

  getStudentsByGroupId(groupId: number, groupName: string) {
    this.searchValue = "";
    const filteredGroups = this.defaultGroups.filter(group => group.id === groupId)
    this.groups = filteredGroups;
    this.selectedGroupId = groupId;
    this.groupName = groupName;
  }

  submitData(): void {
    if (this.form.valid) {
      const form = { ...this.form.value };
      if (!form.name.trim().length) {
        this.isInvalidSubmit = true;
        return;
      }
      form.categorytype = 1;
      if (this.updateFolderId) {
        form.id = this.updateFolderId;
        this.dataHandlerService.updateData(form, "category/filelibfolder", this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage("TR_DATA_SAVED", SUCCESS_KEY);
          form.name = "";
          form.parentId = null;
          form.id = null;
          this.cancel();
          this.getFolders();
        })
      } else {
        this.dataHandlerService.createData(form, "category/filelibfolder", this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage("TR_DATA_SAVED", SUCCESS_KEY);
          this.cancel();
          this.getFolders();
        })
      }
    } else {
      this.isInvalidSubmit = true;
    }
  }


}

