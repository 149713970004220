export const MENU_KEY = 'menu';
export const TOKEN_KEY = 'token';
export const USER_KEY = 'user';
export const COURSE_BREAD_CRUMB_KEY = 'courseBreadCrumb';
export const SELECTED_CATEGORY_ID_KEY = 'selectedCategoryId';
export const LANGUAGE_KEY = 'language';
export const ADMIN_KEY = 'admin';
export const TIME_ZONE_KEY = 'time_zone';
export const SUCCESS_KEY = 'success';
export const ERROR_KEY = 'error';

export const userTypes = {
  ADMIN_USER_TYPE_KEY: 2,
  CURATOR_USER_TYPE_KEY: 3,
  STUDENT_USER_TYPE_KEY: 4,
  LECTURER_USER_TYPE_KEY: 5,
  INSTRUCTOR_USER_TYPE_KEY: 6,
  MET_DEPARTMENT_WORKER_KEY: 7,
  CONTENT_MANAGER_USER_TYPE_KEY: 10,
  CONSULTANT_USER_TYPE_KEY: 11,
}

export const START_TIME_KEY = 'startTime';
export const END_TIME_KEY = 'endTime';

export const TR_CREATEUSER_KEY = 'TR_CREATEUSER';
export const TR_UPDATEUSER_KEY = 'TR_UPDATEUSER';
export const TR_USERTYPES_KEY = 'TR_USERTYPES';
export const TR_USERS_KEY = 'TR_USERS';
export const TR_CREATEUSERTYPE_KEY = 'TR_CREATEUSERTYPE';
export const TR_EDITUSERTYPE_KEY = 'TR_EDITUSERTYPE';
export const TR_WEBINARS_KEY = 'TR_WEBINARS';
export const TR_WEBINAR_CREATE_KEY = 'TR_WEBINARCREATE';
export const TR_WEBINAR_UPDATE_KEY = 'TR_WEBINARUPDATE';
export const TR_STUDENTS_KEY = 'TR_STUDENTS';
export const TR_CREATE_STUDENT_KEY = 'TR_CREATE_STUDENT';
export const TR_UPDATE_STUDENT_KEY = 'TR_UPDATE_STUDENT';
export const TR_ORGANIZATIONS_KEY = 'TR_ORGANIZATIONS';
export const TR_CREATE_ORGANIZATION_KEY = 'TR_CREATE_ORGANIZATION';
export const TR_UPDATE_ORGANIZATION_KEY = 'TR_UPDATE_ORGANIZATION';
export const TR_STREAMING_SETTINGS = 'TR_STREAMING_SETTINGS';
export const TR_PAGES_KEY = 'TR_PAGES';
export const TR_SITE_PAGES_KEY = 'TR_SITE_PAGES';
export const TR_ADMIN_PAGES_KEY = 'TR_ADMIN_PAGES';
export const TR_CREATE_PAGE_KEY = 'TR_CREATE_PAGE';
export const TR_UPDATE_PAGE_KEY = 'TR_UPDATE_PAGE';
export const TR_GROUPS_KEY = 'TR_GROUPS';
export const TR_CREATE_GROUP_KEY = 'TR_CREATE_GROUP';
export const TR_UPDATE_GROUP_KEY = 'TR_UPDATE_GROUP';
export const TR_ORGANIZATION_SETTINGS_KEY = 'TR_ORGANIZATION_SETTINGS';
export const TR_LANGUAGES_KEY = 'TR_LANGUAGES';
export const TR_CREATE_LANGUAGE_KEY = 'TR_CREATE_LANGUAGE';
export const TR_UPDATE_LANGUAGE_KEY = 'TR_UPDATE_LANGUAGE';
export const TR_PAYMENT_PACKAGES_KEY = 'TR_PAYMENT_PACKAGES';
export const TR_CREATE_PAYMENT_PACKAGE_KEY = 'TR_CREATE_PAYMENT_PACKAGE';
export const TR_UPDATE_PAYMENT_PACKAGE_KEY = 'TR_UPDATE_PAYMENT_PACKAGE';
export const TR_LIBRARY_KEY = 'TR_LIBRARY';
export const TR_SUBJECTS_KEY = 'TR_SUBJECTS';
export const TR_CREATE_MESSAGE_KEY = 'TR_CREATE_MESSAGE';
export const TR_UPDATE_MESSAGE_KEY = 'TR_UPDATE_MESSAGE';
export const TR_DELETE_MESSAGE_KEY = 'TR_DELETE_MESSAGE';
export const TR_VALID_IMAGE_MESSAGE_ERROR_KEY = 'TR_VALID_IMAGE_MESSAGE_ERROR';
export const TR_VALID_FILE_MESSAGE_ERROR_KEY = 'TR_INVALID_DOC_FILE_EXC';
export const TR_INVALID_SIGNED_DOC_FILE_EXC_KEY = 'TR_INVALID_SIGNED_DOC_FILE_EXC';
export const TR_COMPLETE_FIELDS_MESSAGE_ERROR_KEY = 'TR_COMPLETE_FIELDS_MESSAGE_ERROR';
export const TR_STREAMING_SETTINGS_MESSAGE_KEY = 'TR_STREAMING_SETTINGS_MESSAGE';
export const TR_ADMINISTRATION_KEY = 'TR_ADMINISTRATION';
export const TR_PAGE_CONTENT_KEY = 'TR_PAGE_CONTENT';
export const TR_CREATE_PAGE_CONTENT_KEY = 'TR_CREATE_PAGE_CONTENT';
export const TR_UPDATE_PAGE_CONTENT_KEY = 'TR_UPDATE_PAGE_CONTENT';
export const TR_CATEGORY_KEY = 'TR_CATEGORY';
export const TR_TRANSLATIONS_KEY = 'TR_TRANSLATIONS';
export const TR_CREATE_TRANSLATIONS_KEY = 'TR_TRANSLATION_CREATE';
export const TR_UPDATE_TRANSLATIONS_KEY = 'TR_UPDATE_TRANSLATION';
export const TR_EDUCATIONAL_MATERIALS_KEY = 'TR_EDUCATIONAL_MATERIALS';
export const TR_UPDATE_DOCUMENT_KEY = 'TR_UPDATE_DOCUMENT';
export const TR_CREATE_DOCUMENT_KEY = 'TR_CREATE_DOCUMENT';
export const TR_PAGE_NOT_FOUND_KEY = 'TR_PAGE_NOT_FOUND';
export const TR_PROFILE_KEY = 'TR_PROFILE';
export const TR_RESET_PASSWORD_SUCCESS_KEY = 'TR_RESET_PASSWORD_SUCCESS';
export const TR_AVATAR_SUCCESS_UPLOAD_KEY = 'TR_AVATAR_SUCCESS_UPLOAD';
export const TR_MESSAGE_SEND_KEY = 'TR_MESSAGE_SEND';
export const WEB_SUBTITLES_KEY = ['TR_SINGLE_WEBINAR', 'TR_PERIODIC_WEBINAR', 'TR_CUSTOM_WEBINAR'];
export const TR_DOCUMENT_CATEGORY_KEY = 'TR_DOCUMENT_CATEGORY';
export const TR_ARCHIVE_SUCCESS_KEY = 'TR_ARCHIVE_SUCCESS';
export const TR_UNARCHIVE_SUCCESS_KEY = 'TR_UNARCHIVE_SUCCESS';
export const TR_TEMPLATES_KEY = 'TR_TEMPLATES';
export const TR_CREATE_TEMPLATE_KEY = 'TR_CREATE_TEMPLATE';
export const TR_UPDATE_TEMPLATE_KEY = 'TR_UPDATE_TEMPLATE';
export const TR_TEMPLATE_INFO_DATA_KEY = 'TR_TEMPLATE_INFO_DATA';
export const TR_UPDATE_TEMPLATE_INFO_DATA_KEY = 'TR_UPDATE_TEMPLATE_INFO_DATA';
export const TR_CREATE_TEMPLATE_INFO_DATA_KEY = 'TR_CREATE_TEMPLATE_INFO_DATA';
export const TR_TEMPLATE_INFO_KEY = 'TR_TEMPLATE_INFO';
export const TR_UPDATE_TEMPLATE_INFO_KEY = 'TR_UPDATE_TEMPLATE_INFO';
export const TR_CREATE_TEMPLATE_INFO_KEY = 'TR_CREATE_TEMPLATE_INFO';
export const TR_DOCUMENT_PRINT_KEY = 'TR_PRINT_OUT';
export const TR_DOCUMENT_SUBSCRIPTION_KEY = 'TR_DOCUMENT_SUBSCRIPTION';
export const TR_YOUR_REQUEST_SEND_MESSAGE_KEY = 'TR_YOUR_REQUEST_SEND_MESSAGE';
export const TR_CREATE_COURSE_KEY = 'TR_CREATE_COURSE';
export const TR_UPDATE_COURSE_KEY = 'TR_UPDATE_COURSE';
export const TR_CREATE_TOPIC_KEY = 'TR_CREATE_TOPIC';
export const TR_UPDATE_TOPIC_KEY = 'TR_UPDATE_TOPIC';
export const TR_CREATE_SECTION_KEY = 'TR_CREATE_SECTION';
export const TR_UPDATE_SECTION_KEY = 'TR_UPDATE_SECTION';
export const TR_CREATE_FILE_KEY = 'TR_CREATE_FILE';
export const TR_UPDATE_FILE_KEY = 'TR_UPDATE_FILE';
export const TR_ACTIVATED_SUCCESSFULLY_KEY = 'TR_ACTIVATED_SUCCESSFULLY';
export const TR_DEACTIVATED_SUCCESSFULLY_KEY = 'TR_DEACTIVATED_SUCCESSFULLY';
export const TR_FIND_YOUR_ACCOUNT_KEY = 'TR_FIND_YOUR_ACCOUNT';
export const TR_LOGIN_KEY = 'TR_LOGIN';
export const TR_RESET_PASSWORD_KEY = 'TR_RESET_PASSWORD';
export const TR_FAILS_KEY = 'TR_FAILS';
export const TR_CREATE_QUESTION_KEY = 'TR_CREATE_QUESTION';
export const TR_UPDATE_QUESTION_KEY = 'TR_UPDATE_QUESTION';
export const TR_ONE_ANSWER_ONLY_KEY = 'TR_ONE_ANSWER_ONLY';
export const TR_MULTIPLE_ANSWERS_ALLOWED_KEY = 'TR_MULTIPLE_ANSWERS_ALLOWED';
export const TR_CREATE_QUIZ_KEY = 'TR_CREATE_QUIZ';
export const TR_UPDATE_QUIZ_KEY = 'TR_UPDATE_QUIZ';
export const TR_MULTI_CHOICE_KEY = 'TR_MULTI_CHOICE';
export const TR_MATCHING_QUESTION_KEY = 'TR_MATCHING_QUESTION';
export const TR_SHORT_ANSWER_KEY = 'TR_SHORT_ANSWER';
export const TR_TRUE_FALSE_KEY = 'TR_TRUE_FALSE';
export const TR_NONE_KEY = 'TR_NONE';
export const TR_UNIMPORTANT_CASE_KEY = 'TR_UNIMPORTANT_CASE';
export const TR_CASE_MUST_MATCH_KEY = 'TR_CASE_MUST_MATCH';
export const TR_FIRST_EXPIRE_TYPE_KEY = 'TR_FIRST_EXPIRE_TYPE';
export const TR_SECOND_EXPIRE_TYPE_KEY = 'TR_SECOND_EXPIRE_TYPE';
export const TR_THIRD_EXPIRE_TYPE_KEY = 'TR_THIRD_EXPIRE_TYPE';
export const TR_SECONDS_KEY = 'TR_SECONDS';
export const TR_MINUTES_KEY = 'TR_MINUTES';
export const TR_HOURS_KEY = 'TR_HOURS';
export const TR_DAYS_KEY = 'TR_DAYS';
export const TR_WEEKS_KEY = 'TR_WEEKS';
export const TR_UNLIMITED_KEY = 'TR_UNLIMITED';
export const TR_HIGHEST_GRADE_KEY = 'TR_HIGHEST_GRADE';
export const TR_AVERAGE_GRADE_KEY = 'TR_AVERAGE_GRADE';
export const TR_FIRST_ATTEMPT_KEY = 'TR_FIRST_ATTEMPT';
export const TR_LAST_ATTEMPT_KEY = 'TR_LAST_ATTEMPT';
export const TR_CONFIGS_KEY = 'TR_CONFIGS';
export const TR_CURRENCY_KEY = 'TR_CURRENCY';
export const TR_CREATE_CONFIG_KEY = 'TR_CREATE_CONFIG';
export const TR_UPDATE_CONFIG_KEY = 'TR_UPDATE_CONFIG';
export const TR_UPDATE_COMPLAINT_KEY = 'TR_UPDATE_COMPLAINT';
export const TR_URL_COPIED = 'TR_URL_COPIED';

export const VALID_IMAGE_TYPES_KEY = ['jpeg', 'png', 'jpg', 'JPEG', 'PNG', 'JPG', 'SVG', 'svg'];
export const VALID_SIGNED_FILE_IMAGE_TYPES_KEY = ['jpeg', 'png', 'jpg', 'pdf', 'JPEG', 'PNG', 'JPG', 'PDF'];
// export const VALID_FILE_TYPES_KEY = ['pdf', 'txt', 'doc', 'rtf', 'xls', 'xlsx', 'PDF', 'TXT', 'DOC', 'RTF', 'XLS', 'XLSX'];
export const VALID_FILE_TYPES_KEY = ['pdf', 'txt', 'doc', 'docx', 'rtf', 'xls', 'xlsx', 'jpeg', 'png', 'jpg', 'svg', 'PDF', 'TXT', 'DOC', 'DOCX', 'RTF', 'XLS', 'XLSX', 'JPEG', 'PNG', 'JPG', 'SVG'];
export const VALID_DOCUMENT_FILE_TYPES_KEY = ['pdf', 'txt', 'doc', 'docx', 'rtf', 'xls', 'xlsx', 'jpeg', 'png', 'jpg', 'JPEG', 'PNG', 'JPG', 'PDF', 'TXT', 'DOC', 'RTF', 'XLS', 'XLSX'];
export const VALID_FILE_LIBRARY_TYPES_KEY = ['pdf', 'txt', 'doc', 'docx', 'rtf', 'xls', 'xlsx', 'jpeg', 'png', 'jpg', 'JPEG', 'PNG', 'JPG', 'PDF', 'TXT', 'DOC', 'RTF', 'XLS', 'XLSX', 'DOCX'];
export const ORGANIZATION_EDUCATION_LEVEL_PROCESS_TRANSLATION_KEYS = ['TR_LEVEL', 'TR_PROGRAM', 'TR_DIRECTION', 'TR_PROGRAM_NAME', 'TR_DISCIPLINE'];
export const ORGANIZATION_EDUCATION_LEVEL_PROCESS_TRANSLATION_WITHOUT_DISCIPLINE_KEYS = ['TR_LEVEL', 'TR_PROGRAM', 'TR_DIRECTION', 'TR_PROGRAM_NAME'];
export const TRANSLATION_TYPES_KEY = ['Default', 'Admin', 'Site', 'Validation', 'Exception', 'Email', 'Notification', 'Page', 'User type', 'Category'];

// Added by Gigo 16.03.22
export const MONTH_ARR_KEYS_RUSSIAN = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь'
];

export const MONTH_ARR_KEY = [
  'TR_JANUARY',
  'TR_FEBRUARY',
  'TR_MARTH',
  'TR_APRIL',
  'TR_MAY',
  'TR_JUNE',
  'TR_JULY',
  'TR_AUGUST',
  'TR_SEPTEMBER',
  'TR_OCTOBER',
  'TR_NOVEMBER',
  'TR_DECEMBER'
];

export const WEEK_DAYS_KEY = [
  'TR_SUNDAY',
  'TR_MONDAY',
  'TR_TUESDAY',
  'TR_WEDNESDAY',
  'TR_THURSDAY',
  'TR_FRIDAY',
  'TR_SATURDAY'
];

export const COURSE_LEVELS = [
  "TR_ALL_LEVELS",
  "TR_BEGINNERS",
  "TR_INTERMEDIATE",
  "TR_EXPERT",
];

export const OUTPUT_AUDIO_BITRATE_ARR_KEY = [64, 96, 128];
export const VIDEO_BASE_RESOLUTION_ARR_KEY = ['1280x720'];
export const VIDEO_OUTPUT_RESOLUTION_ARR_KEY = ['320x180'];
export const VIDEO_COMMON_FPS_VALUE_ARR_KEY = ['10', '20'];

export const INFO_TYPES_KEY = [
  'organization-name',
  'input-string',
  'input-short-string',
  'input-long-string',
  'table-input-string',
  'table-input-date',
  'table-input-number',
  'table-number-and-date',
  'input-date-to-right',
  'input-number',
  'input-date',
  'input-create-date',
  'table-input-start-end-time',
  'table-list',
  'input-time',
  'drop-down-group',
  'library',
  'table-library',
  'list',
  'students-list',
  'inputs-list',
  'sign-row',
  'table-sign-part',
  'text-area',
  'sign-row-arr',
  'add-row-button',
  'remove-row-button',
  'students-in-table',
  'drop-down-group-not-draw'
];

export const QUESTION_TYPES_ARR_KEY = [
  { value: 0, name: TR_MULTI_CHOICE_KEY },
  { value: 1, name: TR_MATCHING_QUESTION_KEY },
  { value: 2, name: TR_SHORT_ANSWER_KEY },
  { value: 3, name: TR_TRUE_FALSE_KEY },
];

export const CHOISE_NUMBERING_TYPES_ARR_KEY = [
  { value: 0, name: TR_NONE_KEY },
  { value: 1, name: 'a, b, c ...' },
  { value: 2, name: 'A, B, C ...' },
  { value: 3, name: '1, 2, 3 ...' },
  { value: 4, name: 'i, ii, iii ...' },
  { value: 5, name: 'I, II, III ...' },
];

export const GRADES_ARR_KEY = [0, 100, 90, 83.33333, 80, 75, 70, 66.66667, 60, 50, 40, 33.33333, 30, 25, 20, 16.66667, 14.28571, 12.5, 11.11111, 10, 5,
  -5, -10, -11.11111, -12.5, -14.28571, -16.66667, -20, -25, -30, -33.33333, -40, -50, -60, -66.66667, -70, -75, -80, -83.33333, -90, -100];

export const POSITIVE_GRADES_ARR_KEY = [0, 100, 90, 83.33333, 80, 75, 70, 66.66667, 60, 50, 40, 33.33333, 30, 25, 20, 16.66667, 14.28571, 12.5, 11.11111, 10, 5];

export const MULTI_ANSWER_TYPES = [TR_ONE_ANSWER_ONLY_KEY, TR_MULTIPLE_ANSWERS_ALLOWED_KEY];

export const ANSWER_CASE_TYPES = [TR_UNIMPORTANT_CASE_KEY, TR_CASE_MUST_MATCH_KEY];

export const EXPIRE_TYPES_ARR_KEY = [
  TR_FIRST_EXPIRE_TYPE_KEY,
  TR_SECOND_EXPIRE_TYPE_KEY,
  TR_THIRD_EXPIRE_TYPE_KEY
];

export const TIME_TYPES_ARR_KEY = [TR_SECONDS_KEY, TR_MINUTES_KEY, TR_HOURS_KEY, TR_DAYS_KEY, TR_WEEKS_KEY];

export const ALLOWED_ATTEMPTS_ARR_KEY = [TR_UNLIMITED_KEY, '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

export const GRADING_METHODS_ARR_KEY = [TR_HIGHEST_GRADE_KEY, TR_AVERAGE_GRADE_KEY, TR_FIRST_ATTEMPT_KEY, TR_LAST_ATTEMPT_KEY];

export const USER_CREATE = "user_create";
export const USER_UPDATE = "user_update";
export const USER_DELETE = "user_delete";
export const USER_VIEW = "user_view";
export const USERTYPE_CREATE = "usertype_create";
export const USERTYPE_UPDATE = "usertype_update";
export const USERTYPE_DELETE = "usertype_delete";
export const USERTYPE_VIEW = "usertype_view";
export const DEFAULT_USERTYPE_CREATE = "default_usertype_create";
export const DEFAULT_USERTYPE_UPDATE = "default_usertype_update";
export const DEFAULT_USERTYPE_DELETE = "default_usertype_delete";
export const DEFAULT_USERTYPE_VIEW = "default_usertype_view";
export const WEBINAR_CREATE = "webinar_create";
export const WEBINAR_UPDATE = "webinar_update";
export const WEBINAR_DELETE = "webinar_delete";
export const WEBINAR_VIEW = "webinar_view";
export const GROUP_CREATE = "group_create";
export const GROUP_UPDATE = "group_update";
export const GROUP_DELETE = "group_delete";
export const GROUP_VIEW = "group_view";
export const ORGANIZATION_CREATE = "organization_create";
export const ORGANIZATION_UPDATE = "organization_update";
export const ORGANIZATION_DELETE = "organization_delete";
export const ORGANIZATION_VIEW = "organization_view";
export const CATEGORY_CREATE = "category_create";
export const CATEGORY_UPDATE = "category_update";
export const CATEGORY_DELETE = "category_delete";
export const CATEGORY_VIEW = "category_view";
export const COUNTRY_CREATE = "country_create";
export const COUNTRY_UPDATE = "country_update";
export const COUNTRY_DELETE = "country_delete";
export const COUNTRY_VIEW = "country_view";
export const LANGUAGE_CREATE = "language_create";
export const LANGUAGE_UPDATE = "language_update";
export const LANGUAGE_DELETE = "language_delete";
export const LANGUAGE_VIEW = "language_view";
export const LIBRARY_CREATE = "library_create";
export const LIBRARY_UPDATE = "library_update";
export const LIBRARY_DELETE = "library_delete";
export const LIBRARY_VIEW = "library_view";
export const MESSAGE_CREATE = "message_create";
export const MESSAGE_UPDATE = "message_update";
export const MESSAGE_DELETE = "message_delete";
export const MESSAGE_VIEW = "message_view";
export const NOTIFICATION_CREATE = "notification_create";
export const NOTIFICATION_UPDATE = "notification_update";
export const NOTIFICATION_DELETE = "notification_delete";
export const NOTIFICATION_VIEW = "notification_view";
export const PAGE_CONTENT_CREATE = "page_content_create";
export const PAGE_CONTENT_UPDATE = "page_content_update";
export const PAGE_CONTENT_DELETE = "page_content_delete";
export const PAGE_CONTENT_VIEW = "page_content_view";
export const PAGE_CREATE = "page_create";
export const PAGE_UPDATE = "page_update";
export const PAGE_DELETE = "page_delete";
export const PAGE_VIEW = "page_view";
export const FILE_LIBRARY_VIEW = "file_library_view";
export const FILE_LIBRARY_CREATE = "file_library_create";
export const FILE_LIBRARY_UPDATE = "file_library_update";
export const FILE_LIBRARY_DELETE = "file_library_delete";
export const FILE_FOLDER_VIEW = "file_folder_view";
export const FILE_FOLDER_CREATE = "file_folder_create";
export const FILE_FOLDER_UPDATE = "file_folder_update";
export const FILE_FOLDER_DELETE = "file_folder_delete";
export const PAYMENT_PACKAGE_CREATE = "payment_package_create";
export const PAYMENT_PACKAGE_UPDATE = "payment_package_update";
export const PAYMENT_PACKAGE_DELETE = "payment_package_delete";
export const PAYMENT_PACKAGE_VIEW = "payment_package_view";
export const PAYMENT_SYSTEM_CREATE = "payment_system_create";
export const PAYMENT_SYSTEM_UPDATE = "payment_system_update";
export const PAYMENT_SYSTEM_DELETE = "payment_system_delete";
export const PAYMENT_SYSTEM_VIEW = "payment_system_view";
export const ROLE_CREATE = "role_create";
export const ROLE_UPDATE = "role_update";
export const ROLE_DELETE = "role_delete";
export const ROLE_VIEW = "role_view";
export const STUDENT_CREATE = "student_create";
export const STUDENT_UPDATE = "student_update";
export const STUDENT_DELETE = "student_delete";
export const STUDENT_VIEW = "student_view";
export const TRANSLATION_CREATE = "translation_create";
export const TRANSLATION_UPDATE = "translation_update";
export const TRANSLATION_DELETE = "translation_delete";
export const TRANSLATION_VIEW = "translation_view";
export const GROUP_STUDENT_CREATE = "group_student_create";
export const GROUP_STUDENT_UPDATE = "group_student_update";
export const GROUP_STUDENT_DELETE = "group_student_delete";
export const GROUP_STUDENT_VIEW = "group_student_view";
export const LIBRARY_DATA_CREATE = "library_data_create";
export const LIBRARY_DATA_UPDATE = "library_data_update";
export const LIBRARY_DATA_DELETE = "library_data_delete";
export const LIBRARY_DATA_VIEW = "library_data_view";
export const NOTIFICATION_HISTORY_CREATE = "notification_history_create";
export const NOTIFICATION_HISTORY_UPDATE = "notification_history_update";
export const NOTIFICATION_HISTORY_DELETE = "notification_history_delete";
export const NOTIFICATION_HISTORY_VIEW = "notification_history_view";
export const ORGANIZATION_PAYMENT_PACKAGE_CREATE = "organization_payment_package_create";
export const ORGANIZATION_PAYMENT_PACKAGE_UPDATE = "organization_payment_package_update";
export const ORGANIZATION_PAYMENT_PACKAGE_DELETE = "organization_payment_package_delete";
export const ORGANIZATION_PAYMENT_PACKAGE_VIEW = "organization_payment_package_view";
export const ORGANIZATION_PAYMENT_CREATE = "organization_payment_create";
export const ORGANIZATION_PAYMENT_UPDATE = "organization_payment_update";
export const ORGANIZATION_PAYMENT_DELETE = "organization_payment_delete";
export const ORGANIZATION_PAYMENT_VIEW = "organization_payment_view";
export const ORGANIZATION_USER_CREATE = "organization_user_create";
export const ORGANIZATION_USER_UPDATE = "organization_user_update";
export const ORGANIZATION_USER_DELETE = "organization_user_delete";
export const ORGANIZATION_USER_VIEW = "organization_user_view";
export const PAGE_ROLE_CREATE = "page_role_create";
export const PAGE_ROLE_UPDATE = "page_role_update";
export const PAGE_ROLE_DELETE = "page_role_delete";
export const PAGE_ROLE_VIEW = "page_role_view";
export const USER_SESSION_CREATE = "user_session_create";
export const USER_SESSION_UPDATE = "user_session_update";
export const USER_SESSION_DELETE = "user_session_delete";
export const USER_SESSION_VIEW = "user_session_view";
export const USERTYPE_ROLE_CREATE = "usertype_role_create";
export const USERTYPE_ROLE_UPDATE = "usertype_role_update";
export const USERTYPE_ROLE_DELETE = "usertype_role_delete";
export const USERTYPE_ROLE_VIEW = "usertype_role_view";
export const WEBINAR_FILE_CREATE = "webinar_file_create";
export const WEBINAR_FILE_UPDATE = "webinar_file_update";
export const WEBINAR_FILE_DELETE = "webinar_file_delete";
export const WEBINAR_FILE_VIEW = "webinar_file_view";
export const WEBINAR_USER_CREATE = "webinar_user_create";
export const WEBINAR_USER_UPDATE = "webinar_user_update";
export const WEBINAR_USER_DELETE = "webinar_user_delete";
export const WEBINAR_USER_VIEW = "webinar_user_view";
export const PAGE_USERTYPE_CREATE = "page_usertype_create";
export const PAGE_USERTYPE_UPDATE = "page_usertype_update";
export const PAGE_USERTYPE_DELETE = "page_usertype_delete";
export const PAGE_USERTYPE_VIEW = "page_usertype_view";
export const SUBJECT_CREATE = "subject_create";
export const SUBJECT_UPDATE = "subject_update";
export const SUBJECT_DELETE = "subject_delete";
export const SUBJECT_VIEW = "subject_view";
export const USER_BY_USERTYPE_VIEW = "user_by_usertype_view";
export const EDUCATION_LEVEL_CREATE = "education_level_create";
export const EDUCATION_LEVEL_UPDATE = "education_level_update";
export const EDUCATION_LEVEL_DELETE = "education_level_delete";
export const EDUCATION_LEVEL_VIEW = "education_level_view";
export const USER_SELF_UPDATE = 'user_self_update';
export const DOCUMENTCATEGORY_CREATE = "documentcategory_create";
export const DOCUMENTCATEGORY_UPDATE = "documentcategory_update";
export const DOCUMENTCATEGORY_DELETE = "documentcategory_delete";
export const DOCUMENTCATEGORY_VIEW = "documentcategory_view";
export const DOCUMENTTEMPLATE_CREATE = "documenttemplate_create";
export const DOCUMENTTEMPLATE_UPDATE = "documenttemplate_update";
export const DOCUMENTTEMPLATE_DELETE = "documenttemplate_delete";
export const DOCUMENTTEMPLATE_VIEW = "documenttemplate_view";
export const DOCUMENTTEMPLATE_I_CREATE = "documenttemplate_i_create";
export const DOCUMENTTEMPLATE_I_UPDATE = "documenttemplate_i_update";
export const DOCUMENTTEMPLATE_I_DELETE = "documenttemplate_i_delete";
export const DOCUMENTTEMPLATE_I_VIEW = "documenttemplate_i_view";
export const DOCUMENTTEMPLATE_I_D_CREATE = "documenttemplate_i_d_create";
export const DOCUMENTTEMPLATE_I_D_UPDATE = "documenttemplate_i_d_update";
export const DOCUMENTTEMPLATE_I_D_DELETE = "documenttemplate_i_d_delete";
export const DOCUMENTTEMPLATE_I_D_VIEW = "documenttemplate_i_d_view";
export const ORGANIZATIONDOCUMENT_CREATE = "organizationdocument_create";
export const ORGANIZATIONDOCUMENT_UPDATE = "organizationdocument_update";
export const ORGANIZATIONDOCUMENT_DELETE = "organizationdocument_delete";
export const ORGANIZATIONDOCUMENT_VIEW = "organizationdocument_view";
export const ORGANIZATIONDOCUMENT_SIGN = "organizationdocument_sign";
export const STUDENT_ARCHIVE = "student_archive";
export const USER_PASSWORD_VIEW = "user_password_view";
export const CONFIG_CREATE = "config_create";
export const CONFIG_UPDATE = "config_update";
export const CONFIG_DELETE = "config_delete";
export const CONFIG_VIEW = "config_view";
export const CURRENCY_CREATE = "currency_create";
export const CURRENCY_UPDATE = "currency_update";
export const CURRENCY_DELETE = "currency_delete";
export const CURRENCY_VIEW = "currency_view";
export const COMPLAINT_UPDATE = "complaint_update";
export const COMPLAINT_VIEW = "complaint_view";


export const PACKAGE_PROPERTY_VIEW = "package_property_view";
export const PACKAGE_PROPERTY_CREATE = "package_property_create";
export const PACKAGE_PROPERTY_UPDATE = "package_property_update";
export const PACKAGE_PROPERTY_DELETE = "package_property_delete";
