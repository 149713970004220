import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { RolesService } from 'src/app/Services/roles.service';
import { MainService } from 'src/app/Services/main.service';
import { LanguageService } from 'src/app/Services/language.service';
import { ICourseData } from 'src/app/Models/ICourseData';
import { ADMIN_KEY } from 'src/app/Constants/main-keys';
import { COURSE_PATH_KEY, COURSE_URL_KEY, UNVERIFIED_PATH_KEY, VERIFIED_PATH_KEY } from 'src/app/Constants/request-path-keys';

@Component({
  selector: 'app-courses-table',
  templateUrl: './courses-table.component.html',
  styleUrls: ['./courses-table.component.scss']
})
export class CoursesTableComponent implements OnInit {
  courses: Array<ICourseData> = [];
  userRoles: Array<string> = [];
  selectedCourseId: number;
  selectedCourseName: string;
  deleteCourseId: number;
  deleteCourseName: string;
  isPageLoaded: boolean;
  isVerifiedCourses: boolean;
  isUnverifiedCourses: boolean;
  isPublishedCourses: boolean;

  constructor(
    public mainService: MainService,
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    private router: Router,
    private languageService: LanguageService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;

    this.getVerifiedCourses(false).then(() => this.setPageLoader(false));
  }

  async getCourses() {
    this.courses = await this.dataHandlerService.getData(COURSE_PATH_KEY);
  }

  async getVerifiedCourses(isVerified: boolean) {
    this.courses = await this.dataHandlerService.getData(`${COURSE_PATH_KEY}/${isVerified ? VERIFIED_PATH_KEY : UNVERIFIED_PATH_KEY}`);
  }

  setPageLoader(isLoading: boolean): void {
    this.isPageLoaded = !isLoading;
  }

  toggleVerifiedCourses(): void {
    this.isVerifiedCourses = !this.isVerifiedCourses;
    this.getVerifiedCourses(this.isVerifiedCourses);
  }

  openCourseView(id: number): void {
    this.router.navigate([this.languageService.currentLanguage, ADMIN_KEY, COURSE_URL_KEY, 'course-view', id]);
  }

}
