<div class="title-block mb-3">
  <h4 class="card-title">{{ 'TR_FILE_LIBRARY' | translate}}</h4>
</div>

<div>
  <div *ngIf="file_library_create" class="d-inline-block">
    <button class="mb-2 mr-2 btn btn-success create-btn" title="Create template" [routerLink]="['file-library-form']">
      <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_CREATE' | translate }}</span>
    </button>
  </div>
  <hr>
  <div class="title-block mb-3">
    <h4 class="card-title">
      {{ !updateFolderId ? ('TR_CREATE_FOLDER' | translate) : ('TR_UPDATE_FOLDER' | translate) }}
    </h4>
  </div>
  <div>
    <form (submit)="submitData()" [formGroup]="form">
      <table class="align-middle mb-0 table table-striped table-hover library-component-body">
        <tbody>
          <tr>
            <td>
              <div class="d-inline-block" ngbDropdown>
                <button type="button" ngbDropdownToggle class="btn btn-primary td-drop-btn">
                  {{ selectedFolderName ? selectedFolderName : 'TR_FOLDER' | translate }}
                </button>
                <div ngbDropdownMenu>
                  <button type="button" class="dropdown-item td-drop-btn" *ngFor="let item of folders"
                    (click)="selectParentFolder( item.name, item.id)">
                    {{ item.name }}
                  </button>
                </div>
              </div>
            </td>
            <td>
              <input type="text" name="name" [placeholder]="'TR_NAME' | translate"
                [class.error-border]="(isInvalidSubmit && (!form.value.name (form.value.name && !form.value.name.trim()))) || (form.value.name.trim().length && form.value.name.trim().length > nameMaxLength)"
                class="form-control" autocomplete="off" formControlName="name" />
            </td>
            <td class="level-table-btns text-right">
              <app-save-button *ngIf="file_folder_create" [isCurrent]="true" [spinnerId]="submitSpinnerId"
                [buttonClasses]="'group-btn-padding mb-1 mr-1 btn-transition btn btn-outline-primary'"
                (submit)="submitData()"></app-save-button>
              <button *ngIf="file_folder_create" type="button"
                class="mb-1 btn-transition btn btn-outline-danger table-action-btn" [title]="'TR_CANCEL' | translate"
                (click)="cancel()">
                <i class="pe-7s-close-circle"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
  <hr>









  <div class="row">
    <div class="col-lg-6 col-12">
      <h4 class="card-title">
        {{'TR_FILES' | translate }}
      </h4>
      <ul *ngIf="list && list.length" class="file-library-accardion p-0 groups-accordion">
        <ng-template #folders let-list>
          <ngb-accordion #acc="ngbAccordion" *ngFor="let item of list" (panelChange)="getFileLibraryByFolderId($event)">
            <ngb-panel id={{item.id}} title={{item.name}}>
              <ng-template ngbPanelTitle>
                <div role="group" class="btn-group-sm btn-group ml-2">
                  <button *ngIf="file_folder_update" class="btn-transition btn btn-outline-info"
                    [title]="'TR_UPDATE' | translate" (click)="updateFolder($event, item.id, item.parentId, item.name)">
                    <i class="pe-7s-note"></i>
                  </button>
                  <button *ngIf="file_folder_delete" class="ml-1 btn-transition btn btn-outline-danger"
                    [title]="'TR_DELETE' | translate"
                    (click)="confirmDeleteFolder($event, deleteconfirmFolder, item.id, item.name)">
                    <i class="pe-7s-trash"></i>
                  </button>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div *ngIf="item.children && item.children.length > 0">
                  <ng-container *ngTemplateOutlet="folders; context:{ $implicit: item.children }"></ng-container>
                </div>
                <div *ngIf="fileLibrary && fileLibrary.length">
                  <div *ngFor="let i of fileLibrary">
                    <div *ngIf="i.categoryId === item.id">
                      <div class="table-responsive">
                        <table class="align-middle mb-0 table table-striped table-hover">
                          <tbody>
                            <tr>
                              <td class="d-flex">
                                <input id="" name="" type="checkbox" class="mt-2 pointer float-right mr-2"
                                  [disabled]="activeFileCheckBoxId && activeFileCheckBoxId != i.id ? true : false"
                                  [(ngModel)]="isFileCheckBoxChecked"
                                  (click)="onFileCheckBoxChange($event, i)" />
                                <div class="file-block">
                                  <img *ngIf="i.mimeType.includes('image')" class="file-item pointer" [src]="i.filePath"
                                    (click)="zoomImage(i.filePath)" />
                                  <div *ngIf="i.mimeType.includes('video')" class="d-inline pointer"
                                    (click)="zoomVideo(i.filePath)">
                                    <i class="pe-7s-video"></i>
                                  </div>
                                  <div
                                    *ngIf="i.mimeType.includes('text') || i.mimeType.includes('pdf') || i.mimeType.includes('application')"
                                    class="d-inline pointer" (click)="zoomFile(i.filePath)">
                                    <i class="pe-7s-file"></i>
                                  </div>
                                </div>
                                <span class="ml-2">{{ i.name }}</span>
                              </td>
                              <td class="text-right">
                                <div role="group" class="btn-group-sm btn-group">
                                  <button *ngIf="file_library_delete" class="ml-1 btn-transition btn btn-outline-danger"
                                    [title]="'TR_DELETE' | translate"
                                    (click)="confirmDeleteFile(deleteconfirmFile, i.id, i.name)">
                                    <i class="pe-7s-trash"></i>
                                  </button>

                                  <button type="button" [title]="'TR_DOWNLOAD' | translate"
                                    class="btn-transition btn btn-outline-success anchor-succes-btn ml-1"
                                    (click)="$event.stopPropagation()">
                                    <a *ngIf="i.filePath && !i.signFilePath" [href]="i.filePath + '/download'"
                                      target="_blank">
                                      <i class="pe-7s-download"></i>
                                    </a>
                                    <a *ngIf="i.signFilePath" [href]="i.signFilePath + '/download'" target="_blank">
                                      <i class="pe-7s-download"></i>
                                    </a>
                                    <a *ngIf="!i.filePath && !i.signFilePath" [routerLink]="['download', i.id]"
                                      target="_blank">
                                      <i class="pe-7s-download"></i>
                                    </a>
                                    <div *ngIf="i == 0">
                                      <app-help-alert alertKey="document_download"></app-help-alert>
                                    </div>
                                  </button>

                                  <!-- <button type="button" class="mr-2 btn-transition btn btn-outline-info download-btn">
                                        <a [href]="i.filePath + '/download'" target="_blank" class="btn btn-default submit-button">
                                          {{ 'TR_DOWNLOAD' | translate }}
                                        </a>
                                      </button> -->

                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </ng-template>
        <ng-container *ngTemplateOutlet="folders; context:{ $implicit: list }"></ng-container>
        <div *ngIf="zoomImagePath" class="zoom-file-block" (click)="closeZoomFiles()">
          <div class="zoom-image" [ngStyle]="{ 'background-image' : 'url(' + zoomImagePath + ')' }"
            (click)="($event.stopPropagation())"></div>
          <i class="pe-7s-close" (click)="closeZoomFiles()" [title]="'TR_CLOSE' | translate"></i>
        </div>
        <div *ngIf="zoomVideoPath" class="zoom-file-block" (click)="closeZoomFiles()">
          <video [src]="zoomVideoPath" class="upload-avatar" controls (click)="($event.stopPropagation())"></video>
          <i class="pe-7s-close" (click)="closeZoomFiles()" [title]="'TR_CLOSE' | translate"></i>
        </div>
      </ul>
    </div>

    <div class="col-lg-6 col-12">
      <h4 class="card-title">
        {{searchValue ? ('TR_STUDENTS' | translate) : ('TR_GROUPS' | translate) }}
      </h4>
      <div class="search-block">
        <div class="search-box">
          <input
            name="search"
            [placeholder]="'TR_SEARCH_BY_DOC_NUMBER' | translate"
            type="text"
            class="form-control"
            autocomplete="off"
            [disabled] = "selectedGroupId ? true : false"
            [(ngModel)]="searchValue"
            (ngModelChange)="filterStudentsByDocNumber()"
          />
        </div>
        <div [ngClass]="searchValue ? 'disabled' : '' ">
          <div class="filter-block" ngbDropdown>
            <button type="button" ngbDropdownToggle class="mb-2 btn btn-primary word-break-break-word max-line-1 max-width-150">
              {{ groupName ? groupName : ('TR_GROUPS' | translate) }}
              <!-- {{ 'TR_GROUPS' | translate }} -->
              <app-help-alert alertKey="sort_students_by_groups"></app-help-alert>
            </button>
            <div ngbDropdownMenu>
              <button class="dropdown-item" (click)="setAllGroups()">{{ 'TR_ALL' | translate }}</button>
              <button class="dropdown-item" *ngFor="let item of defaultGroups"
                (click)="getStudentsByGroupId(item.id, item.groupName)">{{ item.groupName }}</button>
            </div>
          </div>
        </div>
      </div>
      <ul *ngIf="groups && groups.length && !filteredStudents.length && !searchValue" class="file-library-accardion p-0 ">
        <ng-template #foldersItem let-groups>
          <ngb-accordion #acc="ngbAccordion" *ngFor="let item of groups" class="file-accordion groups-accordion">
            <ngb-panel id={{item.id}} title={{item.groupName}}>
              <ng-template ngbPanelTitle>
                <div role="group" class="btn-group-sm btn-group ml-2">
                  <!-- <button *ngIf="file_folder_update" class="btn-transition btn btn-outline-info"
                    [title]="'TR_UPDATE' | translate" (click)="updateFolder($event, item.id, item.parentId, item.name)">
                    <i class="pe-7s-note"></i>
                  </button>
                  <button *ngIf="file_folder_delete" class="ml-1 btn-transition btn btn-outline-danger"
                    [title]="'TR_DELETE' | translate"
                    (click)="confirmDeleteFolder($event, deleteconfirmFolder, item.id, item.name)">
                    <i class="pe-7s-trash"></i>
                  </button> -->
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div *ngIf="item.students && item.students.length > 0">
                  <div *ngIf="item.students && item.students.length">
                    <div *ngFor="let i of item.students">
                      <div *ngIf="i.groupId === item.id">
                        <div class="table-responsive">
                          <table class="align-middle mb-0 table table-striped table-hover">
                            <tbody>
                              <tr>
                                <td class="d-flex align-items-baseline">
                                  <input id="" name="" type="checkbox" class="mt-2 pointer float-right mr-3"
                                    [disabled]="!activeFileCheckBoxId ? true : false"
                                    [(ngModel)]="i.checked"
                                    (click)="onUserCheckBoxChange($event, i)" />
                                  <div class="file-block">
                                    <img *ngIf="i.avatarFilePath" class="user-avatar" [src]="i.avatarFilePath" />
                                  </div>
                                  <span class="ml-2">{{ i.userName }}</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </ng-template>
        <ng-container *ngTemplateOutlet="foldersItem; context:{ $implicit: groups }"></ng-container>
      </ul>
      <div *ngIf="filteredStudents && filteredStudents.length && searchValue">
        <div *ngFor="let i of filteredStudents">
          <div>
            <div class="table-responsive">
              <table class="align-middle mb-0 table table-striped table-hover">
                <tbody>
                  <tr>
                    <td class="d-flex align-items-baseline">
                      <input id="" name="" type="checkbox" class="mt-2 pointer float-right mr-3"
                        [disabled]="!activeFileCheckBoxId ? true : false"
                        [(ngModel)]="i.checked"
                        (click)="onUserCheckBoxChange($event, i)" />
                      <div class="file-block">
                        <img *ngIf="i.avatarFilePath" class="user-avatar" [src]="i.avatarFilePath" />
                      </div>
                      <span class="ml-2">{{ i.userName }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #deleteconfirmFile let-c="close" let-d="dismiss">
    <div class="custom-modal modal-body">
      <p>{{ 'TR_DELETE_ERROR' | translate }} <b class="word-break-break-word">{{ deleteFileName }}</b>?</p>
    </div>
    <div class="custom-modal modal-footer">
      <app-action-button [isCurrent]="true" [spinnerId]="deleteSpinnerId"
        buttonClasses="btn-transition btn btn-outline-info" spinnerClasses="small-button-spinner"
        [buttonName]="'TR_YES'" (clicked)="deleteFile()"></app-action-button>
      <button type="button" class="btn-transition btn btn-danger" (click)="c('Close click')">{{ 'TR_NO' | translate
        }}</button>
    </div>
  </ng-template>

  <ng-template #deleteconfirmFolder let-c="close" let-d="dismiss">
    <div class="custom-modal modal-body">
      <p>{{ 'TR_DELETE_ERROR' | translate }} <b class="word-break-break-word">{{ deleteFolderName }}</b>?</p>
    </div>
    <div class="custom-modal modal-footer">
      <app-action-button [isCurrent]="true" [spinnerId]="deleteSpinnerId"
        buttonClasses="btn-transition btn btn-outline-info" spinnerClasses="small-button-spinner"
        [buttonName]="'TR_YES'" (clicked)="deleteFolder()"></app-action-button>
      <button type="button" class="btn-transition btn btn-danger" (click)="c('Close click')">{{ 'TR_NO' | translate
        }}</button>
    </div>
  </ng-template>