<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon k-cursor-pointer goback" (click)="mainService.goBack('document')"
        [title]="'TR_GOBACK' | translate"><i class="icon pe-7s-left-arrow icon-gradient bg-plum-plate"></i></div>
      <div>{{ isCreateForm ? ('TR_CREATE_DOCUMENT' | translate) : ('TR_UPDATE_DOCUMENT' | translate)
        }}
      </div>
    </div>
  </div>
</div>
<div class="main-card mb-3 card">
  <div class="card-body">
    <!-- <div class="title-block mb-3">
      <i class="fa fa-fw" [title]="'TR_GOBACK' | translate" (click)="mainService.goBack('document')"></i>
      <h5 class="card-title">{{ isCreateForm ? ('TR_CREATE_DOCUMENT' | translate) : ('TR_UPDATE_DOCUMENT' | translate)
        }}</h5>
    </div> -->
    <form (submit)="submitData()" [formGroup]="form">
      <div class="wrap-flex form-outline">
        <div *ngIf="documentcategory_view" ngbDropdown>
          <div class="flex flex-column">
            <!-- <label>{{ ('TR_DOCUMENT_TYPE' | translate) + mainService.asterisk }}</label> -->
            <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-outline-primary"
              [class.error-border]="isInvalidSubmit && !form.value.documentCategoryId">
              {{ selectedDocumentCategoryName ? selectedDocumentCategoryName : ('TR_DOCUMENT_TYPE' | translate) +
              mainService.asterisk }}
            </button>
          </div>
          <div ngbDropdownMenu>
            <button type="button" class="dropdown-item" *ngFor="let item of documentCategories"
              (click)="setSelectedDocumentCategory(item.id, item.name)">
              {{ item.name }}
            </button>
          </div>
        </div>
        <div *ngIf="!selectedFile" ngbDropdown>
          <div class="flex flex-column">
            <!-- <label>{{ 'TR_TEMPLATEE' | translate }}</label> -->
            <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-outline-primary">{{ selectedTemplateName ?
              selectedTemplateName : 'TR_TEMPLATEE' | translate }}</button>
          </div>
          <div ngbDropdownMenu>
            <button type="button" class="dropdown-item" *ngFor="let item of templates"
              (click)="setSelectedTemplate(item)">
              {{ item.name }}
            </button>
          </div>
        </div>
      </div>
      <hr>
      <div class="mt-2 mb-3 upload-block form-outline d-block">
        <button type="button" class="mb-2 mr-2 btn-transition btn btn-warning upload-btn" (click)="file.click()">
          <i class="pe-7s-plus"></i>
          <p>{{ 'TR_UPLOAD_FILE' | translate }}</p>
        </button>
        <input #file hidden type="file" name="upload-file" (change)="handleFileInput($event.target.files)" />

        <div class="mt-4">
          <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-3">
            <div *ngIf="filePath && !uploadedImagePath" [ngStyle]="{ 'background-image' : 'url(' + filePath + ')' }"
              class="uploaded-image"></div>
            <div *ngIf="uploadedImagePath" [ngStyle]="{ 'background-image' : 'url(' + uploadedImagePath + ')' }"
              class="uploaded-image"></div>
          </div>
          <div class="webinar-image" *ngIf="fileName">{{ fileName }}</div>
          <div *ngIf="!fileName && filePath">{{ filePath }}</div>
          <button *ngIf="selectedFile" type="button" class="btn-transition btn btn-outline-dark" (click)="removeFile()">
            {{ 'TR_REMOVE' | translate }}
          </button>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6">
          <div class="position-relative form-group">
            <label for="name">{{ ('TR_NAME' | translate) + mainService.asterisk }}</label>
            <!-- [placeholder]="('TR_NAME' | translate) + mainService.asterisk" -->
            <input name="name" type="text" class="form-control"
              [class.error-border]="(isInvalidSubmit && (!form.value.name || (form.value.name  && !form.value.name.trim()))) || (form.value.name && form.value.name.trim().length && form.value.name.trim().length > nameMaxLength)"
              autocomplete="off" formControlName="name" />
            <p class="warning-message mt-1">{{ 'TR_DOCUMENT_NAME_WARNING_MSG' | translate }}</p>
            <p *ngIf="form.controls['name'].hasError('pattern')" class="error">{{ 'TR_USERNAME_WARNING_MSG' | translate
              }}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="position-relative form-group">
            <label for="description">{{ 'TR_DESCRIPTION_OR_NUMBER' | translate }}</label>
            <!-- [placeholder]="'TR_DESCRIPTION_OR_NUMBER' | translate" -->
            <input name="description" type="text"
              [class.error-border]="form.value.description && form.value.description.trim().length > desriptionMaxLength"
              class="form-control" autocomplete="off" formControlName="description" />
            <p class="warning-message mt-1">{{ 'TR_DESCRIPTION_WARNING_MSG' | translate }}</p>
          </div>
        </div>
      </div>
      <div class="form-row">
      </div>
      <ckeditor *ngIf="openRTE && !filePath" [class.closed-form]="!closePopUp" [class.opened-form]="!selectedTemplateId"
        class="text" formControlName="data"></ckeditor>
      <div *ngIf="openTemplate" class="initial-pop-op" [class.template-pop-up]="selectedTemplateId">
        <app-template-document-form (templateDataEmiter)="getTemplateData($event)" (closeEmit)="closeTemplate()"
          [templateData]="selectedTemplateData" [documentBodyData]="documentBodyData"></app-template-document-form>
      </div>
      <app-submit-button [spinnerId]="submitSpinnerId"
        [buttonName]="isCreateForm ? ('TR_CREATE' | translate) : ('TR_UPDATE' | translate)"
        [isInvalidSubmit]="isInvalidSubmit" [isFormValid]="form.valid"></app-submit-button>
    </form>
  </div>
</div>