export const USER_CURRENT_PATH_KEY = 'user/admincurrent';
export const PAGE_STRUCT_PATH_KEY = 'page/adminstruct?frontendpage=';
export const USER_TYPE_PATH_KEY = 'usertype';
export const USERS_PATH_KEY = 'user';
export const TRANSLATIONS_PATH_KEY = 'translation';
export const LANGUAGE_PATH_KEY = 'language';
export const USER_ACTIVATE_PATH_KEY = 'user/activate';
export const LIBRARY_BY_DISPLAY_NAME_PATH_KEY = 'library/bydisplayname';
export const ORGANIZATIONS_PATH_KEY = 'organization';
export const USERS_FILTER_PATH_KEY = 'user/filter';
export const MESSAGE_PATH_KEY = 'message';
export const GROUP_PATH_KEY = 'group';
export const WEBINARS_PATH_KEY = 'webinar';
// export const WEBINARS_BY_CURATOR_ID_PATH_KEY = 'webinar?ownerId=';
// export const WENINARS_BY_GROUP_ID_PATH_KEY = 'webinar?groupId=';
export const WEBINARS_BY_CURATOR_ID_PATH_KEY = 'b2BWebinar/getByOwner?ownerId=';
export const WENINARS_BY_GROUP_ID_PATH_KEY = 'b2BWebinar/getByDivision?divisionId=';
export const GROUPS_BY_CURATOR_ID_PATH_KEY = 'group?ownerId=';
export const GROUPS_BY_ORGANIZATION_ID_PATH_KEY = 'group?orgId=';
export const WEBINAR_BY_WEBINAR_CALENDAR_PATH_KEY = 'webinar/webinarcalendar';
export const ORGANIZATION_EDUCATION_LEVEL_PATH_KEY = 'organizationEducationLevel';
export const STUDENTS_PATH_KEY = 'student';
export const STUDENT_ARCHIVE_PATH_KEY = 'student/archive';
export const GROUP_STUDENTS_BY_GROUP_ID_PATH_KEY = 'groupstudent/groupId';
export const GROUPS_PATH_KEY = 'group';
export const PAGE_USER_TYPES_GET_PATH_KEY = 'pageusertype/page';
export const PAGE_USER_TYPES_PATH_KEY = 'pageusertype';
export const PAGE_ROLE_PATH_KEY = 'pagerole';
export const USER_TYPE_ROLE_PATH_KEY = 'usertyperole';
export const DEFAULT_USER_TYPES_PATH_KEY = 'usertype/default';
export const PAYMENT_PACKAGES_PATH_KEY = 'paymentpackage';
export const CONFIG_PATH_KEY = 'config';
export const CURRENCY_PATH_KEY = 'currency';
export const COMPLAINT_PATH_KEY = 'complaint';
export const ORGANIZATION_SETTINGS_PATH_KEY = 'organizationsetting';
export const DOCUMENT_CATEGORY_PATH_KEY = 'documentCategory';
export const ORGANIZATION_DOCUMENT_PATH_KEY = 'organizationDocument';
export const DOCUMENT_TEMPLATE_PATH_KEY = 'documentTemplate';
export const DOCUMENT_TEMPLATE_INFO_PATH_KEY = 'documentTemplateInfo';
export const DOCUMENT_TEMPLATE_INFO_DATA_PATH_KEY = 'documentTemplateInfoData';
export const DOCUMENT_SIGN_PATH_KEY = 'OrganizationDocument/sign';
export const MENU_GROUPS_FOR_PAGES_PATH_KEY = 'page/menuGroups';
export const PAGE_GET_PATH_KEY = 'page/get';
export const CURRENT_PAGE_GET_PATH_KEY = 'page/getPage';
export const PAGE_GET_FOR_CONTENT_PATH_KEY = 'page/getforcontent';
export const PAGE_ENABLE_PATH_KEY = 'page/enable';
export const PAGE_TYPES_FOR_PAGES_PATH_KEY = 'page/pageTypes';
export const CATEGORIES_FOR_PAGES_PATH_KEY = 'page/categories';
export const PAGE_UPDATE_PATH_KEY = 'page/update';
export const PAGE_CREATE_PATH_KEY = 'page/create';
export const LIBRARY_PATH_KEY = 'library';
export const LIBRARY_DATA_PATH_KEY = 'librarydata';
export const LANGUAGES_PATH_KEY = 'language';
export const CATEGORIES_PATH_KEY = 'category';
export const PAGE_CONTENT_PATH_KEY = 'pageContent';
export const PAGE_CONTENT_BY_LANGPATH_KEY = 'pageContent/getbylang';
export const PAGE_CATEGORIES_PATH_KEY = 'page/categories';
export const BY_LANG_ID_PATH_KEY = 'byLangId';
export const SUBJECT_ID_PATH_KEY = 'subjectId';
export const SUBJECT_LECTURER_PATH_KEY = 'SubjectLecturer';
export const GET_RESET_LINK_PATH_KEY = 'getResetLink';
// export const ACCESS_TO_NEW_PASSWORD_PATH_KEY = 'resetPassword/accessToNewPassword';
export const ACCESS_TO_NEW_PASSWORD_PATH_KEY = 'user/changecredentials';
// export const UNAUTH_NEW_PASSWORD_PATH_KEY = 'resetPassword/newPassword';
export const UNAUTH_NEW_PASSWORD_PATH_KEY = 'user/updatecredentials';
export const COURSE_PATH_KEY = 'course';
export const COURSE_TOPIC_PATH_KEY = 'course/topic';
export const VERIFY_PATH_KEY = 'verify';
export const DENY_VERIFICATION_PATH_KEY = 'denyverification';
export const PUBLISH_PATH_KEY = 'publish';
export const VERIFIED_PATH_KEY = 'verified';
export const UNVERIFIED_PATH_KEY = 'unverified';
export const PUBLISHED_PATH_KEY = 'published';
export const SECTION_PATH_KEY = 'section';
export const FILE_LIBRARY_PATH_KEY = 'filelibrary';
export const GROUP_STUDENTS_PATH_KEY = 'groupstudent';
export const COURSE_TOPIC_FILE_PATH_KEY = 'courseTopicFile';
export const TOPIC_ID_PATH_KEY = 'topicid';
export const NEW_PASSWORD_PATH_KEY = 'updatecredentials';
export const QUESTION_PATH_KEY = 'question';
export const QUESTIONS_GROUP_PATH_KEY = 'questionsGroup';
export const QUIZ_PATH_KEY = 'coursequiz';

export const ID_PATH_PARAM_KEY = 'id=';
export const PAGE_SIZE_PATH_PARAM_KEY = 'pageSize=';
export const PAGE_NUMBER_PATH_PARAM_KEY = 'pageNumber=';
export const ISO_CODE_PATH_PARAM_KEY = 'isocode=';
export const TYPE_PARAM_KEY = 'type=';
export const USER_TYPE_ID_PATH_PARAM_KEY = 'usertypeId=';
export const ORG_ID_PATH_PARAM_KEY = 'orgId=';
export const IS_FRONT_END_PAGE_PATH_PARAM_KEY = 'frontendpage=';
export const PAGE_ID_PATH_PARAM_KEY = 'pageId=';
export const CATEGORY_ID_PATH_PARAM_KEY = 'categoryId=';
export const TEMPLATE_ID_PATH_PARAM_KEY = 'templateId=';
export const TEMPLATE_INFO_ID_PATH_PARAM_KEY = 'templateInfoId=';
export const LIB_ID_PATH_PARAM_KEY = 'libId=';
export const MENU_GROUP_ID_PATH_PARAM_KEY = 'menuGroupId=';
export const ENABLE_PATH_PARAM_KEY = 'enable=';
export const LANG_ID_PATH_PARAM_KEY = 'langId=';
export const EMAIL_PATH_PARAM_KEY = 'email=';
export const QUESTIONS_GROUP_ID_PATH_PARAM_KEY = 'questionsGroupId=';
export const BY_TYPE_PARAM_KEY = 'bytype';
export const BY_LANG_PARAM_KEY = 'bylang';
export const CHANGE_CREDENTIALS_LINK_KEY = 'changecredentialslink';
export const UPDATEPASSWORD_KEY = 'updatepassword';
export const USER_KEY = 'user';

export const USERS_URL_KEY = 'user';
export const USER_FORM_URL_KEY = 'user-form';
export const WEBINAR_URL_KEY = 'webinar';
export const WEBINAR_FORM_URL_KEY = 'webinar-form';
export const STUDENT_URL_KEY = 'student';
export const STUDENT_FORM_URL_KEY = 'student-form';
export const GROUP_URL_KEY = 'group';
export const GROUP_FORM_URL_KEY = 'group-form';
export const USER_TYPE_URL_KEY = 'usertype';
export const ORGANIZATION_URL_KEY = 'organization';
export const DOCUMENT_URL_KEY = 'document';
export const PAGE_URL_KEY = 'page';
export const PAYMENT_PACKAGE_URL_KEY = 'paymentpackage';
export const CONFIG_URL_KEY = 'config';
export const COMPLAINT_URL_KEY = 'complaint';
export const PAGE_CONTENT_URL_KEY = 'content';
export const TEMPLATE_URL_KEY = 'templates';
export const TEMPLATE_INFO_URL_KEY = 'templates-info';
export const TEMPLATE_INFO_DATA_URL_KEY = 'templates-info-data';
export const LANGUAGE_URL_KEY = 'language';
export const TRANSLATION_URL_KEY = 'translation';
export const COURSE_URL_KEY = 'course';
export const COURSE_VIEW_URL_KEY = 'course-view';
export const FILE_LIBRARY_URL_KEY = 'filelibrary';
export const QUESTION_URL_KEY = 'question';
export const QUIZ_URL_KEY = 'quiz';
export const CURRENCY_URL_KEY = 'currency';


