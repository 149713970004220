<div class="main-card mb-3 card">
  <div class="card-body">
    <div class="title-block mb-3">
      <i class="fa fa-fw" [title]="'TR_GOBACK' | translate" (click)="mainService.goBack('organization')"></i>
      <h4 class="card-title">{{ 'TR_STREAMING_SETTINGS' | translate }}</h4>
    </div>
    <form (submit)="submitData()" [formGroup]="form">
      <!-- <div class="row">
        <div class="col-12"><hr /></div>
      </div>
      <h5 class="mb-3">{{ 'TR_APPLICATION_SETTINGS' | translate }}</h5>
      <div class="wrap-flex form-outline">
        <div *ngIf="organization_view" class="mr-3" ngbDropdown>
          <div class="flex flex-column">
            <label>{{ 'TR_VIDEO_BASE_RESOLUTION' | translate }}</label>
            <button type="button" ngbDropdownToggle class="mb-2 btn btn-outline-info">
              {{ form.value.videoBaseResolution ? form.value.videoBaseResolution : 'TR_VIDEO_BASE_RESOLUTION' | translate }}
            </button>
          </div>
          <div ngbDropdownMenu>
            <button type="button" class="dropdown-item" *ngFor="let item of videoBaseResolutions" (click)="setSelectedVideoBaseResolution(item)">
              {{ item }}
            </button>
          </div>
        </div>
        <div *ngIf="organization_view" class="mr-3" ngbDropdown>
          <div class="flex flex-column">
            <label>{{ 'TR_VIDEO_OUTPUT_RESOLUTION' | translate }}</label>
            <button type="button" ngbDropdownToggle class="mb-2 btn btn-outline-info">
              {{ form.value.videoOutputResolution ? form.value.videoOutputResolution : 'TR_VIDEO_OUTPUT_RESOLUTION' | translate }}
            </button>
          </div>
          <div ngbDropdownMenu>
            <button type="button" class="dropdown-item" *ngFor="let item of videoOutputResolutions" (click)="setSelectedVideoOutputResolution(item)">
              {{ item }}
            </button>
          </div>
        </div>
        <div *ngIf="organization_view" class="mr-3" ngbDropdown>
          <div class="flex flex-column">
            <label>{{ 'TR_VIDEO_COMMON_FPS_VALUE' | translate }}</label>
            <button type="button" ngbDropdownToggle class="mb-2 btn btn-outline-info">
              {{ form.value.videoCommonFpsvalue ? form.value.videoCommonFpsvalue : 'TR_VIDEO_COMMON_FPS_VALUE' | translate }}
            </button>
          </div>
          <div ngbDropdownMenu>
            <button type="button" class="dropdown-item" *ngFor="let item of videoCommonFpsValues" (click)="setSelectedVideoCommonFpsValue(item)">
              {{ item }}
            </button>
          </div>
        </div>
        <div *ngIf="organization_view" class="mr-3" ngbDropdown>
          <div class="flex flex-column">
            <label>{{ 'TR_AUDIO_BITRATE' | translate }}</label>
            <button type="button" ngbDropdownToggle class="mb-2 btn btn-outline-info">
              {{ form.value.outputAudioBitrate ? form.value.outputAudioBitrate : 'TR_AUDIO_BITRATE' | translate }}
            </button>
          </div>
          <div ngbDropdownMenu>
            <button type="button" class="dropdown-item" *ngFor="let item of outputAudioBitrates" (click)="setSelectedAudioBitrate(item)">
              {{ item }}
            </button>
          </div>
        </div>
        <div *ngIf="organization_view" class="mr-3" ngbDropdown>
          <div class="flex flex-column">
            <label>{{ 'TR_LANGUAGES' | translate }}</label>
            <button type="button" ngbDropdownToggle class="mb-2 btn btn-outline-info">
              {{ selectedLanguageName ? selectedLanguageName : 'TR_LANGUAGES' | translate }}
            </button>
          </div>
          <div ngbDropdownMenu>
            <button type="button" class="dropdown-item" *ngFor="let item of languages" (click)="setSelectedLanguage(item.languageId, item.name)">
              {{ item.name }}
            </button>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 mt-1 mb-2 checkboxes-block">
          <div class="position-relative form-check">
            <input name="generalStreamingStartConfirmation" id="generalStreamingStartConfirmation" type="checkbox" class="form-check-input" formControlName="generalStreamingStartConfirmation">
            <label for="generalStreamingStartConfirmation" class="form-check-label">{{ 'TR_STREAMING_START_CONFIRMATION' | translate }}</label>
          </div>
          <div class="position-relative form-check">
            <input name="generalStreamingStopConfirmation" id="generalStreamingStopConfirmation" type="checkbox" class="form-check-input" formControlName="generalStreamingStopConfirmation">
            <label for="generalStreamingStopConfirmation" class="form-check-label">{{ 'TR_STREAMING_STOP_CONFIRMATION' | translate }}</label>
          </div>
          <div class="position-relative form-check">
            <input name="advancedReconnectEnable" id="advancedReconnectEnable" type="checkbox" class="form-check-input" formControlName="advancedReconnectEnable">
            <label for="advancedReconnectEnable" class="form-check-label">{{ 'TR_AUTOMATICALLY_RECONNECT' | translate }}</label>
          </div>
        </div>
      </div> -->
      <div class="form-row">
        <div class="col-lg-4 col-md-4">
          <div class="position-relative form-group">
            <label for="streamServer">{{ 'TR_SERVER' | translate }}</label>
            <input
              name="streamServer"
              [placeholder]="'TR_SERVER' | translate"
              type="text"
              class="form-control"
              autocomplete="off"
              formControlName="streamServer"
            />
          </div>
        </div>
      </div>
      <!-- <div class="form-row">
        <div class="col-lg-4 col-md-4 col-12">
          <div class="position-relative form-group">
            <label for="advancedMaximumRetries">{{ 'TR_MAXIMUM_RETRIES' | translate }}</label>
            <input
              min="0"
              max="10000"
              value="10"
              name="advancedMaximumRetries"
              [placeholder]="'TR_MAXIMUM_RETRIES' | translate"
              type="number"
              class="form-control"
              autocomplete="off"
              formControlName="advancedMaximumRetries"
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-lg-4 col-md-4 col-12">
          <div class="position-relative form-group">
            <label for="outputVideoBitrate">{{ 'TR_OUTPUT_VIDEO_BITRATE' | translate }}</label>
            <input
              min="200"
              max="1000000"
              step="50"
              value="1000"
              name="outputVideoBitrate"
              [placeholder]="'TR_OUTPUT_VIDEO_BITRATE' | translate"
              type="number"
              class="form-control"
              autocomplete="off"
              formControlName="outputVideoBitrate"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-12">
          <div class="position-relative form-group">
            <label for="advancedRetryDelay">{{ 'TR_RETRY_DELAY' | translate }}</label>
            <input
              min="0"
              max="30"
              value="10"
              name="advancedRetryDelay"
              [placeholder]="'TR_RETRY_DELAY' | translate"
              type="number"
              class="form-control"
              autocomplete="off"
              formControlName="advancedRetryDelay"
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 mt-1 mb-2 checkboxes-block">
          <div class="position-relative form-check">
            <input name="streamUseAuthentication" id="streamUseAuthentication" type="checkbox" class="form-check-input" formControlName="streamUseAuthentication">
            <label for="streamUseAuthentication" class="form-check-label">{{ 'TR_USE_AUTHENTICATION' | translate }}</label>
          </div>
        </div>
      </div>
      <div *ngIf="form.value.streamUseAuthentication" class="form-row">
        <div class="col-lg-4 col-md-4">
          <div class="position-relative form-group">
            <label for="streamUsername">{{ 'TR_USERNAME' | translate }}</label>
            <input
              name="streamUsername"
              [placeholder]="'TR_USERNAME' | translate"
              type="text"
              class="form-control"
              autocomplete="off"
              formControlName="streamUsername"
            />
          </div>
        </div>
      </div>
      <div *ngIf="form.value.streamUseAuthentication" class="form-row">
        <div class="col-lg-8 col-md-8">
          <label for="streamPassword">{{ 'TR_PASSWORD' | translate }}</label>
          <div class="input-group row m-0">
            <div class="col-6 p-0">
              <input
                name="streamPassword"
                [placeholder]="'TR_PASSWORD' | translate"
                type="text"
                class="form-control"
                autocomplete="new-password"
                formControlName="streamPassword"
              />
            </div>
            <div class="input-group-append col-6 p-0">
              <button type="button" class="btn btn-primary" (click)="generatePassword()">
                {{ 'TR_GENERATE_PASSWORD' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12"><hr /></div>
      </div>
      <h5 class="mb-2">{{ 'TR_EXTENDED_SETTINGS' | translate }}</h5>
      <div class="form-row">
        <div class="col-lg-4 col-md-4">
          <div class="position-relative form-group">
            <label for="dataServer">{{ 'TR_FILE_SERVER' | translate }}</label>
            <input
              name="dataServer"
              [placeholder]="'TR_FILE_SERVER' | translate"
              type="text"
              class="form-control"
              autocomplete="off"
              formControlName="dataServer"
            />
          </div>
        </div>
      </div> -->
      <div class="row">
        <div class="col-12"><hr /></div>
      </div>
      <app-submit-button
        [spinnerId]="submitSpinnerId"
        [buttonName]="'TR_SUBMIT' | translate"
        [isFormValid]="form.valid"
      ></app-submit-button>
    </form>
  </div>
</div>
