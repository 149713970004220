<ng-container *ngIf="languages.length">
  <div [@architectUIAnimation]="prepareRoute(o)">
    <div class="language-container">
      <div ngbDropdown>
        <button type="button" ngbDropdownToggle class="mr-2 btn lang-btn">
          <span class="ml-2 capitalize">{{ currentLanguageName ? currentLanguageName : 'TR_LANGUAGES' | translate }}</span><i class="pe-7s-global"></i>
        </button>
        <div ngbDropdownMenu>
          <button type="button" class="dropdown-item capitalize" *ngFor="let item of languages" (click)="setLanguage(item.isocode2)">{{ item.name }}</button>
        </div>
      </div>
    </div>
    <router-outlet #o="outlet"></router-outlet>
    <app-page-loader></app-page-loader>
  </div>
</ng-container>
