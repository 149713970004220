import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { RolesService } from 'src/app/Services/roles.service';
import { AlertService } from 'src/app/Services/alert.service';
import { IStudentData } from 'src/app/Models/IStudentData';
import { GROUP_STUDENTS_BY_GROUP_ID_PATH_KEY, GROUP_STUDENTS_PATH_KEY } from 'src/app/Constants/request-path-keys';
import { GROUP_STUDENT_CREATE, GROUP_STUDENT_VIEW, SUCCESS_KEY, TR_CREATE_MESSAGE_KEY } from 'src/app/Constants/main-keys';
import { guid } from '@progress/kendo-angular-common';

@Component({
  selector: 'app-group-students',
  templateUrl: './group-students.component.html',
  styleUrls: ['./group-students.component.sass']
})

export class GroupStudentsComponent implements OnInit {
  @Input() groupId: number;
  @Output() closeEmit = new EventEmitter(null);

  groupStudents: Array<IStudentData> = [];
  userRoles: Array<string> = [];
  group_student_view: boolean;
  group_student_create: boolean;
  modalSpinnerId: string = guid();

  removeStudentId: number;
  removeStudentName: string;

  constructor(
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    private modalService: NgbModal,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();

    if (!this.group_student_view) { return; }

    this.getStudentsByGroupId(true);
  }

  setRoles() {
    this.userRoles.forEach(item => {
      switch (item) {
        case GROUP_STUDENT_VIEW:
          this.group_student_view = true;
          break;
        case GROUP_STUDENT_CREATE:
          this.group_student_create = true;
          break;
      }
    });
  }

  getStudentsByGroupId(isAllStudents: boolean) {
    if (!this.group_student_view) { return; }
    if (this.groupId) {
      this.dataHandlerService.getDataWithModalSpinner(
        `${GROUP_STUDENTS_BY_GROUP_ID_PATH_KEY}/${this.groupId}/${isAllStudents}`,
        this.modalSpinnerId
      ).then(response => this.groupStudents = response);
    }
  }

  changeCheckboxValue(event, isBelong: boolean, studentId: number, studentName: string, content: any): void {
    if (!this.group_student_create) { return; }
    if (isBelong) {
      event.preventDefault();
      this.removeStudentId = studentId;
      this.removeStudentName = studentName;
      this.modalService.open(content).result;
    } else {
      this.triggerGroupStudent(!isBelong, studentId);
    }
  }

  triggerGroupStudent(isBelong: boolean, studentId: number): void {
    if (!this.group_student_create) { return; }
    const body = {
      create: isBelong,
      groupId: this.groupId,
      studentId: studentId
    };

    this.dataHandlerService.createData(body, GROUP_STUDENTS_PATH_KEY, null).then(() => {
      this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
      const selectedStudent = this.groupStudents.find(data => data.id === studentId);
      if (selectedStudent) {
        selectedStudent.isBelongToCurrentGroup = !selectedStudent.isBelongToCurrentGroup;
      }
    });
  }

}
