<div [class]="'app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme" (mouseover)="sidebarHover()"
  (mouseout)="sidebarHover()">
  <div class="app-header__logo">
    <a class="_logo-src" routerLink="/home"></a>
    <div class="header__pane ml-auto">
      <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
        [ngClass]="{'is-active' : globals.toggleSidebar}" (click)="toggleSidebar()">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </div>
  <div class="app-sidebar-content">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar [autoPropagation]="true">
        <div class="v-sidebar-menu vsm-default">
          <div *ngIf="isMenuLoaded" class="vsm-list">
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter">
              <ng-container *ngFor="let item of menu">
                <ngb-panel [id]="item.id + 'dashboardsMenu'" *ngIf="item.children.length">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="vsm-link">
                      <i [ngClass]="['vsm-icon', item.pageKeywords ? item.pageKeywords : '']"></i>
                      <span class="vsm-title">{{item.name}}</span>
                      <i class="vsm-arrow"></i>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="vsm-dropdown">
                      <div class="vsm-list">
                        <div class="vsm-item" *ngFor="let child of item.children">
                          <a [routerLink]="child.uri" routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">{{ child.name }}</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel *ngIf="!item.children.length" [id]="item.id + 'dashboardsMenu'">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="vsm-link" [routerLink]="item.uri" routerLinkActive="active-item">
                      <i [ngClass]="['vsm-icon', item.pageKeywords ? item.pageKeywords : '']"></i>
                      <span class="vsm-title">{{item.name}}</span>
                    </button>
                  </ng-template>
                </ngb-panel>
              </ng-container>
            </ngb-accordion>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>