<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon k-cursor-pointer goback" (click)="mainService.goBack('student')"
        [title]="'TR_GOBACK' | translate"><i class="icon pe-7s-left-arrow icon-gradient bg-plum-plate"></i></div>
      <div>{{ 'TR_CREATE_STUDENT' | translate }}
      </div>
    </div>
  </div>
</div>
<div class="main-card mb-3 card">
  <div class="card-body">
    <!-- <div class="title-block mb-3">
      <i class="fa fa-fw" [title]="'TR_GOBACK' | translate" (click)="mainService.goBack('student')"></i>
      <h5 class="card-title">{{ 'TR_CREATE_STUDENT' | translate }}</h5>
    </div> -->
    <div class="wrap-flex form-outline">
      <div ngbDropdown>
        <div class="flex flex-column">
          <!-- <label>{{ 'TR_TIME_ZONE' | translate }}</label> -->
          <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-outline-primary">
            {{ selectedTimeZone ? selectedTimeZone : 'TR_TIME_ZONE' | translate }}
          </button>
        </div>
        <div ngbDropdownMenu>
          <button type="button" class="dropdown-item" *ngFor="let item of timeZoneData"
            (click)="setSelectedTimeZone(item.name)">
            {{ item.name }}
          </button>
        </div>
      </div>
      <div ngbDropdown>
        <div class="flex flex-column">
          <!-- <label>{{ 'TR_LANGUAGES' | translate }}</label> -->
          <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-outline-primary">
            {{ selectedLanguageName ? selectedLanguageName : 'TR_LANGUAGES' | translate }}
          </button>
        </div>
        <div ngbDropdownMenu>
          <button type="button" class="dropdown-item" *ngFor="let item of languages"
            (click)="setSelectedLanguage(item.languageId, item.name)">
            {{ item.name }}
          </button>
        </div>
      </div>
    </div>
    <hr>
    <form (submit)="submitData()" [formGroup]="form">
      <div class="form-row">
        <div class="col-md-4">
          <div class="position-relative form-group">
            <label for="personalDocumentNumber">{{ ('TR_DOCUMENT_NUMBER' | translate) + mainService.asterisk }}</label>
            <!-- [placeholder]="('TR_DOCUMENT_NUMBER' | translate) + mainService.asterisk" -->
            <input name="personalDocumentNumber" type="text" class="form-control"
              [class.error-border]="(isInvalidSubmit && !form.value.personalDocumentNumber) || (form.value.personalDocumentNumber.trim().length && form.value.personalDocumentNumber.trim().length > personalDocumentMaxLength)"
              autocomplete="off" formControlName="personalDocumentNumber" />
            <!-- [class.error-border]="(isInvalidSubmit && !form.value.personalDocumentNumber.trim().length) || (form.value.personalDocumentNumber.trim().length && form.value.personalDocumentNumber.trim().length > personalDocumentMaxLength)" -->
            <p class="warning-message mt-1">{{ 'TR_DOCUMENT_NUMBER_WARNING_MSG' | translate }}</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="position-relative form-group">
            <label for="username">{{ 'TR_USERNAME' | translate }}</label>
            <!-- [placeholder]="'TR_USERNAME' | translate" -->
            <input name="username" type="text" class="form-control" autocomplete="off" formControlName="username"
              (ngModelChange)="filter($event, 'username')" />
            <p class="warning-message mt-1">{{ 'TR_USERNAME_WARNING_MSG' | translate }}</p>
          </div>
        </div>
        <div *ngIf="!userId && users.length" class="col-md-4">
          <hr />
          <div class="existing-users-block">
            <p>{{ 'TR_SELECTEXISTINGUSER' | translate }}</p>
            <div *ngFor="let item of users">
              <button class="mb-2 mr-2 btn btn-focus active" (click)="getUserData(item)">
                {{ item.lastname }} {{ item.firstname }} {{ item.middlename }}
              </button>
            </div>
          </div>
          <hr />
        </div>
        <div class="col-md-4">
          <div class="position-relative form-group">
            <label for="password">{{ 'TR_PASSWORD' | translate }}</label>
            <!-- <div class="input-group row m-0"> -->
            <div class="input-button-group">
              <!-- [placeholder]="'TR_PASSWORD' | translate" -->
              <input name="password" type="text" class="form-control" autocomplete="new-password"
                formControlName="password" />
              <button type="button" class="btn btn-primary" (click)="generatePassword()">
                {{ 'TR_GENERATE_PASSWORD' | translate }}
              </button>
            </div>
            <!-- <div class="input-group-append col-6 p-0">
              </div> -->
            <!-- </div> -->
            <p class="warning-message mt-1">{{ 'TR_PASSWORD_WARNING_MSG' | translate }}</p>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-4 mt-2">
          <div class="position-relative form-group">
            <label for="lastname">{{ ('TR_LASTNAME' | translate) + mainService.asterisk }}</label>
            <!-- [placeholder]="('TR_LASTNAME' | translate) + mainService.asterisk" -->
            <input name="lastname" type="text" class="form-control"
              [class.error-border]="(isInvalidSubmit && (!form.value.lastname || !form.value.lastname.trim() || form.value.lastname.trim().length < 2 || form.value.lastname.trim().length >= 100)) || isInvalidLastname"
              autocomplete="off" formControlName="lastname" (blur)="checkFieldIsValid($event,'lastname')" />
            <p class="warning-message mt-1">{{ 'TR_LAST_NAME_WARNING_MSG' | translate }}</p>
          </div>
        </div>
        <div class="col-md-4 mt-2">
          <div class="position-relative form-group">
            <label for="firstname">{{ ('TR_FIRSTNAME' | translate) + mainService.asterisk }}</label>
            <!-- [placeholder]="('TR_FIRSTNAME' | translate) + mainService.asterisk" -->
            <input name="firstname" type="text" class="form-control"
              [class.error-border]="(isInvalidSubmit && (!form.value.firstname || !form.value.firstname.trim() || form.value.firstname.trim().length < 2 || form.value.firstname.trim().length >=100)) || isInvalidFirstname"
              autocomplete="off" formControlName="firstname" (blur)="checkFieldIsValid($event,'firstname')" />
            <p class="warning-message mt-1">{{ 'TR_FIRST_NAME_WARNING_MSG' | translate }}</p>
          </div>
        </div>
        <div class="col-md-4 mt-2">
          <div class="position-relative form-group">
            <label for="middlename">{{ 'TR_MIDDLENAME' | translate }}</label>
            <!-- [placeholder]="'TR_MIDDLENAME' | translate" -->
            <input name="middlename" type="text" class="form-control" autocomplete="off" formControlName="middlename" />
            <p class="warning-message mt-1">{{ 'TR_MIDDLE_NAME_WARNING_MSG' | translate }}</p>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-4 mt-2">
          <div class="position-relative form-group">
            <label for="email">{{ ('TR_EMAIL' | translate) + mainService.asterisk }}</label>
            <!-- [placeholder]="'TR_EMAIL' | translate" -->
            <input name="email" type="email" class="form-control"
              [class.error-border]="(isInvalidSubmit && !form.value.email) || form.controls['email'].hasError('email')"
              autocomplete="off" formControlName="email" (ngModelChange)="filter($event, 'email')" />
            <p class="warning-message mt-1">{{ 'TR_EMAIL_WARNING_MSG' | translate }}</p>
          </div>
        </div>
        <div class="col-md-4 mt-2">
          <div class="position-relative form-group">
            <label for="dob">{{ 'TR_DOB' | translate }}</label>
            <input name="dob" type="date" class="form-control" autocomplete="off" formControlName="dob" [max]="today"
              [class.error-border]="isInvalidDob" (blur)="checkDates($event)" />
            <p class="warning-message mt-1">{{ 'TR_DATE_WARNING_MSG' | translate }}</p>
          </div>
        </div>
        <div class="col-md-4 mt-2">
          <div class="position-relative form-group">
            <label for="phone">{{ 'TR_PHONE' | translate }}</label>
            <!-- [placeholder]="'TR_PHONE' | translate" -->
            <input name="phone" type="tel" autocomplete="off" class="form-control"
              [class.error-border]="form.controls['phone'].hasError('pattern') || (isInvalidPhonenumber && form.value.phone)"
              autocomplete="off" formControlName="phone" (ngModelChange)="filter($event, 'phone')"
              (blur)="checkFieldIsValid($event,'phonenumber')" />
            <p class="warning-message mt-1">{{ 'TR_PHONE_WARNING_MSG' | translate }}</p>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-4 mt-2">
          <div class="position-relative form-group">
            <label for="mobilePhone">{{ 'TR_MOBILEPHONE' | translate }}</label>
            <!-- [placeholder]="'TR_MOBILEPHONE' | translate" -->
            <input name="mobilePhone" type="tel" class="form-control"
              [class.error-border]="form.controls['mobilePhone'].hasError('pattern') || (isInvalidPhonenumber && form.value.mobilePhone)"
              autocomplete="off" formControlName="mobilePhone" (ngModelChange)="filter($event, 'mobilePhone')"
              (blur)="checkFieldIsValid($event,'phonenumber')" />
            <p class="warning-message mt-1">{{ 'TR_PHONE_WARNING_MSG' | translate }}</p>
          </div>
        </div>
        <div class="col-md-4 mt-2">
          <div class="position-relative form-group">
            <label for="addressResidence">{{ 'TR_ADDRESSRESIDENCE' | translate }}</label>
            <!-- [placeholder]="'TR_ADDRESSRESIDENCE' | translate" -->
            <input name="addressResidence" type="text" class="form-control" autocomplete="off"
              formControlName="addressResidence" />
          </div>
        </div>
        <div class="col-md-4 mt-2">
          <div class="position-relative form-group">
            <label for="addressRegistration">{{ 'TR_ADDRESSREGISTRATION' | translate }}</label>
            <!-- [placeholder]="'TR_ADDRESSREGISTRATION' | translate" -->
            <input name="addressRegistration" type="text" class="form-control" autocomplete="off"
              formControlName="addressRegistration" />
          </div>
        </div>
      </div>
      <div class="form-row">
      </div>
      <div class="form-row">
      </div>
      <app-submit-button [spinnerId]="createSpinnerId" [buttonName]="'TR_CREATE' | translate"
        [isInvalidSubmit]="isInvalidSubmit" [isFormValid]="form.valid"></app-submit-button>
    </form>
  </div>
</div>