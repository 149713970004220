<div class="main-card card">
  <div class="card-body">
    <form (submit)="submitData()" [formGroup]="form">
      <div class="wrap-flex form-outline">
        <div ngbDropdown>
          <div class="flex flex-column">
            <label>{{ ('TR_LANGUAGES' | translate) + mainService.asterisk }}</label>
            <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-outline-primary" [class.error-border]="isInvalidSubmit && !form.value.languageId">
              {{ selectedLanguageName ? selectedLanguageName : ('TR_LANGUAGES' | translate) + mainService.asterisk }}
            </button>
          </div>
          <div ngbDropdownMenu>
            <button type="button" class="dropdown-item" *ngFor="let item of languages" (click)="setSelectedLanguage(item.languageId, item.name)">
              {{ item.name }}
            </button>
          </div>
        </div>
        <div ngbDropdown>
          <div class="flex flex-column">
            <label>{{ ('TR_TYPE' | translate) + mainService.asterisk }}</label>
            <button type="button" ngbDropdownToggle class="mb-2 btn btn-outline-primary" [class.error-border]="isInvalidSubmit && !form.value.type && form.value.type !== 0">
              {{ selectedTypeName ? selectedTypeName : ('TR_TYPE' | translate) + mainService.asterisk }}
            </button>
          </div>
          <div ngbDropdownMenu>
            <button type="button" class="dropdown-item" *ngFor="let item of translationTypes;index as i" (click)="setSelectedType(item, i)">
              {{ item }}
            </button>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-12">
          <div class="position-relative form-group">
            <label for="shortKey">{{ ('TR_KEY' | translate) + mainService.asterisk }}</label>
            <input
              name="shortKey"
              [placeholder]="('TR_KEY' | translate) + mainService.asterisk"
              type="text"
              class="form-control"
              [class.error-border]="(isInvalidSubmit && !form.value.shortKey && !translationId) || form.controls['shortKey'].hasError('pattern') || hasKeyUniqueError"
              autocomplete="off"
              formControlName="shortKey"
              (ngModelChange)="checkUniqueKey($event)"
            />
            <small *ngIf="hasKeyUniqueError" class="red">{{ 'TR_KEY_UNIQUE_MESSAGE' | translate }}</small>
            <p class="warning-message mb-0 mt-1">{{ 'TR_KEY_WARNING_MESSAGE' | translate }}</p>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-12">
          <div class="position-relative form-group">
            <label for="translation1">{{ ('TR_TEXT' | translate) + mainService.asterisk }}</label>
            <textarea
              id="translation"
              rows="8"
              name="translation1"
              [placeholder]="('TR_TEXT' | translate) + mainService.asterisk"
              type="text"
              class="form-control translate-text scroll"
              [class.error-border]="isInvalidSubmit && !form.value.translation1"
              autocomplete="off"
              value="form.value.translation1"
              formControlName="translation1"
            ></textarea>
            <button
              type="button"
              class="mb-2 mr-2 border-0 btn-transition btn btn-outline-secondary remove-content-btn"
              [title]="('TR_REMOVE' | translate) + ' ' + ('TR_TEXT' | translate)"
              (click)="removeTranslationText()"
            >
              <i class="pe-7s-close"></i>
            </button>
            <button
              id="btnCopy"
              type="button"
              class="mb-2 mr-2 border-0 btn-transition btn btn-outline-secondary copy-btn"
              [title]="'TR_COPY' | translate"
              data-clipboard-target="#translation"
            >
              <i class="pe-7s-copy-file"></i>
            </button>
          </div>
        </div>
      </div>
      <app-submit-button
        [spinnerId]="createSpinnerId"
        [buttonName]="isCreateForm ? ('TR_CREATE' | translate) : ('TR_UPDATE' | translate)"
        [isInvalidSubmit]="isInvalidSubmit"
        [isFormValid]="form.valid"
        [isFloatRight]="true"
      ></app-submit-button>
    </form>
  </div>
</div>
<!-- <div class="modal-body min-modal">
  <app-page-loader [isModalLoader]="true"></app-page-loader>
</div> -->