<div class="d-flex">
  <div class="header-btn-lg pr-0">
    <div class="widget-content p-0">
      <div class="widget-content-wrapper">
        <div class="widget-content-left">
          <div class="btn-group" ngbDropdown #myBox="ngbDropdown" placement="bottom-right">
            <button type="button" class="btn btn-link p-0 mr-2" ngbDropdownToggle>
              <span>
                <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                  <div [ngStyle]="{ 'background-image' : 'url(' + user.avatarFilePath + ')' }" class="small-avatar"></div>
                </div>
              </span>
            </button>
            <div ngbDropdownMenu class="user-dropdown-block">
              <div class="user-block">
                <div class="user-avatar" [ngStyle]="{ 'background-image' : 'url(' + user.avatarFilePath + ')' }"></div>
                <div class="content">
                  <p>{{ user.lastname }} {{ user.firstname}} {{ user.middlename }}</p>
                  <button class="border-0 btn-transition btn btn-outline-link p-0" routerLink="profile">{{ 'TR_VIEW_PROFILE' | translate }}</button>
                </div>
              </div>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item" (click)="myBox.close(); signOut()">Sign out</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

