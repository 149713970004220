import { Component, OnInit } from '@angular/core';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { RolesService } from 'src/app/Services/roles.service';
import { ITemplateData } from 'src/app/Models/ITemplateData';
import { DOCUMENT_TEMPLATE_PATH_KEY } from 'src/app/Constants/request-path-keys';
import { DOCUMENTTEMPLATE_CREATE, DOCUMENTTEMPLATE_UPDATE, DOCUMENTTEMPLATE_VIEW, TR_TEMPLATES_KEY } from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-templates-table',
  templateUrl: './templates-table.component.html',
  styleUrls: ['./templates-table.component.scss']
})
export class TemplatesTableComponent implements OnInit {
  template_view: boolean;
  template_create: boolean;
  template_update: boolean;
  isPageLoaded: boolean;
  userRoles: Array<string> = [];
  templates: Array<ITemplateData> = [];

  constructor(
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    private translationService: TranslationService,
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_TEMPLATES_KEY);

    this.getTemplates();
  }

  setRoles(): void {
    this.userRoles.forEach(role => {
      switch (role) {
        case DOCUMENTTEMPLATE_VIEW:
          this.template_view = true;
          break;
        case DOCUMENTTEMPLATE_CREATE:
          this.template_create = true;
          break;
        case DOCUMENTTEMPLATE_UPDATE:
          this.template_update = true;
          break;
      }
    });
  }

  async getTemplates() {
    if (!this.template_view) { return; }
    this.dataHandlerService.getDataWithSpinner(DOCUMENT_TEMPLATE_PATH_KEY).then(response => {
      this.templates = response;
    });
  }
}
