import { Component, OnInit } from '@angular/core';
import { TR_FAILS_KEY } from 'src/app/Constants/main-keys';
import { IResponseModel } from 'src/app/Models/IResponseModel';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { PAGE_NUMBER_PATH_PARAM_KEY, PAGE_SIZE_PATH_PARAM_KEY } from 'src/app/Constants/request-path-keys';

@Component({
  selector: 'app-fail-logs-table',
  templateUrl: './fail-logs-table.component.html',
  styleUrls: ['./fail-logs-table.component.scss']
})
export class FailLogsTableComponent implements OnInit {
  isPageLoaded: boolean;
  fails: Array<any> = [];
  userRoles: Array<string> = [];
  pageSizeArr = [10, 20, 50, 100, 200, 500, 1000];
  totalPages: number = 0;
  currentPage: number = 1;
  pageSize: number = 10;

  constructor(
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    private mainService: MainService,
    private translationService: TranslationService,
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;

    this.translationService.setPageTitle(TR_FAILS_KEY);

    // this.getFailsByPagination();
  }

  getFailsByPagination() {
    this.dataHandlerService.nodeGetData(this.currentPage, this.pageSize, PAGE_NUMBER_PATH_PARAM_KEY, PAGE_SIZE_PATH_PARAM_KEY, 'errors/get-errors').then((response: IResponseModel) => {
      if (response.pagination) {
        const paginationData = JSON.parse(response.pagination);
        this.totalPages = paginationData.TotalPages * 10;
        this.pageSize = paginationData.PageSize;
        this.currentPage = paginationData.CurrentPage;
      }
      this.fails = response.data;
    });
  }

  setPagination(currentPage: string): void {
    const semyColumnIndex = currentPage.indexOf('(');
    this.currentPage = +currentPage.substring(0, semyColumnIndex);
    // this.getFailsByPagination();
  }

  setSelectedPageSize(pageSize: number) {
    this.pageSize = pageSize;
    // this.getFailsByPagination();
  }

}
