import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { delay, startWith, tap } from 'rxjs/operators';
import { MainService } from 'src/app/Services/main.service';

@Component({
  selector: 'app-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss']
})
export class ActionButtonComponent implements OnInit {
  @Output() clicked = new EventEmitter();

  @Input() buttonClasses: string;
  @Input() spinnerClasses: string;
  @Input() isCurrent: boolean;
  @Input() spinnerId: string;
  @Input() buttonName: string;

  isSubmitted: boolean;

  constructor(
    private mainService: MainService
  ) { }

  ngOnInit() {
    this.mainService.buttonSpinners.pipe(
      startWith(null),
      delay(0),
      tap(spinners => {
        this.isSubmitted = spinners && spinners.find(spinner => spinner === this.spinnerId) && this.isCurrent;
      })
    ).subscribe();
  }

  buttonClicked(): void {
    this.clicked.emit();
  }
}
