import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { guid } from '@progress/kendo-angular-common';
import { ActivatedRoute } from '@angular/router';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { AlertService } from 'src/app/Services/alert.service';
import { ITemplateData, ITemplateInfoData, ITemplateInfoDataModel } from 'src/app/Models/ITemplateData';
import {
  DOCUMENTTEMPLATE_I_D_CREATE,
  DOCUMENTTEMPLATE_I_D_UPDATE,
  DOCUMENTTEMPLATE_I_D_VIEW,
  DOCUMENTTEMPLATE_I_VIEW,
  DOCUMENTTEMPLATE_VIEW,
  SUCCESS_KEY,
  TR_CREATE_MESSAGE_KEY,
  TR_CREATE_TEMPLATE_INFO_DATA_KEY,
  TR_UPDATE_MESSAGE_KEY,
  TR_UPDATE_TEMPLATE_INFO_DATA_KEY
} from 'src/app/Constants/main-keys';
import {
  DOCUMENT_TEMPLATE_INFO_DATA_PATH_KEY,
  DOCUMENT_TEMPLATE_INFO_PATH_KEY,
  DOCUMENT_TEMPLATE_PATH_KEY,
  TEMPLATE_ID_PATH_PARAM_KEY,
  TEMPLATE_INFO_DATA_URL_KEY
} from 'src/app/Constants/request-path-keys';

@Component({
  selector: 'app-template-info-data-form',
  templateUrl: './template-info-data-form.component.html',
  styleUrls: ['./template-info-data-form.component.scss']
})
export class TemplateInfoDataFormComponent implements OnInit {
  form: FormGroup;
  templateInfoDataId: number;
  selectedTemplateInfoName: string;
  selectedTemplateName: string;
  templateInfoData: ITemplateInfoData;
  userRoles: Array<string> = [];
  templates: Array<ITemplateData> = [];
  templatesInfo: Array<ITemplateInfoData> = [];
  template_view: boolean;
  templateinfo_view: boolean;
  templateinfodata_view: boolean;
  templateinfodata_create: boolean;
  templateinfodata_update: boolean;
  isInvalidSubmit: boolean;
  submitSpinnerId: string = guid();
  isCreateForm: boolean = true;

  constructor(
    private dataHandlerService: DataHandlerService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private translationService: TranslationService,
    public mainService: MainService,
    private rolesService: RolesService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_CREATE_TEMPLATE_INFO_DATA_KEY);

    this.form = this.formBuilder.group({
      dtiId: [null, Validators.required],
      data: [null, Validators.required]
    });

    this.route.paramMap.subscribe(params => {
      this.templateInfoDataId = +params.get('item.id');

      if (this.templateInfoDataId) {
        this.isCreateForm = false;
        this.translationService.setPageTitle(TR_UPDATE_TEMPLATE_INFO_DATA_KEY);
        this.getTemplateInfoDataById(this.templateInfoDataId);
      }
    });

    this.getTemplates();
  }

  setRoles(): void {
    this.userRoles.forEach(role => {
      switch (role) {
        case DOCUMENTTEMPLATE_VIEW:
          this.template_view = true;
          break;
        case DOCUMENTTEMPLATE_I_VIEW:
          this.templateinfo_view = true;
          break;
        case DOCUMENTTEMPLATE_I_D_VIEW:
          this.templateinfodata_view = true;
          break;
        case DOCUMENTTEMPLATE_I_D_CREATE:
          this.templateinfodata_create = true;
          break;
        case DOCUMENTTEMPLATE_I_D_UPDATE:
          this.templateinfodata_update = true;
          break;
      }
    });
  }

  setSelectedTemplate(id: number, name: string): void {
    this.form.patchValue({ dtiId: null });
    this.selectedTemplateInfoName = '';
    this.selectedTemplateName = name;
    this.getTemplatesInfoByTemplateId(id);
  }

  setSelectedTemplateInfo(id: number, name: string): void {
    this.selectedTemplateInfoName = name;
    this.form.patchValue({ dtiId: id });
  }

  async getTemplatesInfoByTemplateId(id: number) {
    if (!this.templateinfo_view) { return; }
    this.templatesInfo = await this.dataHandlerService.getData(`${DOCUMENT_TEMPLATE_INFO_PATH_KEY}?${TEMPLATE_ID_PATH_PARAM_KEY}${id}`);
  }

  getTemplates(): void {
    if (!this.template_view) { return; }
    this.dataHandlerService.getData(DOCUMENT_TEMPLATE_PATH_KEY).then(response => {
      this.templates = response;
      if (!this.selectedTemplateName && this.templateInfoData) {
        this.selectedTemplateName = this.templates.find(template => template.id === this.templateInfoData.documentTemplateId).name;
      }
    });
  }

  getTemplateInfoDataById(id: number): void {
    if (!this.templateinfodata_view) { return; }
    this.dataHandlerService.getDataWithSpinner(`${DOCUMENT_TEMPLATE_INFO_DATA_PATH_KEY}/${id}`).then(response => {
      const templateInfoData: ITemplateInfoDataModel = response;
      this.form.patchValue({
        data: templateInfoData.data,
        dtiId: templateInfoData.dtiId
      });

      if (templateInfoData.dtiId) {
        this.dataHandlerService.getDataWithSpinner(`${DOCUMENT_TEMPLATE_INFO_PATH_KEY}/${templateInfoData.dtiId}`).then(response => {
          this.templateInfoData = response;
          this.selectedTemplateInfoName = this.templateInfoData.position;
          if (this.templates.length) {
            this.selectedTemplateName = this.templates.find(data => data.id === this.templateInfoData.documentTemplateId).name;
          }
        });
      }
    });
  }

  submitData(): void {
    if (this.form.valid) {
      const form = { ...this.form.value };
      if (this.templateInfoDataId && this.templateinfodata_update) {
        form.id = this.templateInfoDataId;
        this.dataHandlerService.updateData(form, DOCUMENT_TEMPLATE_INFO_DATA_PATH_KEY, this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
          this.mainService.goBack(TEMPLATE_INFO_DATA_URL_KEY);
        });
      } else if (this.templateinfodata_create) {
        this.dataHandlerService.createData(form, DOCUMENT_TEMPLATE_INFO_DATA_PATH_KEY, this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
          this.mainService.goBack(TEMPLATE_INFO_DATA_URL_KEY);
        });
      }
    } else {
      this.isInvalidSubmit = true;
    }
  }
}
