<div class="title-block mb-3">
  <h4 class="card-title">{{ 'TR_CURRENCY' | translate }}</h4>
</div>

<div *ngIf="currency_create" class="d-inline-block">
  <button class="mb-2 mr-2 btn btn-success create-btn" [title]="'TR_CREATE_CURRENCY' | translate"
    [routerLink]="['currency-form']">
    <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_CREATE' | translate }}</span>
  </button>
</div>

<div class="row">
  <div class="col-12">
    <div *ngIf="currency_view" class="main-card mb-3 card">
      <div class="table-responsive">
        <table class="align-middle mb-0 table table-striped table-hover currency-table">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ 'TR_NAME' | translate }}</th>
              <th>{{ 'TR_ISO_CODE' | translate }}</th>
              <th>{{ 'TR_SYMBOL' | translate }}</th>
              <th>{{ 'TR_RATE' | translate }}</th>
              <th>{{ 'TR_SYMBOL_POSITION' | translate }}</th>
              <th class="text-center">{{ 'TR_ACTIONS' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of currencies let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.isocode }}</td>
              <td>{{ item.symbol }}</td>
              <td>{{ item.rate }}</td>
              <td>{{ item.symbolPosition ? ('TR_LEFT' | translate) : ('TR_RIGHT' | translate) }}</td>
              <td class="text-center">
                <div role="group" class="btn-group-sm btn-group">
                  <button *ngIf="currency_update" class="btn-transition btn btn-outline-info"
                    [title]="'TR_UPDATE_CONFIG' | translate" [routerLink]="['currency-form', item.id]">
                    <i class="pe-7s-note"></i>
                  </button>
                  <button *ngIf="currency_delete" class="ml-1 btn-transition btn btn-outline-danger"
                    [title]="'TR_DELETE' | translate" (click)="confirmDelete(deleteconfirm, item.id, item.name)">
                    <i class="pe-7s-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #deleteconfirm let-c="close" let-d="dismiss">
  <div class="custom-modal modal-body">
    <p>{{ 'TR_DELETE_ERROR' | translate }} <b class="word-break-break-word">{{ deleteCurrencyName }}</b>?</p>
  </div>
  <div class="custom-modal modal-footer">
    <app-action-button *ngIf="currency_delete" [isCurrent]="true" [spinnerId]="deleteSpinnerId"
      buttonClasses="mr-2 btn-transition btn btn-outline-info" spinnerClasses="small-button-spinner"
      [buttonName]="'TR_YES'" (clicked)="delete()"></app-action-button>
    <button type="button" class="mr-2 btn-transition btn btn-outline-dark"
      (click)="c('Close click')">{{ 'TR_NO' | translate }}</button>
  </div>
</ng-template>