import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { guid } from '@progress/kendo-angular-common';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { AlertService } from 'src/app/Services/alert.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { MainService } from 'src/app/Services/main.service';
import { IGroupData } from 'src/app/Models/IGroupData';
import { IUserData } from 'src/app/Models/IUserData';
import { IWebinarData } from 'src/app/Models/IWebinarData';
import { GROUP_PATH_KEY, ORGANIZATION_EDUCATION_LEVEL_PATH_KEY, WEBINARS_PATH_KEY, WEBINAR_URL_KEY } from 'src/app/Constants/request-path-keys';
import {
  EDUCATION_LEVEL_VIEW,
  GROUP_VIEW,
  MONTH_ARR_KEY,
  SUCCESS_KEY,
  TR_CREATE_MESSAGE_KEY,
  TR_UPDATE_MESSAGE_KEY,
  TR_WEBINAR_CREATE_KEY,
  TR_WEBINAR_UPDATE_KEY,
  USER_VIEW,
  WEBINAR_CREATE,
  WEBINAR_UPDATE,
  WEBINAR_VIEW,
  WEB_SUBTITLES_KEY,
  WEEK_DAYS_KEY
} from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-webinar-form',
  templateUrl: './webinar-form.component.html',
  styleUrls: ['./webinar-form.component.scss']
})
export class WebinarFormComponent implements OnInit {
  form: FormGroup;
  webinarId: number;
  selectedLecturerId: number;
  selectedGroupId: number;
  selectedSubjectId: number;
  selectedLecturerName: string;
  selectedGroupName: string;
  selectedSubjectName: string;
  imgURL: any;
  selectedImage: File = null;
  isCreateForm: boolean = true;
  isInvalidSubmit: boolean;
  createWebinar: boolean = true;
  disabledSubject: boolean = true;
  disabledLecturer: boolean = true;
  monthDays: Array<any> = [];
  rangeDays: Array<any> = [];
  groups: Array<IGroupData> = [];
  lecturers: Array<IUserData> = [];
  subjects: Array<any> = [];
  userRoles: Array<string> = [];
  months: Array<string> = MONTH_ARR_KEY;
  weekDaysArr: Array<string> = WEEK_DAYS_KEY;
  webinar_create: boolean;
  webinar_update: boolean;
  webinar_view: boolean;
  maxDate: string;
  minDate: string;
  minTime: string;
  maxTime: string;
  subtitles: Array<string> = WEB_SUBTITLES_KEY;
  readySubtitles: Array<string> = [];
  submitSpinnerId: string = guid();

  constructor(
    private formBuilder: FormBuilder,
    private dataHandlerService: DataHandlerService,
    private route: ActivatedRoute,
    private rolesService: RolesService,
    private translationService: TranslationService,
    public mainService: MainService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.subtitles.forEach(item => {
      this.translationService.translate(item).pipe(
        map((data: string) => this.readySubtitles.push(data))
      ).subscribe();
    });
    this.translationService.setPageTitle(TR_WEBINAR_CREATE_KEY);

    this.userRoles = this.rolesService.userRoles;
    this.setRoles();

    if (!this.webinar_view) { return; }

    this.form = this.formBuilder.group({
      lecturerUserId: [null, Validators.required],
      groupId: [null, Validators.required],
      subjectId: [null, Validators.required],
      image: [null],
      name: [null, Validators.required],
      description: [null],
      duration: [null, [Validators.pattern('^[0-9]+$')]],
      periodicity: [1, Validators.required],
      startDate: [null],
      endDate: [null],
      days: this.formBuilder.array([
        this.formBuilder.group({
          webinarDate: [null, Validators.required],
          startTime: [null, Validators.required],
          endTime: [null, Validators.required]
        })
      ])
    });

    this.getGroups();

    this.route.paramMap.subscribe(params => {
      this.webinarId = +params.get('item.id');
      if (this.webinarId) {
        this.isCreateForm = false;
        this.createWebinar = false;
        this.translationService.setPageTitle(TR_WEBINAR_UPDATE_KEY);

        if (this.userRoles.includes(WEBINAR_VIEW)) {
          this.dataHandlerService.getDataWithSpinner(`${WEBINARS_PATH_KEY}/${this.webinarId}`).then(response => {
            const webinarData: IWebinarData = response;
            this.form.patchValue({
              name: webinarData.name,
              description: webinarData.description,
              duration: webinarData.duration,
              lecturerUserId: webinarData.lecturerUserId,
              groupId: webinarData.groupId,
              subjectId: webinarData.subjectId,
              periodicity: webinarData.periodicity
            });
            this.selectedLecturerName = webinarData.lecturerName;
            this.selectedSubjectName = webinarData.subjectName;
            this.selectedGroupName = webinarData.groupName;
            this.selectedLecturerId = webinarData.lecturerUserId;
            this.selectedSubjectId = webinarData.subjectId;
            this.selectedGroupId = webinarData.groupId;
            this.imgURL = webinarData.avatarFilePath;
          });
        }
      }
    });

    this.form.get('startDate').valueChanges.subscribe(val => {
      this.form.value.endDate && this.form.value.periodicity === 3 && this.openCalendarRange(val, this.form.value.endDate);
    });

    this.form.get('endDate').valueChanges.subscribe(val => {
      this.form.value.startDate && this.form.value.periodicity === 3 && this.openCalendarRange(this.form.value.startDate, val);
    });

  }

  setRoles() {
    this.userRoles.forEach(role => {
      switch (role) {
        case WEBINAR_VIEW:
          this.webinar_view = true;
          break;
        case WEBINAR_UPDATE:
          this.webinar_update = true;
          break;
        case WEBINAR_CREATE:
          this.webinar_create = true;
          break;
      }
    });
  }

  setSelectedLecturer(id: number, name: string): void {
    this.selectedLecturerId = id;
    this.selectedLecturerName = name;
    this.form.patchValue({ lecturerUserId: id });
  }

  setSelectedSubject(id: number, name: string): void {
    this.selectedSubjectId = id;

    if (this.selectedSubjectId) {
      this.selectedLecturerId = null;
      this.selectedLecturerName = null;
      this.disabledLecturer = false;
      this.lecturers = [];
      this.selectedSubjectName = name;
      this.form.patchValue({ subjectId: id, lecturerUserId: null });

      this.getLecturers(this.selectedSubjectId);
    }
  }

  setSelectedGroup(id: number, name: string): void {
    this.selectedGroupId = id;
    const selectedGroup: IGroupData = this.groups.find(data => data.id === id);

    if (this.selectedGroupId && selectedGroup) {
      this.selectedSubjectId = null;
      this.selectedLecturerId = null;
      this.selectedLecturerName = null;
      this.selectedSubjectName = null;
      this.disabledSubject = false;
      this.disabledLecturer = true;
      this.subjects = [];
      this.lecturers = [];
      this.selectedGroupName = name;
      this.form.patchValue({ groupId: id, subjectId: null, lecturerUserId: null });

      this.getSubjects(selectedGroup.organizationEducationLevelId);
    }
  }

  async getGroups() {
    if (!this.userRoles.includes(GROUP_VIEW)) { return; }
    this.groups = await this.dataHandlerService.getData(GROUP_PATH_KEY);
  }

  async getSubjects(programId: number) {
    if (!this.userRoles.includes(EDUCATION_LEVEL_VIEW)) { return; }
    this.subjects = await this.dataHandlerService.getData(`${ORGANIZATION_EDUCATION_LEVEL_PATH_KEY}/${programId}`) || [];
  }

  async getLecturers(subjectId: number) {
    if (!this.userRoles.includes(USER_VIEW)) { return; }
    this.lecturers = await this.dataHandlerService.getData(`SubjectLecturer/subjectId/${subjectId}/false`) || [];
  }

  async handleFileInput(files: FileList) {
    this.selectedImage = files && files[0];
    this.imgURL = files && files[0] && await this.mainService.imageUpload(files[0]);
  }

  submitData(): void {
    if (this.form.valid && this.form.value.days?.length) {
      const form = { ...this.form.value };
      if (this.webinarId) {
        
        if (!this.webinar_update) { return; }
        if (this.selectedImage) {
          form.image = this.selectedImage;
        }
        const formData: any = new FormData();
        for (let i in form) {
          if (form[i] && i !== 'days') {
            formData.append(`${i}`, form[i]);
          }
        }
        formData.append('id', this.webinarId);

        this.dataHandlerService.updateFormData(formData, WEBINARS_PATH_KEY, this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
          this.mainService.goBack(WEBINAR_URL_KEY);
        });
      } else if (this.webinar_create) {
        const days = JSON.parse(JSON.stringify(form.days));
        form.image = this.selectedImage;
        form.duration = +form.duration;
        const formData: any = new FormData();
        for (let i in form) {
          if (form[i] === null) { continue; }
          if (i === 'days') {
            formData.append(`${i}`, JSON.stringify(days));
          } else {
            formData.append(`${i}`, form[i]);
          }
        }

        formData.append('timeZoneOffset', new Date().getTimezoneOffset() / 60);

        this.dataHandlerService.createFormData(formData, WEBINARS_PATH_KEY, this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
          this.mainService.goBack(WEBINAR_URL_KEY);
        });
      }
    } else {
      this.isInvalidSubmit = true;
    }
  }

  tabChangeHandler($event) {
    this.form.patchValue({ periodicity: +$event.nextId, startDate: null, endDate: null });
    const days = <FormArray>this.form.get('days');
    days.clear();
    $event.nextId == 1 && days.push(this.formBuilder.group({ webinarDate: [null], startTime: [null], endTime: [null] }));
    this.rangeDays = [];
    this.monthDays = [];
  }

  setMinTime(value: string): void {
    this.minTime = value;
  }

  setMaxTime(value: string): void {
    this.maxTime = value;
  }

  setMinValue(value: string): void {
    this.minDate = value;
  }

  setMaxValue(value: string): void {
    this.maxDate = value;
  }

  removePeriodicWeekDay(day: string): void {
    const days = <FormArray>this.form.get('days');
    const lastIndex = this.findDaysLastIndex([...days.controls], 'value', 'webinarDay', day);
    days.removeAt(lastIndex);
  }

  addPeriodicWeekDay(day: string): void {
    const days = <FormArray>this.form.get('days');
    days.push(
      this.formBuilder.group({
        webinarDay: [day, Validators.required],
        startTime: [null, Validators.required],
        endTime: [null, Validators.required]
      })
    );
  }

  closePeriodicTimePicker(data, index): void {
    this.form.value.days.forEach((item, ind) => {
      if (item.webinarDay === index && item.timePickerIndex === data['data']) {
        this.form.value.days.splice(ind, 1);
        data['data']--;
        data['dataArr'].splice(data['dataArr'].length - 1, 1);
      }
    });
  }

  addCustomWebinarDay(day: any): void {
    const days = <FormArray>this.form.get('days');
    days.push(
      this.formBuilder.group({
        webinarDate: [`${day.year}-${day.month}-${day.day}`, Validators.required],
        startTime: [null, Validators.required],
        endTime: [null, Validators.required]
      })
    );
  }

  removeCustomWebinarDay(day: any): void {
    const days = <FormArray>this.form.get('days');
    const lastIndex = this.findDaysLastIndex([...days.controls], 'value', 'webinarDate', `${day.year}-${day.month}-${day.day}`);
    days.removeAt(lastIndex);
  }

  findDaysLastIndex(array: Array<any>, firstKey: string, secondKey: string, searchValue: any): number {
    var index = array.slice().reverse().findIndex(x => x[firstKey][secondKey] == searchValue);
    var count = array.length - 1
    var finalIndex = index >= 0 ? count - index : index;
    return finalIndex;
  }

  setMonths(months: number, startMonth: number, endMonth: number, endDay: number, year: number): void {
    for (let j = 0; j < months; j++) {
      let nextMonth = startMonth + 1 + j;
      let lastDayOfNextMonth = new Date(year, nextMonth, 0).getDate();
      if (nextMonth === endMonth) {
        lastDayOfNextMonth = endDay;
      }

      this.rangeDays.push(this.monthDays);
      this.monthDays = [];
      for (let j = 1; j <= lastDayOfNextMonth; j++) {
        const day = new Date(`${nextMonth}/${j}/${year}`);
        const dayName = this.weekDaysArr[day.getDay()];
        this.monthDays.push({
          day: j,
          month: nextMonth,
          year: year,
          dayName: dayName,
          data: null,
          dataArr: []
        });
      }
    }
  }

  setDayDiff(dayDiff: number, months: number, startMonth: number, endMonth: number, endDay: number, year: number): number {
    for (let j = 0; j < months; j++) {
      let nextMonth = startMonth + 1 + j;
      if (nextMonth === endMonth) {
        dayDiff += endDay;
      } else {
        let lastDayOfNextMonth = new Date(year, nextMonth, 0).getDate();
        dayDiff += lastDayOfNextMonth;
      }
    }
    return dayDiff;
  }

  openCalendarRange(start: Date, end: Date): void {
    this.monthDays = [];
    this.rangeDays = [];
    const startDate = new Date(start);
    const endDate = new Date(end);
    const startDay = startDate.getDate();
    const endDay = endDate.getDate();
    const startMonth = startDate.getMonth() + 1;
    const endMonth = endDate.getMonth() + 1;
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();
    const lastDayOfStartMonth = new Date(startYear, startMonth, 0).getDate();
    let month = startMonth;
    let monthsDiff;
    let monthsDiffOfStartYear;
    let dayDiff;

    if (endMonth - startMonth > 1) {
      monthsDiff = endMonth - startMonth;
      dayDiff = (lastDayOfStartMonth - startDay + 1) + endDay + 1;
      dayDiff = this.setDayDiff(dayDiff, monthsDiff, startMonth, 0, 0, startYear);
    } else if (startMonth !== endMonth) {
      dayDiff = (lastDayOfStartMonth - startDay) + endDay + 1;

      if (startYear !== endYear && Math.abs(endMonth - startMonth) > 1) {
        dayDiff = lastDayOfStartMonth - startDay + 1;
        monthsDiffOfStartYear = 12 - startMonth;
        dayDiff = this.setDayDiff(dayDiff, monthsDiffOfStartYear, startMonth, 0, 0, startYear);
        dayDiff = this.setDayDiff(dayDiff, endMonth, 0, endMonth, endDay, endYear);
      }
    } else {
      dayDiff = endDay - startDay;
    }

    for (let i = 0; i <= dayDiff; i++) {
      if (i == 0) {
        const day = new Date(`${startMonth}/${startDay}/${startYear}`);
        const dayName = this.weekDaysArr[day.getDay()];
        this.monthDays.push({
          day: startDay,
          month: startMonth,
          year: startYear,
          dayName: dayName,
          data: null,
          dataArr: []
        });
        continue;
      }

      let nextDay = startDay + i;
      if (nextDay > lastDayOfStartMonth) {
        if (startYear !== endYear && Math.abs(endMonth - startMonth) > 1) {
          this.setMonths(monthsDiffOfStartYear, startMonth, 0, 0, startYear);
          this.setMonths(endMonth, 0, endMonth, endDay, endYear);
          this.rangeDays.push(this.monthDays);
          return;
        } else if (monthsDiff) {
          this.setMonths(monthsDiff, startMonth, endMonth, endDay, startYear);
          this.rangeDays.push(this.monthDays);
          return;
        } else {
          this.rangeDays.push(this.monthDays);
          this.monthDays = [];
          month = endMonth;
          for (let j = 1; j <= endDay; j++) {
            const day = new Date(`${month}/${j}/${startYear}`);
            const dayName = this.weekDaysArr[day.getDay()];
            this.monthDays.push({
              day: j,
              month: month,
              year: startYear,
              dayName: dayName,
              data: null,
              dataArr: []
            });
          }
          this.rangeDays.push(this.monthDays);
          return;
        }
      }
      const day = new Date(`${month}/${nextDay}/${startYear}`);
      const dayName = this.weekDaysArr[day.getDay()];
      this.monthDays.push({
        day: nextDay,
        month: month,
        year: startYear,
        dayName: dayName,
        data: null,
        dataArr: []
      });
    }
    this.rangeDays.push(this.monthDays);
  }
}
