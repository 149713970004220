import { Injectable } from "@angular/core"
import { HttpClient } from '@angular/common/http';
import { Title } from "@angular/platform-browser";
import { Observable, Subject, BehaviorSubject } from "rxjs";
import { shareReplay, map, startWith, switchMap } from "rxjs/operators";
import { CookieService } from "./cookie.service";
import { IResponseModel } from '../Models/IResponseModel';
import { LANGUAGE_KEY } from '../Constants/main-keys';
import { API_URL_DOMAIN_KEY } from '../Constants/domain-keys';
import { ISO_CODE_PATH_PARAM_KEY, TYPE_PARAM_KEY, TRANSLATIONS_PATH_KEY } from "../Constants/request-path-keys";

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  private languageTranslationCache$: Observable<any[]> | null = null;
  private languageDefaultsTranslationCache$: Observable<any[]> | null = null;
  private languageValidationTranslationCache$: Observable<any[]> | null = null;
  private languagePagesTranslationCache$: Observable<any[]> | null = null;
  private languageUserTypesTranslationCache$: Observable<any[]> | null = null;
  private languageExeptionTranslationCache$: Observable<any[]> | null = null;
  languageChange: Subject<void> = new Subject();

  isPageLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  constructor(
    private cookieService: CookieService,
    private http: HttpClient,
    private titleService: Title,
  ) { }

  togglePageLoading(isLoading: boolean): void {
    this.isPageLoading.next(isLoading);
  }

  translateUserTypes(value: string): Observable<string> {
    return this.getCurrentLanguageUserTypesTranslations().pipe(
      map((data: Array<any>) => data.find(item => item.shortKey === value)),
      map((translation: any) => { if (translation) { return translation.translation1 } }),
    );
  }

  private getCurrentLanguageUserTypesTranslations(): Observable<any[]> {
    if (this.languageUserTypesTranslationCache$) {
      return this.languageUserTypesTranslationCache$;
    }

    this.togglePageLoading(true);
    return this.languageUserTypesTranslationCache$ = this.languageChange.pipe(
      startWith(null),
      switchMap(() => this.http.get<any>(`${API_URL_DOMAIN_KEY}/${TRANSLATIONS_PATH_KEY}?${ISO_CODE_PATH_PARAM_KEY}${this.cookieService.getCookie(LANGUAGE_KEY)}&${TYPE_PARAM_KEY}${8}`, { withCredentials: true })),
      map((data: IResponseModel) => {
        this.togglePageLoading(false);
        return data.data;
      }),
      shareReplay(1),
    );
  }

  translatePages(value: string): Observable<string> {
    return this.getCurrentLanguagePagesTranslations().pipe(
      map((data: Array<any>) => data.find(item => item.shortKey === value)),
      map((translation: any) => { if (translation) { return translation.translation1 } }),
    );
  }

  private getCurrentLanguagePagesTranslations(): Observable<any[]> {
    if (this.languagePagesTranslationCache$) {
      return this.languagePagesTranslationCache$;
    }

    this.togglePageLoading(true);
    return this.languagePagesTranslationCache$ = this.languageChange.pipe(
      startWith(null),
      switchMap(() => this.http.get<any>(`${API_URL_DOMAIN_KEY}/${TRANSLATIONS_PATH_KEY}?${ISO_CODE_PATH_PARAM_KEY}${this.cookieService.getCookie(LANGUAGE_KEY)}&${TYPE_PARAM_KEY}${7}`, { withCredentials: true })),
      map((data: IResponseModel) => {
        this.togglePageLoading(false);
        return data.data;
      }),
      shareReplay(1),
    );
  }

  translateValidations(value: string): Observable<string> {
    return this.getCurrentLanguageValidationTranslations().pipe(
      map((data: Array<any>) => data.find(item => item.shortKey === value)),
      map((translation: any) => { if (translation) { return translation.translation1 } }),
    );
  }

  private getCurrentLanguageValidationTranslations(): Observable<any[]> {
    if (this.languageValidationTranslationCache$) {
      return this.languageValidationTranslationCache$;
    }

    this.togglePageLoading(true);
    return this.languageValidationTranslationCache$ = this.languageChange.pipe(
      startWith(null),
      switchMap(() => this.http.get<any>(`${API_URL_DOMAIN_KEY}/${TRANSLATIONS_PATH_KEY}?${ISO_CODE_PATH_PARAM_KEY}${this.cookieService.getCookie(LANGUAGE_KEY)}&${TYPE_PARAM_KEY}${3}`, { withCredentials: true })),
      map((data: IResponseModel) => {
        this.togglePageLoading(false);
        return data.data;
      }),
      shareReplay(1),
    );
  }

  translate(value: string): Observable<string> {
    return this.getCurrentLanguageTranslations().pipe(
      map((data: Array<any>) => data.find(item => item.shortKey === value)),
      map((translation: any) => { if (translation) { return translation.translation1 } }),
    );
  }

  private getCurrentLanguageTranslations(): Observable<any[]> {
    if (this.languageTranslationCache$) {
      return this.languageTranslationCache$;
    }

    this.togglePageLoading(true);
    return this.languageTranslationCache$ = this.languageChange.pipe(
      startWith(null),
      switchMap(() => this.http.get<any>(`${API_URL_DOMAIN_KEY}/${TRANSLATIONS_PATH_KEY}?${ISO_CODE_PATH_PARAM_KEY}${this.cookieService.getCookie(LANGUAGE_KEY)}&${TYPE_PARAM_KEY}${1}`, { withCredentials: true })),
      map((data: IResponseModel) => {
        this.togglePageLoading(false);
        return data.data;
      }),
      shareReplay(1),
    );
  }

  translateDefaults(value: string): Observable<string> {
    return this.getCurrentLanguageDefaultTranslations().pipe(
      map((data: Array<any>) => data.find(item => item.shortKey === value)),
      map((translation: any) => { if (translation) { return translation.translation1 } }),
    );
  }

  private getCurrentLanguageDefaultTranslations(): Observable<any[]> {
    if (this.languageDefaultsTranslationCache$) {
      return this.languageDefaultsTranslationCache$;
    }

    this.togglePageLoading(true);
    return this.languageDefaultsTranslationCache$ = this.languageChange.pipe(
      startWith(null),
      switchMap(() => this.http.get<any>(`${API_URL_DOMAIN_KEY}/${TRANSLATIONS_PATH_KEY}?${ISO_CODE_PATH_PARAM_KEY}${this.cookieService.getCookie(LANGUAGE_KEY)}&${TYPE_PARAM_KEY}${0}`, { withCredentials: true })),
      map((data: IResponseModel) => {
        this.togglePageLoading(false);
        return data.data;
      }),
      shareReplay(1),
    );
  }

  translateExeption(value: string): Observable<string> {
    return this.getExeptionTranslations().pipe(
      map((data: Array<any>) => data.find(item => item.shortKey === value)),
      map((translation: any) => { if (translation) { return translation.translation1 } }),
    );
  }

  private getExeptionTranslations(): Observable<any[]> {
    if (this.languageExeptionTranslationCache$) {
      return this.languageExeptionTranslationCache$;
    }

    this.togglePageLoading(true);
    return this.languageExeptionTranslationCache$ = this.languageChange.pipe(
      startWith(null),
      switchMap(() => this.http.get<any>(`${API_URL_DOMAIN_KEY}/${TRANSLATIONS_PATH_KEY}?${ISO_CODE_PATH_PARAM_KEY}${this.cookieService.getCookie(LANGUAGE_KEY)}&${TYPE_PARAM_KEY}${4}`, { withCredentials: true })),
      map((data: IResponseModel) => {
        this.togglePageLoading(false);
        return data.data;
      }),
      shareReplay(1),
    );
  }

  setPageTitle(value: string): void {
    (new Promise((resolve, reject) => {
      this.translate(value).subscribe((data: string) => {
        if (data) {
          resolve(data)
        } else {
          this.translateDefaults(value).subscribe((data: string) => {
            data && resolve(data);
          });
        }
      });
    })).then((data: string) => { this.titleService.setTitle(data) });
  }
}
