<div class="main-card mb-3 card">
  <div class="card-body">
    <div class="title-block mb-3">
      <i class="fa fa-fw" [title]="'TR_GOBACK' | translate" (click)="mainService.goBack('organization')"></i>
      <h5 class="card-title">{{ ('TR_UPDATE_PACKAGE' | translate) }}</h5>
    </div>
    <form [formGroup]="form">
      <div class="row">
        <div class="form-outline flex-wrap w-100">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <div *ngIf="paymentpackage_view" ngbDropdown>
              <div class="flex flex-column">
                <label>{{ ('TR_PAYMENT_PACKAGES' | translate) + mainService.asterisk}}</label>
                <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-outline-primary"
                  [class.error-border]="isInvalidSubmit && !selectedPaymentPackageId">
                  {{ selectedPaymentPackageName ? selectedPaymentPackageName : ('TR_PAYMENT_PACKAGES' | translate) +
                  mainService.asterisk}}
                </button>
              </div>
              <div ngbDropdownMenu>
                <button class="dropdown-item" *ngFor="let item of paymentPackages"
                  (click)="setSelectedPaymentPackage(item.id, item.packageName)">
                  {{ item.packageName }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <div *ngIf="monthCounts && monthCounts.length" ngbDropdown>
              <div class="flex flex-column">
                <label>{{ ('TR_MONTH_COUNT' | translate) + mainService.asterisk}}</label>
                <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-outline-primary"
                  [class.error-border]="isInvalidSubmit && !monthCount">
                  {{ monthCount ? monthCount : ('TR_MONTH_COUNT' | translate) + mainService.asterisk}}
                </button>
              </div>
              <div ngbDropdownMenu>
                <button class="dropdown-item" *ngFor="let item of monthCounts"
                  (click)="setSelectedMonthCount(item.monthCount)">
                  Months: {{ item.monthCount }}, Price: {{ item.priceAsString }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="position-relative form-check">
            <input name="recurringPayment" id="recurringPayment" type="checkbox" class="form-check-input"
              [checked]="recurringPayment" (change)="onChechboxChange($event)">
            <label for="recurringPayment" class="form-check-label">{{ 'TR_RECURRING_PAYMENT' | translate }}</label>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-lg-3 col-md-4 col-12">
          <div class="mt-3 mb-3 upload-block form-outline">
            <div class="mb-2">
              <button type="button" class="btn-transition btn btn-warning upload-btn"
                [class.error-border]="isInvalidCheque" (click)="file.click()">
                <i class="pe-7s-plus"></i>
                <p>{{ ('TR_UPLOAD_FILE' | translate) + mainService.asterisk }}</p>
              </button>
              <input #file hidden type="file" name="upload-avatar" (change)="handleFileUpload($event)" />
            </div>
            <div *ngIf="uploadedFile" [ngStyle]="{ 'background-image' : 'url(' + uploadedFile + ')' }"
              class="upload-avatar"></div>
          </div>
        </div>
      </div>
      <app-submit-button [spinnerId]="submitSpinnerId"
        [buttonName]="isCreateForm ? ('TR_CREATE' | translate) : ('TR_UPDATE' | translate)"
        [isInvalidSubmit]="isInvalidSubmit" [isFormValid]="form.valid" (click)="submitData()"></app-submit-button>
    </form>
  </div>
</div>