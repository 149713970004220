import { Component, Input, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { ITemplateInfoData } from 'src/app/Models/ITemplateData';
import { DOCUMENTTEMPLATE_I_VIEW, DOCUMENTTEMPLATE_VIEW, MONTH_ARR_KEYS_RUSSIAN } from 'src/app/Constants/main-keys';
import { DOCUMENT_TEMPLATE_INFO_PATH_KEY, DOCUMENT_TEMPLATE_PATH_KEY, TEMPLATE_ID_PATH_PARAM_KEY } from 'src/app/Constants/request-path-keys';

@Component({
  selector: 'app-template-document-view',
  templateUrl: './template-document-view.component.html',
  styleUrls: ['./template-document-view.component.scss']
})
export class TemplateDocumentViewComponent implements OnInit {
  @Input() templateContent: string;
  @Input() templateId: number;

  body: string;
  templateBody: string;
  templateData: any;
  templateInfo: Array<ITemplateInfoData> = [];
  monthArr: Array<string> = MONTH_ARR_KEYS_RUSSIAN;
  tableRowCount: number = 1;
  tableTds: Array<string> = [];
  userRoles: Array<string>;
  documenttemplate_view: boolean;
  documenttemplateinfo_view: boolean;

  constructor(
    private dataHandlerService: DataHandlerService,
    private translationService: TranslationService,
    private rolesService: RolesService,
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();

    if (this.templateId) {
      if (this.templateContent) {
        this.templateData = JSON.parse(this.templateContent);
      }
      this.tableTds = [];
      this.tableRowCount = 1;
      this.getTemplateData(this.templateId);
    }
  }

  setRoles() {
    this.userRoles.forEach(role => {
      switch(role) {
        case DOCUMENTTEMPLATE_VIEW:
          this.documenttemplate_view = true;
          break;
        case DOCUMENTTEMPLATE_I_VIEW:
          this.documenttemplateinfo_view = true;
          break;
      }
    });
  }

  getTemplateData(id: number): void {
    if (!this.documenttemplate_view) { return; }
    this.templateBody = '';
    this.dataHandlerService.getDataWithSpinner(`${DOCUMENT_TEMPLATE_PATH_KEY}/${id}`).then(response => {
      this.templateBody = response.body;
      if (this.templateBody) {
        this.getTemplatesInfoByTemplateId(this.templateId);
      }
    });
  }

  getTemplatesInfoByTemplateId(id: number): void {
    if (!this.documenttemplateinfo_view) { return; }
    this.dataHandlerService.getDataWithSpinner(`${DOCUMENT_TEMPLATE_INFO_PATH_KEY}?${TEMPLATE_ID_PATH_PARAM_KEY}${id}`).then(response => {
      this.templateInfo = response;
      this.setTemplateBody(this.templateInfo, this.templateBody);
    });
  }

  setTemplateBody(templateInfo: Array<ITemplateInfoData>, templateBody: string): void {
    if (templateInfo.length) {
      templateInfo.forEach(info => {
        let dataValue = this.templateData[`a${info.position}`];
        if (dataValue === 'Другое (вписать вручную)') {
          dataValue = '';
        }
        if (info.type === 'organization-name' && templateBody.includes(`{a${info.position}}`)) {
          templateBody = templateBody.replace(`<p>{a${info.position}}</p>`, `<div class="center"><span class="organization-name-title">${dataValue}</span></div>`);
        }
        else if (info.type === 'input-number' && templateBody.includes(`{a${info.position}}`)) {
          templateBody = templateBody.replace(`<p>№ {a${info.position}}</p>`, `<div class="direction-right"><span class="template-data-field direction-right">№ ${dataValue}</span></div>`);
          templateBody = templateBody.replace(`{a${info.position}}`, `${dataValue}`);
        }
        else if (info.type === 'input-create-date' && templateBody.includes(`{a${info.position}}`)) {
          if (dataValue) {
            dataValue = this.setDate(dataValue);
            templateBody = templateBody.replace(`<p>{a${info.position}}</p>`, `<span class="template-data-field">${dataValue}</span>`);
          } else {
            templateBody = templateBody.replace(`<p>{a${info.position}}</p>`, ``);
          }
        }
        else if (info.type === 'input-date' && templateBody.includes(`{a${info.position}}`)) {
          if (dataValue) {
            dataValue = this.setDate(dataValue);
            templateBody = templateBody.replace(`{a${info.position}}`, `${dataValue}`);
          } else {
            templateBody = templateBody.replace(`{a${info.position}}`, ``);
          }
        }
        else if (info.type === 'sign-row-arr' && templateBody.includes(`{a${info.position}}`)) {
          const usersList = dataValue;
          if (usersList.length) {
            templateBody = templateBody.replace(`{a${info.position}}`, `<div class="input-list"><div class="flexable sign-row-mrg"><span class="empty-row"></span><span class="template-data-field">${usersList[0]}</span></div></div>`);
            for (let i = 1; i < usersList.length; i++) {
              if (i === 1) {
                templateBody = templateBody.replace(`${usersList[0]}</span></div></div>`, `${usersList[0]}</span></div><div class="flexable sign-row-mrg"><span class="empty-row"></span><span class="template-data-field">${usersList[i]}</span></div></div>`);
              } else {
                const prevIndex = i - 1;
                templateBody = templateBody.replace(`${usersList[prevIndex]}</span></div></div>`, `${usersList[prevIndex]}</span></div><div class="flexable sign-row-mrg"><span class="empty-row"></span><span class="template-data-field">${usersList[i]}</span></div></div>`);
              }
            }
          } else {
            templateBody = templateBody.replace(`{a${info.position}}`, ``);
          }
        }
        else if (typeof (dataValue) === 'object' && templateBody.includes(`{a${info.position}}`)) {
          const usersList = dataValue;
          if (usersList.length) {
            templateBody = templateBody.replace(`<p>{a${info.position}}</p>`, `<div class="input-list"><span class="template-data-field">1. ${usersList[0]}</span></div>`);
            templateBody = templateBody.replace(`{a${info.position}}`, `<div class="input-list"><span class="template-data-field">1. ${usersList[0]}</span></div>`);
            for (let i = 1; i < usersList.length; i++) {
              if (i === 1) {
                templateBody = templateBody.replace(`${usersList[0]}</span></div>`, `${usersList[0]}</span><span class="template-data-field">2. ${usersList[i]}</span></div>`);
              } else {
                const prevIndex = i - 1;
                const nextIndex = i + 1;
                templateBody = templateBody.replace(`${usersList[prevIndex]}</span></div>`, `${usersList[prevIndex]}</span><span class="template-data-field">${nextIndex}. ${usersList[i]}</span></div>`);
              }
            }
          } else {
            templateBody = templateBody.replace(`{a${info.position}}`, ``);
          }
        }
        else if (info.type === 'sign-row' && templateBody.includes(`{a${info.position}}`)) {
          templateBody = templateBody.replace(`{a${info.position}}`, '<span class="empty-row"></span>');
        }
        else if (info.type === 'table-input-string'
          || info.type === 'table-input-date'
          || info.type === 'table-input-number'
          || info.type === 'table-library'
          || info.type === 'table-list'
          || info.type === 'table-sign-part'
          || info.type === 'table-number-and-date'
          || info.type === 'table-input-start-end-time') {
          this.tableTds.push(`<td>{a${info.position}}</td>`);
          if (this.templateData['tableData'].length) {
            templateBody = templateBody.replace(`<td>{a${info.position}}</td>`, `<td class="col${info.position}-${this.templateData.tableData[0].number}">{a${info.position}}</td>`);
            templateBody = templateBody.replace(`<td>№ {a${info.position}}</td>`, `<td class="col${info.position}-${this.templateData.tableData[0].number}">№ {a${info.position}}</td>`);
          } else {
            templateBody = templateBody.replace(`<td>№ {a${info.position}}</td>`, `<td></td>`);
            templateBody = templateBody.replace(`<td>{a${info.position}}</td>`, `<td></td>`);
          }
        }
        else if (!this.templateData[`a${info.position}`] && info.type !== 'sign-row') {
          templateBody = templateBody.replace(`{a${info.position}}`, '');
        }
        else if (info.type === 'input-date-to-right' && templateBody.includes(`{a${info.position}}`)) {
          templateBody = templateBody.replace(`<p>{a${info.position}}</p>`, `<div class="direction-right">${dataValue}</div>`);
        }
        else if (info.type === 'input-short-string' && templateBody.includes(`{a${info.position}}`)) {
          templateBody = templateBody.replace(`<p>{a${info.position}}</p>`, `${dataValue}`);
        }
        else if (info.type === 'drop-down-group-not-draw' && templateBody.includes(`{a${info.position}}`)) {
          templateBody = templateBody.replace(`<p>{a${info.position}}</p>`, ``);
        }
        else {
          templateBody = templateBody.replace(`{a${info.position}}`, `${dataValue}`);
        }

        if (!dataValue) {
          templateBody = templateBody.replace(`<p>{a${info.position}}</p>`, ``);
        }

      });

      let tableData: Array<any> = this.templateData['tableData'];
      if (tableData && tableData.length) {
        templateBody = templateBody.replace('<tr>', `<tr class="row-th">`);
        templateBody = templateBody.replace('<tr>', `<tr class="row-${tableData[0].number}">`);

        tableData.forEach(data => {
          if (!templateBody.includes(`<tr class="row-${data.number}">`)) {
            let row = `<tr class="row-${data.number}"><td>${++this.tableRowCount}</td></tr>`;
            this.tableTds.forEach(tdItem => {
              const startIndexOfColumn = tdItem.indexOf('{a') + 2;
              const endIndexOfColumn = tdItem.indexOf('}');
              const column = tdItem.substring(startIndexOfColumn, endIndexOfColumn);
              tdItem = tdItem.replace('<td>', `<td class="col${column}-${data.number}">`);
              row = row.replace('</tr>', `${tdItem}</tr>`);
            });
            const lastIndexTR = templateBody.lastIndexOf('</tr>');
            const lastIndexTBody = templateBody.lastIndexOf('</tbody>') + 8;
            const subStr = templateBody.substring(lastIndexTR, lastIndexTBody);
            templateBody = templateBody.replace(subStr, `</tr>${row}</tbody>`);
          }
          const dateData = templateInfo.find(info => info.position.toString() === data.columnNumber);
          if (typeof (data.value) === 'object' && dateData.type === 'table-number-and-date') {
            if (data.value[0]) {
              if(templateBody.includes(`<td class="col${data.columnNumber}-${data.number}">{a${data.columnNumber}}</td>`)) {
                templateBody = templateBody.replace(`<td class="col${data.columnNumber}-${data.number}">{a${data.columnNumber}}</td>`, `<td class="col${data.columnNumber}-${data.number}">№ ${data.value[0]}</td>`);
                if (data.value[1]) {
                  templateBody = templateBody.replace(`<td class="col${data.columnNumber}-${data.number}">№ ${data.value[0]}</td>`, `<td class="col${data.columnNumber}-${data.number}">№ ${data.value[0]} от ${this.setDate(data.value[1])}</td>`);
                }
              } else {
                templateBody = templateBody.replace(`<td class="col${data.columnNumber}-${this.tableRowCount}">{a${data.columnNumber}}</td>`, `<td class="col${data.columnNumber}-${this.tableRowCount}">№ ${data.value[0]}</td>`);
                if (data.value[1]) {
                  templateBody = templateBody.replace(`<td class="col${data.columnNumber}-${this.tableRowCount}">№ ${data.value[0]}</td>`, `<td class="col${data.columnNumber}-${data.number}">№ ${data.value[0]} от ${this.setDate(data.value[1])}</td>`);
                }
              }
            }
            else if (data.value[1]) {
              if(templateBody.includes(`<td class="col${data.columnNumber}-${data.number}">{a${data.columnNumber}}</td>`)) {
                templateBody = templateBody.replace(`<td class="col${data.columnNumber}-${data.number}">{a${data.columnNumber}}</td>`, `<td class="col${data.columnNumber}-${data.number}">от ${this.setDate(data.value[1])}</td>`);
              } else {
                templateBody = templateBody.replace(`<td class="col${data.columnNumber}-${this.tableRowCount}">{a${data.columnNumber}}</td>`, `<td class="col${data.columnNumber}-${data.number}">от ${this.setDate(data.value[1])}</td>`);
              }
            }
          }
          else if (dateData && dateData.type === 'table-input-number') {
            if (templateBody.includes(`<td class="col${data.columnNumber}-${data.number}">{a${data.columnNumber}}</td>`)) {
              templateBody = templateBody.replace(`<td class="col${data.columnNumber}-${data.number}">{a${data.columnNumber}}</td>`, `<td class="col${data.columnNumber}-${data.number}">№ ${data.value}</td>`);
            } else {
              templateBody = templateBody.replace(`<td class="col${data.columnNumber}-${this.tableRowCount}">{a${data.columnNumber}}</td>`, `<td class="col${data.columnNumber}-${data.number}">№ ${data.value}</td>`);
            }
            if (templateBody.includes(`<td class="col${data.columnNumber}-${data.number}">№ {a${data.columnNumber}}</td>`)) {
              templateBody = templateBody.replace(`<td class="col${data.columnNumber}-${data.number}">№ {a${data.columnNumber}}</td>`, `<td class="col${data.columnNumber}-${data.number}">№ ${data.value}</td>`);
            } else {
              templateBody = templateBody.replace(`<td class="col${data.columnNumber}-${this.tableRowCount}">№ {a${data.columnNumber}}</td>`, `<td class="col${data.columnNumber}-${data.number}">№ ${data.value}</td>`);
            }
          }
          else if (dateData && dateData.type === 'table-input-date') {
            if(templateBody.includes(`<td class="col${data.columnNumber}-${data.number}">{a${data.columnNumber}}</td>`)) {
              templateBody = templateBody.replace(`<td class="col${data.columnNumber}-${data.number}">{a${data.columnNumber}}</td>`, `<td class="col${data.columnNumber}-${data.number}">${this.setDate(data.value)}</td>`);
            } else {
              templateBody = templateBody.replace(`<td class="col${data.columnNumber}-${this.tableRowCount}">{a${data.columnNumber}}</td>`, `<td class="col${data.columnNumber}-${data.number}">${this.setDate(data.value)}</td>`);
            }
          }
          else if (data.timeType && data.timeType === 'start') {
            const timeData = tableData.find(time => time.columnNumber == data.columnNumber && time.number == data.number && time.timeType === 'end');
            if (timeData) {
              if (templateBody.includes(`<td class="col${data.columnNumber}-${data.number}">{a${data.columnNumber}}</td>`)) {
                templateBody = templateBody.replace(`<td class="col${data.columnNumber}-${data.number}">{a${data.columnNumber}}</td>`, `<td class="col${data.columnNumber}-${data.number}">${data.value} - ${timeData.value}</td>`);
              } else {
                templateBody = templateBody.replace(`<td class="col${data.columnNumber}-${this.tableRowCount}">{a${data.columnNumber}}</td>`, `<td class="col${data.columnNumber}-${data.number}">${data.value} - ${timeData.value}</td>`);
              }
            }
            else {
              if (templateBody.includes(`<td class="col${data.columnNumber}-${data.number}">{a${data.columnNumber}}</td>`)) {
                templateBody = templateBody.replace(`<td class="col${data.columnNumber}-${data.number}">{a${data.columnNumber}}</td>`, `<td class="col${data.columnNumber}-${data.number}">${data.value} - </td>`);
              } else {
                templateBody = templateBody.replace(`<td class="col${data.columnNumber}-${this.tableRowCount}">{a${data.columnNumber}}</td>`, `<td class="col${data.columnNumber}-${data.number}">${data.value} - </td>`);
              }
            }
          }
          else if (!data.timeType) {
            if (templateBody.includes(`<td class="col${data.columnNumber}-${data.number}">{a${data.columnNumber}}</td>`)) {
              templateBody = templateBody.replace(`<td class="col${data.columnNumber}-${data.number}">{a${data.columnNumber}}</td>`, `<td class="col${data.columnNumber}-${data.number}">${data.value}</td>`);
            } else {
              templateBody = templateBody.replace(`<td class="col${data.columnNumber}-${this.tableRowCount}">{a${data.columnNumber}}</td>`, `<td class="col${data.columnNumber}-${data.number}">${data.value}</td>`);
            }
          }
        });

        templateInfo.forEach(info => {
          for (let i = 0; i < tableData[tableData.length - 1].number + 1; i++) {
            templateBody = templateBody.replace(`<td class="col${info.position}-${i}">{a${info.position}}</td>`, `<td class="col${info.position}-${i}"></td>`);
            templateBody = templateBody.replace(`<td class="col${info.position}-${i}">№ {a${info.position}}</td>`, `<td class="col${info.position}-${i}"></td>`);
          }
        });

      } else {
        templateBody = templateBody.replace('<tr>', `<tr class="row-th">`);
      }

      templateBody = templateBody.replace('<td></td>', '');
      templateBody = templateBody.replace('<td>Действия</td>', '');
      templateBody = templateBody.replace('<table', '<div class="draw-template-table-container"><table class="draw-template-table"');
      templateBody = templateBody.replace('</table>', '</table></div>');
      templateBody = templateBody.replace('<h1>', '<h1 class="center template-title">');
      templateBody = templateBody.replace('<h2>', '<h2 class="center">');
      templateBody = templateBody.replace('<h3>', '<h3 class="center template-title margin-h3">');
      templateBody = templateBody.replace('<h3>', '<h3 class="doc-sub-title">');
      templateBody = templateBody.split('<p>').join(`<p class="draw-flex-wrap">`);
      this.body = templateBody;
    }
  }

  setDate(dateStr: string): string {
    if (!dateStr) { return ''; }
    const date = new Date(dateStr);
    let monthName = this.monthArr[date.getMonth()];
    // this.translationService.translate(monthName).pipe(
    //   map((data: string) => monthName = data)
    // ).subscribe();
    return `${date.getDate()} ${monthName} ${date.getFullYear()}`;
  }
}
