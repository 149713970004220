import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { guid } from '@progress/kendo-angular-common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/Services/alert.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { IUserData } from 'src/app/Models/IUserData';
import { ILanguageData } from 'src/app/Models/ILanguageData';
import {
  STUDENT_CREATE,
  SUCCESS_KEY,
  TIME_ZONE_KEY,
  TR_CREATE_MESSAGE_KEY,
  TR_CREATE_STUDENT_KEY,
  USER_VIEW
} from 'src/app/Constants/main-keys';
import {
  LANGUAGE_PATH_KEY,
  LIBRARY_BY_DISPLAY_NAME_PATH_KEY,
  STUDENTS_PATH_KEY,
  STUDENT_URL_KEY,
  USERS_FILTER_PATH_KEY,
  USERS_PATH_KEY
} from 'src/app/Constants/request-path-keys';

@Component({
  selector: 'app-student-form',
  templateUrl: './student-form.component.html',
  styleUrls: ['./student-form.component.scss']
})
export class StudentFormComponent implements OnInit {
  form: FormGroup;
  languages: Array<ILanguageData> = [];
  timeZoneData: Array<any> = [];
  languagesObs: BehaviorSubject<Array<ILanguageData>> = new BehaviorSubject<Array<ILanguageData>>(null);
  userId: number;
  selectedTimeZone: string;
  selectedLanguageId: number;
  selectedLanguageName: string;
  student_create: boolean;
  user_view: boolean;
  isInvalidSubmit: boolean;
  isInvalidFirstname: boolean;
  isInvalidLastname: boolean;
  isInvalidPhonenumber: boolean;
  isInvalidDob: boolean;
  createSpinnerId: string = guid();
  users: Array<IUserData> = [];
  userRoles: Array<string> = [];
  today: String = new Date().toISOString().split('T')[0];
  filterData = {
    username: '',
    email: '',
    phone: '',
    mobilePhone: '',
  };
  personalDocumentMaxLength: number = 64;

  constructor(
    private formBuilder: FormBuilder,
    private dataHandlerService: DataHandlerService,
    private translationService: TranslationService,
    private rolesService: RolesService,
    public mainService: MainService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_CREATE_STUDENT_KEY);

    this.form = this.formBuilder.group({
      personalDocumentNumber: ['', [Validators.required, Validators.maxLength(this.personalDocumentMaxLength)]],
      password: [''],
      username: [''],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      middlename: [''],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.pattern(`^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$`)],
      mobilePhone: ['', Validators.pattern(`^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$`)],
      dob: [''],
      addressResidence: [''],
      addressRegistration: [''],
      tziidDisplayname: [null],
      defaultLanguageId: [null],
    });

    this.getLanguages();
    this.getTimeZoneData();
  }

  setRoles() {
    this.userRoles.forEach(role => {
      switch (role) {
        case STUDENT_CREATE:
          this.student_create = true;
          break;
        case USER_VIEW:
          this.user_view = true;
          break;
      }
    });
  }

  checkFieldIsValid(event: any, fieldName: string): void {
    if (fieldName === "firstname") {
      const firstNameValue = event.target.value;
      if (!firstNameValue.trim().length || firstNameValue.trim().length < 2 || firstNameValue.trim().length >= 100) {
        this.isInvalidFirstname = true;
        return;
      } else {
        const isValidFirstname = this.mainService.isValidField(firstNameValue, "firstname");
        if (!isValidFirstname) {
          this.isInvalidFirstname = true;
          return;
        } else {
          this.isInvalidFirstname = false;
        }
      }
    }
    if (fieldName === "lastname") {
      const lastNameValue = event.target.value;
      if (!lastNameValue.trim().length || lastNameValue.trim().length < 2 || lastNameValue.trim().length >= 100) {
        this.isInvalidLastname = true;
        return;
      } else {
        const isValidLastname = this.mainService.isValidField(lastNameValue, "lastname");
        if (!isValidLastname) {
          this.isInvalidLastname = true;
          return;
        } else {
          this.isInvalidLastname = false;
        }
      }
    }
    if (fieldName === "phonenumber" && event.target.value.length) {
      if (event.target.value.length) {
        const isValidPhoneNumber = this.mainService.isValidField(event.target.value, "phonenumber");
        if (!isValidPhoneNumber) {
          this.isInvalidPhonenumber = true;
          return;
        } else {
          this.isInvalidPhonenumber = false;
        }
      } else {
        this.isInvalidPhonenumber = false;
      }
    }
  }

  checkDates(event: any): void {
    //firstDate > secondDate ? true : false
    const firstDate = event.target.value;
    if (firstDate) {
      const isValidDate = this.mainService.checkDatesMoreOrEqual(firstDate, this.today);
      if (isValidDate) {
        this.isInvalidDob = false;
      } else {
        this.isInvalidDob = true;
      }
    } else {
      this.isInvalidDob = false;
    }
  }

  async getTimeZoneData() {
    this.timeZoneData = await this.dataHandlerService.getDataWithSpinner(`${LIBRARY_BY_DISPLAY_NAME_PATH_KEY}/${TIME_ZONE_KEY}`);
  }

  getLanguages() {
    this.dataHandlerService.getData(LANGUAGE_PATH_KEY).then(response => {
      // this.languages = response;
      this.languages = response.splice(0, 2);
      this.languagesObs.next(this.languages);
    });
  }

  getUserDataById(userId: number): void {
    this.userId = userId;
    this.dataHandlerService.getDataWithSpinner(`${USERS_PATH_KEY}/${userId}`).then(response => {
      const user = response;
      this.selectedTimeZone = user.tziidDisplayname;
      this.selectedLanguageId = user.defaultLanguageId;

      if (this.languages && this.languages.length) {
        const selectedLanguage: ILanguageData = this.languages.find(data => data.languageId === this.selectedLanguageId);
        if (selectedLanguage) {
          this.selectedLanguageName = selectedLanguage.name;
        }
      }

      this.form.patchValue({
        personalDocumentNumber: user.personalDocumentNumber,
        username: user.username,
        firstname: user.firstname,
        lastname: user.lastname,
        middlename: user.middlename,
        email: user.email,
        phone: user.phone,
        mobilePhone: user.mobilePhone,
        dob: user.dob ? this.mainService.getDateFormat(new Date(user.dob)) : "",
        addressResidence: user.addressResidence,
        addressRegistration: user.addressRegistration,
        createDate: user.createDate,
        tziidDisplayname: user.tziidDisplayname,
      });

      this.form.get('username').disable();
      this.form.get('password').disable();
    });
  }

  setSelectedTimeZone(name: string): void {
    this.selectedTimeZone = name;
    this.form.patchValue({ tziidDisplayname: name });
  }

  setSelectedLanguage(languageId: number, name: string): void {
    this.selectedLanguageName = name;
    this.selectedLanguageId = languageId;
    this.form.patchValue({ defaultLanguageId: languageId });
  }

  async filter(value: string, key: string) {
    if (!this.user_view || this.userId) { return; }
    this.filterData[key] = value;
    this.users = await this.dataHandlerService.createData(this.filterData, USERS_FILTER_PATH_KEY, null);
  }

  getUserData(data: IUserData): void {
    if (data) {
      this.userId = data.id;
      this.getUserDataById(data.id);
    }
  }

  submitData(): void {
    if (this.form.valid && !this.isInvalidFirstname && !this.isInvalidLastname && !this.isInvalidPhonenumber && !this.isInvalidDob) {
      if (!this.student_create) { return; }
      this.form.enable();
      const form = { ...this.form.value };
      form.userTypeId = 4;

      if (this.userId) {
        form.userId = this.userId;
      }

      let formData: any = new FormData();
      for (let i in form) {
        if (form[i]) {
          if (i === 'password') {
            form[i] = btoa(form[i]);
          }
          formData.append(`${i}`, form[i]);
        }
      }

      this.dataHandlerService.createFormData(formData, STUDENTS_PATH_KEY, this.createSpinnerId).then(() => {
        this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
        this.mainService.goBack(STUDENT_URL_KEY);
      });
    } else {
      this.isInvalidSubmit = true;
    }
  }

  generatePassword() {
    const randomPassword = Math.random().toString(36).slice(-8);
    this.form.patchValue({ password: randomPassword });
  }
}
