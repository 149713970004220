import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { MainService } from 'src/app/Services/main.service';
import { AlertService } from 'src/app/Services/alert.service';
import { CATEGORIES_PATH_KEY, BY_TYPE_PARAM_KEY } from 'src/app/Constants/request-path-keys';
import {
  CATEGORY_CREATE,
  CATEGORY_DELETE,
  CATEGORY_UPDATE,
  CATEGORY_VIEW,
  SUCCESS_KEY,
  TR_CATEGORY_KEY,
  TR_CREATE_MESSAGE_KEY,
  TR_DELETE_MESSAGE_KEY,
  TR_UPDATE_MESSAGE_KEY
} from 'src/app/Constants/main-keys';
import { guid } from '@progress/kendo-angular-common';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  updateCategoryId: number;
  deleteCategoryId: number;
  deleteCategoryName: string;
  filterCategoryName: string;
  isUpdate: boolean;
  category_view: boolean;
  category_create: boolean;
  category_update: boolean;
  category_delete: boolean;
  isInvalidSubmit: boolean;
  deleteSpinnerId: string = guid();
  saveSpinnerId: string = guid();
  userRoles: Array<string> = [];
  categories: Array<any> = [];
  categoryModel = {
    name: '',
    parentId: null,
    parentName: ''
  };
  categoryTypes = [{ typeNumber: 0, name: 'TR_REVIEW_OPTIONS' }, { typeNumber: 2, name: 'TR_COURSE_CATEGORIES' }];

  constructor(
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    private translationService: TranslationService,
    private modalService: NgbModal,
    private mainService: MainService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_CATEGORY_KEY);

    this.getCategories(true);
  }

  setRoles(): void {
    this.userRoles.forEach(role => {
      switch (role) {
        case CATEGORY_VIEW:
          this.category_view = true;
          break;
        case CATEGORY_CREATE:
          this.category_create = true;
          break;
        case CATEGORY_UPDATE:
          this.category_update = true;
          break;
        case CATEGORY_DELETE:
          this.category_delete = true;
          break;
      }
    });
  }

  async getCategories(hasSpinner: boolean) {
    this.categories = await (hasSpinner
      ? this.dataHandlerService.getDataWithSpinner(CATEGORIES_PATH_KEY)
      : this.dataHandlerService.getData(CATEGORIES_PATH_KEY));
  }

  filterCategories(categoryType) {
    this.dataHandlerService.getData(`${CATEGORIES_PATH_KEY}/${BY_TYPE_PARAM_KEY}/${categoryType.typeNumber}`).then(response => {
      if (response) {
        this.filterCategoryName = categoryType.name;
        this.categories = response;
      }
    });
  }

  update(category): void {
    this.isUpdate = true;

    this.categoryModel = category;
    this.updateCategoryId = category.id;

    this.categories.forEach(item => {
      if (item.id === this.categoryModel.parentId) {
        this.categoryModel.parentName = item.name;
      }
    });
  }

  confirmDelete(content, id: number, name: string): void {
    this.deleteCategoryId = id;
    this.deleteCategoryName = name;
    this.modalService.open(content).result;
  }

  delete(): void {
    if (!this.category_delete) { return; }
    this.dataHandlerService.deleteData(this.deleteCategoryId, CATEGORIES_PATH_KEY, this.deleteSpinnerId).then(() => {
      this.alertService.translateAndAlertMessage(TR_DELETE_MESSAGE_KEY, SUCCESS_KEY);
      this.getCategories(false);
      this.modalService.dismissAll();
    });
  }

  cancel(): void {
    this.isInvalidSubmit = false;
    this.isUpdate = false;
    this.updateCategoryId = null;
    this.categoryModel = {
      name: '',
      parentId: null,
      parentName: ''
    }
    this.getCategories(false);
  }

  setSelectedParentCategory(id: number, name: string): void {
    this.categoryModel.parentId = id;
    this.categoryModel.parentName = name;
  }

  submitData(): void {
    if (!this.categoryModel.name) {
      this.isInvalidSubmit = true;
    } else {
      if (this.isUpdate && this.category_update) {
        this.dataHandlerService.updateData(this.categoryModel, CATEGORIES_PATH_KEY, this.saveSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
          this.cancel();
        });
      } else if (this.category_create) {
        this.dataHandlerService.createData(this.categoryModel, CATEGORIES_PATH_KEY, this.saveSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
          this.cancel();
        });
      }
    }
  }
}
