import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TR_DOCUMENT_PRINT_KEY } from 'src/app/Constants/main-keys';
import { ORGANIZATION_DOCUMENT_PATH_KEY } from 'src/app/Constants/request-path-keys';
import { IDocumentData } from 'src/app/Models/IDocumentData';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { TranslationService } from 'src/app/Services/translation.service';

@Component({
  selector: 'app-document-print',
  templateUrl: './document-print.component.html',
  styleUrls: ['./document-print.component.scss']
})
export class DocumentPrintComponent implements OnInit {
  documentId: number;
  documentData: IDocumentData;
  documentName: string;
  currentLocation: string;

  @ViewChild('btn') btnElem: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private dataHandlerService: DataHandlerService,
    private translationService: TranslationService,
    public mainService: MainService,
  ) {
    this.currentLocation = window.location.href;
  }

  ngOnInit() {
    this.translationService.setPageTitle(TR_DOCUMENT_PRINT_KEY);
    this.route.paramMap.subscribe(params => {
      this.documentId = +params.get('item.id');
      this.documentId && this.getDocumentData(this.documentId);
    });
  }

  getDocumentData(id: number): void {
    this.dataHandlerService.getDataWithSpinner(`${ORGANIZATION_DOCUMENT_PATH_KEY}/${id}`).then(response => {
      this.documentData = response;
      if (this.documentData.name.includes(' ')) {
        this.documentData.name = this.documentData.name.split(' ').join('_');
      }
      if (this.documentData.name.includes('.')) {
        this.documentName = this.documentData.name + '.pdf';
      } else {
        this.documentName = this.documentData.name;
      }
      if (this.currentLocation.includes('/download')) {
        setTimeout(() => {
          const elem = this.btnElem['_elementRef'].nativeElement as HTMLElement;
          elem.click();
        }, 1100);
      }
    });
  }

  printDocument(documentData: any): void {
    if (documentData.signed && documentData.signFilePath) {
      this.printImage(documentData.signFilePath);
    } else {
      this.print();
    }
  }

  // print(): void {
  //   var newTab = window.open('', '', 'height=800, width=800');
  //   var headstr = `<html><head><title>${this.documentName ? this.documentName : ''}</title></head><body>`;
  //   var divContents = document.getElementById("doc").innerHTML;
  //   var footstr = "</body>";
  //   newTab.document.body.innerHTML = headstr + divContents + footstr;
  //   newTab.print();
  // }

  print() {
    window.print()

  }

  printImage(src: any): void {
    var win = window.open('', '', 'height=800, width=800');
    win.document.open();
    win.document.write([
      '<html>',
      '   <head>',
      '   </head>',
      '   <body onload="window.print()" onafterprint="window.close()">',
      '       <img src="' + src + '"/>',
      '   </body>',
      '</html>'
    ].join(''));
    win.document.close();
  }

}
