import { Component, Input, OnInit } from '@angular/core';
import { MainService } from 'src/app/Services/main.service';
import { startWith, tap, delay } from 'rxjs/operators';

@Component({
  selector: 'app-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss']
})
export class PageLoaderComponent implements OnInit {
  isPageLoading: boolean;

  @Input() isModalLoader: boolean;

  constructor(
    private mainService: MainService,
  ) { }

  ngOnInit() {
    this.mainService.pageSpinners.pipe(
      startWith(null),
      delay(0),
      tap(spinners => {
        this.isPageLoading = spinners?.length ? true : false;
      })
    ).subscribe();

  }

}
