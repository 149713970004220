import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { AlertService } from 'src/app/Services/alert.service';
import { IConfigData } from 'src/app/Models/IConfigData';
import { CONFIG_PATH_KEY } from 'src/app/Constants/request-path-keys';
import {
  CONFIG_CREATE,
  CONFIG_DELETE,
  CONFIG_UPDATE,
  CONFIG_VIEW,
  SUCCESS_KEY,
  TR_DELETE_MESSAGE_KEY,
  TR_CONFIGS_KEY
} from 'src/app/Constants/main-keys';
import { guid } from '@progress/kendo-angular-common';

@Component({
  selector: 'app-config-table',
  templateUrl: './config-table.component.html',
  styleUrls: ['./config-table.component.scss']
})
export class ConfigTableComponent implements OnInit {
  config_view: boolean;
  config_create: boolean;
  config_update: boolean;
  config_delete: boolean;
  deleteConfigId: number;
  deleteConfigName: string;
  userRoles: Array<string> = [];
  configs: Array<IConfigData> = [];
  deleteSpinnerId: string = guid();

  constructor(
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    private translationService: TranslationService,
    private modalService: NgbModal,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_CONFIGS_KEY);

    this.getConfigs();
  }

  setRoles() {
    this.userRoles.forEach(role => {
      switch (role) {
        case CONFIG_VIEW:
          this.config_view = true;
          break;
        case CONFIG_CREATE:
          this.config_create = true;
          break;
        case CONFIG_UPDATE:
          this.config_update = true;
          break;
        case CONFIG_DELETE:
          this.config_delete = true;
          break;
      }
    });
  }

  getConfigs() {
    this.dataHandlerService.getDataWithSpinner(CONFIG_PATH_KEY).then(response => this.configs = response);
  }

  confirmDelete(content, id: number, name: string): void {
    this.deleteConfigId = id;
    this.deleteConfigName = name;
    this.modalService.open(content).result;
  }

  delete(): void {
    if (!this.config_delete) { return; }
    this.dataHandlerService.deleteData(this.deleteConfigId, CONFIG_PATH_KEY, this.deleteSpinnerId).then(() => {
      this.alertService.translateAndAlertMessage(TR_DELETE_MESSAGE_KEY, SUCCESS_KEY);
      this.getConfigs();
      this.modalService.dismissAll();
    });
  }

}
