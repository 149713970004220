<div class="main-card mb-3 card">
  <div class="card-body">
    <div class="title-block mb-3">
      <i class="fa fa-fw" [title]="'TR_GOBACK' | translate" (click)="mainService.goBack('package-property')"></i>
      <h5 class="card-title">{{ isCreateForm ? ('TR_CREATE_PAYMENT_PACKAGE_PROPERTY' | translate) :
        ('TR_UPDATE_PAYMENT_PACKAGE_PROPERTY' | translate) }}</h5>
    </div>
    <form (submit)="submitData()" [formGroup]="form">

      <div *ngIf="propertynames.length" class="mb-1">
        <label for="propertyName" class="m-0">{{ 'TR_PROPERTY_NAME' | translate}} *</label>
        <div ngbDropdown>
          <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-outline-primary"
            [class.error-border]="isInvalidSubmit && !selectedPropertyName">
            {{ selectedPropertyName ? selectedPropertyName : 'TR_PROPERTY_NAME' | translate }}
          </button>
          <div ngbDropdownMenu>
            <button class="dropdown-item" *ngFor="let item of propertynames" (click)="setPropertyName(item)"
              type="button">
              {{ item }}
            </button>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-lg-4 col-md-4">
          <div class="position-relative form-group">
            <label for="displayName">{{ 'TR_DISPALY_NAME' | translate}} *</label>
            <input name="displayName" [placeholder]="'TR_DISPALY_NAME' | translate" type="text" class="form-control"
              [class.error-border]="isInvalidSubmit && ((!form.value.displayName || (form.value.displayName && !form.value.displayName.trim())) || (form.value.displayName && form.value.displayName.trim().length > 100))"
              autocomplete="off" formControlName="displayName" />
          </div>
        </div>
      </div>

      <div class="position-relative form-check my-1">
        <input name="isPricePerUnit" id="isPricePerUnit" type="checkbox" class="form-check-input pointer"
          formControlName="isPricePerUnit">
        <label for="isPricePerUnit" class="form-check-label pointer">{{ 'TR_IS_PRICE_PER_UNIT' | translate}}</label>
      </div>
      <div *ngIf="form.value.isPricePerUnit">
        <div class="form-row">
          <div class="col-lg-4 col-md-4">
            <div class="position-relative form-group">
              <label for="minValue">{{ 'TR_MIN_VALUE' | translate }}</label>
              <input name="minValue" [placeholder]="'TR_MIN_VALUE' | translate" type="number" class="form-control"
                autocomplete="off" min="1" max="10000" formControlName="minValue"
                [class.error-border]="isInvalidSubmit && form.value.isPricePerUnit && (form.value.minValue <= 0 || form.value.minValue >= 10000)" />
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-lg-4 col-md-4">
            <div class="position-relative form-group">
              <label for="maxValue">{{ 'TR_MAX_VALUE' | translate }}</label>
              <input name="maxValue" [placeholder]="'TR_MAX_VALUE' | translate" type="number" class="form-control"
                autocomplete="off" min="1" max="10000" formControlName="maxValue"
                [class.error-border]="isInvalidSubmit && form.value.isPricePerUnit && (form.value.maxValue <= 0 || form.value.maxValue <= form.value.minValue || form.value.maxValue >= 10000)" />
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-lg-4 col-md-4">
            <div class="position-relative form-group">
              <label for="unitName">{{ 'TR_UNIT_NAME' | translate }}</label>
              <input name="unitName" [placeholder]="'TR_UNIT_NAME' | translate" type="text" class="form-control"
                autocomplete="off" formControlName="unitName"
                [class.error-border]="isInvalidSubmit && form.value.isPricePerUnit  && (!form.value.unitName.trim() || (form.value.unitName && form.value.unitName.length > 100))" />
            </div>
          </div>
        </div>
      </div>

      <app-submit-button *ngIf="package_property_create" [spinnerId]="submitSpinnerId"
        [buttonName]="isCreateForm ? ('TR_CREATE' | translate) : ('TR_UPDATE' | translate)"
        [isInvalidSubmit]="isInvalidSubmit" [isFormValid]="form.valid"></app-submit-button>
    </form>
  </div>
</div>