import { Component, OnInit } from '@angular/core';
import { select } from '@angular-redux/store';
import { startWith, tap, delay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ConfigActions } from '../../ThemeOptions/store/config.actions';
import { ThemeOptions } from '../../theme-options';
import { animate, query, style, transition, trigger } from '@angular/animations';
import { DataStorageService } from 'src/app/Services/data-storage.service';
import { AuthService } from 'src/app/Services/auth.service';
import { LanguageService } from 'src/app/Services/language.service';
import { ADMIN_KEY } from 'src/app/Constants/main-keys';
import { Router, RouterOutlet } from '@angular/router';
import { RolesService } from 'src/app/Services/roles.service';
import { MainService } from 'src/app/Services/main.service';
import { TranslationService } from 'src/app/Services/translation.service';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  animations: [
    trigger('architectUIAnimation', [
      transition('* <=> *', [
        query(':enter, :leave', [
          style({
            opacity: 0,
            display: 'flex',
            flex: '1',
            transform: 'translateY(-20px)',
            flexDirection: 'column'

          }),
        ], { optional: true }),
        query(':enter', [
          animate('600ms ease', style({ opacity: 1, transform: 'translateY(0)' })),
        ], { optional: true }),

        query(':leave', [
          animate('600ms ease', style({ opacity: 0, transform: 'translateY(-20px)' })),
        ], { optional: true })
      ]),
    ])
  ]
})

export class BaseLayoutComponent implements OnInit {
  @select('config') public config$: Observable<any>;
  isTranslationLoaded: boolean;
  currentYear: any;

  constructor(
    public globals: ThemeOptions,
    public configActions: ConfigActions,
    private dataStorageService: DataStorageService,
    private authService: AuthService,
    private languageService: LanguageService,
    private router: Router,
    private rolesService: RolesService,
    private mainService: MainService,
    private translationService: TranslationService
  ) {
    const currentUser = this.dataStorageService.getUser();

    if(!currentUser) {
      this.signOut();
    }
    const roles: string = currentUser['roles'];
    const currentUserValidRoles: Array<string> = JSON.parse(atob(roles));
    this.rolesService.setRoles(currentUserValidRoles);
    this.currentYear = new Date().getFullYear()
    // this.translationService.isPageLoading.pipe(
    //   startWith(null),
    //   delay(0),
    //   tap(isLoading => this.isTranslationLoaded = !isLoading)
    // ).subscribe();
  }

  ngOnInit() { }

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

  signOut(): void {
    this.authService.logout();
    this.router.navigate([this.languageService.currentLanguage, ADMIN_KEY]);
  }
}



