<ng-container *ngIf="organization_view">
  <div *ngIf="organization" class="modal-header modal-list-header">
    <h5 class="modal-title ml-2">{{ organization.name }}</h5>
    <button type="button" class="close pb-0" aria-label="Close" (click)="closeEmit.emit(null)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-0">
    <app-modal-loader [modalSpinners]="[organizationSpinnerId]"></app-modal-loader>
    <div *ngIf="organization" class="p-3">
      <div class="modal-row">
        <div>
          <strong class="mr-2">{{ 'TR_ADMINISTRATOR' | translate }}:</strong>
        </div>
        <div class="right">
          <span class="float-right">{{ organization.organizationAdminName }}</span>
        </div>
      </div>
      <div class="modal-row">
        <div>
          <strong class="mr-2">{{ 'TR_DESCRIPTION' | translate }}:</strong>
        </div>
        <div class="right">
          <span class="float-right">{{ organization.description }}</span>
        </div>
      </div>
      <div class="modal-row">
        <div>
          <strong class="mr-2">{{ 'TR_PAYMENT_PACKAGE' | translate }}:</strong>
        </div>
        <div class="right">
          <span class="float-right">{{ organization.paymentPackageName }}</span>
        </div>
      </div>
      <div class="modal-row">
        <div>
          <strong class="mr-2">{{ 'TR_DEADLINE' | translate }}:</strong>
        </div>
        <div class="right">
          <span class="float-right">{{ organization.deadline | date }}</span>
        </div>
      </div>
      <div class="modal-row">
        <div>
          <strong class="mr-2">{{ 'TR_APPLICATION_NAME' | translate }}:</strong>
        </div>
        <div class="right">
          <span class="float-right">{{ organization.streamUrl }}</span>
        </div>
      </div>
      <div class="modal-row">
        <div>
          <strong class="mr-2">{{ 'TR_SHORT_NAME' | translate }}:</strong>
        </div>
        <div class="right">
          <span class="float-right">{{ organization.shortName }}</span>
        </div>
      </div>
      <div class="modal-row">
        <div>
          <strong class="mr-2">{{ 'TR_EMAIL' | translate }}:</strong>
        </div>
        <div class="right">
          <span class="float-right">{{ organization.email }}</span>
        </div>
      </div>
      <div class="modal-row">
        <div>
          <strong class="mr-2">{{ 'TR_ADDRESS' | translate }}:</strong>
        </div>
        <div class="right">
          <span class="float-right">{{ organization.address }}</span>
        </div>
      </div>
      <div class="modal-row">
        <div>
          <strong class="mr-2">{{ 'TR_PHONE' | translate }}:</strong>
        </div>
        <div class="right">
          <span class="float-right">{{ organization.phone }}</span>
        </div>
      </div>
      <div class="modal-row">
        <div>
          <strong class="mr-2">{{ 'TR_MOBILEPHONE' | translate }}:</strong>
        </div>
        <div class="right">
          <span class="float-right">{{ organization.mobilePhone }}</span>
        </div>
      </div>
      <div class="modal-row">
        <div>
          <strong class="mr-2">{{'TR_LOGIN' | translate }}:</strong>
        </div>
        <div class="right">
          <span class="float-right">{{ organization.loginPush }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>
