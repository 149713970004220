<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon"><i class="icon pe-7s-home icon-gradient bg-plum-plate"></i></div>
            <div>{{ 'TR_WELCOME' | translate }}<div class="page-title-subheading">Для того, чтобы вы были готовы
                    работать в программе и использовать все её
                    возможности, вам необходимо пройти несколько шагов для регистрации
                    данных вашей организации в системе.</div>
            </div>
            <!-- <img class="" src="../../../../assets/images/illustrations/welcome.svg" alt=""> -->
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-6">
        <div class="main-card mb-3 card">
            <div class="card-body">
                <h5 class="card-title">
                    <div class="badge badge-warning ml-1">1</div>
                </h5>
                <div
                    class="vertical-time-simple vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                    <div class="vertical-timeline-item dot-success vertical-timeline-element">
                        <div><span class="vertical-timeline-element-icon bounce-in"></span>
                            <div class="vertical-timeline-element-content bounce-in">
                                <h4 class="timeline-title">Для начала нужно настроить структуру организации.</h4>
                                <p>Перейдите по ссылке
                                    <a target="_blank" class="k-info-colored"
                                        href="https://admin.mindalay.com/ru/admin/organizationSettings">[<i
                                            class="fa fa-link"></i> нажмите сюда ]</a>
                                    для
                                    заполнения
                                    данных. Подробную инструкцию вы найдёте в разделе “Помощь – Структура
                                    организации”. <a target="_blank" class="k-info-colored"
                                        href="https://mindalay.com/ru/help/admin-b2b/topic/3124">[<i
                                            class="fa fa-link"></i> нажмите сюда ]</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="vertical-timeline-item dot-success vertical-timeline-element">
                        <div><span class="vertical-timeline-element-icon bounce-in"></span>
                            <div class="vertical-timeline-element-content bounce-in">
                                <h4 class="timeline-title">Далее создайте пользователей и активируйте их (Кураторов,
                                    преподавателей, слушателей…)</h4>
                                <p>Перейдите по ссылке <a target="_blank" class="k-info-colored"
                                        href="https://admin.mindalay.com/ru/admin/user">[<i class="fa fa-link"></i>
                                        нажмите сюда ]</a>
                                    для
                                    создания
                                    пользователей. Подробную инструкцию вы найдёте в разделе “Помощь –
                                    Пользователи и роли”. <a target="_blank" class="k-info-colored"
                                        href="https://mindalay.com/ru/help/admin-b2b/topic/3118">[<i
                                            class="fa fa-link"></i> нажмите сюда ]</a>
                                    Отметим, что создание слушателя требует дополнительной настройки в
                                    разделе “Приёмная комиссия”.</p>
                            </div>
                        </div>
                    </div>
                    <div class="vertical-timeline-item dot-success vertical-timeline-element">
                        <div><span class="vertical-timeline-element-icon bounce-in"></span>
                            <div class="vertical-timeline-element-content bounce-in">
                                <h4 class="timeline-title">Следующий шаг – это создание групп и прикрепление к ним
                                    слушателей и куратора.</h4>
                                <p>Перейдите по ссылке <a target="_blank" class="k-info-colored"
                                        href="https://admin.mindalay.com/ru/admin/group">[<i class="fa fa-link"></i>
                                        нажмите сюда ]</a>
                                    для
                                    заполнения данных. Подробную инструкцию вы найдёте в разделе “Помощь
                                    – Группы”. <a target="_blank" class="k-info-colored"
                                        href="https://mindalay.com/ru/help/admin-b2b/topic/3125">[<i
                                            class="fa fa-link"></i> нажмите сюда ]</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="vertical-timeline-item dot-success vertical-timeline-element">
                        <div><span class="vertical-timeline-element-icon bounce-in"></span>
                            <div class="vertical-timeline-element-content bounce-in">
                                <h4 class="timeline-title">Далее прикрепите преподавателей к созданным дисциплинам.</h4>
                                <p>Перейдите по ссылке
                                    <a target="_blank" class="k-info-colored"
                                        href="https://admin.mindalay.com/ru/admin/organizationSettings">[<i
                                            class="fa fa-link"></i> нажмите сюда ]</a>
                                    для
                                    прикрепления
                                    преподавателей. Подробную инструкцию вы найдёте в разделе “Помощь –
                                    Структура организации”. <a target="_blank" class="k-info-colored"
                                        href="https://mindalay.com/ru/help/admin-b2b/topic/3124">[<i
                                            class="fa fa-link"></i> нажмите сюда ]</a>
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="main-card mb-3 card">
            <div class="card-body">
                <h5 class="card-title">
                    <div class="badge badge-warning ml-1">2</div>
                </h5>
                <div
                    class="vertical-time-simple vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                    <div class="vertical-timeline-item dot-success vertical-timeline-element">
                        <div><span class="vertical-timeline-element-icon bounce-in"></span>
                            <div class="vertical-timeline-element-content bounce-in">
                                <h4 class="timeline-title">Ваша организация готова. Теперь нужно наполнить её учебными
                                    материалами.</h4>
                                <ul class="list-group">
                                    <li class="list-group-item">
                                        <p class="m-0">Создайте вебинары. Подробная инструкция
                                            <a target="_blank" class="k-info-colored"
                                                href="https://mindalay.com/ru/help/admin-b2b/topic/3127">[<i
                                                    class="fa fa-link"></i> нажмите сюда ]</a>
                                        </p>
                                    </li>
                                    <li class="list-group-item">
                                        <p class="m-0">Создайте курсы. Подробная инструкция
                                            <a target="_blank" class="k-info-colored"
                                                href="https://mindalay.com/ru/help/admin-b2b/topic/3129">[<i
                                                    class="fa fa-link"></i> нажмите сюда ]</a>
                                        </p>

                                    </li>
                                    <li class="list-group-item">
                                        <p class="m-0">Создайте тесты. Подробная инструкция
                                            <a target="_blank" class="k-info-colored"
                                                href="https://mindalay.com/ru/help/admin-b2b/topic/3130">[<i
                                                    class="fa fa-link"></i> нажмите сюда ]</a>
                                        </p>

                                    </li>
                                    <li class="list-group-item">
                                        <p class="m-0">Создайте вопросы к тестам. Подробная инструкция
                                            <a target="_blank" class="k-info-colored"
                                                href="https://mindalay.com/ru/help/admin-b2b/topic/3131">[<i
                                                    class="fa fa-link"></i> нажмите сюда ]</a>
                                        </p>

                                    </li>
                                    <li class="list-group-item">
                                        <p class="m-0">Создайте библиотеку учебных материалов. Подробная инструкция
                                            <a target="_blank" class="k-info-colored"
                                                href="https://mindalay.com/ru/help/admin-b2b/topic/3132">[<i
                                                    class="fa fa-link"></i> нажмите сюда ]</a>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="vertical-timeline-item dot-success vertical-timeline-element">
                        <div><span class="vertical-timeline-element-icon bounce-in"></span>
                            <div class="vertical-timeline-element-content bounce-in">
                                <p>Вы всегда сможете общаться по системе внутренних сообщений с
                                    преподавателями, кураторами и обучающимися, зайдя в раздел Сообщения
                                    <a target="_blank" class="k-info-colored"
                                        href="https://mindalay.com/ru/message.">[<i class="fa fa-link"></i> нажмите сюда
                                        ]</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="vertical-timeline-item dot-success vertical-timeline-element">
                        <div><span class="vertical-timeline-element-icon bounce-in"></span>
                            <div class="vertical-timeline-element-content bounce-in">
                                <p>Вы всегда сможете просмотреть историю приобретения платёжных пакетов в
                                    разделе История приобретения пакетов<a target="_blank" class="k-info-colored" href="https://mindalay.com/ru/organization-
                                        admin/payment-package/history">[<i class="fa fa-link"></i>
                                        нажмите сюда ]</a>
                                    или запросите
                                    индивидуальный тарифный план, связавшись с нами по почте или телефону.</p>
                            </div>
                        </div>
                    </div>
                    <div class="vertical-timeline-item dot-success vertical-timeline-element">
                        <div><span class="vertical-timeline-element-icon bounce-in"></span>
                            <div class="vertical-timeline-element-content bounce-in">
                                <p>Параметры и лимиты текущего тарифного плана доступны в разделе
                                    Информация <a target="_blank" class="k-info-colored"
                                        href="https://mindalay.com/ru/organization-admin/info.">[<i
                                            class="fa fa-link"></i>
                                        нажмите сюда ]</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="vertical-timeline-item dot-success vertical-timeline-element">
                        <div><span class="vertical-timeline-element-icon bounce-in"></span>
                            <div class="vertical-timeline-element-content bounce-in">
                                <p>Перейдите по ссылке
                                    <a target="_blank" class="k-info-colored"
                                        href="https://admin.mindalay.com/ru/admin/organizationSettings">[<i
                                            class="fa fa-link"></i> нажмите сюда ]</a>
                                    для
                                    прикрепления
                                    преподавателей. Подробную инструкцию вы найдёте в разделе “Помощь –
                                    Структура организации”. <a target="_blank" class="k-info-colored"
                                        href="https://mindalay.com/ru/help/admin-b2b/topic/3124">[<i
                                            class="fa fa-link"></i> нажмите сюда ]</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <img class="w-100" src="../../../../assets/images/illustrations/welcome.svg" alt=""> -->
<!-- <div class="mb-3 card">
    <div class="card-header-tab card-header p-3">
    </div>
    <div class="no-gutters row">
        <div class="col-sm-6 col-md-4 col-xl-4">
            <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
                <div class="icon-wrapper rounded-circle">
                    <div class="icon-wrapper-bg opacity-10 bg-warning"></div><i
                        class="pe-7s-album text-white opacity-8"></i>
                </div>
                <div class="widget-chart-content">
                    <div class="widget-subheading">Cash Deposits</div>
                    <div class="widget-numbers">1,7M</div>
                    <div class="widget-description opacity-8 text-focus">
                        <div class="d-inline text-danger pr-1">
                            <i name="angle-down" _nghost-fvd-c2="" ng-reflect-name="angle-down"><i _ngcontent-fvd-c2=""
                                    aria-hidden="true" class="fa fa-angle-down" ng-reflect-klass="fa fa-angle-down"
                                    ng-reflect-ng-class=""></i></i><span class="pl-1">54.1%</span>
                        </div> less earnings
                    </div>
                </div>
            </div>
            <div class="divider m-0 d-md-none d-sm-block"></div>
        </div>
        <div class="col-sm-6 col-md-4 col-xl-4">
            <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
                <div class="icon-wrapper rounded-circle">
                    <div class="icon-wrapper-bg opacity-9 bg-danger"></div><i class="pe-7s-portfolio text-white"></i>
                </div>
                <div class="widget-chart-content">
                    <div class="widget-subheading">Invested Dividents</div>
                    <div class="widget-numbers"><span>9M</span></div>
                    <div class="widget-description opacity-8 text-focus"> Grow Rate: <span class="text-info pl-1">
                            <i name="angle-down" _nghost-fvd-c2="" ng-reflect-name="angle-down"><i _ngcontent-fvd-c2=""
                                    aria-hidden="true" class="fa fa-angle-down" ng-reflect-klass="fa fa-angle-down"
                                    ng-reflect-ng-class=""></i></i><span class="pl-1">14.1%</span>
                        </span></div>
                </div>
            </div>
            <div class="divider m-0 d-md-none d-sm-block"></div>
        </div>
        <div class="col-sm-12 col-md-4 col-xl-4">
            <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
                <div class="icon-wrapper rounded-circle">
                    <div class="icon-wrapper-bg opacity-9 bg-success"></div><i class="pe-7s-light text-white"></i>
                </div>
                <div class="widget-chart-content">
                    <div class="widget-subheading">Capital Gains</div>
                    <div class="widget-numbers text-success"><span>$563</span></div>
                    <div class="widget-description text-focus"> Increased by <span class="text-warning pl-1">
                            <i name="angle-up" _nghost-fvd-c2="" ng-reflect-name="angle-up"><i _ngcontent-fvd-c2=""
                                    aria-hidden="true" class="fa fa-angle-up" ng-reflect-klass="fa fa-angle-up"
                                    ng-reflect-ng-class=""></i></i><span class="pl-1">7.35%</span>
                        </span></div>
                </div>
            </div>
        </div>
    </div>
    <div class="text-center d-block p-3 card-footer"></div>
</div> -->
<!-- <div class="mb-3 card">
    <div class="card-header-tab card-header">
        <div class="card-header-title font-size-lg text-capitalize font-weight-normal"><i
                class="header-icon lnr-charts icon-gradient bg-happy-green"></i> Portfolio Performance </div>
    </div>
    <div class="no-gutters row">
        <div class="col-sm-6 col-md-4 col-xl-4">
            <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
                <div class="icon-wrapper rounded-circle">
                    <div class="icon-wrapper-bg opacity-10 bg-warning"></div><i
                        class="pe-7s-album text-white opacity-8"></i>
                </div>
                <div class="widget-chart-content">
                    <div class="widget-subheading">Cash Deposits</div>
                    <div class="widget-numbers">1,7M</div>
                    <div class="widget-description opacity-8 text-focus">
                        <div class="d-inline text-danger pr-1">
                            <i name="angle-down" _nghost-fvd-c2="" ng-reflect-name="angle-down"><i _ngcontent-fvd-c2=""
                                    aria-hidden="true" class="fa fa-angle-down" ng-reflect-klass="fa fa-angle-down"
                                    ng-reflect-ng-class=""></i></i><span class="pl-1">54.1%</span>
                        </div> less earnings
                    </div>
                </div>
            </div>
            <div class="divider m-0 d-md-none d-sm-block"></div>
        </div>
        <div class="col-sm-6 col-md-4 col-xl-4">
            <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
                <div class="icon-wrapper rounded-circle">
                    <div class="icon-wrapper-bg opacity-9 bg-danger"></div><i class="pe-7s-portfolio text-white"></i>
                </div>
                <div class="widget-chart-content">
                    <div class="widget-subheading">Invested Dividents</div>
                    <div class="widget-numbers"><span>9M</span></div>
                    <div class="widget-description opacity-8 text-focus"> Grow Rate: <span class="text-info pl-1">
                            <i name="angle-down" _nghost-fvd-c2="" ng-reflect-name="angle-down"><i _ngcontent-fvd-c2=""
                                    aria-hidden="true" class="fa fa-angle-down" ng-reflect-klass="fa fa-angle-down"
                                    ng-reflect-ng-class=""></i></i><span class="pl-1">14.1%</span>
                        </span></div>
                </div>
            </div>
            <div class="divider m-0 d-md-none d-sm-block"></div>
        </div>
        <div class="col-sm-12 col-md-4 col-xl-4">
            <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
                <div class="icon-wrapper rounded-circle">
                    <div class="icon-wrapper-bg opacity-9 bg-success"></div><i class="pe-7s-light text-white"></i>
                </div>
                <div class="widget-chart-content">
                    <div class="widget-subheading">Capital Gains</div>
                    <div class="widget-numbers text-success"><span>$563</span></div>
                    <div class="widget-description text-focus"> Increased by <span class="text-warning pl-1">
                            <i name="angle-up" _nghost-fvd-c2="" ng-reflect-name="angle-up"><i _ngcontent-fvd-c2=""
                                    aria-hidden="true" class="fa fa-angle-up" ng-reflect-klass="fa fa-angle-up"
                                    ng-reflect-ng-class=""></i></i><span class="pl-1">7.35%</span>
                        </span></div>
                </div>
            </div>
        </div>
    </div>
    <div class="text-center d-block p-3 card-footer"><button
            class="btn-pill btn-shadow btn-wide fsize-1 btn btn-primary btn-lg"><span class="mr-2 opacity-7"><i
                    class="icon icon-anim-pulse ion-ios-analytics-outline"></i></span><span class="mr-1">View Complete
                Report</span></button></div>
</div> -->
<!-- <div class="main-card mb-3 card">
    <div class="card-header">
        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">Company Agents Status</div>
    </div>
    <div class="table-responsive">
        <table class="align-middle text-truncate mb-0 table table-borderless table-hover">
            <thead>
                <tr>
                    <th class="text-center">#</th>
                    <th class="text-center">Avatar</th>
                    <th class="text-center">Name</th>
                    <th class="text-center">Company</th>
                    <th class="text-center">Status</th>
                    <th class="text-center">Due Date</th>
                    <th class="text-center">Target Achievement</th>
                    <th class="text-center">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-center text-muted" style="width: 80px;">#54</td>
                    <td class="text-center" style="width: 80px;"><img alt="" class="rounded-circle"
                            src="./assets/images/avatars/1.jpg" width="40"></td>
                    <td class="text-center"><a href="/architectui-angular-free/">Juan C. Cargill</a></td>
                    <td class="text-center"><a href="/architectui-angular-free/">Micro Electronics</a></td>
                    <td class="text-center">
                        <div class="badge badge-pill badge-danger">Canceled</div>
                    </td>
                    <td class="text-center"><span class="pr-2 opacity-6"><i class="fa fa-business-time"></i></span> 12
                        Dec </td>
                    <td class="text-center" style="width: 200px;">
                        <div class="widget-content p-0">
                            <div class="widget-content-outer">
                                <div class="widget-content-wrapper">
                                    <div class="widget-content-left pr-2">
                                        <div class="widget-numbers fsize-1 text-danger">71%</div>
                                    </div>
                                    <div class="widget-content-right w-100">
                                        <div class="progress-bar-xs progress">
                                            <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="71"
                                                class="progress-bar bg-danger" role="progressbar" style="width: 71%;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td class="text-center">
                        <div class="btn-group-sm btn-group" role="group"><button
                                class="btn-shadow btn btn-primary">Hire</button><button
                                class="btn-shadow btn btn-primary">Fire</button></div>
                    </td>
                </tr>
                <tr>
                    <td class="text-center text-muted" style="width: 80px;">#55</td>
                    <td class="text-center" style="width: 80px;"><img alt="" class="rounded-circle"
                            src="./assets/images/avatars/2.jpg" width="40"></td>
                    <td class="text-center"><a href="/architectui-angular-free/">Johnathan Phelan</a></td>
                    <td class="text-center"><a href="/architectui-angular-free/">Hatchworks</a></td>
                    <td class="text-center">
                        <div class="badge badge-pill badge-info">On Hold</div>
                    </td>
                    <td class="text-center"><span class="pr-2 opacity-6"><i class="fa fa-business-time"></i></span> 12
                        Dec </td>
                    <td class="text-center" style="width: 200px;">
                        <div class="widget-content p-0">
                            <div class="widget-content-outer">
                                <div class="widget-content-wrapper">
                                    <div class="widget-content-left pr-2">
                                        <div class="widget-numbers fsize-1 text-warning">54%</div>
                                    </div>
                                    <div class="widget-content-right w-100">
                                        <div class="progress-bar-xs progress">
                                            <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="54"
                                                class="progress-bar bg-warning" role="progressbar" style="width: 54%;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td class="text-center">
                        <div class="btn-group-sm btn-group" role="group"><button
                                class="btn-shadow btn btn-primary">Hire</button><button
                                class="btn-shadow btn btn-primary">Fire</button></div>
                    </td>
                </tr>
                <tr>
                    <td class="text-center text-muted" style="width: 80px;">#56</td>
                    <td class="text-center" style="width: 80px;"><img alt="" class="rounded-circle"
                            src="./assets/images/avatars/3.jpg" width="40"></td>
                    <td class="text-center"><a href="/architectui-angular-free/">Darrell Lowe</a></td>
                    <td class="text-center"><a href="/architectui-angular-free/">Riddle Electronics</a></td>
                    <td class="text-center">
                        <div class="badge badge-pill badge-warning">In Progress</div>
                    </td>
                    <td class="text-center"><span class="pr-2 opacity-6"><i class="fa fa-business-time"></i></span> 12
                        Dec </td>
                    <td class="text-center" style="width: 200px;">
                        <div class="widget-content p-0">
                            <div class="widget-content-outer">
                                <div class="widget-content-wrapper">
                                    <div class="widget-content-left pr-2">
                                        <div class="widget-numbers fsize-1 text-success">97%</div>
                                    </div>
                                    <div class="widget-content-right w-100">
                                        <div class="progress-bar-xs progress">
                                            <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="97"
                                                class="progress-bar bg-success" role="progressbar" style="width: 97%;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td class="text-center">
                        <div class="btn-group-sm btn-group" role="group"><button
                                class="btn-shadow btn btn-primary">Hire</button><button
                                class="btn-shadow btn btn-primary">Fire</button></div>
                    </td>
                </tr>
                <tr>
                    <td class="text-center text-muted" style="width: 80px;">#56</td>
                    <td class="text-center" style="width: 80px;"><img alt="" class="rounded-circle"
                            src="./assets/images/avatars/4.jpg" width="40"></td>
                    <td class="text-center"><a href="/architectui-angular-free/">George T. Cottrell</a></td>
                    <td class="text-center"><a href="/architectui-angular-free/">Pixelcloud</a></td>
                    <td class="text-center">
                        <div class="badge badge-pill badge-success">Completed</div>
                    </td>
                    <td class="text-center"><span class="pr-2 opacity-6"><i class="fa fa-business-time"></i></span> 12
                        Dec </td>
                    <td class="text-center" style="width: 200px;">
                        <div class="widget-content p-0">
                            <div class="widget-content-outer">
                                <div class="widget-content-wrapper">
                                    <div class="widget-content-left pr-2">
                                        <div class="widget-numbers fsize-1 text-info">88%</div>
                                    </div>
                                    <div class="widget-content-right w-100">
                                        <div class="progress-bar-xs progress">
                                            <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="88"
                                                class="progress-bar bg-info" role="progressbar" style="width: 88%;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td class="text-center">
                        <div class="btn-group-sm btn-group" role="group"><button
                                class="btn-shadow btn btn-primary">Hire</button><button
                                class="btn-shadow btn btn-primary">Fire</button></div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="d-block p-4 text-center card-footer"><button
            class="btn-pill btn-shadow btn-wide fsize-1 btn btn-dark btn-lg"><span class="mr-2 opacity-7"><i
                    class="fa fa-cog fa-spin"></i></span><span class="mr-1">View Complete Report</span></button></div>
</div> -->
<!-- <div class="row">
    <div class="col-sm-12 col-lg-6">
        <div class="mb-3 card">
            <div class="card-header-tab card-header">
                <div class="card-header-title font-size-lg text-capitalize font-weight-normal"><i
                        class="header-icon lnr-cloud-download icon-gradient bg-happy-itmeo"></i> Technical Support
                </div>
            </div>
            <div class="p-0 card-body">
                <div class="p-1 slick-slider-sm mx-auto">
                    <div class="widget-chart widget-chart2 text-left p-0">
                        <div class="widget-chat-wrapper-outer">
                            <div class="widget-chart-content widget-chart-content-lg pb-0">
                                <div class="widget-chart-flex">
                                    <div class="widget-title opacity-5 text-muted text-uppercase">Helpdesk Tickets</div>
                                </div>
                                <div class="widget-numbers">
                                    <div class="widget-chart-flex">
                                        <div><span class="text-warning">34</span></div>
                                        <div class="widget-title ml-2 font-size-lg font-weight-normal text-dark"><span
                                                class="opacity-5 text-muted pl-2 pr-1">5%</span> increase </div>
                                    </div>
                                </div>
                            </div>
                            <div class="widget-chart-wrapper he-auto opacity-10 m-0">
                                <div class="d-block">
                                    <div class="chartjs-size-monitor">
                                        <div class="chartjs-size-monitor-expand">
                                            <div class=""></div>
                                        </div>
                                        <div class="chartjs-size-monitor-shrink">
                                            <div class=""></div>
                                        </div>
                                    </div><canvas basechart="" class="chart chartjs-render-monitor"
                                        style="display: block; width: 457px; height: 150px;" width="457" height="150"
                                        ng-reflect-datasets="[object Object]"
                                        ng-reflect-labels="January,February,March,April,M"
                                        ng-reflect-options="[object Object]" ng-reflect-chart-type="line"
                                        ng-reflect-colors="[object Object]"></canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h6 class="text-muted text-uppercase font-size-md opacity-5 pl-3 pr-3 pb-1 font-weight-normal">Sales
                    Progress</h6>
                <ul class="list-group list-group-flush">
                    <li class="p-3 bg-transparent list-group-item">
                        <div class="widget-content p-0">
                            <div class="widget-content-outer">
                                <div class="widget-content-wrapper">
                                    <div class="widget-content-left">
                                        <div class="widget-heading">Total Orders</div>
                                        <div class="widget-subheading">Last year expenses</div>
                                    </div>
                                    <div class="widget-content-right">
                                        <div class="widget-numbers text-success"><small>$</small> 1896 </div>
                                    </div>
                                </div>
                                <div class="widget-progress-wrapper">
                                    <div class="progress-bar-sm progress-bar-animated-alt progress">
                                        <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="43"
                                            class="progress-bar bg-primary" role="progressbar" style="width: 43%;">
                                        </div>
                                    </div>
                                    <div class="progress-sub-label">
                                        <div class="sub-label-left">YoY Growth</div>
                                        <div class="sub-label-right">100%</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-lg-6">
        <div class="card-hover-shadow-2x mb-3 card">
            <div class="card-header-tab card-header">
                <div class="card-header-title font-size-lg text-capitalize font-weight-normal"><i
                        class="header-icon lnr-lighter icon-gradient bg-amy-crisp"></i> Timeline Example </div>
            </div>
            <div class="scroll-area-lg">
                <perfect-scrollbar ng-reflect-auto-propagation="true" class="ps-show-limits">
                    <div style="position: static;" class="ps ps--active-y" ng-reflect-disabled="false">
                        <div class="ps-content">
                            <div class="p-4">
                                <div
                                    class="vertical-time-simple vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                                    <div class="dot-danger vertical-timeline-element">
                                        <div><span class="vertical-timeline-element-icon bounce-in"></span>
                                            <div class="vertical-timeline-element-content bounce-in">
                                                <h4 class="timeline-title">All Hands Meeting</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dot-warning vertical-timeline-element">
                                        <div><span class="vertical-timeline-element-icon bounce-in"></span>
                                            <div class="vertical-timeline-element-content bounce-in">
                                                <p>Yet another one, at <span class="text-success">15:00 PM</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dot-success vertical-timeline-element">
                                        <div><span class="vertical-timeline-element-icon bounce-in"></span>
                                            <div class="vertical-timeline-element-content bounce-in">
                                                <h4 class="timeline-title">Build the production release <div
                                                        class="badge badge-danger ml-2">NEW</div>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dot-primary vertical-timeline-element">
                                        <div><span class="vertical-timeline-element-icon bounce-in"></span>
                                            <div class="vertical-timeline-element-content bounce-in">
                                                <h4 class="timeline-title"> Something not important </h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dot-warning vertical-timeline-element">
                                        <div><span class="vertical-timeline-element-icon bounce-in"></span>
                                            <div class="vertical-timeline-element-content bounce-in">
                                                <p>Yet another one, at <span class="text-success">15:00 PM</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dot-success vertical-timeline-element">
                                        <div><span class="vertical-timeline-element-icon bounce-in"></span>
                                            <div class="vertical-timeline-element-content bounce-in">
                                                <h4 class="timeline-title">Build the production release <div
                                                        class="badge badge-danger ml-2">NEW</div>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dot-info vertical-timeline-element">
                                        <div><span class="vertical-timeline-element-icon bounce-in"></span>
                                            <div class="vertical-timeline-element-content bounce-in">
                                                <h4 class="timeline-title">This dot has an info state</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dot-dark vertical-timeline-element">
                                        <div><span class="vertical-timeline-element-icon bounce-in"></span>
                                            <div class="vertical-timeline-element-content bounce-in">
                                                <h4 class="timeline-title">This dot has a dark state</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dot-danger vertical-timeline-element">
                                        <div><span class="vertical-timeline-element-icon bounce-in"></span>
                                            <div class="vertical-timeline-element-content bounce-in">
                                                <h4 class="timeline-title">All Hands Meeting</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dot-warning vertical-timeline-element">
                                        <div><span class="vertical-timeline-element-icon bounce-in"></span>
                                            <div class="vertical-timeline-element-content bounce-in">
                                                <p>Yet another one, at <span class="text-success">15:00 PM</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dot-success vertical-timeline-element">
                                        <div><span class="vertical-timeline-element-icon bounce-in"></span>
                                            <div class="vertical-timeline-element-content bounce-in">
                                                <h4 class="timeline-title">Build the production release <div
                                                        class="badge badge-danger ml-2">NEW</div>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dot-primary vertical-timeline-element">
                                        <div><span class="vertical-timeline-element-icon bounce-in"></span>
                                            <div class="vertical-timeline-element-content bounce-in">
                                                <h4 class="timeline-title"> Something not important </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ps__rail-x" style="left: 0px; bottom: 0px;">
                            <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
                        </div>
                        <div class="ps__rail-y" style="top: 0px; height: 400px; right: 0px;">
                            <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 326px;"></div>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
            <div class="d-block text-center card-footer"><button class="btn-shadow btn-wide btn-pill btn btn-focus">
                    View All Messages </button></div>
        </div>
    </div>
</div> -->
<!-- <div class="row">
    <div class="col-sm-12 col-lg-6">
        <div class="card-hover-shadow-2x mb-3 card">
            <div class="card-header-tab card-header">
                <div class="card-header-title font-size-lg text-capitalize font-weight-normal"><i
                        class="header-icon lnr-database icon-gradient bg-malibu-beach"></i>Tasks List
                </div>
                <div class="btn-actions-pane-right text-capitalize actions-icon-btn dropdown" ngbdropdown=""><button
                        aria-haspopup="true" class="btn btn-icon btn-icon-only btn-link dropdown-toggle"
                        ngbdropdowntoggle="" aria-expanded="false">
                        <i name="th" _nghost-fvd-c2="" ng-reflect-name="th"><i _ngcontent-fvd-c2="" aria-hidden="true"
                                class="fa fa-th" ng-reflect-klass="fa fa-th" ng-reflect-ng-class=""></i></i>
                    </button>
                    <div ngbdropdownmenu="" class="dropdown-menu" x-placement="bottom-left"><button
                            class="dropdown-item" tabindex="0" type="button"><i
                                class="dropdown-icon lnr-inbox"></i><span>Menus</span></button><button
                            class="dropdown-item" tabindex="0" type="button"><i
                                class="dropdown-icon lnr-file-empty"></i><span>Settings</span></button><button
                            class="dropdown-item" tabindex="0" type="button"><i
                                class="dropdown-icon lnr-book"></i><span>Actions</span></button>
                        <div class="dropdown-divider" tabindex="-1"></div>
                        <div class="p-1 text-right"><button class="mr-2 btn-shadow btn-sm btn btn-link">View
                                Details</button><button class="mr-2 btn-shadow btn-sm btn btn-primary">Action</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="scroll-area-lg">
                <perfect-scrollbar ng-reflect-auto-propagation="true" class="ps-show-limits">
                    <div style="position: static;" class="ps ps--active-y" ng-reflect-disabled="false">
                        <div class="ps-content">
                            <ul class="todo-list-wrapper list-group list-group-flush">
                                <li class="list-group-item">
                                    <div class="todo-indicator bg-warning"></div>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-2">
                                                <div class="custom-checkbox custom-control"><input
                                                        class="custom-control-input" id="exampleCustomCheckbox12"
                                                        type="checkbox"><label class="custom-control-label"
                                                        for="exampleCustomCheckbox12">&nbsp;</label></div>
                                            </div>
                                            <div class="widget-content-left">
                                                <div class="widget-heading">Wash the car <div
                                                        class="badge badge-danger ml-2">Rejected</div>
                                                </div>
                                                <div class="widget-subheading"><i>Written by Bob</i></div>
                                            </div>
                                            <div class="widget-content-right widget-content-actions"><button
                                                    class="border-0 btn-transition btn btn-outline-success">
                                                    <i name="check" _nghost-fvd-c2="" ng-reflect-name="check"><i
                                                            _ngcontent-fvd-c2="" aria-hidden="true" class="fa fa-check"
                                                            ng-reflect-klass="fa fa-check" ng-reflect-ng-class=""></i>
                                                    </i>
                                                </button><button class="border-0 btn-transition btn btn-outline-danger">
                                                    <i name="trash" _nghost-fvd-c2="" ng-reflect-name="trash"><i
                                                            _ngcontent-fvd-c2="" aria-hidden="true" class="fa fa-trash"
                                                            ng-reflect-klass="fa fa-trash" ng-reflect-ng-class=""></i>
                                                    </i>
                                                </button></div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="todo-indicator bg-focus"></div>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-2">
                                                <div class="custom-checkbox custom-control"><input
                                                        class="custom-control-input" id="exampleCustomCheckbox1"
                                                        type="checkbox"><label class="custom-control-label"
                                                        for="exampleCustomCheckbox1">&nbsp;</label></div>
                                            </div>
                                            <div class="widget-content-left">
                                                <div class="widget-heading">Task with dropdown menu</div>
                                                <div class="widget-subheading">
                                                    <div>By Johnny <div class="badge badge-pill badge-info ml-2">NEW
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="widget-content-right widget-content-actions">
                                                <div class="d-inline-block dropdown" ngbdropdown=""
                                                    placement="bottom-right" ng-reflect-placement="bottom-right"><button
                                                        aria-haspopup="true"
                                                        class="btn btn-icon btn-icon-only btn-link dropdown-toggle"
                                                        ngbdropdowntoggle="" aria-expanded="false"><i
                                                            class="pe-7s-menu btn-icon-wrapper"></i></button>
                                                    <div ngbdropdownmenu="" class="dropdown-menu"
                                                        x-placement="bottom-right">
                                                        <ul class="nav flex-column">
                                                            <li class="nav-item-header nav-item">Activity</li>
                                                            <li class="nav-item"><a class="nav-link"
                                                                    href="/architectui-angular-free/">Chat <div
                                                                        class="ml-auto badge badge-pill badge-info">8
                                                                    </div></a></li>
                                                            <li class="nav-item"><a class="nav-link"
                                                                    href="/architectui-angular-free/">Recover
                                                                    Password</a></li>
                                                            <li class="nav-item-header nav-item">My Account</li>
                                                            <li class="nav-item"><a class="nav-link"
                                                                    href="/architectui-angular-free/">Settings <div
                                                                        class="ml-auto badge badge-success">New</div>
                                                                </a></li>
                                                            <li class="nav-item"><a class="nav-link"
                                                                    href="/architectui-angular-free/">Messages <div
                                                                        class="ml-auto badge badge-warning">512</div>
                                                                </a></li>
                                                            <li class="nav-item"><a class="nav-link"
                                                                    href="/architectui-angular-free/">Logs</a></li>
                                                            <li class="nav-item-divider nav-item"></li>
                                                            <li class="nav-item-btn nav-item"><button
                                                                    class="btn-wide btn-shadow btn btn-danger btn-sm">Cancel</button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="todo-indicator bg-primary"></div>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-2">
                                                <div class="custom-checkbox custom-control"><input
                                                        class="custom-control-input" id="exampleCustomCheckbox4"
                                                        type="checkbox"><label class="custom-control-label"
                                                        for="exampleCustomCheckbox4">&nbsp;</label></div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading">Badge on the right task</div>
                                                <div class="widget-subheading">This task has show on hover actions!
                                                </div>
                                            </div>
                                            <div class="widget-content-right widget-content-actions"><button
                                                    class="border-0 btn-transition btn btn-outline-success">
                                                    <i name="check" _nghost-fvd-c2="" ng-reflect-name="check"><i
                                                            _ngcontent-fvd-c2="" aria-hidden="true" class="fa fa-check"
                                                            ng-reflect-klass="fa fa-check" ng-reflect-ng-class=""></i>
                                                    </i>
                                                </button></div>
                                            <div class="widget-content-right ml-3">
                                                <div class="badge badge-pill badge-success">Latest Task</div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="todo-indicator bg-info"></div>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-2">
                                                <div class="custom-checkbox custom-control"><input
                                                        class="custom-control-input" id="exampleCustomCheckbox2"
                                                        type="checkbox"><label class="custom-control-label"
                                                        for="exampleCustomCheckbox2">&nbsp;</label></div>
                                            </div>
                                            <div class="widget-content-left mr-3">
                                                <div class="widget-content-left"><img alt="" class="rounded"
                                                        src="./assets/images/avatars/1.jpg" width="42"></div>
                                            </div>
                                            <div class="widget-content-left">
                                                <div class="widget-heading">Go grocery shopping</div>
                                                <div class="widget-subheading">A short description for this todo item
                                                </div>
                                            </div>
                                            <div class="widget-content-right widget-content-actions"><button
                                                    class="border-0 btn-transition btn btn-outline-success">
                                                    <i name="check" _nghost-fvd-c2="" ng-reflect-name="check"><i
                                                            _ngcontent-fvd-c2="" aria-hidden="true" class="fa fa-check"
                                                            ng-reflect-klass="fa fa-check" ng-reflect-ng-class=""></i>
                                                    </i>
                                                </button><button class="border-0 btn-transition btn btn-outline-danger">
                                                    <i name="trash" _nghost-fvd-c2="" ng-reflect-name="trash"><i
                                                            _ngcontent-fvd-c2="" aria-hidden="true" class="fa fa-trash"
                                                            ng-reflect-klass="fa fa-trash" ng-reflect-ng-class=""></i>
                                                    </i>
                                                </button></div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="todo-indicator bg-warning"></div>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-2">
                                                <div class="custom-checkbox custom-control"><input
                                                        class="custom-control-input" id="exampleCustomCheckbox12"
                                                        type="checkbox"><label class="custom-control-label"
                                                        for="exampleCustomCheckbox12">&nbsp;</label></div>
                                            </div>
                                            <div class="widget-content-left">
                                                <div class="widget-heading">Wash the car <div
                                                        class="badge badge-danger ml-2">Rejected</div>
                                                </div>
                                                <div class="widget-subheading"><i>Written by Bob</i></div>
                                            </div>
                                            <div class="widget-content-right widget-content-actions"><button
                                                    class="border-0 btn-transition btn btn-outline-success">
                                                    <i name="check" _nghost-fvd-c2="" ng-reflect-name="check"><i
                                                            _ngcontent-fvd-c2="" aria-hidden="true" class="fa fa-check"
                                                            ng-reflect-klass="fa fa-check" ng-reflect-ng-class=""></i>
                                                    </i>
                                                </button><button class="border-0 btn-transition btn btn-outline-danger">
                                                    <i name="trash" _nghost-fvd-c2="" ng-reflect-name="trash"><i
                                                            _ngcontent-fvd-c2="" aria-hidden="true" class="fa fa-trash"
                                                            ng-reflect-klass="fa fa-trash" ng-reflect-ng-class=""></i>
                                                    </i>
                                                </button></div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="todo-indicator bg-focus"></div>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-2">
                                                <div class="custom-checkbox custom-control"><input
                                                        class="custom-control-input" id="exampleCustomCheckbox1"
                                                        type="checkbox"><label class="custom-control-label"
                                                        for="exampleCustomCheckbox1">&nbsp;</label></div>
                                            </div>
                                            <div class="widget-content-left">
                                                <div class="widget-heading">Task with dropdown menu</div>
                                                <div class="widget-subheading">
                                                    <div>By Johnny <div class="badge badge-pill badge-info ml-2">NEW
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="widget-content-right widget-content-actions">
                                                <div class="d-inline-block dropdown" ngbdropdown=""
                                                    placement="bottom-right" ng-reflect-placement="bottom-right"><button
                                                        aria-haspopup="true"
                                                        class="btn btn-icon btn-icon-only btn-link dropdown-toggle"
                                                        ngbdropdowntoggle="" aria-expanded="false"><i
                                                            class="pe-7s-menu btn-icon-wrapper"></i></button>
                                                    <div ngbdropdownmenu="" class="dropdown-menu"
                                                        x-placement="bottom-right">
                                                        <ul class="nav flex-column">
                                                            <li class="nav-item-header nav-item">Activity</li>
                                                            <li class="nav-item"><a class="nav-link"
                                                                    href="/architectui-angular-free/">Chat <div
                                                                        class="ml-auto badge badge-pill badge-info">8
                                                                    </div></a></li>
                                                            <li class="nav-item"><a class="nav-link"
                                                                    href="/architectui-angular-free/">Recover
                                                                    Password</a></li>
                                                            <li class="nav-item-header nav-item">My Account</li>
                                                            <li class="nav-item"><a class="nav-link"
                                                                    href="/architectui-angular-free/">Settings <div
                                                                        class="ml-auto badge badge-success">New</div>
                                                                </a></li>
                                                            <li class="nav-item"><a class="nav-link"
                                                                    href="/architectui-angular-free/">Messages <div
                                                                        class="ml-auto badge badge-warning">512</div>
                                                                </a></li>
                                                            <li class="nav-item"><a class="nav-link"
                                                                    href="/architectui-angular-free/">Logs</a></li>
                                                            <li class="nav-item-divider nav-item"></li>
                                                            <li class="nav-item-btn nav-item"><button
                                                                    class="btn-wide btn-shadow btn btn-danger btn-sm">Cancel</button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="todo-indicator bg-primary"></div>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-2">
                                                <div class="custom-checkbox custom-control"><input
                                                        class="custom-control-input" id="exampleCustomCheckbox4"
                                                        type="checkbox"><label class="custom-control-label"
                                                        for="exampleCustomCheckbox4">&nbsp;</label></div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading">Badge on the right task</div>
                                                <div class="widget-subheading">This task has show on hover actions!
                                                </div>
                                            </div>
                                            <div class="widget-content-right widget-content-actions"><button
                                                    class="border-0 btn-transition btn btn-outline-success">
                                                    <i name="check" _nghost-fvd-c2="" ng-reflect-name="check"><i
                                                            _ngcontent-fvd-c2="" aria-hidden="true" class="fa fa-check"
                                                            ng-reflect-klass="fa fa-check" ng-reflect-ng-class=""></i>
                                                    </i>
                                                </button></div>
                                            <div class="widget-content-right ml-3">
                                                <div class="badge badge-pill badge-success">Latest Task</div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="todo-indicator bg-success"></div>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-2">
                                                <div class="custom-checkbox custom-control"><input
                                                        class="custom-control-input" id="exampleCustomCheckbox3"
                                                        type="checkbox"><label class="custom-control-label"
                                                        for="exampleCustomCheckbox3">&nbsp;</label></div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading">Development Task</div>
                                                <div class="widget-subheading">Finish Vue ToDo List App</div>
                                            </div>
                                            <div class="widget-content-right">
                                                <div class="badge badge-warning mr-2">69</div>
                                            </div>
                                            <div class="widget-content-right"><button
                                                    class="border-0 btn-transition btn btn-outline-success">
                                                    <i name="check" _nghost-fvd-c2="" ng-reflect-name="check"><i
                                                            _ngcontent-fvd-c2="" aria-hidden="true" class="fa fa-check"
                                                            ng-reflect-klass="fa fa-check" ng-reflect-ng-class=""></i>
                                                    </i>
                                                </button><button class="border-0 btn-transition btn btn-outline-danger">
                                                    <i name="trash" _nghost-fvd-c2="" ng-reflect-name="trash"><i
                                                            _ngcontent-fvd-c2="" aria-hidden="true" class="fa fa-trash"
                                                            ng-reflect-klass="fa fa-trash" ng-reflect-ng-class=""></i>
                                                    </i>
                                                </button></div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="ps__rail-x" style="left: 0px; bottom: -137px;">
                            <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
                        </div>
                        <div class="ps__rail-y" style="top: 137px; height: 400px; right: 0px;">
                            <div class="ps__thumb-y" tabindex="0" style="top: 103px; height: 297px;"></div>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
            <div class="d-block text-right card-footer"><button class="mr-2 btn btn-link btn-sm">Cancel</button><button
                    class="btn btn-primary">Add Task</button>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-lg-6">
        <div class="row">
            <div class="col-lg-6">
                <div
                    class="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-success border-success">
                    <div class="widget-chat-wrapper-outer">
                        <div class="widget-chart-content pt-3 pl-3 pb-1">
                            <div class="widget-chart-flex">
                                <div class="widget-numbers">
                                    <div class="widget-chart-flex">
                                        <div class="fsize-4"><small class="opacity-5">$</small><span>874</span></div>
                                    </div>
                                </div>
                            </div>
                            <h6 class="widget-subheading mb-0 opacity-5">sales last month</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div
                    class="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-primary border-primary">
                    <div class="widget-chat-wrapper-outer">
                        <div class="widget-chart-content pt-3 pl-3 pb-1">
                            <div class="widget-chart-flex">
                                <div class="widget-numbers">
                                    <div class="widget-chart-flex">
                                        <div class="fsize-4"><small class="opacity-5">$</small><span>1283</span></div>
                                    </div>
                                </div>
                            </div>
                            <h6 class="widget-subheading mb-0 opacity-5">Sales Income</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div
                    class="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-warning border-warning">
                    <div class="widget-chat-wrapper-outer">
                        <div class="widget-chart-content pt-3 pl-3 pb-1">
                            <div class="widget-chart-flex">
                                <div class="widget-numbers">
                                    <div class="widget-chart-flex">
                                        <div class="fsize-4"><small class="opacity-5">$</small><span>1286</span></div>
                                    </div>
                                </div>
                            </div>
                            <h6 class="widget-subheading mb-0 opacity-5">last month sales</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div
                    class="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-danger border-danger">
                    <div class="widget-chat-wrapper-outer">
                        <div class="widget-chart-content pt-3 pl-3 pb-1">
                            <div class="widget-chart-flex">
                                <div class="widget-numbers">
                                    <div class="widget-chart-flex">
                                        <div class="fsize-4"><small class="opacity-5">$</small><span>564</span></div>
                                    </div>
                                </div>
                            </div>
                            <h6 class="widget-subheading mb-0 opacity-5">total revenue</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div
                    class="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-info border-info">
                    <div class="widget-chat-wrapper-outer">
                        <div class="widget-chart-content pt-3 pl-3 pb-1">
                            <div class="widget-chart-flex">
                                <div class="widget-numbers">
                                    <div class="widget-chart-flex">
                                        <div class="fsize-4"><small class="opacity-5">$</small><span>1283</span></div>
                                    </div>
                                </div>
                            </div>
                            <h6 class="widget-subheading mb-0 opacity-5">Sales Income</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div
                    class="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-warning border-warning">
                    <div class="widget-chat-wrapper-outer">
                        <div class="widget-chart-content pt-3 pl-3 pb-1">
                            <div class="widget-chart-flex">
                                <div class="widget-numbers">
                                    <div class="widget-chart-flex">
                                        <div class="fsize-4"><small class="opacity-5">$</small><span>874</span></div>
                                    </div>
                                </div>
                            </div>
                            <h6 class="widget-subheading mb-0 opacity-5">sales last month</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- <div class="card mb-3">
    <div class="no-gutters row">
        <div class="col-md-12 col-lg-4">
            <ul class="list-group list-group-flush">
                <li class="bg-transparent list-group-item">
                    <div class="widget-content p-0">
                        <div class="widget-content-outer">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left">
                                    <div class="widget-heading">Total Orders</div>
                                    <div class="widget-subheading">Last year expenses</div>
                                </div>
                                <div class="widget-content-right">
                                    <div class="widget-numbers text-success">1896</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="bg-transparent list-group-item">
                    <div class="widget-content p-0">
                        <div class="widget-content-outer">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left">
                                    <div class="widget-heading">Clients</div>
                                    <div class="widget-subheading">Total Clients Profit</div>
                                </div>
                                <div class="widget-content-right">
                                    <div class="widget-numbers text-primary">$12.6k</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="col-md-12 col-lg-4">
            <ul class="list-group list-group-flush">
                <li class="bg-transparent list-group-item">
                    <div class="widget-content p-0">
                        <div class="widget-content-outer">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left">
                                    <div class="widget-heading">Followers</div>
                                    <div class="widget-subheading">People Interested</div>
                                </div>
                                <div class="widget-content-right">
                                    <div class="widget-numbers text-danger">45,9%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="bg-transparent list-group-item">
                    <div class="widget-content p-0">
                        <div class="widget-content-outer">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left">
                                    <div class="widget-heading">Products Sold</div>
                                    <div class="widget-subheading">Total revenue streams</div>
                                </div>
                                <div class="widget-content-right">
                                    <div class="widget-numbers text-warning">$3M</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="col-md-12 col-lg-4">
            <ul class="list-group list-group-flush">
                <li class="bg-transparent list-group-item">
                    <div class="widget-content p-0">
                        <div class="widget-content-outer">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left">
                                    <div class="widget-heading">Total Orders</div>
                                    <div class="widget-subheading">Last year expenses</div>
                                </div>
                                <div class="widget-content-right">
                                    <div class="widget-numbers text-success">1896</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="bg-transparent list-group-item">
                    <div class="widget-content p-0">
                        <div class="widget-content-outer">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left">
                                    <div class="widget-heading">Clients</div>
                                    <div class="widget-subheading">Total Clients Profit</div>
                                </div>
                                <div class="widget-content-right">
                                    <div class="widget-numbers text-primary">$12.6k</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div> -->