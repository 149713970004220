import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { RolesService } from 'src/app/Services/roles.service';
import { MainService } from 'src/app/Services/main.service';
import { AlertService } from 'src/app/Services/alert.service';
import { IFileLibraryData } from 'src/app/Models/IFileLibraryData';
import { FILE_LIBRARY_PATH_KEY } from 'src/app/Constants/request-path-keys';
import { SUCCESS_KEY, TR_DELETE_MESSAGE_KEY, TR_URL_COPIED } from 'src/app/Constants/main-keys';
import { guid } from '@progress/kendo-angular-common';

@Component({
  selector: 'app-file-library-table',
  templateUrl: './file-library-table.component.html',
  styleUrls: ['./file-library-table.component.scss']
})
export class FileLibraryTableComponent implements OnInit {
  files: Array<IFileLibraryData> = [];
  userRoles: Array<string> = [];
  deleteFileId: number;
  deleteFileName: string;
  deleteSpinnerId: string = guid();

  constructor(
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private mainService: MainService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.getFileLibrary();
  }

  getFileLibrary() {
    this.dataHandlerService.getDataWithSpinner(FILE_LIBRARY_PATH_KEY).then(response => {
      this.files = response;
    });
  }

  confirmDelete(content, id: number, name: string): void {
    this.modalService.open(content).result;
    this.deleteFileId = id;
    this.deleteFileName = name;
  }

  copyLink(uri: string): void {
    if (!uri) { return; }
    navigator.clipboard.writeText(uri);
    this.alertService.translateAndAlertMessage(TR_URL_COPIED, SUCCESS_KEY);
  }

  deleteFile(): void {
    if (!this.deleteFileId) { return; }
    this.dataHandlerService.deleteData(this.deleteFileId, FILE_LIBRARY_PATH_KEY, this.deleteSpinnerId).then(() => {
      this.alertService.translateAndAlertMessage(TR_DELETE_MESSAGE_KEY, SUCCESS_KEY);
      this.deleteFileId = null;
      this.deleteFileName = null;
      const deleteFileIndex = this.files.findIndex(data => data.id === this.deleteFileId);
      deleteFileIndex !== -1 && this.files.splice(deleteFileIndex, 1);
      this.modalService.dismissAll();
    });
  }

}
