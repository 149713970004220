import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { AlertService } from 'src/app/Services/alert.service';
import { IPageData } from 'src/app/Models/IPageData';
import { IUserTypeData } from 'src/app/Models/IUserData';
import { IOrganizationData } from 'src/app/Models/IOrganizationData';
import {
  ORGANIZATION_VIEW,
  PAGE_ROLE_VIEW,
  PAGE_USERTYPE_CREATE,
  PAGE_VIEW,
  SUCCESS_KEY,
  TR_DELETE_MESSAGE_KEY,
  TR_USERTYPES_KEY,
  USERTYPE_CREATE,
  USERTYPE_DELETE,
  USERTYPE_UPDATE,
  USERTYPE_VIEW,
  DEFAULT_USERTYPE_CREATE,
  DEFAULT_USERTYPE_UPDATE,
  DEFAULT_USERTYPE_DELETE,
  DEFAULT_USERTYPE_VIEW,
} from 'src/app/Constants/main-keys';
import {
  IS_FRONT_END_PAGE_PATH_PARAM_KEY,
  ORGANIZATIONS_PATH_KEY,
  ORG_ID_PATH_PARAM_KEY,
  PAGE_USER_TYPES_GET_PATH_KEY,
  PAGE_USER_TYPES_PATH_KEY,
  USER_TYPE_ID_PATH_PARAM_KEY,
  USER_TYPE_PATH_KEY
} from 'src/app/Constants/request-path-keys';
import { guid } from '@progress/kendo-angular-common';

@Component({
  selector: 'app-user-types-table',
  templateUrl: './user-types-table.component.html',
  styleUrls: ['./user-types-table.component.scss']
})
export class UserTypesTableComponent implements OnInit {
  userTypeId: number;
  selectedUserTypeId: number;
  deleteUserTypeId: number;
  deleteUserTypeName: string;
  isDefaultUserTypeSelected: boolean;
  selectedPageId: number;
  isDisablePage: boolean;
  pages: Array<IPageData> = [];
  userTypes: Array<IUserTypeData> = [];
  organizations: Array<IOrganizationData> = [];
  userRoles: Array<string> = [];
  usertype_create: boolean;
  usertype_update: boolean;
  organization_view: boolean;
  page_view: boolean;
  usertype_view: boolean;
  usertype_delete: boolean;
  role_view: boolean;
  page_usertype_create: boolean;
  selectedOrganizationName: string;
  userTypeDeleteSpinnerId: string = guid();
  default_usertype_create: boolean;
  default_usertype_update: boolean;
  default_usertype_delete: boolean;
  default_usertype_view: boolean;

  constructor(
    private dataHandlerService: DataHandlerService,
    private translationService: TranslationService,
    private rolesService: RolesService,
    private modalService: NgbModal,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_USERTYPES_KEY);

    this.getOrganizations();
    this.getAllUsertypes(true);
  }

  setRoles() {
    this.userRoles.forEach(role => {
      switch (role) {
        case USERTYPE_VIEW:
          this.usertype_view = true;
          break;
        case USERTYPE_CREATE:
          this.usertype_create = true;
          break;
        case USERTYPE_UPDATE:
          this.usertype_update = true;
          break;
        case USERTYPE_DELETE:
          this.usertype_delete = true;
          break;
        case ORGANIZATION_VIEW:
          this.organization_view = true;
          break;
        case PAGE_VIEW:
          this.page_view = true;
          break;
        case PAGE_ROLE_VIEW:
          this.role_view = true;
          break;
        case PAGE_USERTYPE_CREATE:
          this.page_usertype_create = true;
          break;

        case DEFAULT_USERTYPE_CREATE:
          this.default_usertype_create = true;
          break;
        case DEFAULT_USERTYPE_UPDATE:
          this.default_usertype_update = true;
          break;
        case DEFAULT_USERTYPE_DELETE:
          this.default_usertype_delete = true;
          break;
        case DEFAULT_USERTYPE_VIEW:
          this.default_usertype_view = true;
          break;
      }
    });
  }

  async getOrganizations() {
    if (!this.organization_view) { return; }
    this.organizations = await this.dataHandlerService.getData(ORGANIZATIONS_PATH_KEY);
  }

  getAllUsertypes(hasSpinner: boolean): void {
    if (!this.usertype_view) { return; }
    (hasSpinner
      ? this.dataHandlerService.getDataWithSpinner(USER_TYPE_PATH_KEY)
      : this.dataHandlerService.getData(USER_TYPE_PATH_KEY)).then(response => {
        this.userTypes = response;
        this.selectedOrganizationName = '';
        this.userTypes.forEach(userType => {
          this.translationService.translateUserTypes(userType.displayName).subscribe(translatedUserTypeName => {
            if (translatedUserTypeName) {
              userType.displayName = translatedUserTypeName;
            }
          });
        });
      });
  }

  getOrganizationUserTypes(organization: IOrganizationData): void {
    if (!this.usertype_view) { return; }
    this.dataHandlerService.getData(`${USER_TYPE_PATH_KEY}?${ORG_ID_PATH_PARAM_KEY}${organization.id}`).then(response => {
      this.userTypes = response;
      this.selectedOrganizationName = organization.name;
      this.userTypes.forEach(userType => {
        this.translationService.translateUserTypes(userType.displayName).subscribe(translatedUserTypeName => {
          if (translatedUserTypeName) {
            userType.displayName = translatedUserTypeName;
          }
        });
      });
    });
  }

  pagesView(target: HTMLElement, data: IUserTypeData): void {
    if (!this.page_view || target.nodeName !== 'TD') { return; }
    this.userTypeId = null;
    this.selectedPageId = null;
    this.selectedUserTypeId = data.id;
    this.isDefaultUserTypeSelected = data.isDefault;

    if (this.page_view) {
      this.dataHandlerService.getData(
        `${PAGE_USER_TYPES_GET_PATH_KEY}?${USER_TYPE_ID_PATH_PARAM_KEY}${this.selectedUserTypeId}&${IS_FRONT_END_PAGE_PATH_PARAM_KEY}${false}`
      ).then(response => {
        this.pages = response.pages;
        this.isDisablePage = response.disable;
        this.pages.forEach(page => {
          this.translationService.translatePages(page.name).subscribe(translatedPageName => {
            if (translatedPageName) {
              page.name = translatedPageName;
            }
            if (page.children.length) {
              page.children.forEach(children => {
                if (children.name) {
                  this.translationService.translatePages(children.name).subscribe(translatedChildrenPageName => {
                    if (translatedChildrenPageName) {
                      children.name = translatedChildrenPageName;
                    }
                  });
                }
              });
            }
          });
        });
      });
    }
  }

  changePageValue(val: boolean, pageId: number): void {
    const body = {
      create: val,
      usertypeId: this.selectedUserTypeId,
      pageId: pageId
    };

    this.dataHandlerService.createData(body, PAGE_USER_TYPES_PATH_KEY, null);
  }

  rolesView(content, target: HTMLElement, data: IPageData): void {
    if (data.id && this.role_view && data.isDefault && target.nodeName === 'TD' && !this.modalService.hasOpenModals()) {
      this.selectedPageId = data.id;
      this.userTypeId = this.selectedUserTypeId;
      this.modalService.open(content, { size: 'lg' }).result;
    }
  }

  confirmDelete(content: any, id: number, name: string): void {
    this.modalService.open(content).result;
    this.deleteUserTypeId = id;
    this.deleteUserTypeName = name;
  }

  delete(): void {
    if (!this.deleteUserTypeId || !this.usertype_delete) { return; }
    this.dataHandlerService.deleteData(this.deleteUserTypeId, USER_TYPE_PATH_KEY, this.userTypeDeleteSpinnerId).then(() => {
      this.alertService.translateAndAlertMessage(TR_DELETE_MESSAGE_KEY, SUCCESS_KEY);
      const deleteUserTypeIndex = this.userTypes.findIndex(data => data.id === this.deleteUserTypeId);
      deleteUserTypeIndex !== -1 && this.userTypes.splice(deleteUserTypeIndex, 1);
      this.deleteUserTypeId = null;
      this.deleteUserTypeName = '';
      this.modalService.dismissAll();
    });
  }

}
