<div class="title-block mb-3">
  <h4 class="card-title">{{ 'TR_LANGUAGES' | translate }}</h4>
</div>

<div *ngIf="language_create" class="d-inline-block">
  <button class="mb-2 mr-2 btn btn-success create-btn" [title]="'TR_CREATE_LANGUAGE' | translate"
    [routerLink]="['language-form']">
    <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_CREATE' | translate }}</span>
  </button>
</div>

<div class="row">
  <div class="col-md-6 col-sm-8 col-12">
    <div class="main-card mb-3 card">
      <div class="table-responsive">
        <table class="align-middle mb-0 table table-striped table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ 'TR_NAME' | translate }}</th>
              <th>{{ 'TR_ISO_CODE' | translate }}</th>
              <th>{{ 'TR_ISO_CODE_TWO' | translate }}</th>
              <th *ngIf="language_update" class="text-right">{{ 'TR_ACTIONS' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of languages let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.isocode }}</td>
              <td>{{ item.isocode2 }}</td>
              <td *ngIf="language_update" class="text-right">
                <div role="group" class="btn-group-sm btn-group">
                  <button class="btn-transition btn btn-outline-info" [title]="'TR_UPDATE_LANGUAGE' | translate"
                    [routerLink]="['language-form', item.languageId]">
                    <i class="pe-7s-note"> </i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>