import { Component, OnInit } from '@angular/core';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'page-main-info-component',
  templateUrl: './page-main-info.component.html',
  styleUrls: ['./page-main-info.component.scss'],
})

export class PageMainInComponent implements OnInit {

  pageInfoData = null;
  closeResult = '';

  constructor(
    private dataHandlerService: DataHandlerService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
  ) {

    this.route.url.subscribe(() => {
      setTimeout(() => {
        this.getLibraryDataByPageName();
      });
    });
  }

  ngOnInit() {
    // setTimeout(() => {
    //   this.getLibraryDataByPageName()
    // });
  }

  getLibraryDataByPageName(): void {
    let main_info = document.getElementById("main_info");
    if (main_info && main_info.getAttribute("data-page-name")) {
      this.dataHandlerService.getDataWithSpinner(`librarydata/page_helps?pageName=${main_info.getAttribute("data-page-name")}`).then(response => {
        const data = [...response];
        if (data && data.length && data.find(item => item.name === "main_info")) {
          this.pageInfoData = data.find(item => item.name === "main_info")
        }
      });
    } else {
      this.pageInfoData = "";
    }
  }

  open(content: any) {
    this.modalService.open(content).result;
  }

}
