<div class="title-block mb-3">
  <h4 class="card-title">{{ 'TR_FILE_LIBRARY' | translate }}</h4>
</div>

<div class="wrap-flex">
  <button class="mb-2 mr-2 btn btn-success create-btn" [title]="'TR_CREATE_FILE' | translate" [routerLink]="['filelibrary-form']">
    <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_CREATE' | translate }}</span>
  </button>
</div>

<div class="row">
  <div class="col-lg-6 col-md-8 col-12">
    <div class="main-card mb-3 card">
      <div class="table-responsive">
        <table class="align-middle mb-0 table table-striped table-hover">
          <thead>
            <tr>
              <th>{{ 'TR_NAME' | translate }}</th>
              <th class="text-right">{{ 'TR_ACTIONS' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of files">
              <td>{{ item.name }}</td>
              <td class="text-right">
                <div role="group" class="btn-group-sm btn-group">
                  <!-- <div *ngIf="!item.organizationId">
                    <button type="button" class="mt-2 btn btn-lg btn-primary" (click)="copyLink(form.value.fileEntity)">{{ 'TR_COPY_LINK' | translate }}</button>
                  </div> -->
                  <button class="ml-1 btn-transition btn btn-outline-success" [title]="'TR_COPY_LINK' | translate" (click)="copyLink(item.filePath)">
                    <i class="pe-7s-copy-file"></i>
                  </button>
                  <button class="ml-1 btn-transition btn btn-outline-info" [title]="'TR_UPDATE_FILE' | translate" [routerLink]="['filelibrary-form', item.id]">
                    <i class="pe-7s-note"></i>
                  </button>
                  <button class="ml-1 btn-transition btn btn-outline-primary" [title]="'TR_DELETE' | translate" (click)="confirmDelete(delete, item.id, item.name)">
                    <i class="pe-7s-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #delete let-c="close" let-d="dismiss">
  <div class="custom-modal modal-body">
    <p class="word-break-break-word">{{ 'TR_DELETE_ERROR' | translate }} {{ deleteFileName }} ?</p>
  </div>
  <div class="custom-modal modal-footer">
    <app-action-button
      [isCurrent]="true"
      [spinnerId]="deleteSpinnerId"
      buttonClasses="btn-transition btn btn-outline-primary"
      spinnerClasses="small-button-spinner"
      [buttonName]="'TR_YES'"
      (clicked)="deleteFile()"
    ></app-action-button>
    <button type="button" class="btn-transition btn btn-danger" (click)="c('Close click')">{{ 'TR_NO' | translate }}</button>
  </div>
</ng-template>
