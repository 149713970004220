import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MENU_KEY, TOKEN_KEY, USER_KEY } from 'src/app/Constants/main-keys';
import { ILoginData, IUserData } from 'src/app/Models/IUserData';
import { AlertService } from 'src/app/Services/alert.service';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { LanguageService } from 'src/app/Services/language.service';
import { MainService } from 'src/app/Services/main.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { guid } from '@progress/kendo-angular-common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataStorageService } from 'src/app/Services/data-storage.service';
import { PAGE_STRUCT_PATH_KEY, USER_CURRENT_PATH_KEY } from 'src/app/Constants/request-path-keys';
import { AuthService } from 'src/app/Services/auth.service';


@Component({
  selector: 'authentication.component',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})

export class AuthenticationComponent implements OnInit {
  user: IUserData = JSON.parse(localStorage.getItem(USER_KEY));
  submitSpinnerId: string = guid();
  errorMessage: string;
  tokenData: any = null;

  constructor(
    private dataStorageService: DataStorageService,
    private formBuilder: FormBuilder,
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    private translationService: TranslationService,
    private alertService: AlertService,
    private authService: AuthService,
    public mainService: MainService,
    private route: ActivatedRoute,
    private router: Router,
    private languageService: LanguageService,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      let refreshToken = params.get('refreshToken')
      if (!refreshToken) {
        return;
      }
      if (this.user) {
        localStorage.clear();
        window.location.reload();
        this.router.navigate([this.languageService.currentLanguage, "authentication", refreshToken]);
      }
      this.authenticateUser(refreshToken)
    });

  }

  authenticateUser(refreshToken: string): void {
    if (!refreshToken) return;

    this.authService.signInUserByRefreshToken(refreshToken).subscribe(data => {
      if (data) {
        this.dataHandlerService.getData(USER_CURRENT_PATH_KEY).then(response => {
          if (response) {
            const data = { ...response };
            if (data.isAdmin && !data.isOrganizationActive){
              let token = this.dataStorageService.getToken();
              let refreshTokenLink = `https://${window.location.hostname.replace("admin.", "")}/${this.languageService.currentLanguage}/authentication/${token.refreshToken}`;
              window.location.href = refreshTokenLink;
            }else{
              this.dataStorageService.setUser(response);
              this.dataHandlerService.getData(`${PAGE_STRUCT_PATH_KEY}${false}`).then(response => {
                const menuData = response;
                localStorage.setItem(MENU_KEY, JSON.stringify(menuData));
                this.router.navigate(['/']);
              });
            }
          } else {
            window.history.back();
            this.authService.logout();
          }
        }).catch((e) => {
          window.history.back();
          localStorage.removeItem(TOKEN_KEY);
          localStorage.removeItem(USER_KEY);
          this.errorMessage = e;
          this.authService.logout();
        });
      }
    });

  }

  setToken(userData: any): void {
    this.dataStorageService.setToken(userData);
  }

}
