import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { guid } from '@progress/kendo-angular-common';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { RolesService } from 'src/app/Services/roles.service';
import { IRoleData } from 'src/app/Models/IUserData';
import { PAGE_ROLE_CREATE, PAGE_ROLE_VIEW, USERTYPE_ROLE_CREATE } from 'src/app/Constants/main-keys';
import { PAGE_ID_PATH_PARAM_KEY, PAGE_ROLE_PATH_KEY, USER_TYPE_ID_PATH_PARAM_KEY, USER_TYPE_ROLE_PATH_KEY } from 'src/app/Constants/request-path-keys';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {
  val: number = 1;
  pageRolesData: Array<IRoleData> = [];
  userTypeRolesData: Array<IRoleData> = [];
  userRoles: Array<string> = [];
  pagerole_view: boolean;
  usertype_role_create: boolean;
  page_role_create: boolean;
  pageRolesSpinnerId: string = guid();
  userTypeRolesSpinnerId: string = guid();

  @Input() userTypeId: number;
  @Input() pageId: number;
  @Input() isDefaultUserTypeSelected: boolean;

  @Output() closeEmit = new EventEmitter();

  constructor(
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
  ) {
  }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();

    this.getPageRoles();
    this.getUserTypeRoles();
  }

  setRoles() {
    this.userRoles.forEach(role => {
      switch (role) {
        case PAGE_ROLE_VIEW:
          this.pagerole_view = true;
          break;
        case USERTYPE_ROLE_CREATE:
          this.usertype_role_create = true;
          break;
        case PAGE_ROLE_CREATE:
          this.page_role_create = true;
          break;
      }
    });
  }

  async getPageRoles() {
    if (!this.pageId || !this.page_role_create) { return; }
    this.dataHandlerService.getDataWithModalSpinner(`${PAGE_ROLE_PATH_KEY}?${PAGE_ID_PATH_PARAM_KEY}${this.pageId}`, this.pageRolesSpinnerId).then(response => {
      this.pageRolesData = response;
    });
  }

  async getUserTypeRoles() {
    if (!this.userTypeId || !this.pagerole_view) { return; }
    this.dataHandlerService.getDataWithModalSpinner(
      `${PAGE_ROLE_PATH_KEY}?${PAGE_ID_PATH_PARAM_KEY}${this.pageId}&${USER_TYPE_ID_PATH_PARAM_KEY}${this.userTypeId}`,
      this.userTypeRolesSpinnerId
    ).then(response => {
      this.userTypeRolesData = response;
    });
  }

  changeUserTypeRoleValue(val: boolean, roleid: number): void {
    const body = {
      create: val,
      usertypeId: this.userTypeId,
      roleId: roleid
    };

    if (!this.usertype_role_create) { return; }
    this.dataHandlerService.createData(body, USER_TYPE_ROLE_PATH_KEY, null);
  }

  changePageRoleValue(val: boolean, roleId: number): void {
    if (!this.page_role_create) { return; }
    const body = {
      create: val,
      pageId: this.pageId,
      roleId: roleId
    };

    this.dataHandlerService.createData(body, PAGE_ROLE_PATH_KEY, null).then(() => this.getUserTypeRoles());
  }
}
