<div class="title-block mb-3">
  <h4 class="card-title">{{ 'TR_PAGE_CONTENT' | translate }}</h4>
</div>

<div *ngIf="content_create" class="d-inline-block">
  <button class="mb-2 mr-2 btn btn-success create-btn" [title]="'TR_CREATE_PAGE_CONTENT' | translate"
    [routerLink]="['page-content-form']">
    <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_CREATE' | translate }}</span>
  </button>
</div>

<div class="main-card mb-3 card">
  <ngb-tabset *ngIf="content_view">
    <ngb-tab [title]="language.name" *ngFor="let language of languages;index as i">
      <ng-template ngbTabContent>
        <div class="table-responsive">
          <table class="align-middle mb-0 table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>{{ 'TR_NAME' | translate }}</th>
                <th>{{ 'TR_CATEGORY' | translate }}</th>
                <th>{{ 'TR_SHORT_CONTENT' | translate }}</th>
                <th>{{ 'TR_FULL_CONTENT' | translate }}</th>
                <th>{{ 'TR_SHOW_IN_SLIDER' | translate }}</th>
                <th class="text-center">{{ 'TR_ACTIONS' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of language.content let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.pageCategoryName }}</td>
                <td>{{ item.shortContent }}</td>
                <td>{{ item.fullContent }}</td>
                <td class="text-center">
                  <input disabled [name]="item.name" type="checkbox" class="mt-2 pointer"
                    [checked]="item.showInSlider" />
                </td>
                <td class="level-table-btns text-center">
                  <div role="group" class="btn-group-sm btn-group">
                    <button *ngIf="content_update" class="btn-transition btn btn-outline-info"
                      [title]="'TR_UPDATE_PAGE_CONTENT' | translate"
                      [routerLink]="[ 'page-content-form', language.languageId, item.id ]">
                      <i class="pe-7s-note"></i>
                    </button>
                    <button *ngIf="content_delete" class="ml-1 btn-transition btn btn-outline-primary"
                      [title]="'TR_DELETE' | translate" (click)="confirmDelete(deleteconfirm, item.id, item.name)">
                      <i class="pe-7s-trash"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
</div>

<ng-template #deleteconfirm let-c="close" let-d="dismiss">
  <div class="custom-modal modal-body">
    <p>{{ 'TR_DELETE_ERROR' | translate }} <b class="word-break-break-word">{{ deletePageContentName }}</b>?</p>
  </div>
  <div class="custom-modal modal-footer">
    <app-action-button [isCurrent]="true" [spinnerId]="deleteSpinnerId"
      buttonClasses="btn-transition btn btn-outline-primary" spinnerClasses="small-button-spinner"
      [buttonName]="'TR_YES'" (clicked)="delete()"></app-action-button>
    <button type="button" class="btn-transition btn btn-danger"
      (click)="c('Close click')">{{ 'TR_NO' | translate }}</button>
  </div>
</ng-template>