<div class="title-block mb-3">
  <h4 class="card-title">{{ 'TR_DOCUMENT_CATEGORY' | translate }}</h4>
</div>

<div class="row">
  <div class="col-md-8 col-12">
    <div *ngIf="documentcategory_view" class="main-card mb-3 card">
      <div class="table-responsive">
        <table class="align-middle mb-0 table table-striped table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ 'TR_NAME' | translate }}</th>
              <th>{{ 'TR_DESCRIPTION' | translate }}</th>
              <th class="text-right">{{ 'TR_ACTIONS' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="documentcategory_update && documentcategory_create">
              <td></td>
              <td>
                <input name="name" type="text" class="form-control"
                  [class.error-border]="isInvalidSubmit && !categoryModel.name" autocomplete="off"
                  [placeholder]="('TR_NAME' | translate) + mainService.asterisk" [(ngModel)]="categoryModel.name" />
              </td>
              <td>
                <input name="description" type="text" class="form-control" autocomplete="off"
                  [placeholder]="'TR_DESCRIPTION' | translate" [(ngModel)]="categoryModel.description" />
              </td>
              <td class="level-table-btns text-right">
                <div role="group" class="btn-group-sm btn-group">
                  <app-save-button [spinnerId]="saveSpinnerId" [isCurrent]="true"
                    buttonClasses="mb-1 mr-1 btn-transition btn btn-outline-primary right-border-0 group-btn-padding"
                    (submit)="submitData()"></app-save-button>
                  <button class="mb-1 btn-transition btn btn-outline-danger" [title]="'TR_CANCEL' | translate"
                    (click)="cancel()">
                    <i class="pe-7s-close-circle"></i>
                  </button>
                </div>
              </td>
            </tr>
            <tr *ngFor="let item of documentCategories let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td class="level-table-btns text-right">
                <div role="group" class="btn-group-sm btn-group">
                  <button *ngIf="documentcategory_update" class="btn-transition btn btn-outline-info"
                    [title]="'TR_UPDATE' | translate" (click)="update(item)">
                    <i class="pe-7s-note"></i>
                  </button>
                  <button *ngIf="documentcategory_delete" class="ml-1 btn-transition btn btn-outline-danger"
                    [title]="'TR_DELETE' | translate" (click)="confirmDelete(deleteconfirm, item)">
                    <i class="pe-7s-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #deleteconfirm let-c="close" let-d="dismiss">
  <div class="custom-modal modal-body">
    <p>{{ 'TR_DELETE_ERROR' | translate }} <b class="word-break-break-word">{{ deleteDocumentCategoryName }}</b>?</p>
  </div>
  <div class="custom-modal modal-footer">
    <app-action-button [isCurrent]="true" [spinnerId]="deleteSpinnerId"
      buttonClasses="btn-transition btn btn-outline-primary" spinnerClasses="small-button-spinner"
      [buttonName]="'TR_YES'" (clicked)="delete()"></app-action-button>
    <button type="button" class="btn-transition btn btn-danger"
      (click)="c('Close click')">{{ 'TR_NO' | translate }}</button>
  </div>
</ng-template>