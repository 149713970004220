// import { Component, ElementRef, OnInit } from '@angular/core';
// import { delay, startWith, tap } from 'rxjs/operators';
// import { IHelpResponseData } from 'src/app/Models/IHelpAlertData';
// import { MainService } from 'src/app/Services/main.service';

// @Component({
//   selector: 'app-help-alert-view',
//   templateUrl: './help-alert-view.component.html',
//   styleUrls: ['./help-alert-view.component.scss']
// })
// export class HelpAlertViewComponent implements OnInit {
//   data: IHelpResponseData;
//   containerPosition: any;
//   alertBlockClassList: string;
//   alertPositions: object = {
//     top: null,
//     left: null,
//     right: null,
//     bottom: null
//   };
//   helpAlertLength: number;

//   constructor(
//     private elRef: ElementRef,
//     private mainService: MainService
//   ) { }

//   ngOnInit(): void {
//     this.mainService.helpData.pipe(
//       startWith(null),
//       delay(0),
//       tap(helpAlerts => this.helpAlertLength = helpAlerts?.length)
//     ).subscribe();

//     setTimeout(() => {
//       const alertComponentWidth = this.elRef.nativeElement.firstChild.offsetWidth;
//       const alertComponentHeight = this.elRef.nativeElement.firstChild.offsetHeight;
//       if (alertComponentWidth + this.containerPosition.offsetLeft > document.body.offsetWidth) {
//         // LEFT ALERT
//         this.alertPositions = {
//           top: `0px`,
//           right: `${this.containerPosition.offsetWidth + 5}px`,
//         }
//         this.alertBlockClassList = 'right';
//       }
//       else if (this.containerPosition.offsetTop - alertComponentHeight < 0) {
//         // BOTTOM ALERT
//         this.alertPositions = {
//           top: `${this.containerPosition.offsetHeight + 5}px`,
//           left: '0px'
//         };
//         this.alertBlockClassList = 'top';
//       } else {
//         // TOP ALERT
//         this.alertPositions = {
//           bottom: `calc(100% + 10px)`,
//           left: '0px'
//         };
//         this.alertBlockClassList = 'bottom';
//       }
//     });
//   }

//   next(event): void {
//     event.stopPropagation();
//     const content = this.mainService.getHelpData()
//       .filter(data => data.selector !== this.data.selector && data.displayOrder > this.data.displayOrder)
//       .map(data => ({ ...data, timeout: null }));
//     this.mainService.setHelpData(null);
//     content.sort((first, second) => first.displayOrder - second.displayOrder);
//     content.forEach((item, index) => {
//       if (index === 0) {
//         item.startTime = 0;
//       }
//       else {
//         item.startTime = content[index - 1].startTime + content[index - 1].seconds;
//       }
//     });
//     this.mainService.setHelpData(content);
//   }

//   close(event): void {
//     event.stopPropagation();
//     this.mainService.setHelpData(null);
//   }

// }

import { Component, ElementRef, OnInit } from '@angular/core';
import { delay, startWith, tap } from 'rxjs/operators';
import { IHelpResponseData } from 'src/app/Models/IHelpAlertData';
import { MainService } from 'src/app/Services/main.service';

@Component({
  selector: 'app-help-alert-view',
  templateUrl: './help-alert-view.component.html',
  styleUrls: ['./help-alert-view.component.scss']
})
export class HelpAlertViewComponent implements OnInit {
  data: IHelpResponseData;
  containerPosition: any;
  alertBlockClassList: string;
  alertPositions: object = {
    top: null,
    left: null,
    right: null,
    bottom: null
  };
  helpAlertLength: number;

  constructor(
    private elRef: ElementRef,
    private mainService: MainService
  ) {  }

  ngOnInit(): void {
    this.mainService.helpData.pipe(
      startWith(null),
      delay(0),
      tap(helpAlerts => this.helpAlertLength = helpAlerts?.length)
    ).subscribe();

    setTimeout(() => {
      const alertComponentWidth = this.elRef.nativeElement.firstChild.offsetWidth;
      const alertComponentHeight = this.elRef.nativeElement.firstChild.offsetHeight;
      if (alertComponentWidth + this.containerPosition.offsetLeft > document.body.offsetWidth) {
        // LEFT ALERT
        this.alertPositions = {
          top: `0px`,
          right: `${this.containerPosition.offsetWidth + 5}px`,
        }
        this.alertBlockClassList = 'right';
      }
      else if (this.containerPosition.offsetTop - alertComponentHeight < 0) {
        // BOTTOM ALERT
        this.alertPositions = {
          top: `${this.containerPosition.offsetHeight + 5}px`,
          left: '0px'
        };
        this.alertBlockClassList = 'top';
      } else {
        // TOP ALERT
        this.alertPositions = {
          bottom: `calc(100% + 10px)`,
          left: '0px'
        };
        this.alertBlockClassList = 'bottom';
      }
    });
  }

  next(event: any): void {
    event.stopPropagation();
    const content = this.mainService.getHelpData()
      // .filter(data => data.name !== this.data.name && data.displayOrder > this.data.displayOrder)
      .filter(data => data.name !== this.data.name)
      .map(data => ({ ...data, timeout: null }));
    this.mainService.setHelpData(null);
    // content.sort((first, second) => first.displayOrder - second.displayOrder);
    content.forEach((item, index) => {
      if (index === 0) {
        item.startTime = 0;
      }
      else {
        item.startTime = content[index - 1].startTime + content[index - 1].seconds;
      }
    });
    this.mainService.setHelpData(content);
  }

  close(event: any): void {
    event.stopPropagation();
    this.mainService.setHelpData(null);
  }

  clickParentComponent(event: any): void{
    event.stopPropagation();
  }

}
