<ng-container *ngIf="groupId">
  <div class="modal-header modal-list-header">
    <h5 class="modal-title" style="word-break: break-all;">{{ groupStudents[0] && groupStudents[0].groupName }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="closeEmit.emit(null)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-list p-0">
    <app-modal-loader [modalSpinners]="[modalSpinnerId]"></app-modal-loader>
    <div class="p-3">
      <div class="list-row">
        <label for="getActiveStudents" class="mr-2 pointer font-500">{{ 'TR_GET_ACTIVE_STUDENTS' | translate }}</label>
        <input
          id="getActiveStudents"
          name="getActiveStudents"
          type="checkbox"
          class="mt-2 pointer float-right"
          [(ngModel)]="getActiveStudents"
          (ngModelChange)="getStudentsByGroupId(!getActiveStudents)"
        />
      </div>
      <div class="list-row" *ngFor="let student of groupStudents">
        <label [htmlFor]="student.username" class="mr-2 pointer">{{ student.studentName }}</label>
        <input
          [id]="student.username"
          [name]="student.studentName"
          type="checkbox"
          [disabled]="!group_student_create"
          class="mt-2 pointer float-right"
          [checked]="student.isBelongToCurrentGroup"
          (click)="changeCheckboxValue($event, student.isBelongToCurrentGroup, student.id, student.studentName, removeModal)"
        />
      </div>
    </div>
  </div>
</ng-container>

<ng-template #removeModal let-c="close" let-d="dismiss">
  <div class="custom-modal modal-body">
    <p>{{ 'TR_REMOVE_QUESTION' | translate }} <b class="word-break-break-word">{{ removeStudentName }}</b> {{ 'TR_FROM' | translate }} {{ groupName }} ?</p>
  </div>
  <div class="custom-modal modal-footer">
    <button type="button" class="mr-2 btn-transition btn btn-outline-primary" (click)="triggerGroupStudent(false, removeStudentId); c('Close click')">{{ 'TR_YES' | translate }}</button>
    <button type="button" class="btn-transition btn btn-danger" (click)="c('Close click')">{{ 'TR_NO' | translate }}</button>
  </div>
</ng-template>