import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { guid } from '@progress/kendo-angular-common';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { MainService } from 'src/app/Services/main.service';
import { AlertService } from 'src/app/Services/alert.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { LANGUAGES_PATH_KEY, LANGUAGE_URL_KEY } from 'src/app/Constants/request-path-keys';
import {
  LANGUAGE_CREATE,
  LANGUAGE_UPDATE,
  LANGUAGE_VIEW,
  SUCCESS_KEY,
  TR_CREATE_LANGUAGE_KEY,
  TR_CREATE_MESSAGE_KEY,
  TR_UPDATE_LANGUAGE_KEY,
  TR_UPDATE_MESSAGE_KEY
} from 'src/app/Constants/main-keys';

@Component({
  selector: 'app-language-form',
  templateUrl: './language-form.component.html',
  styleUrls: ['./language-form.component.scss']
})
export class LanguageFormComponent implements OnInit {
  languageId: number;
  form: FormGroup;
  userRoles: Array<string>;
  language_create: boolean;
  language_update: boolean;
  language_view: boolean;
  isInvalidSubmit: boolean;
  isCreateForm: boolean = true;
  submitSpinnerId: string = guid();

  constructor(
    private formBuilder: FormBuilder,
    private dataHandlerService: DataHandlerService,
    private route: ActivatedRoute,
    private rolesService: RolesService,
    public mainService: MainService,
    private translationService: TranslationService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.setRoles();
    this.translationService.setPageTitle(TR_CREATE_LANGUAGE_KEY);

    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      isocode: ['', Validators.required],
      isocode2: ['', Validators.required],
    });

    this.route.paramMap.subscribe(params => {
      this.languageId = +params.get('item.id');
      if (this.languageId) {
        this.isCreateForm = false;
        this.translationService.setPageTitle(TR_UPDATE_LANGUAGE_KEY);
        this.getLanguageById();
      }
    });
  }

  setRoles(): void {
    this.userRoles.forEach(role => {
      switch (role) {
        case LANGUAGE_UPDATE:
          this.language_update = true;
          break;
        case LANGUAGE_CREATE:
          this.language_create = true;
          break;
        case LANGUAGE_VIEW:
          this.language_view = true;
          break;
      }
    })
  }

  getLanguageById(): void {
    if (!this.language_view) { return; }
    this.dataHandlerService.getDataWithSpinner(`${LANGUAGES_PATH_KEY}/${this.languageId}`).then(response => {
      const languageData = response;
      this.form.patchValue({
        name: languageData.name,
        isocode: languageData.isocode,
        isocode2: languageData.isocode2
      });
    });
  }

  submitData(): void {
    if (this.form.valid) {
      const form = { ...this.form.value };
      if (this.languageId && this.language_update) {
        form.languageId = this.languageId;
        this.dataHandlerService.updateData(form, LANGUAGES_PATH_KEY, this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_UPDATE_MESSAGE_KEY, SUCCESS_KEY);
          this.mainService.goBack(LANGUAGE_URL_KEY);
        });
      } else if (this.language_create) {
        this.dataHandlerService.createData(form, LANGUAGES_PATH_KEY, this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage(TR_CREATE_MESSAGE_KEY, SUCCESS_KEY);
          this.mainService.goBack(LANGUAGE_URL_KEY);
        });
      }
    } else {
      this.isInvalidSubmit = true;
    }
  }
}
