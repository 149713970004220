import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { AlertService } from 'src/app/Services/alert.service';
import { ICurrencyData } from 'src/app/Models/ICurrencyData';
import { CURRENCY_PATH_KEY } from 'src/app/Constants/request-path-keys';
import {
  CURRENCY_CREATE,
  CURRENCY_DELETE,
  CURRENCY_UPDATE,
  CURRENCY_VIEW,
  SUCCESS_KEY,
  TR_DELETE_MESSAGE_KEY,
  TR_CURRENCY_KEY
} from 'src/app/Constants/main-keys';
import { guid } from '@progress/kendo-angular-common';

@Component({
  selector: 'app-currency-table',
  templateUrl: './currency-table.component.html',
  styleUrls: ['./currency-table.component.scss']
})
export class CurrencyTableComponent implements OnInit {
  currency_view: boolean;
  currency_create: boolean;
  currency_update: boolean;
  currency_delete: boolean;
  deleteCurrencyId: number;
  deleteCurrencyName: string;
  userRoles: Array<string> = [];
  currencies: Array<ICurrencyData> = [];
  deleteSpinnerId: string = guid();

  constructor(
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    private translationService: TranslationService,
    private modalService: NgbModal,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    
    this.setRoles();
    this.translationService.setPageTitle(TR_CURRENCY_KEY);
    this.getCurrency();
  }

  setRoles() {
    this.userRoles.forEach(role => {
      switch (role) {
        case CURRENCY_VIEW:
          this.currency_view = true;
          break;
        case CURRENCY_CREATE:
          this.currency_create = true;
          break;
        case CURRENCY_UPDATE:
          this.currency_update = true;
          break;
        case CURRENCY_DELETE:
          this.currency_delete = true;
          break;
      }
    });
  }

  getCurrency() {
    this.dataHandlerService.getDataWithSpinner(CURRENCY_PATH_KEY).then(response => this.currencies = response);
  }

  confirmDelete(content, id: number, name: string): void {
    this.deleteCurrencyId = id;
    this.deleteCurrencyName = name;
    this.modalService.open(content).result;
  }

  delete(): void {
    if (!this.currency_delete) { return; }
    this.dataHandlerService.deleteData(this.deleteCurrencyId, CURRENCY_PATH_KEY, this.deleteSpinnerId).then(() => {
      this.alertService.translateAndAlertMessage(TR_DELETE_MESSAGE_KEY, SUCCESS_KEY);
      this.getCurrency();
      this.modalService.dismissAll();
    });
  }

}
