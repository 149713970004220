<div class="app-page-title">
  <div class="page-title-wrapper flex-wrap">
    <div class="page-title-heading">
      <div class="page-title-icon"><i class="icon pe-7s-user icon-gradient bg-plum-plate"></i></div>
      <div>{{ 'TR_USERS' | translate }}
        <page-main-info-component id="main_info" data-page-name="angular_admin_users">
        </page-main-info-component>
      </div>
    </div>
    <div class="page-title-actions"><button type="button" class="btn btn-success create-user align-items-center no-wrap"
        [title]="'TR_CREATEUSER' | translate" [routerLink]="['user-form']">
        <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_CREATE' | translate }}</span>
        <app-help-alert alertKey="user_create"></app-help-alert>
      </button>
      <button *ngIf="canSendMessage && send_message" type="button" class="mt-2 mr-2 btn btn-warning create-user"
        [title]="'TR_SENDMESSAGE' | translate" (click)="openMessageBox(message, null)">
        <i class="pe-7s-chat"> </i><span class="ml-2">{{ 'TR_SENDMESSAGE' | translate }}</span>
      </button>
    </div>
  </div>
</div>
<!-- <div class="title-block mb-3">
  <h4 class="card-title">{{ 'TR_USERS' | translate }}</h4>
</div> -->

<div class="row">
  <div class="col-md-6 col-sm-12 col-12">
    <div class="btn-block">
      <!-- <button type="button" class="mb-2 btn btn-success create-user-btn" [title]="'TR_CREATEUSER' | translate"
        [routerLink]="['user-form']">
        <i class="pe-7s-add-user"></i><span class="ml-2">{{ 'TR_CREATE' | translate }}</span>
        <app-help-alert alertKey="user_create"></app-help-alert>
      </button> -->

      <!-- <button *ngIf="canSendMessage && send_message" type="button" class="mb-2 mr-2 btn btn-warning create-user-btn"
        [title]="'TR_SENDMESSAGE' | translate" (click)="openMessageBox(message, null)">
        <i class="pe-7s-chat"> </i><span class="ml-2">{{ 'TR_SENDMESSAGE' | translate }}</span>
      </button> -->
    </div>
  </div>

  <div class="col-md-6 col-sm-12 col-12">
    <div class="filter-block">
      <div *ngIf="usertype_view" ngbDropdown class="mb-2">
        <button type="button" ngbDropdownToggle class="btn btn-primary">
          {{ selectedUserTypeName ? selectedUserTypeName : 'TR_USERTYPES' | translate }}
          <app-help-alert alertKey="users_list_sort"></app-help-alert>
        </button>
        <app-help-alert alertKey="filter-btn"></app-help-alert>
        <div ngbDropdownMenu>
          <button type="button" class="dropdown-item" (click)="getAllUsers()">{{ 'TR_ALL' | translate }}</button>
          <button type="button" class="dropdown-item" *ngFor="let item of userTypes"
            (click)="setSelectedUserType(item.id)">{{ item.displayName || item.name }}</button>
        </div>
      </div>

      <div ngbDropdown class="mb-2">
        <button type="button" ngbDropdownToggle class="btn btn-warning">{{ 'TR_USERS_PER_PAGE' | translate }} ({{
          pageSize }})</button>
        <div ngbDropdownMenu>
          <button type="button" class="dropdown-item" *ngFor="let item of pageSizeArr"
            (click)="setSelectedPageSize(item)">{{ item }}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="main-card mb-3 card">
  <div class="table-responsive">
    <table class="align-middle mb-0 table table-striped table-hover">
      <thead>
        <tr>
          <!-- <th>
            <input name="checkItem" type="checkbox" class="mt-2 pointer" [(ngModel)]="checkAll"
              (ngModelChange)="checkAllUsers()" />
            <app-help-alert alertKey="message-checkbox"></app-help-alert>
          </th> -->
          <th>#</th>
          <th>
            {{ 'TR_USERNAME' | translate }}
            <app-help-alert alertKey="mass_message_write"></app-help-alert>
          </th>
          <th>{{ 'TR_FULLNAME' | translate }}</th>
          <th>{{ 'TR_EMAIL' | translate }}</th>
          <th *ngIf="organization_view">{{ 'TR_ORGANIZATION' | translate }}</th>
          <th>{{ 'TR_USERTYPE' | translate }}</th>
          <th class="text-right">
            {{ 'TR_ACTIONS' | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="pointer" title="Click to see full data" *ngFor="let item of users; let i = index"
          (click)="userView($event.target, userview, item.id)">
          <!-- <td>
            <input name="checkItem" type="checkbox" class="mt-2 pointer" [(ngModel)]="item.checked"
              (ngModelChange)="getCheckboxValue(item.checked)" />
          </td> -->
          <td>{{ i + 1 }}</td>
          <td>{{ item.username }}</td>
          <td>{{ item.lastname }} {{ item.firstname }} {{ item.middlename }}</td>
          <td>{{ item.email }}</td>
          <td *ngIf="organization_view">{{ item.organizationName }}</td>
          <td>{{ item.userTypeName }}</td>
          <td class="text-right">
            <div role="group" class="btn-group-sm btn-group">
              <button class="btn-transition btn"
                [ngClass]="item.isActive ? 'btn-outline-success' : 'btn-outline-danger'"
                [title]="(item.isActive ? 'TR_DEACTIVATE' : 'TR_ACTIVATE') | translate"
                (click)="confirmActiveInactiveAction(activeInactiveContent, item.id)">
                <i [ngClass]="item.isActive ? 'pe-7s-check' : 'pe-7s-close-circle'"> </i>
                <div *ngIf="i == 0">
                  <app-help-alert alertKey="user_activate_deactivate"></app-help-alert>
                </div>
              </button>
              <button *ngIf="user_update" class="ml-1 btn-transition btn btn-outline-info"
                [title]="'TR_EDITUSERDATA' | translate" [routerLink]="['user-form', item.id]">
                <i class="pe-7s-note"> </i>
                <div *ngIf="i == 0">
                  <app-help-alert alertKey="user_data_edit"></app-help-alert>
                </div>
              </button>
              <button *ngIf="user_delete" class="ml-1 btn-transition btn btn-outline-danger"
                [title]="'TR_DELETE' | translate" (click)="confirmDelete(deleteContent, item.id)">
                <i class="pe-7s-trash"> </i>
              </button>
              <button *ngIf="send_message" class="ml-1 btn-transition btn btn-outline-pink"
                [title]="'TR_SENDMESSAGE' | translate" (click)="openMessageBox(message, item.id)">
                <i class="pe-7s-chat"> </i>
                <div *ngIf="i == 0">
                  <app-help-alert alertKey="message_write"></app-help-alert>
                </div>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="p-0 pt-3 text-center card-footer center">
    <ngb-pagination *ngIf="totalPages<5" [collectionSize]="totalPages*10" [(page)]="currentPage"
      aria-label="Default pagination" (click)="setPagination($event.target.textContent)"></ngb-pagination>
    <ngb-pagination *ngIf="totalPages==5 || totalPages>5" [collectionSize]="totalPages*10" [(page)]="currentPage"
      [maxSize]="4" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
      (click)="setPagination($event.target.textContent)"></ngb-pagination>
  </div>
  <div class="my-2"></div>
</div>

<ng-template #deleteContent let-c="close" let-d="dismiss">
  <div class="custom-modal modal-body">
    <p class="word-break-break-word">{{ 'TR_DELETE_ERROR' | translate }} {{ deleteUser.lastname }} {{
      deleteUser.firstname }} {{ deleteUser.middlename }} ? </p>
  </div>
  <div class="custom-modal modal-footer">
    <app-action-button [isCurrent]="true" [spinnerId]="userDeleteSpinnerId"
      buttonClasses="btn-transition btn btn-outline-primary" spinnerClasses="small-button-spinner"
      [buttonName]="'TR_YES'" (clicked)="delete();"></app-action-button>
    <button type="button" class="btn-transition btn btn-danger" (click)="c('Close click')">{{ 'TR_NO' | translate
      }}</button>
  </div>
</ng-template>

<ng-template #activeInactiveContent let-c="close" let-d="dismiss">
  <div class="custom-modal modal-body">
    <p class="word-break-break-word">{{ 'TR_ARE_YOU_SUTE' | translate }}
      {{ (isActivation ? 'TR_ACTIVATE' : 'TR_DEACTIVATE') | translate }}
      {{ activeInactiveUserData.lastname }} {{ activeInactiveUserData.firstname }} {{ activeInactiveUserData.middlename
      }}?
    </p>
  </div>
  <div class="custom-modal modal-footer">
    <app-action-button [isCurrent]="true" [spinnerId]="activationSpinnerId"
      buttonClasses="btn-transition btn btn-outline-primary" spinnerClasses="small-button-spinner"
      [buttonName]="'TR_YES'" (clicked)="triggerActivation()"></app-action-button>
    <button type="button" class="btn-transition btn btn-danger" (click)="c('Close click')">{{ 'TR_NO' | translate
      }}</button>
  </div>
</ng-template>

<ng-template #message let-c="close" let-d="dismiss">
  <div class="custom-modal modal-body">
    <textarea name="messageContent" cols="30" rows="10" class="textarea" [(ngModel)]="messageContent"
      [placeholder]="'TR_TYPEMESSAGE' | translate"></textarea>
  </div>
  <div class="custom-modal modal-footer">
    <app-action-button [isCurrent]="true" [spinnerId]="sendMessageSpinnerId"
      buttonClasses="btn-transition btn btn-outline-primary" spinnerClasses="small-button-spinner"
      [buttonName]="'TR_SEND'" (clicked)="sendMessage()"></app-action-button>
    <button type="button" class="mr-2 btn-transition btn btn-danger" (click)="c('Close click')">
      {{ 'TR_CANCEL' | translate }}</button>
  </div>
</ng-template>

<ng-template #userview let-c="close" let-d="dismiss">
  <app-user-view [userId]="userViewId" (closeEmit)="c('Close click')"></app-user-view>
</ng-template>