<ng-container *ngIf="isPageLoaded">
  <div class="title-block mb-3">
    <h4 class="card-title">{{ 'TR_FAILS' | translate }}</h4>
  </div>

  <div class="row">
    <div class="col-12">
      <div ngbDropdown>
        <button type="button" ngbDropdownToggle class="mb-2 btn btn-warning">{{ 'TR_USERS_PER_PAGE' | translate }} ({{ pageSize }})</button>
        <div ngbDropdownMenu>
          <button type="button" class="dropdown-item" *ngFor="let item of pageSizeArr" (click)="setSelectedPageSize(item)">{{ item }}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="main-card mb-3 card">
    <div class="table-responsive">
      <table class="align-middle mb-0 table table-striped table-hover">
        <thead>
          <tr>
            <th>{{ 'TR_WEBINARNAME' | translate }}</th>
            <th>{{ 'TR_FULLNAME' | translate }}</th>
            <th>{{ Url }}</th>
            <th>{{ 'TR_MESSAGE' | translate }}</th>
            <th>{{ 'TR_LINE' | translate }}</th>
            <th>{{ 'TR_DATE' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of fails">
            <td>{{ item.webinarName }}</td>
            <td>{{ item.currentUserName }}</td>
            <td>{{ item.URL }}</td>
            <td>{{ item.message }}</td>
            <td>{{ item.line }}</td>
            <td>{{ item.dateTime | translate }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="p-0 pt-3 text-center card-footer center">
      <ngb-pagination *ngIf="totalPages<70" [collectionSize]="totalPages" [(page)]="currentPage"
        aria-label="Default pagination" (click)="setPagination($event.target.textContent)"></ngb-pagination>
      <ngb-pagination *ngIf="totalPages>70 || totalPages==70" [collectionSize]="totalPages" [(page)]="currentPage"
        [maxSize]="4" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
        (click)="setPagination($event.target.textContent)"></ngb-pagination>
    </div>
  </div>
</ng-container>