<div class="app-page-title">
  <div class="page-title-wrapper flex-wrap">
    <div class="page-title-heading">
      <div class="page-title-icon"><i class="icon pe-7s-note2 icon-gradient bg-plum-plate"></i></div>
      <div>
        {{ 'TR_EDUCATIONAL_MATERIALS' | translate }}
        <page-main-info-component id="main_info" data-page-name="angular_mdw_documents">
        </page-main-info-component>
      </div>
    </div>
    <div *ngIf="document_create" class="page-title-actions d-flex"><button class="mr-2 btn btn-success create-btn no-wrap mt-2"
        [title]="'TR_CREATE_DOCUMENT' | translate" [routerLink]="['document-form']">
        <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_CREATE' | translate }}</span>
        <app-help-alert alertKey="document_template_create"></app-help-alert>
      </button>
    </div>
  </div>
</div>
<!-- <div class="title-block mb-3">
  <h4 class="card-title">{{ 'TR_EDUCATIONAL_MATERIALS' | translate }}</h4>
</div> -->
<!-- 
<div *ngIf="document_create" class="d-inline-block">
  <button class="mb-2 mr-2 btn btn-success create-btn" [title]="'TR_CREATE_DOCUMENT' | translate"
    [routerLink]="['document-form']">
    <i class="fa fa-plus"></i><span class="ml-2">{{ 'TR_CREATE' | translate }}</span>
    <app-help-alert alertKey="document_template_create"></app-help-alert>
  </button>
</div> -->

<!-- <div *ngIf="documentcategory_view" class="d-inline-block" ngbDropdown>
  <button type="button" ngbDropdownToggle class="mb-2 mr-2 btn btn-primary">
    {{ selectedDocumentCategoryName ? selectedDocumentCategoryName : 'TR_DOCUMENT_TYPE' | translate }}
  </button>
  <div ngbDropdownMenu>
    <button class="dropdown-item" (click)="getAllDocuments()">{{ 'TR_ALL' | translate }}</button>
    <button class="dropdown-item" *ngFor="let item of documentCategories" (click)="getDocumentsByCategory(item.id)">{{ item.name }}</button>
  </div>
</div> -->

<div class="row">

  <div *ngIf="documentcategory_view" class="col-lg-6 col-md-6 col-12">
    <div class="main-card mb-3 card">
      <div class="table-responsive">
        <table class="align-middle mb-0 table table-striped table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>
                {{ 'TR_DOCUMENT_TYPE' | translate }}
                <app-help-alert alertKey="list_of_documents_view"></app-help-alert>
              </th>
              <th>{{ 'TR_DESCRIPTION_OR_NUMBER' | translate }}</th>
              <!-- <th *ngIf="document_update" class="text-right">{{ 'TR_ACTIONS' | translate }}</th> -->
            </tr>
          </thead>
          <tbody>
            <tr class="pointer" [class.selected]="selectedCategoryId === category.id"
              (click)="getDocumentsByCategory(category.id)" *ngFor="let category of documentCategories let i = index">
              <td>{{ i + 1 }}</td>
              <td class="position-relative">
                {{ (category.name | translate) ? (category.name | translate) : category.name }}
                <div *ngIf="category.name === 'Other'">
                  <app-help-alert alertKey="new_document_category_create"></app-help-alert>
                </div>
              </td>
              <td>{{ (category.description | translate) ? (category.description | translate) : category.description }}
              </td>
              <!-- <td *ngIf="document_update" class="text-right">
                <div role="group" class="btn-group-sm btn-group">
                  <button *ngIf="category.id" class="btn-transition btn btn-outline-info"
                    [title]="'TR_CREATE_CURRENT_TYPE_DOCUMENT' | translate"
                    [routerLink]="['document-form/type', category.id]">
                    <i class="pe-7s-note"></i>
                  </button>
                </div>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div *ngIf="documents.length" class="col-lg-6 col-md-6 col-12">
    <div class="main-card mb-3 card">
      <div class="table-responsive">
        <table class="align-middle mb-0 table table-striped table-hover second-table">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ 'TR_DOCUMENT_NAME' | translate }}</th>
              <th>{{ 'TR_DESCRIPTION_OR_NUMBER' | translate }}</th>
              <th class="text-right position-relative">
                {{ 'TR_ACTIONS' | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="pointer" (click)="openSelectedDocumentView($event.target, templatedocumentview, item)"
              *ngFor="let item of documents; let i = index">
              <td>{{ i + 1 }}</td>
              <td class="word-break-break-word">{{ item.name }}</td>
              <td class="word-break-break-word">{{ item.description }}</td>
              <td class="text-right nowrap">
                <div role="group" class="btn-group-sm btn-group">
                  <button type="button" [title]="'TR_DOWNLOAD' | translate"
                    class="btn-transition btn btn-outline-success anchor-succes-btn">
                    <a *ngIf="item.filePath && !item.signFilePath" [href]="item.filePath + '/download'">
                      <i class="pe-7s-download"></i>
                    </a>
                    <a *ngIf="item.signFilePath" [href]="item.signFilePath + '/download'">
                      <i class="pe-7s-download"></i>
                    </a>
                    <a *ngIf="!item.filePath && !item.signFilePath" [routerLink]="['download', item.id]">
                      <i class="pe-7s-download"></i>
                    </a>
                    <div *ngIf="i == 0">
                      <app-help-alert alertKey="document_download"></app-help-alert>
                    </div>
                  </button>
                  <button *ngIf="document_update" type="button" [disabled]="item.signed"
                    [title]="'TR_UPDATE' | translate" class="ml-1 btn-transition btn btn-outline-info"
                    [routerLink]="['document-form', item.id]">
                    <i class="pe-7s-note"></i>
                    <div *ngIf="i == 0">
                      <app-help-alert alertKey="document_edit"></app-help-alert>
                    </div>
                  </button>
                  <button type="button" [title]="'TR_PRINT_OUT' | translate"
                    class="ml-1 btn-transition btn btn-outline-primary" [routerLink]="['print', item.id]">
                    <i class="pe-7s-print"></i>
                    <div *ngIf="i == 0">
                      <app-help-alert alertKey="document_print"></app-help-alert>
                    </div>
                  </button>
                  <button *ngIf="document_delete" type="button" [disabled]="item.signed"
                    [title]="'TR_DELETE' | translate" class="ml-1 btn-transition btn btn-outline-danger"
                    (click)="confirmDelete(deleteconfirm, item)">
                    <i class="pe-7s-trash"></i>
                    <div *ngIf="i == 0">
                      <app-help-alert alertKey="document_delete"></app-help-alert>
                    </div>
                  </button>
                  <button *ngIf="document_sign" type="button" [disabled]="item.signed" [title]="'TR_SIGN' | translate"
                    class="ml-1 btn-transition btn btn-outline-focus" [routerLink]="['subscribe', item.id]">
                    <i class="pe-7s-note2"></i>
                    <div *ngIf="i == 0">
                      <app-help-alert alertKey="document_sign"></app-help-alert>
                    </div>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #deleteconfirm let-c="close" let-d="dismiss">
  <div class="custom-modal modal-body">
    <p>{{ 'TR_DELETE_ERROR' | translate }} <b class="word-break-break-word">{{ deleteDocumentName }}</b>?</p>
  </div>
  <div class="custom-modal modal-footer">
    <app-action-button [isCurrent]="true" [spinnerId]="deleteSpinnerId"
      buttonClasses="btn-transition btn btn-outline-info" spinnerClasses="small-button-spinner" [buttonName]="'TR_YES'"
      (clicked)="deleteDocument()"></app-action-button>
    <button type="button" class="btn-transition btn btn-danger" (click)="c('Close click')">{{ 'TR_NO' | translate
      }}</button>
  </div>
</ng-template>

<ng-template #templatedocumentview let-c="close" let-d="dismiss">
  <div class="modal-header modal-list-header">
    <h5 class="modal-title word-break-break-word">{{ selectedDocument.name }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="c('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="selectedDocument.signFilePath">
      <div class="signed-image" [ngStyle]="{ 'background-image' : 'url(' + selectedDocument.signFilePath + ')' }">
      </div>
    </div>
    <div *ngIf="!selectedDocument.signFilePath && selectedDocument.filePath"
      class="flexable flex-column vertical-center mb-2">
      <div class="signed-image" [ngStyle]="{ 'background-image' : 'url(' + selectedDocument.filePath + ')' }"></div>
      <p class="right-mrg">{{ 'TR_UPLOADED_FILE' | translate }}</p>
      <button type="button" class="mr-2 btn-transition btn btn-outline-info download-btn">
        <a [href]="selectedDocument.filePath + '/download'" target="_blank" class="btn btn-default submit-button">
          {{ 'TR_DOWNLOAD' | translate }}
        </a>
      </button>
    </div>
    <div *ngIf="!selectedDocument.signFilePath && !selectedDocument.documentTemplateId && selectedDocument.data"
      [innerHTML]="selectedDocument.data" class="p-1"></div>
    <app-template-document-view *ngIf="!selectedDocument.signFilePath && selectedDocument.documentTemplateId"
      [templateContent]="selectedDocument.data" [templateId]="selectedDocument.documentTemplateId">
    </app-template-document-view>
  </div>
</ng-template>