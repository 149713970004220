import { Component, OnInit } from '@angular/core';
import { DataHandlerService } from 'src/app/Services/data-handler.service';
import { RolesService } from 'src/app/Services/roles.service';
import { TranslationService } from 'src/app/Services/translation.service';
import { AlertService } from 'src/app/Services/alert.service';
import {
  SUCCESS_KEY,
  TR_PAGES_KEY,
  FILE_LIBRARY_VIEW,
  FILE_LIBRARY_CREATE,
  FILE_LIBRARY_UPDATE,
  FILE_LIBRARY_DELETE,
  TR_VALID_FILE_MESSAGE_ERROR_KEY,
  ERROR_KEY,
} from 'src/app/Constants/main-keys';
import { guid } from '@progress/kendo-angular-common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MainService } from 'src/app/Services/main.service';
import { VALID_IMAGE_TYPES_KEY, VALID_FILE_TYPES_KEY, ADMIN_KEY } from './../../../Constants/main-keys';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from 'src/app/Services/language.service';

@Component({
  selector: 'app-file-library-form-table',
  templateUrl: './file-library-form.component.html',
  styleUrls: ['./file-library-form.component.scss']
})

export class FileLibraryForm implements OnInit {

  submitSpinnerId: string = guid();
  userRoles: Array<string> = [];
  fileId: number;
  form: FormGroup;
  selectedFolderName: string;
  selectedFolderId: number;
  isInvalidSubmit: boolean = false;
  folderName: string;
  uploadedFile: any;
  isImage: boolean;
  isVideo: boolean;
  isFile: boolean;
  fileLibrary: Array<string> = [];
  folders: Array<string> = [];
  isCreateForm: boolean = true;
  // categoryId: number = null;
  file_library_view: boolean;
  file_library_create: boolean;
  file_library_update: boolean;
  file_library_delete: boolean;
  nameMaxLength: number = 200;

  constructor(
    private formBuilder: FormBuilder,
    private dataHandlerService: DataHandlerService,
    private rolesService: RolesService,
    private translationService: TranslationService,
    private alertService: AlertService,
    public mainService: MainService,
    private route: ActivatedRoute,
    private router: Router,
    private languageService: LanguageService,
  ) { }

  ngOnInit() {
    this.userRoles = this.rolesService.userRoles;
    this.translationService.setPageTitle(TR_PAGES_KEY);

    this.setRoles();
    this.getFolders();

    this.route.paramMap.subscribe(params => {
      this.fileId = +params.get('item.id');

      if (this.fileId) {
        this.isCreateForm = false;
      }
    });

    this.form = this.formBuilder.group({
      name: ['', Validators.maxLength(this.nameMaxLength)],
      fileEntity: ['', Validators.required],
      categoryId: [null, Validators.required],
    });

  }

  setRoles() {
    this.userRoles.forEach(role => {
      switch (role) {
        case FILE_LIBRARY_VIEW:
          this.file_library_view = true;
          break;
        case FILE_LIBRARY_CREATE:
          this.file_library_create = true;
          break;
        case FILE_LIBRARY_UPDATE:
          this.file_library_update = true;
          break;
        case FILE_LIBRARY_DELETE:
          this.file_library_delete = true;
          break;
      }
    });
  }

  getFolders() {
    if (!this.file_library_view) { return; }
    this.dataHandlerService.getDataWithSpinner("category/bytype/1").then(response => {
      const data = [...response];
      this.setFolders(data)
    });
  }

  setFolders(array: any) {
    array.forEach((element: any) => {
      this.folders.push(element);
      if (element.children && element.children) {
        this.setFolders(element.children);
      }
    });
  }

  selectParentFolder(name: string, id: number): void {
    if (!name || !id) { return; }
    this.selectedFolderName = name;
    this.form.patchValue({ categoryId: id });
  }

  async handleFileUpload(event: any) {
    const files = event.target.files;
    if (files && files[0]) {
      this.mainService.fileLibraryUpload(files[0]).then((response: any) => {
        this.uploadedFile = response.result;
        this.form.patchValue({ fileEntity: files[0] });
        const fileExtention = response.fileExtention;
        this.isImage = VALID_IMAGE_TYPES_KEY.includes(fileExtention);
        this.isFile = VALID_FILE_TYPES_KEY.includes(fileExtention);
      });
    }
  }

  goBack(): void {
    this.router.navigate([this.languageService.currentLanguage, ADMIN_KEY, "document", "file-library"]);
  }

  submitData(): void {
    if (this.form.valid) {
      const form = { ...this.form.value };
      if (!form.categoryId || !form.name.trim().length || !form.fileEntity) {
        this.isInvalidSubmit = true;
        return;
      }
      const formData = new FormData();
      for (let i in form) {
        formData.append(`${i}`, form[i]);
      }
      if (this.fileId) {
        formData.append('id', this.fileId.toString());
        this.dataHandlerService.updateFormData(formData, "filelibrary", this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage("TR_DATA_SAVED", SUCCESS_KEY);
          this.router.navigate([this.languageService.currentLanguage, ADMIN_KEY, "document", "file-library"]);
        });
      } else {
        this.dataHandlerService.createFormData(formData, "filelibrary", this.submitSpinnerId).then(() => {
          this.alertService.translateAndAlertMessage("TR_DATA_SAVED", SUCCESS_KEY);
          this.router.navigate([this.languageService.currentLanguage, ADMIN_KEY, "document", "file-library"]);
        });
      }
    } else {
      this.isInvalidSubmit = true;
    }
  }

}
